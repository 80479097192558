import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { authenticationActions } from "../../reducers/authenticationReducers";

import imageToBase64 from "image-to-base64/browser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";

import { ApiServices } from "../../api/ApiServices";
import axios from "../../api/axios";

import InputContainer from "../../common/inputs/InputContainer";
import MyProfile from "./MyProfile";
import "./MyProfile.css";

import ProfilePicture from "../../images/profile-picture.jpg";

const RegistrationDetails = () => {
  const dispatch = useDispatch();
  const [infoUdated, SetInfoUpdated] = useState({
    picture: false,
    details: false,
  });
  const LoggedInfo = useSelector((state) => state.authentication);

  const [userId, SetUserId] = useState(LoggedInfo.userInfo.id);
  const [firstName, SetFirstName] = useState({
    value: LoggedInfo.userInfo.firstname,
    error: false,
    errorText: "",
  });
  const [lastName, SetLastName] = useState({
    value: LoggedInfo.userInfo.lastname,
    error: false,
    errorText: "",
  });
  const [email, SetEmail] = useState({
    value: LoggedInfo.userInfo.email,
    error: false,
    errorText: "",
  });
  const [mobile, SetMobile] = useState({
    value: LoggedInfo.userInfo.phone1,
    error: false,
    errorText: "",
  });
  const [profilePicture, SetProfilePicture] = useState({
    img: LoggedInfo.userInfo.profileimageurl,
  });

  const FirstNameHandler = (event) => {
    SetFirstName({ value: event.target.value, error: false });
    SetInfoUpdated({ picture: false, details: false });
  };

  const LastNameHandler = (event) => {
    SetLastName({ value: event.target.value, error: false });
    SetInfoUpdated({ picture: false, details: false });
  };
  const EmailHandler = (event) => {
    SetEmail({ value: event.target.value, error: false });
    SetInfoUpdated({ picture: false, details: false });
  };

  const MobileHandler = (event) => {
    SetMobile({ value: event.target.value, error: false });
    SetInfoUpdated({ picture: false, details: false });
  };

  const UpdateFormHandler = (event) => {
    event.preventDefault();
    if (firstName.value === "") {
      SetFirstName({
        ...firstName,
        error: true,
        errorText: "Enter your name",
      });
    }
    if (lastName.value === "") {
      SetLastName({
        ...lastName,
        error: true,
        errorText: "Enter your name",
      });
    }
    if (email.value === "") {
      SetEmail({
        ...email,
        error: true,
        errorText: "Enter your email id",
      });
    }
    if (mobile.value === "") {
      SetMobile({
        ...mobile,
        error: true,
        errorText: "Enter your mobile number",
      });
    }

    if (
      firstName.value !== "" &&
      lastName.value !== "" &&
      email.value !== "" &&
      mobile.value !== ""
    ) {
      axios
        .post(
          `${ApiServices(
            "updateuser"
          )}&users[0][id]=${userId}&users[0][firstname]=${
            firstName.value
          }&users[0][lastname]=${lastName.value}&users[0][email]=${
            email.value
          }&users[0][phone1]=${mobile.value}`
        )
        .then((response) => {
          if (response.status == 200) {
            SetInfoUpdated({ details: true, picture: false });
            axios
              .post(
                `${ApiServices("getuser")}&values[0]=${
                  LoggedInfo.userInfo.username
                }`
              )
              .then((responseUser) => {
                if (responseUser.data[0]) {
                  dispatch(
                    authenticationActions.login({
                      token: LoggedInfo.userInfo.token,
                      ...responseUser.data[0],
                    })
                  );
                }
              });
          }
        });
    }
  };

  const ProfilePictureHandler = (event) => {
    let base64Content;
    SetProfilePicture({ img: URL.createObjectURL(event.target.files[0]) });
    imageToBase64(URL.createObjectURL(event.target.files[0])) // Path to the image
      .then((response) => {
        base64Content = response;

        axios
          .post(
            `${ApiServices(
              "fileupload"
            )}&filepath=/&filename=profile.jpg&itemid=${
              Math.floor(Math.random() * 9999) + 1
            }&filecontent=${base64Content}`
          )
          .then((response) => {
            if (response.data.itemid) {
              axios
                .post(
                  `${ApiServices("updateprofilepicture")}&draftitemid=${
                    response.data.itemid
                  }&userid=${userId}`
                )
                .then((response) => {
                  if (response.data.success) {
                    SetInfoUpdated({ details: false, picture: true });
                    axios
                      .post(
                        `${ApiServices("getuser")}&values[0]=${
                          LoggedInfo.userInfo.username
                        }`
                      )
                      .then((responseUser) => {
                        if (responseUser.data[0]) {
                          dispatch(
                            authenticationActions.login({
                              token: LoggedInfo.userInfo.token,
                              ...responseUser.data[0],
                            })
                          );
                        }
                      });
                  }
                });
            }
          });
      })
      .catch((error) => {
        console.log(error); // Logs an error if there was one
      });
  };

  return (
    <MyProfile active="Registration Details">
      <div className="col-md-12 d-flex">
        <div className="col-md-3 text-center">
          <img
            src={profilePicture.img}
            alt="Profile Icon"
            className="profile-picture"
          />
          <div className="upload-picture">
            <FontAwesomeIcon icon={faPencilAlt} className="upload-icon" />
            <input
              type="file"
              className="upload-input"
              onChange={ProfilePictureHandler}
              accept="image/*"
            />
          </div>
        </div>
        <div className="col-md-9">
          {infoUdated.picture && (
            <p className="success-response">
              Profile picture updated successfully
            </p>
          )}
          {infoUdated.details && (
            <p className="success-response">
              Profile details updated successfully
            </p>
          )}
          <form onSubmit={UpdateFormHandler}>
            <div className="d-flex">
              <InputContainer
                type="text"
                name="firstname"
                labelName="First Name"
                labelExtra=""
                labelClass="c-green"
                placeholder="Enter your first name"
                onChange={FirstNameHandler}
                value={firstName.value}
                error={firstName.error}
                errorText={firstName.errorText}
                className="m-10-0 w-50 pr-10"
              />
              <InputContainer
                type="text"
                name="lastname"
                labelName="Last Name"
                labelExtra=""
                labelClass="c-green"
                placeholder="Enter your last name"
                onChange={LastNameHandler}
                value={lastName.value}
                error={lastName.error}
                errorText={lastName.errorText}
                className="m-10-0 w-50 pl-10"
              />
            </div>
            <div>
              <InputContainer
                type="text"
                name="email"
                labelName="Email ID"
                labelExtra=""
                labelClass="c-green"
                placeholder="Enter your email ID"
                onChange={EmailHandler}
                value={email.value}
                error={email.error}
                errorText={email.errorText}
                className="m-10-0"
              />
            </div>
            <div>
              <InputContainer
                type="text"
                name="mobile"
                labelName="Mobile Number"
                labelExtra=""
                labelClass="c-green"
                placeholder="Enter your mobile number"
                onChange={MobileHandler}
                value={mobile.value}
                error={mobile.error}
                errorText={mobile.errorText}
                className="m-10-0"
              />
            </div>
            <div className="pl-50">
              <button className="button save" type="submit">
                Update
              </button>
            </div>
          </form>
        </div>
      </div>
    </MyProfile>
  );
};

export default RegistrationDetails;
