import React, { useState } from 'react';
import './css/grade_1.scss';
import './css/border.scss';
import $ from 'jquery';
import axios from "../../api/axios";
import Modal from 'react-modal';
import * as type from "../../urls";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Tree1 from './images/tree/Tree_Full.png';
import Flower from './images/flower.png';
import { useEffect } from 'react';
import { faCheckCircle, faStar } from '@fortawesome/free-solid-svg-icons';

import StudyMaterialImg from "../grades/images/Materials.png";
import PracticeImg from "../grades/images/Practice.png";
import NcrtImg from "../grades/images/ncrt.png";
import videoImg from "../grades/images/video.png";

import NoVideoImg from "../grades/images/no-video.png";
import NoWorksheetImg from "../grades/images/No-worksheet.png";
import { Button } from 'react-bootstrap';

const GradeListOne = (props) => {
    const customStyles = {
      content: {
        background: "linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab)",
        "background-size": "400% 400%",
        animation: "gradient 10s ease infinite",
      },
    };
    const [subTitle, setSubTitle] = useState('');
    const [selectedChapter, setSelectedChapter] = useState('');
    const [interactiveContentURL, setInteractiveContentURL] = useState('');
    const [h5pList, setH5pList] = useState([]);
    const [scormList, setScormList] = useState([]);
    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [dataList, setDataList] = useState([]);
    const [fileContentList, setFileContentList] = useState([]);
    const childToken = JSON.parse(localStorage.getItem("childToken"));
    const userId = childToken.childId;
    const images = require.context('./images', true);
    const paths = require.context('./images/path', true);
    console.log('subj', props?.subjectList);
    const handleClick = (event) => {
        $('.grade-canvas').addClass('jumb-class');
        let selId = event.currentTarget.id.split('-')[1];
        console.log(event.currentTarget, selId);
        setSelectedChapter(selId);
        //drawLine(selId);
        setTimeout(() => {
            $('.grade-canvas').removeClass('jumb-class');
            $('.grade-canvas').addClass('hide-bg');
            $('.detail-list-view').removeClass('hide');
        }, 1000);
    }

    

    function openModal() {
      setIsOpen(true);
    }
  
    function afterOpenModal() {
      // references are now sync'd and can be accessed.
      //subtitle.style.color = '#f00';
    }
  
    function closeModal() {
      setIsOpen(false);
    }

    const drawLine = (selId) => {
      var canvas = document.getElementById("myCanvas");
      let sel_id = document.getElementById('house-' + selId);
      var ctx = canvas.getContext("2d");
      console.log('offset', sel_id.offsetLeft, sel_id.offsetTop);
      console.log('offset1',sel_id.nextSibling.offsetLeft, sel_id.nextSibling.offsetTop);
      ctx.moveTo(0,0);
      ctx.lineTo(200,300);
      // ctx.moveTo(sel_id.offsetLeft, sel_id.offsetTop);
      // ctx.lineTo(sel_id.nextSibling.offsetLeft, sel_id.nextSibling.offsetTop);
      ctx.stroke();
    }

    useEffect(() => {
        getContentDetails('sm');
    }, [selectedChapter]);

    useEffect(() => {
        loadScormContent('ws');
    }, [scormList])

    const selectTopics = (event) => {
      var type = event.currentTarget.id;
      console.log(type);
      let temp_type_val = '';
        setInteractiveContentURL('');
        switch(type) {
          case "quiz_menu":
            temp_type_val = "qz";
            setSubTitle('Quiz');
            break;

          case "solved_menu":
            temp_type_val = "bs";
            setSubTitle('Book Solution');
            break;
          case "ncert_menu":
            temp_type_val = "nc";
            setSubTitle('NCERT');
            break;
          case "inter_menu":
            temp_type_val = "is";
            break;
          case "topics_menu":
            temp_type_val = "sm";
            setSubTitle('Study Materials');
            console.log('Study Materials');
            break;
          case "pract_menu":
            temp_type_val = "ws";
            setSubTitle('Worksheet');
            break;
          case "video_menu":
            temp_type_val = 'vm';
            setSubTitle('Video');
            break;
          default:
            break;
        }
        loadScormContent(temp_type_val);
        openModal();
        //window.location.href = `#/courses/${selectedChapter}?&chapter=${selectedChapter}&view= + ${event.currentTarget.id.split('_')[1]}`;
    }
    const getData = (dataList, type, optType) => {
        let data = []; 
        if (dataList && dataList.length > 0) {
          dataList.forEach((content) => {
            let fileName_arr = [];
            if (type === 'scorm' && dataList.length && dataList[0].filename) {
              fileName_arr = content?.filename.split(".")[0]?.split("_");
            } else if (type === 'content') {
              fileName_arr = content.name.split(".")[0]?.split("_");
            } else if (type === 'video') {
              content?.contentfiles.forEach((cf) => {
                if (cf?.mimetype === 'video/mp4') {
                  data = cf;
                }
              })
            } else {
              fileName_arr = content?.name?.split("_");
            }
    
            if (
              fileName_arr && fileName_arr.length && fileName_arr[fileName_arr.length - 1].toLowerCase() ===
              optType.toLowerCase()
            ) {
              data = content;
            }
    
    
          });
        }
        return data;
    }

    const getScormContentList = (type) => {
        axios
          .get(
            `${process.env.REACT_APP_MOODLE_URL}webservice/rest/server.php?wstoken=${window.token}&moodlewsrestformat=json&wsfunction=local_custom_webservice_scormuserattemp&userid=${userId}&courseid=${selectedChapter}`
          )
          .then((res) => {
            setScormList(res?.data?.scormuserattempdetails);
            console.log(res?.data);
            getH5PContentList(type);
          });
      }
    
    const getH5PContentList = (type) => {
        axios
          .get(
            `${process.env.REACT_APP_MOODLE_URL}webservice/rest/server.php?wstoken=${window.token}&moodlewsrestformat=json&wsfunction=mod_h5pactivity_get_h5pactivities_by_courses&courseids[0]=${selectedChapter}`
          )
          .then((res) => {
            setH5pList(res?.data?.h5pactivities);
            
          });
      }
    
    const getContentDetails = (type) => {
        axios
          .get(
            `${process.env.REACT_APP_MOODLE_URL}webservice/rest/server.php?wstoken=${window.token}&moodlewsrestformat=json&wsfunction=mod_resource_get_resources_by_courses&courseids[0]=${selectedChapter}`
          )
          .then((res) => {
            console.log(res.data.resources);
            setDataList(res?.data?.resources)
            getScormContentList("sm");
          });
    }

    const getVideoContent = (id) => {
        //let childToken = JSON.parse(localStorage.getItem("childToken"));
        axios.get(type.GetVideoContent(window.token, id)).then((res) => {
            setFileContentList(res?.data);
        })
      }

    const loadScormContent = (type) => {
        let childToken = JSON.parse(localStorage.getItem("childToken"));
        let flag = false;
        let url = '';
        let content = getData(dataList, 'content', type);
        let h5pContent = getData(h5pList, 'h5p', type);
        let data = getData(scormList, 'scorm', type);
        // let video_content = this.getVideoData(fileContentList, 'video', type);
        if (content && content.contentfiles && content.contentfiles.length) {
          let fileArray = content?.contentfiles[0]?.fileurl;
          url = `${process.env.REACT_APP_PDFJS_URL}${fileArray}?token=${process.env.REACT_APP_MOODLE_TOKEN}`;
          flag = true;
        } else if (data && data.scormid) {
          url = `${process.env.REACT_APP_MOODLE_URL}scorm_view/index.html?wstoken=${window.token}&scoid=${data.scoid}&currentorg=${data.currentorg}&attempt=${data.attempt}&scormid=${data.scormid}&a=${data.a}&sesskey=${data.sesskey}&attemptcount=${data.attemptcount}&moodleUrl=${process.env.REACT_APP_MOODLE_URL}`;
          flag = true;
        } else if (h5pContent && h5pContent.package && h5pContent.package[0] && h5pContent.package[0].fileurl) {
          url = `${process.env.REACT_APP_MOODLE_URL}h5p/embed.php?url=${h5pContent.package[0].fileurl}&token=${process.env.REACT_APP_MOODLE_TOKEN}&preventredirect=1&component=mod_h5pactivity&embed=1`;
          flag = true;
        // } else if (video_content) {
        //   // console.log('video', video_content, this.state.dataList);
        //   if (video_content?.fileurl) {
        //     url = video_content?.fileurl + `&token=${childToken?.token}`;
        //   }
          
        //   if (url) {
        //     flag = true;
        //   } else {
        //     flag = false;
        //   }
          
        } else {
          flag = false;
        }
    
        if (flag) {
            setInteractiveContentURL(url);
            setTimeout(() => {
                $("#quiz_menu_load").show();
                $(".loader").hide();
            }, 1500);
        } else {
            $("#no-data").show();
        }
      }
    const closePopover = () => {
        $('.grade-canvas').removeClass('hide-bg');
        $('.detail-list-view').addClass('hide');
    }
    
    const completedTask = () => {
      closePopover();
      document.getElementById('house-' + selectedChapter).classList.add("completed");
      document.getElementById('house-' + selectedChapter).nextSibling.classList.add("house-active");
      // setTimeout(() => {
      //   document.getElementById('img-wow').classList.add('hide');
      // }, 100);
      
      props.completedTask();
    }

    // const panorama = new PANOLENS.ImagePanorama( PanormaImg );
    // const viewer = new PANOLENS.Viewer();
    // viewer.add( panorama );
    const handleMouseMove = (e) => {
        var left = $('.panorama').offset().left;
        var width = $('.panorama').width();
        var offset = e.pageX - left;
        var percentage = offset / width * 100;

        $('.panorama').css('background-position', percentage + '% 0');
    }
    return (
      
        <div className="grade-list">
          
          <div className="grade-canvas image-2">
              
              <img className=" tree-1" src ={Tree1} />
              <img className=" flower-1" src ={Flower} />
              {/* <img className=" path" src ={Path} /> */}
              {props.subjectList && props.subjectList.slice(0,10).map((d, i) => (
                  <div id={"house-"+ d?.id} onClick={handleClick} className={i === 0 ? "house  house-" + (i+1) + " house-active" : "house  house-" + (i+1) }>
                      <img src={images(`./house_${i+1}.png`).default} />
                      <FontAwesomeIcon className='done' icon={faCheckCircle} />
                      {/* <img id='img-wow' src={WoWGif} /> */}
                      <div className='img-wow'>
                        <FontAwesomeIcon icon={faStar} class="checked"/>
                        <FontAwesomeIcon icon={faStar} class="checked"/>
                        <FontAwesomeIcon icon={faStar} class="checked"/>
                      </div>
                  </div>
              ))}
                 
            </div>
            
            {props.gamemode && 
                // <div className=" detail-list-view  hide">
                //     {/* <span>+</span> */}
                //     <span onClick={closePopover} className="close-icon">X</span>
                //     <div className="col-md-2 sub-menu-content">
                //         <div id="topics_menu" onClick={selectTopics} className="sub-menu-icon-wrapper">
                //             <img  src={StudyMaterialImg} className="sub-menu-icon" />
                //             <p> Study Material</p>
                //         </div>
                //         <div id="ncrt_menu"  onClick={selectTopics} className="sub-menu-icon-wrapper">
                //             <img src={NcrtImg} className="sub-menu-icon" />
                //             <p> Book Solution</p>
                //         </div>
                //         <div id="quiz_menu"  onClick={selectTopics} className="sub-menu-icon-wrapper">
                //             <img src={NcrtImg } className="sub-menu-icon" /> 
                //             <p> NCERT Book</p>
                //         </div>
                //         <div id="book_menu"   onClick={selectTopics} className="sub-menu-icon-wrapper">
                //             <img src={videoImg} className="sub-menu-icon" />
                //             <p> Video</p>
                //         </div>
                //         <div id="book_menu"   onClick={selectTopics} className="sub-menu-icon-wrapper">
                //             <img src={PracticeImg} className="sub-menu-icon" />
                //             <p> Worksheet</p>
                //         </div>
                        
                //     </div> 
                //     <div className="col-md-8 content-wrapper content-wrapper-quiz">
                //         {/* <img
                //         onClick={this.toggleExpand.bind(this)}
                //         className="expand-img"
                //         src={expandImg}
                //         /> */}
                //         <div className="quiz-content-inner">
                //         {/* <h3 className="header">{this.state.interactiveHeader}</h3> */}
                //         {interactiveContentURL && (
                //             <iframe
                //             id="quiz_menu_load"
                //             src={interactiveContentURL}
                //             key={interactiveContentURL}
                //             />
                //         )}
                //         {!interactiveContentURL && (
                //             <div id="no-data" className="hide">
                //                 <img src={NoWorksheetImg} />
                //             </div>
                //         )}
                //         {/* <iframe src={`http://20.198.89.85:8081/moodle/mod/hvp/embed.php?id=111&secret=${window.token}&user_id=testuser@xyz.com`} /> */}
                //         </div>
                //     </div>
                //     <div onClick={completedTask} className='mark-completion'>Mark as Completed</div>
                    
                // </div>
                <div className='detail-list-view hide'>
                  <span onClick={closePopover} className="close-icon">X</span>
                  <div className='detail-view'>
                      <button id="topics_menu"  onClick={selectTopics.bind(this)} className=" event-1 event-btn active"> 
                        <img  src={StudyMaterialImg} className="sub-menu-icon" />
                        <p> Study Material</p>
                      </button>
                      <button id="ncrt_menu"  onClick={selectTopics.bind(this)} className=" event-2 event-btn">
                        <img src={NcrtImg} className="sub-menu-icon" />
                        <p> Book Solution</p>
                      </button>
                      <button  id="quiz_menu"  onClick={selectTopics.bind(this)} className=" event-3 event-btn">
                        <img src={NcrtImg } className="sub-menu-icon" />
                        <p> NCERT Book</p>
                      </button>
                      <button id="video_menu" onClick={selectTopics.bind(this)} className=" event-4 event-btn">
                        <img src={videoImg} className="sub-menu-icon" />
                        <p> Video</p>
                      </button>
                      <button id="book_menu" onClick={selectTopics.bind(this)} className=" event-5 event-btn">
                        <img src={PracticeImg} className="sub-menu-icon" />
                        <p> Worksheet</p>
                      </button>
                  </div>
                  <div>
                    <Modal
                      isOpen={modalIsOpen}
                      onAfterOpen={afterOpenModal}
                      onRequestClose={closeModal}
                      style={customStyles}
                      contentLabel="Example Modal"
                    >
                      <div className="col-md-12 d-flex">
                        <h2 className='col-md-11 sub-title-modal'>{subTitle}</h2>
                        <span onClick={closeModal} className="col-md-5  close-icon">X</span>
                      </div>
                      
                      <div className="col-md-12 content-wrapper content-wrapper-quiz">
                        <div className="quiz-content-inner">
                        {interactiveContentURL && (
                            <iframe
                            id="quiz_menu_load"
                            src={interactiveContentURL}
                            key={interactiveContentURL}
                            />
                        )}
                        {!interactiveContentURL && (
                            <div id="no-data" className="hide">
                                <img src={NoWorksheetImg} />
                            </div>
                        )}
                         <Button>Cancel</Button>
                        <Button>Mark as Completed</Button>
                        </div>
                      </div>
                    </Modal>
                  </div>    
                </div>
                
                
            }



            {!props.gamemode &&
                <div onMouseOver={handleMouseMove.bind(this)} className=" detail-list-view  panorama hide">
                    <span onClick={closePopover} className="close-icon">X</span>
                    <span className="txt-message">Comming Soon !..</span>
                </div>
            }
            
        </div>
        
    )
}

export default GradeListOne;