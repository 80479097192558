import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";

// Messages
import Messages from './Messages';

//API
import axios from "../../api/axios";
import { ApiServices } from "../../api/ApiServices";
import * as type from "../../urls";

// Header
import SubjectHeader from "../common/subjectHeader.jsx";
import "../gradeList/gradelist.scss";
import "../grades/chapter.scss";

// CSS
import "./Discussion.scss";

// Images
import LogoImg from "../../images/Logoicon.png";
import ChapterCountActive from "./images/chapter-count-active.svg";
import ChapterCount from "./images/chapter-count.svg";
import DiscussionImage from "./images/discussion-image.jpg";
import LikeImage from "./images/like.svg";
import Reply from "./images/reply.svg";
import GroupImage from "../../images/group-image.svg";
import advertisement from "./images/ad.png";

const Discussion = () => {
  const history = useHistory();
  const params = useParams();

  const [gradeId, SetGradeId] = useState([]);
  const [chatInfo, SetChatInfo] = useState({});
  const [courseInfo, SetCourseInfo] = useState({});
  const [MainPost, SetMainPost] = useState([]);
  const [chatMember, SetChatMember] = useState(0);
  const [RecentMessages, SetRecentMessages] = useState([]);
  const [userInfo, SetUserInfo] = useState({});

  // Redux Variables
  // const userInfo = useSelector((state) => state.childInfo);
  const subjectList = useSelector((state) => state.subjects.data);


  const navigationChapterGroup = () => { };

  useEffect(() => {
    if (userInfo.token && userInfo.childId) {
      axios.get(`${ApiServices("getChatInfoBasedOnId")}${userInfo.token}&userid=${userInfo.childId}&conversationid=${params.id}`).then((response) => {
        console.log();
        SetMainPost(response.data);
      });
    }
  }, [params.id, userInfo]);


  useEffect(() => {
    let localVar = JSON.parse(localStorage.getItem("childToken"))
    if (localVar && localVar.id && localVar != "") {
      SetUserInfo(localVar)
    }
  }, [])

  const GoToCommunityHandler = () => {
    history.push("../community");
  };

  const GradeInfo = (value) => {
    if (value == 1) {
      return '1st';
    }
    if (value == 2) {
      return '2ndst';
    }
    if (value == 3) {
      return '3rd';
    }
    return `${value}th`
  }

  const recentMessageHandler = (allPosts) => {
    SetRecentMessages(allPosts);
  }

  console.log(MainPost);
  return (
    <main className="community-section">
      <div className="course-container">
        <SubjectHeader
          headerColour="red-background"
          profileColor="red"
        />
      </div>
      <div className="community-content discussion-content">
        <div className="w-100">

          <p
            className="back-to-community"
            onClick={() => {
              GoToCommunityHandler();
            }}
          >
            Back
          </p>
        </div>
        <div className="discussion-item d-inline-flex">
          <div className="d-grid discussion-details">
            <p className="discussion-name">{MainPost.name}</p>
            <div className="discussion-creator-container">
              <div className="creator-picture-container">
                <img
                  src={MainPost.imageurl ? MainPost.imageurl : GroupImage}
                  alt="Children Profile Picture"
                  className="creator-picture"
                />
              </div>
              <div className="creator-info-container">
                {/* <p className="creator-name">Started by Nitin Sliver</p>
                <div className="discussion-item-info-container">
                  <p className="discussion-item-status">{chatInfo.status}</p>
                  <p className="discussion-item-created-on">
                    Created on {new Date().toLocaleDateString()}
                  </p>
                </div> */}
              </div>
            </div>
            <div className="d-flex discussion-count">
              <div className="d-flex like-container">
                {/* <p className="creator-grade">{GradeInfo(userInfo.grade)} Grd, Maths</p> */}
              </div>
              <div className="d-flex align-items-center">
                <img
                  src={ChapterCountActive}
                  alt="Chapter Count Icon"
                  className="chapter-count-icon"
                />
                <p className="chapter-count">{MainPost.membercount} members</p>
              </div>
            </div>
          </div>
        </div>
        <div className="members-detail-container d-flex">
          <div className="col-12 col-sm-12 col-md-9 p-0">
            <div className="discussion-content-container mh-52-vh">
              {params.id && <Messages key={params.id} id={params.id} courseInfo={courseInfo} recentMessage={recentMessageHandler} />}
            </div>
          </div>
          <div className="d-none d-xs-none d-sm-none d-md-block col-md-3 p-0">
            <div className="members-list-container">
              <p className="recent-messages">Members</p>
              <div className="member-item-list">
                {RecentMessages.length > 0 &&
                  RecentMessages.map((user, i) => {
                    if (user.id) {
                      return (
                        <div className="member-item-container d-flex" key={i}>
                          <div className="col-md-2 p-0 text-center child-picture-container">
                            <FontAwesomeIcon
                              icon={faCircle}
                              className="active-icon"
                            />
                            <img
                              src={user.profileimageurlsmall}
                              alt="Children Profile Picture"
                              className="child-profile-picture"
                            />
                          </div>
                          <div className="col-md-9 p-0 child-info-container">
                            <p className="child-name">{user.fullname}</p>
                            <p className="child-message">
                              {user.message}
                            </p>
                          </div>
                        </div>
                      );
                    }
                  })}
              </div>
            </div>
            <div className="members-list-container ads-container">
              <img src={advertisement} alt="Google Ads" className="ads" />
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Discussion;
