import React, {useState, useEffect} from 'react';
import { GetToken } from "../../api/ApiServices";

import axios from "../../api/axios";

import SubjectHeader from '../common/subjectHeader.jsx';

import * as type from "../../urls";
import $ from 'jquery';

import "./rewards.scss";

import RewardImg from "./images/reward.png";
import RewardImg2x from "./images/reward@2x.png";
import RewardImg3x from "./images/reward@3x.png";

const RewardView = () => {

    const [subjectContent, setSubjectContent] = useState([]);
    const [rewardValues, setRewardValues] = useState([]);
    useEffect(() => {
        getSubject(6);
        getRewardsValue();
    }, []);

    const navigationChapterGroup = (event) => {
        window.location.href = `#/courses/${event.currentTarget.id}?chapter=${event.currentTarget.id}`;
        //this.setState({chapterContent: GetChapterContent(event.currentTarget.id)});
    } 
    const getRewardsValue = () => {
        var bodyFormData = new FormData();
        bodyFormData.append('username', 'testuser');
        bodyFormData.append('action', 'rewards');
        axios.post(`https://lms-dev.centralindia.cloudapp.azure.com/moodle/custom_apis/rewards_api.php`, bodyFormData)
            .then((res) => {
                setRewardValues(res.data)
            })
    }
    const getSubject = (id) => {
        if (!window.token) {
            GetToken().then((res) => {
                window.token = res.data.token;
                getSubjectList(id);
            });
        } else {
            getSubjectList(id);
        }
    };
    
    const getSubjectList = (id) => {
        axios.get(type.GetSubjectURL(window.token)).then((res) => {
            let temp = [];
            res.data.forEach((val) => {
            if (val.parent == id) {
                temp.push(val);
            }
            });
            temp.forEach((subj) => {
            getSubjectChapters(subj.id).then((chapterList) => {
                subj.chapters = {};
                subj.chapters = chapterList.data.courses;
            });
            });
            setTimeout(() => {
                console.log(temp);
                setSubjectContent(temp);
            }, 500);
        });
    };

    const getSubjectChapters = (id) => {
        return axios.get(type.GetSubjectListURL(window.token, id));
    };

    const chooseTemplate = (evt) => {
        $('.rewards-tab span').removeClass('selected');
        console.log($(evt.target).addClass('selected'));
    }

    const rewardList = (val) => {
        let listOptions = [];
        Object.keys(val).forEach(key => 
            listOptions.push(<div className="all-reward-single">
                <div className="left-content">
                    <span className="type">
                        Quiz
                    </span>
                    <span className="course-content">{key}</span>
                </div>
                <div className="right-content">
                    <span className="points-value"> Points - <span className="points-count">{rewardValues[0].course.activity[key][0][1]}</span></span>
                </div>
            </div>)
        )
        return listOptions;
    }
    
    

    return(
        <>
            <div className="course-container">
                <SubjectHeader headerColour="reward-header-background" subjectList={subjectContent} navigationChapterGroup={navigationChapterGroup} />  
            </div>
            <div className="reward-background">
                <div className="overview">
                    <img
                        src={RewardImg}
                        srcSet={`${RewardImg} 1x, ${RewardImg2x} 2x, ${RewardImg3x} 3x`}
                        alt="reward"
                        className="reward-img"
                    />
                    <div className="reward-list">
                        <div className="full-width"><h3>My Rewards</h3></div>
                        <div className="reward-list-page">
                            <div className="reward-points">
                                <span className="count">{rewardValues && rewardValues[0] && rewardValues[0].total_points || 0}</span>
                                <span className="text">Points Earned</span>
                            </div>
                            <div className="reward-points-group">
                                    <div className="reward-single">
                                        <span className="count">30</span>
                                        <span className="text">Study Materials</span>
                                    </div>
                                    <div className="reward-single">
                                        <span className="count">30</span>
                                        <span className="text">Quiz</span>
                                    </div>
                                    <div className="reward-single">
                                        <span className="count">50</span>
                                        <span className="text">Interactive Session</span>
                                    </div>
                                    <div className="reward-single">
                                        <span className="count">0</span>
                                        <span className="text">Book Solved</span>
                                    </div>
                                    <div className="reward-single">
                                        <span className="count">0</span>
                                        <span className="text">Practice</span>
                                    </div>
                                    <div className="reward-single">
                                        <span className="count">0</span>
                                        <span className="text">Video Content</span>
                                    </div>
                                    <div className="reward-single">
                                        <span className="count">0</span>
                                        <span className="text">Mind Map</span>
                                    </div>
                                    <div className="reward-single">
                                        <span className="count">0</span>
                                        <span className="text">Points Earned</span>
                                    </div>
                            </div>
                        </div>
                        
                    </div>
                    
                </div>
                <div onClick={chooseTemplate} className="rewards-tab">
                    <span id="template-rewards" className="selected">All Rewards</span>
                    <span id="template-badges">Badges</span>
                    <span>Cash Rewards</span>
                </div>

                <div className="rewards template-rewards">
                    <div className="filter">
                        <select>
                            <option>All Months</option>
                        </select>
                    </div>
                    <div id="all-rewards" className="">
                        
                        <div className="all-reward-group">
                            <h5>Rewards found: 2</h5>
                            {rewardValues && rewardValues[0] && rewardValues[0] && rewardValues[0].course 
                                && rewardValues[0].course.activity 
                                && rewardList(rewardValues[0].course.activity)
                            
                            
                            }
                        </div>
                    </div>
                </div>

                <div className=" hide template-badges rewards">
                    <div className="filter">
                        <select>
                            <option>All Months</option>
                        </select>
                    </div>
                    <div id="all-rewards" className="">
                        
                        <div className="all-reward-group">
                            <h5>Rewards found: 2</h5>
                            {rewardValues && rewardValues[0] && rewardValues[0] && rewardValues[0].course 
                                && rewardValues[0].course.activity 
                                && rewardList(rewardValues[0].course.activity)
                            
                            
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default RewardView;