import { useState, useEffect } from "react";
import axios from "../../api/axios";
import Footer from "../../common/footer/footer";

import AcitivityWall from "./ActivityWall";
import MyActivity from "./MyActivity";
import NewActivity from "./NewActivity";
import activityData from "../../data/activity.js";

import GetSubjectChapters from "../../data/gradeList";
import SubjectHeader from "../common/subjectHeader.jsx";
import "../gradeList/gradelist.scss";
import "../grades/chapter.scss";

import "./index.css";

import { activityListValues, activityWallValues, newactivityValues } from './tempValues';

const Activities = () => {
  const [activities, SetActivities] = useState({
    score: 0,
    activityList: activityListValues,
    activityWall: activityWallValues,
    newActivities: newactivityValues,
  });
  const [sectionShow, SetSectionShow] = useState("wall");
  const [gradeId, SetGradeId] = useState([]);

  const navigationChapterGroup = () => { };

  useEffect(() => {
    SetGradeId(GetSubjectChapters("1"));

    axios
      .get(`/api/activity/student/testStudent`, {
        headers: {
          "content-type": "application/json",
          Accept: "application/json",
        },
      })
      .then(
        (response) => {
          SetActivities({
            score: response.data.studentMarks,
            activityList: response.data.activityList,
            activityWall: response.data.announcementWalls,
            newActivities: response.data.newActivities,
          });
        },
        (error) => {
          console.log(error);
        }
      );
  }, []);

  const SectionHandler = (value) => {
    SetSectionShow(value);
  };

  return (
    <>
      <div className="course-container">
        <SubjectHeader
          subjectList={gradeId}
          navigationChapterGroup={navigationChapterGroup}
          headerColour="secondary-header-background"
          profileColor="orange"
        />
      </div>
      <div className="page-container secondary-background">
        <div class="list-group list-group-custom">
          <div
            class={`list-group-item list-group-item-action list-group-item-custom activity-tabs ${sectionShow === "wall" && "active"
              } `}
            id="wall"
            onClick={() => {
              SectionHandler("wall");
            }}
          >
            The Wall
          </div>
          <div
            class={`list-group-item list-group-item-action list-group-item-custom activity-tabs ${sectionShow === "myactivity" && "active"
              } `}
            id="myactivity"
            onClick={() => {
              SectionHandler("myactivity");
            }}
          >
            My Assignments
          </div>
          <div
            class={`d-flex list-group-item list-group-item-action list-group-item-custom activity-tabs ${sectionShow === "newactivity" && "active"
              } `}
            id="myactivity"
            onClick={() => {
              SectionHandler("newactivity");
            }}
          >
            New Assignments
            {/* <div className="notification">2</div> */}
          </div>
        </div>
        <div className="activity-cards-container">
          {sectionShow === "wall" && <AcitivityWall activities={activities} />}
          {sectionShow === "myactivity" && (
            <MyActivity activities={activities} />
          )}
          {sectionShow === "newactivity" && (
            <NewActivity newActivities={activities.newActivities} />
          )}
        </div>
      </div>
      <div className="activity-footer">
        <Footer />
      </div>

    </>
  );
};

export default Activities;
