import React, { useEffect } from "react";
import styled from "styled-components";
import axios from "../../api/axios";
import Moment from "moment";
import { ApiServices } from "../../api/ApiServices";
import ReCAPTCHA from "react-google-recaptcha";

import classes from "./Signup.module.css";
import SignupDetails1 from "./ui/SignupDetails1";
import SignupDetails2 from "./ui/SignupDetails2";
import SignupDetails3 from "./ui/SignupDetails3";
import Footer from "../../common/footer";

import BackgroundDesign from "../../images/design.svg";
import Student from "../../images/student-flying.svg";
import Spinner from "../../images/spinner.svg";
// import Logo from "../../images/logo.png";
import Logo from "../../images/logoImg.png";
import ShortLogo from "../../images/short-logo.svg";
import Logo2X from "../../images/logoImg.png";
import Logo3X from "../../images/logoImg.png";

import { useState, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import md5 from "crypto-js/md5";
import InputContainer from "../../common/inputs/InputContainer";

import Verify from "./Verify";
import VerifySuccess from "./VerifySuccess";

import "../../styles/styles.css";

// URL
import { GetSubjectURL } from "../../urls";

let genderOptions = [
  {
    label: "Male",
    value: "Male",
  },
  {
    label: "Female",
    value: "Female",
  },
  {
    label: "Prefer Not To Say",
    value: "Prefer Not To Say",
  },
];

let UserTypeOptions = [
  {
    label: "Parent",
    value: "Parent",
  },
  {
    label: "Guardian",
    value: "Guardian",
  },
  {
    label: "Teacher",
    value: "Teacher",
  },
];

const Signup = () => {
  const locationParam = useLocation()

  const history = useHistory();
  const [showVerify, SetShowVerify] = useState(false);
  const [verifyDecision, SetVerifyDecision] = useState(false);
  const [captchaErrorText, SetCaptchaErrorText] = useState("");
  const [childFirstName, SetChildFirstName] = useState({
    value: "",
    error: false,
    errorText: "",
  });

  const [childLastName, SetChildLastName] = useState({
    value: "",
    error: false,
    errorText: "",
  });

  const [gender, SetGender] = useState({
    value: "",
    error: false,
    errorText: "",
  });

  const [dateOfBirth, SetDateOfBirth] = useState({
    value: "",
    error: false,
    errorText: "",
  });

  const [grade, SetGrade] = useState({
    value: "",
    error: false,
    errorText: "",
  });

  const [board, SetBoard] = useState({
    value: "",
    error: false,
    errorText: "",
  });

  const [location, SetLocation] = useState({
    value: "",
    error: false,
    errorText: "",
  });

  const [address, SetAddress] = useState({
    value: "",
    error: false,
    errorText: "",
  });

  const [childUserName, SetChildUserName] = useState({
    value: "",
    error: false,
    errorText: "",
  });

  const [childPassword, SetChildPassword] = useState({
    value: "",
    error: false,
    errorText: "",
  });

  const [userId, SetUserId] = useState({
    value: "",
    error: false,
    errorText: "",
  });
  const [firstName, SetFirstName] = useState({
    value: "",
    error: false,
    errorText: "",
  });
  const [lastName, SetLastName] = useState({
    value: "",
    error: false,
    errorText: "",
  });
  const [phoneNumber, SetPhoneNumber] = useState({
    value: "",
    error: false,
    errorText: "",
  });
  const [email, SetEmail] = useState({
    value: "",
    error: false,
    errorText: "",
  });
  const [usertype, SetUsertype] = useState({
    value: "",
    error: false,
    errorText: "",
  });
  const [password, SetPassword] = useState({
    value: "",
    error: false,
    errorText: "",
  });

  const [loaderShow, SetLoaderShow] = useState(false);
  const [boardOptions, SetBoardOptions] = useState([]);
  const [allCategories, SetAllCategories] = useState([]);
  const [gradeOptions, SetGradeOptions] = useState([]);

  const recaptchaRef = useRef();

  useEffect(() => {
    axios.get(GetSubjectURL(process.env.REACT_APP_MOODLE_TOKEN)).then((res) => {
      let BoardFiltered = res.data.filter((data) => data.parent == 0);
      let options = [];
      BoardFiltered.map((option) => {
        if (option.name.toLowerCase().search("_demo") < 0) {
          options.push({
            label: option.name,
            value: option.id,
          })
        }
      })
      SetAllCategories(res.data);
      SetBoardOptions(options)
    });

  }, [])

  useEffect(() => {
    if (locationParam.state?.gradename && boardOptions) {
      let filteredOption = boardOptions.find((board) => board.label.toLowerCase() == "cbse")
      if (filteredOption?.value)
        BoardHandler(Number(filteredOption.value));
    }
  }, [locationParam.state, boardOptions])

  useEffect(() => {
    if (locationParam.state?.gradename && gradeOptions) {
      let filteredOption = gradeOptions.find((grade) => grade.label.toLowerCase() == `grade ${locationParam.state.gradename}`)
      console.log(filteredOption);
      if (filteredOption?.value)
        GradeHandler(Number(filteredOption.value));
    }
  }, [locationParam.state, gradeOptions])

  const ChildFirstNameHandler = (event) => {
    SetChildFirstName({
      value: event.target.value.replace(/[^a-zA-Z]/g, ""),
      error: false,
    });
  };

  const ChildLastNameHandler = (event) => {
    SetChildLastName({
      value: event.target.value.replace(/[^a-zA-Z]/g, ""),
      error: false,
    });
  };

  const GenderHandler = (value) => {
    SetGender({ value: value, error: false });
  };

  const DateOfBirthHandler = (value) => {
    SetDateOfBirth({ value: value, error: false });
  };

  const GradeHandler = (value) => {
    console.log(value);
    SetGrade({ value: value, error: false });
  };

  const BoardHandler = (value) => {
    console.log(value);
    SetBoard({ value: value, error: false });
    let options = [];
    allCategories.map((option) => {
      if (option.parent == value) {
        options.push({
          label: option.name,
          value: option.id,
        })
      }
    })

    SetGradeOptions(options);
  };

  const LocationHandler = (event) => {
    SetLocation({
      value: event.target.value.replace(/[^a-zA-Z]/g, ""),
      error: false,
    });
  };

  const AddressHandler = (event) => {
    SetAddress({
      value: event.target.value.replace(/[^a-zA-Z]/g, ""),
      error: false,
    });
  };

  const ChildUserNameHandler = (event) => {
    SetChildUserName({
      value: event.target.value.replace(/[^\w\s]/gi, ""),
      error: false,
    });
  };

  const ChildPasswordHandler = (event) => {
    SetChildPassword({ value: event.target.value, error: false });
  };

  const FirstNameHandler = (event) => {
    SetFirstName({
      value: event.target.value.replace(/[^a-zA-Z]/g, ""),
      error: false,
    });
  };

  const LastNameHandler = (event) => {
    SetLastName({
      value: event.target.value.replace(/[^a-zA-Z]/g, ""),
      error: false,
    });
  };

  const PhoneNumberHandler = (event) => {
    SetPhoneNumber({ value: event.number, error: false });
  };

  const EmailHandler = (event) => {
    SetEmail({ value: event.target.value, error: false });
  };

  const PasswordHandler = (event) => {
    SetPassword({ value: event.target.value, error: false });
  };

  const UserTypeHandler = (value) => {
    SetUsertype({ value: value, error: false });
  };

  const SigninRedirectHandler = () => {
    history.push("./parent");
  };

  const captchaChange = () => {
    SetCaptchaErrorText("");
  };

  const SignUpSubmitHandler = (event) => {
    event.preventDefault();
    SetLoaderShow(true);
    const recaptchaValue = recaptchaRef.current.getValue();

    if (recaptchaValue == "") {
      SetCaptchaErrorText("Captcha is Invalid");
      SetLoaderShow(false);
    }

    if (childFirstName.value === "") {
      SetChildFirstName({
        ...childFirstName,
        error: true,
        errorText: "Please Enter Student First Name",
      });
    }
    if (childLastName.value === "") {
      SetChildLastName({
        ...childLastName,
        error: true,
        errorText: "Please Enter Student Last Name",
      });
    }
    if (gender.value === "") {
      SetGender({
        ...gender,
        error: true,
        errorText: "Please Select Student Gender",
      });
    }
    if (dateOfBirth.value === "") {
      SetDateOfBirth({
        ...dateOfBirth,
        error: true,
        errorText: "Please Select Student Date of Birth",
      });
    }
    if (grade.value === "") {
      SetGrade({
        ...grade,
        error: true,
        errorText: "Please Select Student Grade",
      });
    }
    if (board.value === "") {
      SetBoard({
        ...board,
        error: true,
        errorText: "Please Select Student Board",
      });
    }
    if (location.value === "") {
      SetLocation({
        ...location,
        error: true,
        errorText: "Please Enter Student location",
      });
    }
    if (address.value === "") {
      SetAddress({
        ...address,
        error: true,
        errorText: "Please Enter Student address",
      });
    }
    if (childUserName.value === "") {
      SetChildUserName({
        ...childUserName,
        error: true,
        errorText: "Please Enter Student User Name",
      });
    }

    if (childUserName.value.length < 6 || childUserName.value.length > 30) {
      SetChildUserName({
        ...childUserName,
        error: true,
        errorText: "Please Enter Student User Name As Per Instruction",
      });
    }

    if (childPassword.value === "") {
      SetChildPassword({
        ...childPassword,
        error: true,
        errorText: "Please Enter Valid Student Password",
      });
    }

    if (
      childPassword.value.length < 8 ||
      (childPassword.value.match(/[A-Z]/g) || []).length < 1 ||
      (childPassword.value.match(/[a-z]/g) || []).length < 1 ||
      (
        childPassword.value.match(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/) ||
        []
      ).length < 1
    ) {
      SetChildPassword({
        ...childPassword,
        error: true,
        errorText: "Please Enter Student Password As Per Instruction",
      });
    }

    if (usertype.value === "") {
      SetUsertype({
        ...usertype,
        error: true,
        errorText: "Please Enter Valid User Type",
      });
      SetLoaderShow(false);
    }

    if (usertype.value !== "" && firstName.value === "") {
      SetFirstName({
        ...firstName,
        error: true,
        errorText: `Please Enter ${usertype.value} First Name`,
      });
      SetLoaderShow(false);
    }

    if (usertype.value !== "" && lastName.value === "") {
      SetLastName({
        ...lastName,
        error: true,
        errorText: `Please Enter ${usertype.value} Last Name`,
      });
      SetLoaderShow(false);
    }

    let emailValue = email.value.split("@");
    if (
      usertype.value !== "" && (
        email.value === "" ||
        !email.value.includes("@") ||
        emailValue[0].length <= 0 ||
        emailValue[1].length <= 0)
    ) {
      SetEmail({ ...email, error: true, errorText: "Please Enter Valid Email ID" });
      SetLoaderShow(false);
    }


    if (usertype.value !== "" && (phoneNumber.value === "" || phoneNumber.value.length < 12)) {
      SetPhoneNumber({
        ...phoneNumber,
        error: true,
        errorText: "Pleaes Enter Valid Mobile Number",
      });
      SetLoaderShow(false);
    }


    if (
      recaptchaValue !== "" &&
      !childFirstName.error &&
      !childLastName.error &&
      !gender.error &&
      !dateOfBirth.error &&
      !grade.error &&
      !board.error &&
      !location.error &&
      !address.error &&
      !childUserName.error &&
      !password.error &&
      !usertype.error &&
      !firstName.error &&
      !lastName.error &&
      !email.error &&
      !phoneNumber.error &&
      childFirstName.value !== "" &&
      childLastName.value !== "" &&
      gender.value !== "" &&
      dateOfBirth.value !== "" &&
      grade.value !== "" &&
      board.value !== "" &&
      location.value !== "" &&
      address.value !== "" &&
      childUserName.value !== "" &&
      usertype.value !== "" &&
      firstName.value !== "" &&
      lastName.value !== "" &&
      !(
        email.value === "" ||
        (!email.value.includes("@") && emailValue[0].length <= 0) ||
        emailValue[1].length <= 0
      ) &&
      !(
        childPassword.value === "" ||
        childPassword.value.length < 6 ||
        (childPassword.value.match(/[A-Z]/g) || []).length < 1 ||
        (childPassword.value.match(/[a-z]/g) || []).length < 1
      ) &&
      !(phoneNumber.value === "" || (phoneNumber.value && phoneNumber.value.length < 10)) &&
      childUserName.value !== "" &&
      childUserName.value.length >= 6 &&
      childUserName.value.length <= 30
    ) {
      axios
        .post(
          `${ApiServices("signup")}&firstname=${firstName.value}&lastname=${lastName.value
          }&email=${email.value}&phone=${phoneNumber.value}&password=${childPassword.value
          }&usertype=${usertype.value}`
        )
        .then(
          (parentres) => {
            if (!isNaN(parentres.data)) {
              let gradeInfo = allCategories.find((data) => data.id == grade.value);

              let gradeName = gradeInfo.name?.toLowerCase().replace("grade ", '');
              axios
                .post(
                  `${ApiServices("childSignup")}&firstname=${childFirstName.value}&lastname=${childLastName.value}&username=${childUserName.value}&classname=${address.value}&parentid=${parentres.data}&gender=${gender.value}&gradeid=${grade.value}&dob=${Moment(dateOfBirth.value).format("DD-MM-YY")}&grade=${gradeName}&board=${board.value}&password=${childPassword.value}&createdby=parent`
                )
                .then(
                  (studentres) => {
                    if (!isNaN(studentres.data)) {

                      SetPassword({ value: "", error: false });
                      SetPhoneNumber({ value: "", error: false });
                      SetUserId({ value: studentres.data, error: false });
                      let res = {
                        childid: studentres.data,
                        id: studentres.data,
                        firstname: childFirstName.value,
                        lastname: childLastName.value,
                        username: childUserName.value,
                        parentid: parentres.data,
                        address: address.value,
                        gender: gender.value,
                        dob: Moment(dateOfBirth.value).format("DD-MM-YY"),
                        grade: "Grade " + gradeName,
                        gradeid: grade.value,
                        gradeId: grade.value,
                        board: board.value,
                        package: 0,
                        payment: 0,
                        token: process.env.REACT_APP_MOODLE_TOKEN
                      };

                      localStorage.setItem("child", JSON.stringify(res));
                      sessionStorage.setItem("child", JSON.stringify(res));
                      localStorage.setItem("childToken", JSON.stringify(res));
                      sessionStorage.setItem("childToken", JSON.stringify(res));

                      SetShowVerify(true);
                      SetLoaderShow(false);


                      // setTimeout(() => {
                      //   history.push(
                      //     props.redirectionUrl
                      //       ? props.redirectionUrl
                      //       : "./subscription-list"
                      //   );
                      // }, 100);
                    } else {
                      if (studentres.data.message) {
                        SetChildPassword({
                          ...childPassword,
                          error: true,
                          errorText: studentres.data.message,
                        });
                        SetLoaderShow(false);
                        return;
                      }
                      if (studentres.data.search("First Name") > 0) {
                        SetChildFirstName({
                          ...childFirstName,
                          error: true,
                          errorText: "Please Enter Student First Name",
                        });
                        SetLoaderShow(false);
                        return;
                      }
                      if (studentres.data.search("Last Name") > 0) {
                        SetChildLastName({
                          ...childLastName,
                          error: true,
                          errorText: "Please Enter Student Last Name",
                        });
                        SetLoaderShow(false);
                        return;
                      }
                      if (studentres.data.search("Gender") > 0) {
                        SetGender({
                          ...gender,
                          error: true,
                          errorText: "Please Select Gender",
                        });
                        SetLoaderShow(false);
                        return;
                      }
                      if (studentres.data.search("Date of Birth") > 0) {
                        SetDateOfBirth({
                          ...dateOfBirth,
                          error: true,
                          errorText: "Please Select Date Of Birth",
                        });
                        SetLoaderShow(false);
                        return;
                      }
                      if (studentres.data.search("Grade") > 0) {
                        SetGrade({
                          ...grade,
                          error: true,
                          errorText: "Please Select Grade",
                        });
                        SetLoaderShow(false);
                        return;
                      }
                      if (studentres.data.search("Board") > 0) {
                        SetBoard({
                          ...board,
                          error: true,
                          errorText: "Please Select Board",
                        });
                        SetLoaderShow(false);
                        return;
                      }
                      if (studentres.data.search("classname") > 0) {
                        SetAddress({
                          ...address,
                          error: true,
                          errorText: "Please Enter Address",
                        });
                        SetLoaderShow(false);
                        return;
                      }
                      if (studentres.data.search("User Name") > 0) {
                        SetChildUserName({
                          ...childUserName,
                          error: true,
                          errorText: "Please Enter User Name",
                        });
                        SetLoaderShow(false);
                        return;
                      }
                      if (studentres.data.search("Already exist") > 0) {
                        SetChildUserName({
                          ...childUserName,
                          error: true,
                          errorText: "User Name Already Exists",
                        });
                        SetLoaderShow(false);
                        return;
                      }
                      if (studentres.data.search("Passsword") > 0) {
                        SetPassword({
                          ...password,
                          error: true,
                          errorText: "Please Enter Student Password As Per Instruction",
                        });
                        SetLoaderShow(false);
                        return;
                      }

                      SetChildFirstName({
                        ...childFirstName,
                        error: true,
                        errorText: studentres.data,
                      });
                      SetLoaderShow(false);
                    }
                  },
                  (error) => {
                    SetLoaderShow(false);
                  }
                );
            } else {
              if (parentres.data.message) {
                SetFirstName({
                  ...firstName,
                  error: true,
                  errorText: "Please Submit Again",
                });
                SetLastName({
                  ...lastName,
                  error: true,
                  errorText: "Please Submit Again",
                });
                return;
              }

              if (parentres.data.search("First Name") > 0) {
                SetFirstName({
                  ...firstName,
                  error: true,
                  errorText: `Please Enter ${usertype.value} First Name`,
                });
                SetLoaderShow(false);
                return;
              }

              if (parentres.data.search("Last Name") > 0) {
                SetLastName({
                  ...lastName,
                  error: true,
                  errorText: `Please Enter ${usertype.value} Last Name`,
                });
                SetLoaderShow(false);
                return;
              }

              if (parentres.data.search("Password") > 0) {
                SetPassword({
                  ...password,
                  error: true,
                  errorText: "Please Enter Valid Password",
                });
                SetLoaderShow(false);
                return;
              }

              if (parentres.data.search("Phone") > 0) {
                SetPhoneNumber({
                  ...phoneNumber,
                  error: true,
                  errorText: "Please Enter Valid Mobile Number",
                });
                SetLoaderShow(false);
                return;
              }

              if (parentres.data.search("Usertype") > 0) {
                SetUsertype({
                  ...usertype,
                  error: true,
                  errorText: "Please Enter Valid Register By",
                });
                SetLoaderShow(false);
                return;
              }

              SetEmail({
                ...email,
                error: true,
                errorText: parentres.data,
              });
              SetLoaderShow(false);
              return;
            }
          },
          (error) => {
            if (error.parentres) {
              let parentres = error.parentres.data.message.toLowerCase();
              if (parentres.search("email") > 0) {
                SetEmail({
                  ...phoneNumber,
                  error: true,
                  errorText: "Email already exists",
                });
                SetLoaderShow(false);
                return;
              }
              if (parentres.search("password") > 0) {
                SetPassword({
                  ...email,
                  error: true,
                  errorText: "Password should minimum of 8 characters",
                });
                SetLoaderShow(false);
                return;
              }
              if (parentres.search("mobile") > 0) {
                SetPhoneNumber({
                  ...password,
                  error: true,
                  errorText: "Enter valid mobile number",
                });
                return;
              }

              if (parentres.data.search("Usertype") > 0) {
                SetUsertype({
                  ...usertype,
                  error: true,
                  errorText: "Enter valid Register By",
                });
                SetLoaderShow(false);
                return;
              }

              SetFirstName({
                ...firstName,
                error: true,
                errorText: "Something went wrong",
              });
              SetLastName({
                ...lastName,
                error: true,
                errorText: "Something went wrong",
              });
              SetLoaderShow(false);
            } else {
              SetFirstName({
                ...firstName,
                error: true,
                errorText: "Something went wrong",
              });
              SetLastName({
                ...lastName,
                error: true,
                errorText: "Something went wrong",
              });
              SetLoaderShow(false);
            }
            SetLoaderShow(false);
          }
        );
    }
  };

  const VerifyDecisionHandler = (value) => {
    if (value) {
      SetVerifyDecision(true);
    }
  };

  return (
    <>
      {loaderShow && (
        <img className={classes.loader} src={Spinner} alt="Loading" />
      )}
      {!showVerify && !verifyDecision && (
        <Container>
          <ShivaniLabs1ColumnRow className="signup-container">
            <img
              src={BackgroundDesign}
              alt="Signup Background"
              className={`${classes["signup-background"]} signup-background`}
            />
            <ShivaniLabs1Column>
              <div
                className="signup-logo-container"
                onClick={() => {
                  history.push("./home");
                }}
              >
                <img
                  src={Logo}
                  srcSet={`${Logo} 1x, ${Logo2X} 2x, ${Logo3X} 3x`}
                  alt="Logo"
                  className="logo"
                />
              </div>
              <H1 className="signup-header">Learn any where any time</H1>
              <SignupDetails1 />
              <SignupDetails2 />
              <SignupDetails3 />
              <img
                src={Student}
                className={`${classes["student-icon"]} student-flying-icon`}
                alt="Student Flying"
              />
            </ShivaniLabs1Column>
            <SignUpStackStack>
              {/* <img
                src={ShortLogo}
                alt="Logo"
                className="logo short-logo mobile-show"
              /> */}
              <Dropdown className="d-inline mx-2 w-100 signin-dropdown">
                <Dropdown.Toggle
                  id="dropdown-autoclose-true"
                  className={classes["signin-redirect"]}
                >
                  Have an account? Sign In
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {/* <Dropdown.Item href="#/parent">Parent Sign In</Dropdown.Item> */}
                  <Dropdown.Item href="#/login">Student Sign In</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <div className="signup-header-mobile">
                Learn any where <br /> any time
              </div>
              <div
                className={`${classes["form-container"]} signup-form-container`}
              >
                <div>
                  <div>
                    <div
                      className={`${classes["heading-container"]} signup-heading-container`}
                    >
                      <SignUpStack>
                        <h4 className="font-weight-bold">Register A Student</h4>
                      </SignUpStack>
                      {!showVerify && (
                        <LearnFromTheBest>
                          Learn from the best teachers
                        </LearnFromTheBest>
                      )}
                    </div>
                    {showVerify && !verifyDecision && (
                      <Verify
                        email={email.value}
                        userId={userId.value}
                        parentName={`${firstName.value} ${lastName.value}`}
                        VerifyDecisionHandler={VerifyDecisionHandler}
                      />
                    )}
                    {!showVerify && (
                      <form onSubmit={SignUpSubmitHandler}>
                        <div className="d-flex">
                          <div className="col-md-6 d-inline-block p-0 p-r-10">
                            <InputContainer
                              type="text"
                              name="name"
                              labelName="Student First Name"
                              labelClass=""
                              placeholder=""
                              onChange={ChildFirstNameHandler}
                              error={childFirstName.error}
                              value={childFirstName.value}
                              errorText={childFirstName.errorText}
                              disabled={loaderShow}
                              className="col-md-12 p-0"
                            />
                          </div>
                          <div className="col-md-6 d-inline-block p-0">
                            <InputContainer
                              type="text"
                              name="lastname"
                              labelName="Student Last Name"
                              labelClass=""
                              placeholder=""
                              onChange={ChildLastNameHandler}
                              error={childLastName.error}
                              value={childLastName.value}
                              errorText={childLastName.errorText}
                              disabled={loaderShow}
                              className="col-md-12 p-0"
                            />
                          </div>
                        </div>
                        <div className="d-flex">
                          <div className="col-md-6 d-inline-block p-0 p-r-10">
                            <InputContainer
                              type="select"
                              name="gender"
                              labelName="Gender"
                              labelClass=""
                              placeholder=""
                              options={genderOptions}
                              onChange={GenderHandler}
                              error={gender.error}
                              errorText={gender.errorText}
                              disabled={loaderShow}
                              className={`col-md-12 p-0`}
                            />
                          </div>
                          <div className="col-md-6 d-inline-block p-0">
                            <InputContainer
                              type="date"
                              name="dob"
                              labelName="Date of Birth"
                              labelClass=""
                              placeholder=""
                              onChange={DateOfBirthHandler}
                              error={dateOfBirth.error}
                              errorText={dateOfBirth.errorText}
                              disabled={loaderShow}
                              className="col-md-12 p-0 dob-picker"
                            />
                          </div>
                        </div>
                        <div className="d-flex">
                          <div className="col-md-6 d-inline-block p-0 p-r-10">
                            <InputContainer
                              type="select"
                              name="board"
                              labelName="Board"
                              labelClass=""
                              placeholder=""
                              value={board.value}
                              options={boardOptions}
                              onChange={BoardHandler}
                              error={board.error}
                              errorText={board.errorText}
                              disabled={loaderShow}
                              className={`col-md-12 p-0`}
                            />
                          </div>
                          <div className="col-md-6 d-inline-block p-0">
                            <InputContainer
                              type="select"
                              name="grade"
                              labelName="Grade"
                              labelClass=""
                              placeholder=""
                              value={grade.value}
                              options={gradeOptions}
                              onChange={GradeHandler}
                              error={grade.error}
                              errorText={grade.errorText}
                              disabled={loaderShow}
                              className={`col-md-12 p-0`}
                            />
                          </div>
                        </div>
                        <div className="d-flex">
                          <div className="col-md-6 d-inline-block p-0 p-r-10">
                            <InputContainer
                              type="text"
                              name="location"
                              labelName="Location"
                              labelClass=""
                              placeholder=""
                              onChange={LocationHandler}
                              error={location.error}
                              value={location.value}
                              errorText={location.errorText}
                              disabled={loaderShow}
                              className="col-md-12 p-0"
                            />
                          </div>
                          <div className="col-md-6 d-inline-block p-0">
                            <InputContainer
                              type="text"
                              name="address"
                              labelName="Address"
                              labelClass=""
                              placeholder=""
                              onChange={AddressHandler}
                              error={address.error}
                              value={address.value}
                              errorText={address.errorText}
                              disabled={loaderShow}
                              className="col-md-12 p-0"
                            />
                          </div>
                        </div>
                        <div className="">
                          {/* <div className="username-instruction-container">
                            <p className="username-instruction-header">
                              What should a username contain?
                            </p>
                            <p className="username-instruction-content">
                              Choose a username 6–30 characters long. Your username can be
                              any combination of letters or numbers
                            </p>
                            <p className="username-instruction-content">
                              Usernames can contain letters (a-z), numbers (0-9), and
                              periods. No special Character allowed
                            </p>
                          </div> */}
                          <div className="col-md-12 d-inline-block p-0">
                            <InputContainer
                              type="text"
                              name="username"
                              labelName="User Name"
                              labelClass=""
                              placeholder=""
                              value={childUserName.value}
                              onChange={ChildUserNameHandler}
                              error={childUserName.error}
                              errorText={childUserName.errorText}
                              disabled={loaderShow}
                              className="col-md-12 p-0"
                            />
                          </div>

                          <div className="col-md-12 d-inline-block p-0">
                            <InputContainer
                              type="password"
                              name="password"
                              labelName="Password"
                              labelClass=""
                              placeholder=""
                              onChange={ChildPasswordHandler}
                              error={childPassword.error}
                              errorText={childPassword.errorText}
                              disabled={loaderShow}
                              className="col-md-12 p-0"
                            />
                          </div>
                          {/* <div className="username-instruction-container">
                            <p className="username-instruction-content username-instruction-password">
                              At least 8 characters <br />A mixture of both uppercase and
                              lowercase letters and numbers.
                              <br />
                              Inclusion of at least one special character, e.g., ! @ # ? ]
                            </p>
                          </div> */}
                        </div>
                        <InputContainer
                          type="select"
                          name="usertype"
                          labelName="Register By"
                          labelClass=""
                          placeholder="Register By"
                          options={UserTypeOptions}
                          onChange={UserTypeHandler}
                          selectValue={usertype.value}
                          error={usertype.error}
                          errorText={usertype.errorText}
                          disabled={false}
                          className="my-wards-calendar-input"
                        />

                        {usertype.value &&
                          <>
                            <div className="d-flex">
                              <InputContainer
                                type="text"
                                name="name"
                                labelName={`${usertype.value} First Name`}
                                placeholder="Enter first name"
                                onChange={FirstNameHandler}
                                value={firstName.value}
                                error={firstName.error}
                                errorText={firstName.errorText}
                                disabled={loaderShow}
                                className="col-md-6 adjecent-input"
                              />
                              <InputContainer
                                type="text"
                                name="name"
                                labelName={`${usertype.value} Last Name`}
                                placeholder="Enter last name"
                                onChange={LastNameHandler}
                                value={lastName.value}
                                error={lastName.error}
                                errorText={lastName.errorText}
                                disabled={loaderShow}
                                className="col-md-6 adjecent-input"
                              />
                            </div>
                            <InputContainer
                              type="text"
                              name="email"
                              labelName="Email"
                              placeholder="Enter email"
                              onChange={EmailHandler}
                              error={email.error}
                              errorText={email.errorText}
                              disabled={loaderShow}
                            />
                            <InputContainer
                              type="phoneNumber"
                              name="mobileNumber"
                              labelName="Mobile Number"
                              placeholder="Enter mobile number"
                              onChange={PhoneNumberHandler}
                              error={phoneNumber.error}
                              errorText={phoneNumber.errorText}
                              disabled={loaderShow}
                            />
                          </>}
                        <div className={classes["captcha-container"]}>
                          <p className={classes["captcha"]}>
                            Please tick the box to continue
                          </p>
                          <ReCAPTCHA
                            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                            ref={recaptchaRef}
                            onChange={captchaChange}
                            onClick={captchaChange}
                          />
                          {captchaErrorText != "" && (
                            <p
                              className={`${classes["error-statement"]} ${classes["captcha"]}`}
                            >
                              {captchaErrorText}
                            </p>
                          )}
                        </div>
                        <div className="text-center">
                          <button
                            className={classes["signup-button"]}
                            disabled={loaderShow}
                          >
                            Sign Up
                          </button>
                        </div>
                      </form>
                    )}
                  </div>
                </div>
              </div>
            </SignUpStackStack>
          </ShivaniLabs1ColumnRow>
          <Footer />
        </Container>
      )}
      {showVerify && !verifyDecision && (
        <Container>
          <ShivaniLabs1ColumnRow className="signup-container">
            <img
              src={BackgroundDesign}
              alt="Signup Background"
              className={`${classes["signup-background"]} signup-background`}
            />
            <ShivaniLabs1Column>
              <div
                className="signup-logo-container"
                onClick={() => {
                  history.push("./home");
                }}
              >
                <img
                  src={Logo}
                  srcSet={`${Logo} 1x, ${Logo2X} 2x, ${Logo3X} 3x`}
                  alt="Logo"
                  className="logo"
                />
              </div>
              <H1 className="signup-header">Learn any where any time</H1>
              <SignupDetails1 />
              <SignupDetails2 />
              <SignupDetails3 />
            </ShivaniLabs1Column>
            <SignUpStackStack>
              <Dropdown className="d-inline mx-2 w-100 signin-dropdown">
                <Dropdown.Toggle
                  id="dropdown-autoclose-true"
                  className={classes["signin-redirect"]}
                >
                  Have an account? Sign In
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item href="#/parent">Parent Sign In</Dropdown.Item>
                  <Dropdown.Item href="#/login">Student Sign In</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <div
                className={`${classes["form-container"]} signup-form-container`}
              >
                <div>
                  <div className="verify-wrapper">
                    <div
                      className={`signup-form-container`}
                    >
                      <SignUpStack>
                        <h4 className="font-weight-bold">Verify</h4>
                      </SignUpStack>
                    </div>
                    <Verify
                      email={email.value}
                      userId={userId.value}
                      parentName={`${firstName.value} ${lastName.value}`}
                      VerifyDecisionHandler={VerifyDecisionHandler}
                    />
                  </div>
                  <img
                    src={Student}
                    className={`${classes["student-icon"]} student-flying-icon`}
                    alt="Student Flying"
                  />
                </div>
              </div>
            </SignUpStackStack>
          </ShivaniLabs1ColumnRow>
          <Footer />
        </Container>
      )}
      {showVerify &&
        verifyDecision &&
        <VerifySuccess
          redirectedTo={locationParam.state?.grade ? "demo" : "discover-courses"}
          url={locationParam.state?.grade ? `/courses/${locationParam.state.grade}?grade=${locationParam.state.grade}&subject=${locationParam.state.subject}&chapter=${locationParam.state.chapter}&flag=true&view=true&board=CBSE` : grade} />
      }
    </>
  );
};

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const ShivaniLabs1 = styled.span`
  background-color: transparent;
  color: rgba(213, 49, 26, 1);
  font-size: 28px;
  font-weight: 700;
  font-style: normal;
`;

const H1 = styled.span`
  width: 367px;
  background-color: transparent;
  color: rgba(253, 173, 37, 1);
  font-size: 2.5rem;
  font-weight: 700;
  font-style: normal;
  margin-top: 9vh;
  margin-left: 40px;
`;

const ShivaniLabs1Column = styled.div`
  width: 40%;
  flex-direction: column;
  display: flex;
  margin-top: 15px;
`;

const SignUp = styled.span`
  font-size: 32px;
  font-weight: 700;
  font-style: normal;
  margin-left: 5%;
`;

const SignUpStack = styled.div`
  margin-top: 20%;
  text-align: left;
`;

const LearnFromTheBest = styled.div`
  color: rgba(253, 173, 37, 1);
  font-size: 0.8rem;
  font-weight: 600;
`;

const SignUpStackStack = styled.div`
  width: 60%;
  min-height: 820px;
  height: auto;
`;

const ShivaniLabs1ColumnRow = styled.div`
  // background: url("${BackgroundDesign}") no-repeat 105% 35%;
  background-size: 80% 135%;
  flex-direction: row;
  display: flex;
  padding-left: 20px;
  margin-right: 0px;
`;

export default Signup;
