import { useState } from "react";
import InputContainer from "../../common/inputs/InputContainer";

import "./ChildRewards.scss";

import badge1 from "./images/badge-1.svg";
import badge2 from "./images/badge-2.svg";
import badge3 from "./images/badge-3.svg";

let rewardsOptions = [
  {
    label: "English",
    value: "English",
  },
  {
    label: "Maths",
    value: "Maths",
  },
  {
    label: "Science",
    value: "Science",
  },
];

let rewardsList = [
  {
    id: 1,
    date: "17-May-2021",
    type: "Quiz",
    name: "Maths : Pair of Linear Equations in Two Variables.",
    rewardType: "Points",
    rewardAs: "text",
    rewardPoint: "30",
    rewardImage: "",
  },
  {
    id: 2,
    date: "17-May-2021",
    type: "Quiz",
    name: "Maths : Pair of Linear Equations in Two Variables.",
    rewardType: "badges",
    rewardAs: "image",
    rewardPoint: "230",
    rewardImage: "./images/badge-1.svg",
  },
  {
    id: 3,
    date: "17-May-2021",
    type: "Quiz",
    name: "Maths : Pair of Linear Equations in Two Variables.",
    rewardType: "cash",
    rewardAs: "text",
    rewardPoint: "30",
    rewardImage: "",
  },
  {
    id: 4,
    date: "17-May-2021",
    type: "Quiz",
    name: "Maths : Pair of Linear Equations in Two Variables.",
    rewardType: "badges",
    rewardAs: "image",
    rewardPoint: "30",
    rewardImage: "./images/badge-2.svg",
  },
  {
    id: 5,
    date: "17-May-2021",
    type: "Quiz",
    name: "Maths : Pair of Linear Equations in Two Variables.",
    rewardType: "badges",
    rewardAs: "text",
    rewardPoint: "30",
    rewardImage: "",
  },
  {
    id: 6,
    date: "17-May-2021",
    type: "Quiz",
    name: "Maths : Pair of Linear Equations in Two Variables.",
    rewardType: "badges",
    rewardAs: "image",
    rewardPoint: "30",
    rewardImage: "./images/badge-3.svg",
  },
];
const ChildRewards = () => {
  const [rewards, SetRewards] = useState({
    value: "",
    error: false,
    errorText: "",
  });

  const [months, SetMonths] = useState({
    value: "",
    error: false,
    errorText: "",
  });
  const [search, SetSearch] = useState({
    value: "",
    error: false,
    errorText: "",
  });

  const RewardsHandler = (value) => {
    SetRewards({ value: value, error: false });
  };
  const MonthsHandler = (value) => {
    SetMonths({ value: value, error: false });
  };
  const SearchHandler = (value) => {
    SetSearch({ value: value, error: false });
  };
  return (
    <div className="child-rewards-container">
      <div className="rewards-count-container">
        <div className="reward-item points-earned">
          <p className="rewards-point">532</p>
          <p className="rewards-name">Points Earned</p>
        </div>
        <div className="reward-item badge-1">
          <p className="rewards-point">110</p>
          <p className="rewards-name">Badges</p>
        </div>
        <div className="reward-item badge-2">
          <p className="rewards-point">110</p>
          <p className="rewards-name">Badges</p>
        </div>
        <div className="reward-item badge-3">
          <p className="rewards-point">110</p>
          <p className="rewards-name">Badges</p>
        </div>
        <div className="reward-item badge-4">
          <p className="rewards-point">110</p>
          <p className="rewards-name">Badges</p>
        </div>
        <div className="reward-item badge-5">
          <p className="rewards-point">110</p>
          <p className="rewards-name">Badges</p>
        </div>
        <div className="reward-item badge-6">
          <p className="rewards-point">110</p>
          <p className="rewards-name">Badges</p>
        </div>
        <div className="reward-item badge-7">
          <p className="rewards-point">110</p>
          <p className="rewards-name">Badges</p>
        </div>
        <div className="reward-item badge-8">
          <p className="rewards-point">110</p>
          <p className="rewards-name">Badges</p>
        </div>
      </div>
      <div className="rewards-info-container">
        <div className="rewards-search-container text-align-end d-flex">
          <InputContainer
            type="text"
            name="email"
            labelName=""
            labelClass="d-none"
            className="rewards-search"
            placeholder=""
            inputClass="rewards-search-input"
            onChange={SearchHandler}
            error={search.error}
            errorText={search.errorText}
          />
          <InputContainer
            type="select"
            name="rewards"
            labelClass="d-none"
            placeholder=""
            options={rewardsOptions}
            onChange={RewardsHandler}
            error={rewards.error}
            errorText={rewards.errorText}
            disabled={false}
            className="rewards-input"
          />
          <InputContainer
            type="select"
            name="month"
            labelName=""
            labelClass="d-none"
            placeholder=""
            options={rewardsOptions}
            onChange={MonthsHandler}
            error={months.error}
            errorText={months.errorText}
            disabled={false}
            className="rewards-input"
          />
        </div>
        <p className="rewards-heaing">Rewards found - 22</p>
        <div className="rewards-items-container">
          {rewardsList &&
            rewardsList.map((reward) => {
              return (
                <div id={reward.id} className="rewards-item-container">
                  <div className="rewards-item-info">
                    <p className="reward-item-date">{reward.date}</p>
                    <div className="d-flex mt-10">
                      <p className="rewards-item-type">{reward.type}</p>
                      <p className="rewards-item-name">{reward.name}</p>
                    </div>
                  </div>
                  <div
                    className={`rewards-given-container reward-as-${reward.rewardType.toLowerCase()}`}
                  >
                    <p className="rewards-tag">{reward.rewardType} -</p>
                    {reward.rewardAs == "text" && (
                      <p className="rewards-received-text">
                        {reward.rewardType == "cash" && "₹"}{" "}
                        {reward.rewardPoint}
                      </p>
                    )}
                    {reward.rewardAs == "image" && (
                      <img
                        src={require(`${reward.rewardImage}`).default}
                        alt="Badges"
                      />
                    )}
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default ChildRewards;
