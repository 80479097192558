export const activityListValues = [
    {
        activityDescription: "This the description of the activity",
        activityDisplayPicture: "Description",
        activityImage: "Description",
        activityName: "Whole Numbers",
        activityPoints: "15",
        activityPostedDate: "28/08/2022",
        activitySubject: "Maths",
        activitySubmittedDate: "28/08/2022",
        activityUserDestination: "India",
        activityUsername: "Aravinth",
    },
    {
        activityDescription: "This the description of the activity",
        activityDisplayPicture: "Description",
        activityImage: "Description",
        activityName: "Knowing our Numbers",
        activityPoints: "10",
        activityPostedDate: "28/08/2022",
        activitySubject: "Maths",
        activitySubmittedDate: "28/08/2022",
        activityUserDestination: "India",
        activityUsername: "Aravinth",
    },
];

export const activityWallValues = [
    {
        announcementDescription: "Numbers",
        announcementDisplayPicture: "",
        announcementImage: "",
        announcementName: "",
        announcementPoints: "",
        announcementPostedDate: "",
        announcementSubject: "",
        announcementSubmittedDate: "",
        announcementUserDestination: "",
        announcementUsernam: ""
    },
];
export const newactivityValues = [
    {
        newActivityDescription: "Knowing our numbers",
        newActivityId: "1",
        newActivityImage: "",
        newActivityName: "Knowing our numbers",
        newActivityPicture: "",
        newActivityPoints: "10",
        newActivityPostedDate: "28/08/2022",
        newActivitySubject: "Maths",
        newActivitySubmittedDate: "28/08/2022",
        newActivityUserDestination: "India",
        newActivityUsername: "newActivityUsername",
    },
    {
        newActivityDescription: "Knowing our numbers",
        newActivityId: "1",
        newActivityImage: "",
        newActivityName: "Knowing our numbers",
        newActivityPicture: "",
        newActivityPoints: "10",
        newActivityPostedDate: "28/08/2022",
        newActivitySubject: "Maths",
        newActivitySubmittedDate: "28/08/2022",
        newActivityUserDestination: "India",
        newActivityUsername: "newActivityUsername",
    },
];