import styled from "styled-components";
import image from '../../../images/signup-details-2.svg';
import './index.css';

const SignupDetails3 = () => {
    return (
        <div className="details-section">
            <div className="details-container">
                <span className="details-header">Digital learning</span>
                <span className="details-content">With the advent of technology, the Gen X, is more compatible with laptop buttons and tablet keys. However, merely providing students with access to devices doesn't necessarily lead to better outcomes.
                </span>
            </div>
            <div className="image-container">
                <img src={image} alt="Detail Image 2" />
            </div>
        </div>
    )
}

export default SignupDetails3;