import React, { useRef, useState } from "react";
import { Editor } from "@tinymce/tinymce-react";

const TinymceEditor = () => {
  const editorRef = useRef(null);
  const [output, setOutput] = useState("");

  function createMarkup() {
    return {
      __html: output,
    };
  }

  const log = () => {
    if (editorRef.current) {
      setOutput(editorRef.current.getContent());
    }
  };
  return (
    <>
      <Editor
        apiKey="ukihubbak5pmtfy4syxcgx456o2cs6gwct5yg3o8ojm790en"
        onInit={(evt, editor) => (editorRef.current = editor)}
        initialValue=""
        init={{
          height: 300,
          browser_spellcheck: true,
          statusbar: false,
          menubar: false,
          plugins: [
            "advlist autolink lists link image charmap print preview anchor",
            "searchreplace visualblocks code fullscreen",
            "insertdatetime media table paste code",
          ],
          toolbar:
            "undo redo | formatselect | " +
            "bold italic backcolor | alignleft aligncenter " +
            "alignright alignjustify | bullist numlist outdent indent | " +
            "removeformat",
          content_style:
            "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
        }}
      />
      <button className="float-right" onClick={log}>
        Submit
      </button>
      <div dangerouslySetInnerHTML={createMarkup()} />
    </>
  );
};

export default TinymceEditor;
