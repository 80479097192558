import React from "react";
import "./header.css";

export default function Header() {
  return (
    <div className="header-app">
      <div className="header-logo">
        <span>Edu-Portal</span>
      </div>
      <div className="header-menu-group">
        <a className="header-menu" href="#/grade">
          Grades
        </a>
        <a className="header-menu" href="#/signup">
          Sign Up
        </a>
        <a className="header-menu" href="#/login">
          Login
        </a>
      </div>
    </div>
  );
}
