export const SERVICE_URL = {
  GET_SUBJECT_LIST: "/api/gc/subjects",
  GET_CONTENT: "/api/gc/content",
};


export const GetSubjectListURL = (token, id) => {
  return `${process.env.REACT_APP_MOODLE_URL}webservice/rest/server.php?wstoken=${token}&moodlewsrestformat=json&wsfunction=core_course_get_courses_by_field&field=category&value=${id}`;
};

export const GetEnrollSubject = (token, id) => {
  return `${process.env.REACT_APP_MOODLE_URL}webservice/rest/server.php?wstoken=${token}&moodlewsrestformat=json&wsfunction=core_enrol_get_users_courses&userid=${id}`;
};

export const GetTimeLineClass = (token, id) => {
  return `${process.env.REACT_APP_MOODLE_URL}webservice/rest/server.php?wstoken=${token}&moodlewsrestformat=json&wsfunction=core_course_get_recent_courses&userid=${id}`;
};

export const GetSubjectURL = (token) => {
  return `${process.env.REACT_APP_MOODLE_URL}webservice/rest/server.php?wstoken=${token}&moodlewsrestformat=json&wsfunction=core_course_get_categories`;
};

export const GetContent = (token, id) => {
  return `${process.env.REACT_APP_MOODLE_URL}webservice/rest/server.php?wstoken=${token}&moodlewsrestformat=json&wsfunction=mod_page_get_pages_by_courses&courseids[0]=${id}`;
};

export const GetVideoContent = (token, id) => {
  return `${process.env.REACT_APP_MOODLE_URL}webservice/rest/server.php?wstoken=${token}&moodlewsrestformat=json&wsfunction=core_course_get_contents&courseid=${id}`;
};

export const GetInteractiveContent = (token, id) => {
  return `${process.env.REACT_APP_MOODLE_URL}webservice/rest/server.php?wstoken=${token}&moodlewsrestformat=json&wsfunction=mod_scorm_get_scorms_by_courses%20&courseids[0]=${id}`;
};

export const AddFavourites = (token, itemId, comp) => {
  return `${process.env.REACT_APP_MOODLE_URL}webservice/rest/server.php?wstoken=${token}&moodlewsrestformat=json&wsfunction=core_course_add_content_item_to_user_favourites&componentname=${comp}&contentitemid=${itemId}`;
};

export const RemoveFavourites = (token, itemId, comp) => {
  return `${process.env.REACT_APP_MOODLE_URL}webservice/rest/server.php?wstoken=${token}&moodlewsrestformat=json&wsfunction=core_course_remove_content_item_from_user_favourites&componentname=${comp}&contentitemid=${itemId}`;
};

export const GetH5pContent = (token, id) => {
  return `${process.env.REACT_APP_MOODLE_URL}webservice/rest/server.php?wstoken=${token}&moodlewsrestformat=json&wsfunction=core_course_get_contents&courseid=${id}`;
};

export const GetAllCourse = (token) => {
  return `${process.env.REACT_APP_MOODLE_URL}webservice/rest/server.php?wstoken=${token}&moodlewsrestformat=json&wsfunction=core_course_get_courses`;
};

export const EnrollCourses = (token) => {
  return `${process.env.REACT_APP_MOODLE_URL}/webservice/rest/server.php?wstoken=${token}&wsfunction=enrol_manual_enrol_users&moodlewsrestformat=json`;
};

export const GetEnrolledCourses = (token) => {
  return `${process.env.REACT_APP_MOODLE_URL}webservice/rest/server.php?wstoken=${token}&moodlewsrestformat=json&wsfunction=core_enrol_get_users_courses`;
};

export const PostPackage = (id) => {
  return `${process.env.REACT_APP_MOODLE_URL}webservice/rest/server.php?wstoken=${process.env.REACT_APP_MOODLE_TOKEN}&moodlewsrestformat=json&wsfunction=local_custom_webservice_package&userid=${id}&package=1`;
};

export const PostPayment = (id) => {
  return `${process.env.REACT_APP_MOODLE_URL}webservice/rest/server.php?wstoken=${process.env.REACT_APP_MOODLE_TOKEN}&moodlewsrestformat=json&wsfunction=local_custom_webservice_payment&userid=${id}`;
};