import React from 'react';
import cx from 'classnames';
import SliderContext from './context'
import ShowDetailsButton from './ShowDetailsButton'
import Mark from './Mark'
import './ItemHome.scss'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGraduationCap } from '@fortawesome/free-solid-svg-icons';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';

const ItemHome = ({ movie, ix, view }) => (
  <SliderContext.Consumer>
    {({ onSelectSlide, currentSlide, elementRef }) => {
      const isActive = currentSlide && currentSlide.id === movie.id;
      const url = movie?.overviewfiles && movie?.overviewfiles[0] && movie?.overviewfiles[0].fileurl + '?token=' + process.env.REACT_APP_MOODLE_TOKEN;
      return (
        // <div onClick={() => window.location.href = `#/courses/${movie.id}?chapter=${movie.id}` }
        <div onClick={() => window.location.href = `#/detail/${movie.categoryid}/${movie.id}/${view}` }
          ref={elementRef}
          style={url ? {
              background : `url("${url}")` ,
              backgroundSize: "100% 100%",
          } : {}}
          className={cx('item course-color-'+ (ix % 10), {
            'item--open': isActive,
          })}

        >
          {/* <img src={movie?.overviewfiles && movie?.overviewfiles[0] && movie?.overviewfiles[0].fileurl + '?token=' + window.token} /> */}
          {url &&
            <FontAwesomeIcon className='transparent-cap' icon={faGraduationCap}  />
          }

          {!url &&
            <FontAwesomeIcon  icon={faGraduationCap}  />
          }
          
          <div className='details'>
            {/* <div className='slot-left'></div> */}
            <div className='full-width'>
                <div className='header-text'>
                    <p className='col-md-9'>{movie.displayname}</p>
                    <p className='price'>{movie?.customfields[1]?.value == 0 ? 'Free' : '₹' + movie?.customfields[1]?.value}</p>
                </div>
                <div className='d-none d-xs-none d-sm-none d-md-block'>
                  <div dangerouslySetInnerHTML={{__html: movie?.customfields[0]?.value}} className='desc-text '></div>
                </div>
                {/* <div className='d-block d-xs-block d-sm-block d-md-none'>
                  <p className='price'>{movie?.customfields[1]?.value == 0 ? 'Free' : '' + movie?.customfields[1]?.value}</p>
                </div> */}
                <div>
                  <p className='col-md-9 duration'>{'Duration: ' + movie?.customfields[2]?.value}</p>
                </div>
                  
            </div>

           
          </div>
          {/* <ShowDetailsButton onClick={() => onSelectSlide(movie)} />
          {isActive && <Mark name={movie.displayname} desc={movie.summary} />} */}
        </div>
      );
    }}
  </SliderContext.Consumer>
);

export default ItemHome;
