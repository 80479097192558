import PersonWalking from "./images/person-walking.webp";
import PersonWalking2X from "./images/person-walking@2x.webp";
import PersonWalking3X from "./images/person-walking@3x.webp";

const ProgressBar = ({ bgcolor, progress, showImage, showCount }) => {
  const Parentdiv = {
    height: "7px",
    width: "100%",
    backgroundColor: "#e2e2e2",
    borderRadius: 40,
    marginTop: showImage ? "60px" : "",
  };

  const Childdiv = {
    height: "100%",
    width: `${progress}%`,
    backgroundColor: bgcolor,
    borderRadius: 40,
    textAlign: "right",
  };

  const ProgressWalking = {
    margin: "-75px 25px 0px 0px",
  };

  const ProgressCount = {
    margin: "0px -5px 0px 0px",
    padding: "10px 0px",
    fontSize: "10px",
    fontFamily: "Montserrat",
    fontWeight: "500",
    color: "#000",
  };

  return (
    <div style={Parentdiv}>
      <div style={Childdiv}>
        {showImage && (
          <img
            src={PersonWalking}
            srcSet={`${PersonWalking} 1x, ${PersonWalking2X} 2x, ${PersonWalking3X} 3x`}
            alt="Progress Walking"
            style={ProgressWalking}
          />
        )}
        {showCount && <p style={ProgressCount}>{progress}</p>}
      </div>
    </div>
  );
};

export default ProgressBar;
