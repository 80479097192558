import { useState } from "react";
import { useHistory } from "react-router-dom";
import axios from "../../api/axios";
import { ApiServices } from "../../api/ApiServices";
import classes from "../../common/inputs/InputContainer.module.css";
import "./Verify.css";

const Verify = (props) => {
  const [veriNumber, SetVeriNumber] = useState({ value: "", error: false });
  const [resendVerification, SetResendVerification] = useState({
    error: false,
    status: "",
  });
  const history = useHistory();

  const VerfiChangeHandler = (event) => {
    SetVeriNumber({ value: event.target.value, error: false });
  };

  const VerifyFormHandler = () => {
    if (veriNumber.value === "" || veriNumber.value.length < 6) {
      SetVeriNumber({ ...veriNumber, error: true });
      return true;
    }

    axios
      .post(
        `${ApiServices("verifyOtp")}&userid=${props.userId}&secretkey=${veriNumber.value
        }`
      )
      .then(
        (response) => {
          if (response.data === "Parent is Registered Successfully") {
            sessionStorage.setItem("parentName", props.parentName);
            sessionStorage.setItem("parentEmail", props.email);
            sessionStorage.setItem("parentId", props.userId);
            props.VerifyDecisionHandler(true);
          } else {
            SetVeriNumber({ ...veriNumber, error: true });
          }
        },
        (error) => {
          SetVeriNumber({ ...veriNumber, error: true });
        }
      );
  };

  const ResendOtpHandler = () => {
    axios.post(`/api/signup/resendOtp/${props.email}`).then(
      (response) => {
        SetResendVerification({ error: true, status: "success" });
        SetVeriNumber({ ...veriNumber, error: false });
      },
      (error) => {
        SetResendVerification({ error: true, status: "error" });
        SetVeriNumber({ ...veriNumber, error: false });
      }
    );
  };

  return (
    <div className="verify-container">
      <div>
        <div className="verify-info">
          {" "}
          Please enter the verification code sent to your email
        </div>
        <div className="verify-input-container">
          <label className="verify-label">Verfication code</label>
          <input
            type="number"
            className={`verify-input ${veriNumber.error ? classes.error : ""}`}
            placeholder="Enter verification code"
            value={veriNumber.value}
            onChange={VerfiChangeHandler}
          />
          {veriNumber.error && (
            <p className={classes["error-statement"]}>
              Invalid verification code
            </p>
          )}
          {resendVerification.error &&
            resendVerification.status === "success" && (
              <p className={classes["success-statement"]}>
                Verfication code sent successfully
              </p>
            )}
          {resendVerification.error &&
            resendVerification.status === "error" && (
              <p className={classes["error-statement"]}>
                Failed to resend verfication code
              </p>
            )}
        </div>
        <button className="verify-btn" onClick={VerifyFormHandler}>
          Verify
        </button>
        <button className="verify-resend" onClick={ResendOtpHandler}>
          Resend verification code
        </button>
      </div>
    </div>
  );
};

export default Verify;
