import { useState } from "react";
import { useSelector } from "react-redux";
import axios from "../../api/axios";
import InputContainer from "../../common/inputs/InputContainer";
import MyProfile from "./MyProfile";
import "./MyProfile.css";

const ChangePassword = () => {
  const isLoggedIn = useSelector((state) => state.authentication);
  console.log(isLoggedIn);
  const [oldPassword, SetOldPassword] = useState({
    value: "",
    error: false,
    errorText: "",
  });
  const [newPassword, SetNewPassword] = useState({
    value: "",
    error: false,
    errorText: "",
  });
  const [confirmPassword, SetConfirmPassword] = useState({
    value: "",
    error: false,
    errorText: "",
  });

  const OldPasswordHandler = (event) => {
    SetOldPassword({ value: event.target.value, error: false });
  };
  const NewPasswordHandler = (event) => {
    SetNewPassword({ value: event.target.value, error: false });
  };
  const ConfirmPasswordHandler = (event) => {
    SetConfirmPassword({ value: event.target.value, error: false });
  };

  const PasswordFormHandler = (event) => {
    event.preventDefault();
    if (oldPassword.value === "") {
      SetOldPassword({
        ...oldPassword,
        error: true,
        errorText: "Enter your old password",
      });
    }
    if (newPassword.value === "") {
      SetNewPassword({
        ...newPassword,
        error: true,
        errorText: "Enter your new password",
      });
    }
    if (confirmPassword.value === "") {
      SetConfirmPassword({
        ...confirmPassword,
        error: true,
        errorText: "Enter your new password again",
      });
    }

    if (
      oldPassword.value !== "" &&
      newPassword.value !== "" &&
      confirmPassword.value !== ""
    ) {
      axios
        .put(
          "/myProfile/changePassword",
          {
            confirmPassword: confirmPassword.value,
            newPassword: newPassword.value,
            oldPassword: oldPassword.value,
            username: isLoggedIn.username,
          },
          {
            headers: {
              Authorization: `Bearer ${isLoggedIn.token}`,
            },
          }
        )
        .then(
          (response) => {
            if (
              response.data.message === "Verification Otp sent successfully!"
            ) {
              SetOldPassword({ value: "", error: false, errorText: "" });
              SetNewPassword({ value: "", error: false, errorText: "" });
              SetConfirmPassword({ value: "", error: false, errorText: "" });
            }
          },
          (error) => {
            SetOldPassword({
              ...oldPassword,
              errorText: "Invalid Credentials Provided",
              error: true,
            });
          }
        );
    }
  };

  const ResetFormHandler = () => {
    SetOldPassword({ value: "", error: false });
    SetNewPassword({ value: "", error: false });
    SetConfirmPassword({ value: "", error: false });
  };
  return (
    <MyProfile active="Change Password">
      <form onSubmit={PasswordFormHandler}>
        <InputContainer
          type="password"
          name="password"
          labelName="Old Password"
          labelExtra=""
          labelClass="c-green"
          placeholder="Enter your old password"
          onChange={OldPasswordHandler}
          error={oldPassword.error}
          errorText={oldPassword.errorText}
          className="m-10-0"
        />
        <InputContainer
          type="password"
          name="password"
          labelName="New Password"
          labelExtra=""
          labelClass="c-green"
          placeholder="Enter your new password"
          onChange={NewPasswordHandler}
          error={newPassword.error}
          errorText={newPassword.errorText}
          className="m-10-0"
        />
        <InputContainer
          type="password"
          name="password"
          labelName="Confirm Password"
          labelExtra=""
          labelClass="c-green"
          placeholder="Re-Enter your new password"
          onChange={ConfirmPasswordHandler}
          error={confirmPassword.error}
          errorText={confirmPassword.errorText}
          className="m-10-0"
        />
        <div className="pl-50">
          <button className="button save" type="submit">
            Save
          </button>
          <button
            className="button cancel"
            type="reset"
            onClick={ResetFormHandler}
          >
            Cancel
          </button>
        </div>
      </form>
    </MyProfile>
  );
};

export default ChangePassword;
