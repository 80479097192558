import React, { useState, useEffect } from "react";

import { GetList } from "../../data/subscription";
import { useSelector, useDispatch } from "react-redux";
import "./ChildSubscription.scss";
import ParentFooter from "./ParentFooter";

import ParentHeader from "./ParentHeader";

const ChildSubscription = (props) => {
  const [subscriptionList, setSubscription] = useState([]);
  let childrenInfo = useSelector((state) => state.childInfo.info);
  useEffect(() => {
    setSubscription(GetList());
  }, []);

  let childArray = [];
  for (const [key, value] of Object.entries(childrenInfo)) {
    childArray.push(childrenInfo[key]);
  }

  return (
    <>
      <ParentHeader active="#/parent/subscription" />
      <main className="parent-portal parent-subscription-group">
        <h3 className="page-header"> Choose Subscription Plan</h3>
        {childArray && (
          <div className="student-details">
            <span className="">{childArray[0].firstName}</span>
            <div> Grade: {childArray[0].gradeId}</div>
            <div> Board: {childArray[0].boardId}</div>
          </div>
        )}

        <div className="subscription-list">
          <div className="subscription-group">
            {subscriptionList &&
              subscriptionList.map((item) => {
                return (
                  <div className="plans-details" key={item.name}>
                    <div className="plan-border-wrapper">
                      <h4 className="plan-flag-plan">{item.name}</h4>
                      <h5 className="price plan-value">₹{item.price}</h5>
                      <div
                        //   onClick={listView}
                        id={"button_" + item.id}
                        className={
                          item.id != 1 ? "disabled plan-button" : "plan-button"
                        }
                      >
                        Select
                      </div>
                    </div>

                    <div className="plan-features">
                      <h6 className="sub-headers">BOARDS</h6>
                      <div className="wrapper">
                        {item.boards &&
                          item.boards.map((val) => {
                            return <span>{val}</span>;
                          })}
                      </div>
                      <h6 className="sub-headers">SUBJECTS</h6>
                      <div className="wrapper">
                        {item.subjects &&
                          item.subjects.map((val) => {
                            return <span>{val}</span>;
                          })}
                      </div>
                      <h6 className="sub-headers">ADDON's</h6>
                      <div className="wrapper">
                        {!item.addons.length && <span>No addon's</span>}
                        {item.addons &&
                          item.addons.map((val) => {
                            return <span>{val}</span>;
                          })}
                      </div>
                      <h6 className="sub-headers">USER GROUPS</h6>
                      <div className="wrapper">
                        {!item.userGroups.length && <span>No usergroups</span>}
                        {item.userGroups &&
                          item.userGroups.map((val) => {
                            return <span>{val}</span>;
                          })}
                      </div>
                      <h6 className="headers">FEATURES</h6>
                      <div className="wrapper">
                        {item.features &&
                          item.features.map((val) => {
                            return <span>{val.name}</span>;
                          })}
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </main>
      <ParentFooter />
    </>
  );
};

export default ChildSubscription;
