import React from "react";
// import { DropdownButton, Dropdown } from 'react-bootstrap';
import Nav from "react-bootstrap/Nav";
import "./chapter.scss";
import "./grade.css";
import $ from "jquery";
import _ from "lodash";

import * as Scroll from "react-scroll";
import {
  Link,
  Button,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller,
} from "react-scroll";

/* Images */
// import LogoImg from "./images/group-2.svg";
// import MenuImg from "./images/menu.png";
import TopicsImg from "./images/bitmap-copy@3x.png";
import AskImg from "./images/bitmap-copy-2@3x.png";
import NcrtImg from "./images/ncrt.png";
import BookBackImg from "./images/bitmap-copy-3@3x.png";
import QuizImg from "./images/bitmap-copy-4@3x.png";
import CharacterImg from "./images/girl-baby.png";
import expandImg from "./images/expand@3x.png";
import solvedImg from "./images/solved.png";
import AskGroupImg from "./images/group-5.png";
import audioImg from "./images/listen.png";
import videoImg from "./images/video.png";
import InterImg from "./images/Interaction.png";
import StudyMaterialImg from "./images/Materials.png";
import PracticeImg from "./images/Practice.png";
import NoActivityImg from "./images/no-act.png";
import MoreOptionsImg from "./images/more-options.png";
import NoQuizImg from "./images/no-qz.png";
import NoWorksheetImg from "./images/No-worksheet.png";
import NoBSImg from "./images/No-Book-solution.png";

import NoSMImg from "./images/no-sm.png";
import NoVideoImg from "./images/no-video.png";
import ThinkGifImg from "./images/boy-thinking-idea.gif";

import axios from "../../api/axios";
import { GetToken } from "../../api/ApiServices";
import * as type from "../../urls";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { faBookmark } from "@fortawesome/free-solid-svg-icons";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/bundle";
import "swiper/swiper.min.css";
import "swiper/swiper-bundle.css";

// // import Swiper core and required modules
import SwiperCore, { Navigation, Autoplay } from "swiper";

/* Data */
import GetSubjectChapters from "../../data/gradeList";
import {
  GetChapterContent,
  GetNCRTContents,
  GetPracticeQuestions,
} from "../../data/chapters";
import ChapterBodyContent from "./chapterBodyContent";
import ChapterNCRTContent from "./nCRTQuestion";
import PracticeQuestions from "./practiceQuestion";
import SubjectHeader from "../common/subjectHeader";
import FooterGreen from "../../common/footer/footer";

import { SERVICE_URL } from "../../urls";
import { arrayIncludes } from "@material-ui/pickers/_helpers/utils";

const Learner = (props) => {
  return (
    <div>
      <p>Welcome, {props.sco.learnerName}!</p>
      <p>Your course status is currently: {props.sco.completionStatus}</p>
      <p>Click the button below to complete the course!</p>
      <button onClick={() => props.sco.setStatus("completed")}>
        Mark me complete!
      </button>
    </div>
  );
};

class Courses extends React.Component {
  constructor(props) {
    super(props);
    console.log(props);
    const {
      match: { params },
    } = props;
    const qs = new URLSearchParams(props.location.search);
    const childToken = JSON.parse(localStorage.getItem("childToken"));
    // 1?subj=1&chapter=1&view=1
    this.state = {
      courseId: params.id,
      loading: false,
      subjectList: [],
      fileContentList: [],
      subjectListGroup: [],
      chapterId: qs.get("chapter") || 4,
      board: childToken.board,
      gradeId: qs.get("grade") || childToken.gradeId.id,
      userId: childToken.childId,
      subjectId: qs.get("subject") || 4,
      loginFlag: qs.get("flag") == "true" ? true : false,
      chapterContent: [],
      audioContent: "",
      play: false,
      practiceContent: [],
      expandClass: qs.get("view") ? "expand-content" : "expand-content",
      selectedMenu: this.getMenuByParams(qs.get("view")),
      searchValue: "",
      bookmarkValue: [],
      interactiveContentURL: "",
      subjectName: "",
      subjectId: "",
      chapterName: "",
      chapterSelectId: '',
      menuContent: [],
      questions: "",
      questionList: [],
      toggleHeight: "h-61-vh",
    };
  }

  getMenuByParams(val) {
    var ret = "";
    switch (val) {
      case "1":
        ret = "topics_menu";
        break;
      case "2":
        ret = "ncrt_menu";
        break;
      case "3":
        ret = "quiz_menu";
        break;
      case "4":
        ret = "ask_menu";
        break;
      case "5":
        ret = "book_menu";
        break;
      default:
        ret = "study_menu";
        break;
    }
    return ret;
  }

  componentDidMount() {
    $(".loader").show().css('display', 'flex');;
    this.setState({ loading: true });
    this.getSubject(this.state.gradeId, true);
  }
  setSubjectName (value) {
    this.state({subjectName: value});
  }
  getSubject(id, intialLoad) {
    if (!window.token) {
      let childToken = JSON.parse(localStorage.getItem("childToken"));

      if (childToken.token) {
        window.token = childToken.token;
        this.getSubjectList(id, intialLoad);
      } else {
        $(".loader").hide();
        this.setState({ loading: false });

        localStorage.removeItem("parentToken");
        localStorage.removeItem("child");
        localStorage.removeItem("childToken");
        window.location.href = "#/home";
      }
    } else {
      this.getSubjectList(id, intialLoad);
    }
  }

  getVideoContent(id) {
    //let childToken = JSON.parse(localStorage.getItem("childToken"));
    axios.get(type.GetVideoContent(window.token, id)).then((res) => {
      this.setState({fileContentList: res?.data})
    })
  }

  getCourseContent(intialLoad) {
    let childToken = JSON.parse(localStorage.getItem("childToken"));
    axios.get(type.GetEnrollSubject(window.token, childToken?.id)).then((res) => {
      let temp = [], _tmp = [];
      let categoryGroup = _.uniqBy(res?.data, 'category');
      categoryGroup.forEach((val) => {
        _tmp.push(val.category);
      });
      _tmp.forEach((tmp_val) => {
        this.state?.subjectListGroup?.forEach((subj_list) => {
          if (tmp_val === subj_list.id) {
            temp.push(subj_list);
          }
        })
      })
      temp.forEach((subj) => {
          subj.chapters = {};
          let _chapter = _.filter(res?.data, (cat) => {
            return cat.category === subj.id;
          });
          subj.chapters = _.sortBy(_chapter, 'id');
          subj.chapters.forEach((chapt, ix) => {
            if (chapt.id == this.state.chapterId) {
              this.setState({ subjectName: subj.name });
              this.setState({ subjectId: subj.id });
              this.setState({ chapterName: chapt.fullname });
              this.setState({ chapterSelectId: ix + 1 });
            }
          });
      });
      console.log(temp);
      this.setState({ subjectList: temp });
      this.getContent(this.state.chapterId);
      this.getVideoContent(this.state.chapterId);
      if (intialLoad) {
        this.loadStudyMaterialContent();
      }
      console.log('data', this.state.subjectList);
    }).catch(() => {
      $(".loader").hide();
      this.setState({ loading: false });
    });
    
  }

  getSubjectList(id, intialLoad) {
    axios.get(type.GetSubjectURL(window.token)).then((res) => {
      this.setState({ subjectListGroup: res?.data }, () => {
        this.getCourseContent(intialLoad);
      });
    });
  }

  loadStudyMaterialContent() {
    this.setState({ interactiveContentURL: "" });
    this.setState({ interactiveHeader: "NCERT BOOK" });
    $(".loader").show().css('display', 'flex');;
    this.setState({ loading: true });
    this.getContentDetails("nc");
  }
  getSubjectChapters(id) {
    return axios.get(type.GetSubjectListURL(window.token, id));
  }
  getContent(id) {
    if (!window.token) {
      let childToken = JSON.parse(localStorage.getItem("childToken"));

      if (childToken.token) {
        window.token = childToken.token;
      } else {
        $(".loader").hide();
        this.setState({ loading: false });

        localStorage.removeItem("parentToken");
        localStorage.removeItem("child");
        localStorage.removeItem("childToken");
        window.location.href = "#/home";
      }
    }
    this.setState({ audioContent: "" });
    axios
      .get(type.GetContent(window.token, id))
      .then((res) => {
        console.log('content', res.data);
        this.setState({ chapterContent: res.data }, () => {
          // if (this.state.chapterContent && this.state.chapterContent.pages && this.state.chapterContent.pages[0] && this.state.chapterContent.pages[0].contentfiles) {
          //     this.state.chapterContent.pages[0].contentfiles.forEach((mediaTypeContent) => {
          //         if (mediaTypeContent.mimetype.includes('audio/mp3')) {
          //             this.setState({audioContent: new Audio(mediaTypeContent.fileurl) })
          //         }
          //     })
          // }
        });
        $(".loader").hide();
        this.setState({ loading: false });
      })
      .catch(() => {
        $(".loader").hide();
        this.setState({ loading: false });
      });
  }
  navigationSubChapter(event) {
    let _key = event.target.dataset.rbEventKey;
    let _comp = document.getElementById(_key);
    let _offset = _comp.offsetTop;
    console.log(_offset);
    scroll.scrollTo(_offset - 100);
  }
  toggleExpand() {
    if (!this.state.expandClass) {
      this.setState({
        expandClass: "expand-content",
        toggleHeight: "h-61-vh",
      });
    } else {
      this.setState({ expandClass: "", toggleHeight: "h-61-vh" });
    }
  }

  getScormContentList(type) {
    axios
      .get(
        `${process.env.REACT_APP_MOODLE_URL}webservice/rest/server.php?wstoken=${window.token}&moodlewsrestformat=json&wsfunction=local_custom_webservice_scormuserattemp&userid=${this.state.userId}&courseid=${this.state.chapterId}`
      )
      .then((res) => {
        let data = [],
          flag = false;
        this.setState({ scormList: res.data.scormuserattempdetails }, () => {
          this.getH5PContentList(type)
        });

      });
  }

  getH5PContentList(type) {
    axios
      .get(
        `${process.env.REACT_APP_MOODLE_URL}webservice/rest/server.php?wstoken=${process.env.REACT_APP_MOODLE_TOKEN}&moodlewsrestformat=json&wsfunction=mod_h5pactivity_get_h5pactivities_by_courses&courseids[0]=${this.state.chapterId}`
      )
      .then((res) => {
        this.setState({ h5pList: res.data.h5pactivities }, () => {
          this.loadScormContent(type)
        });

      });
  }

  getContentDetails(type) {
    axios
      .get(
        `${process.env.REACT_APP_MOODLE_URL}webservice/rest/server.php?wstoken=${process.env.REACT_APP_MOODLE_TOKEN}&moodlewsrestformat=json&wsfunction=mod_resource_get_resources_by_courses&courseids[0]=${this.state.chapterId}`
      )
      .then((res) => {
        console.log(res.data.resources);
        this.setState({ dataList: res.data.resources }, () => {
          this.getScormContentList("nc");
        });
      });
  }

  getVideoData (dataList, type, optType) {
    let data = [];
    if(dataList && dataList.length > 0) {
      dataList?.forEach((cot) => {
        cot?.modules?.forEach((modl)=> {
          modl?.contents?.forEach((content) => {
            if (content?.mimetype === 'video/mp4') {
              data = content;
            }
          }) 
        })
      })
    }
    return data;
  }

  getData(dataList, type, optType) {
    let data = [];
    if (dataList && dataList.length > 0) {
      dataList.forEach((content) => {
        let fileName_arr = [];
        if (type === 'scorm' && dataList.length && dataList[0].filename) {
          fileName_arr = content?.filename.split(".")[0]?.split("_");
        } else if (type === 'content') {
          fileName_arr = content.name.split(".")[0]?.split("_");
        } else if (type === 'video') {
          content?.contentfiles.forEach((cf) => {
            if (cf?.mimetype === 'video/mp4') {
              data = cf;
            }
          })
        } else {
          fileName_arr = content?.name?.split("_");
        }

        if (
          fileName_arr && fileName_arr.length && fileName_arr[fileName_arr.length - 1].toLowerCase() ===
          optType.toLowerCase()
        ) {
          data = content;
        }


      });
    }
    return data;
  }
  
  loadScormContent(type) {
    let childToken = JSON.parse(localStorage.getItem("childToken"));
    let flag = false;
    let url = '';
    let content = this.getData(this.state.dataList, 'content', type);
    let h5pContent = this.getData(this.state.h5pList, 'h5p', type);
    let data = this.getData(this.state.scormList, 'scorm', type);
    
    console.log(this.state.dataList, this.state.h5pList, this.state.scormList);
    let video_content = this.getVideoData(this.state.fileContentList, 'video', type);
    console.log('dddd', content, h5pContent, data, video_content );
    if (content && content.contentfiles && content.contentfiles.length) {
      let fileArray = content?.contentfiles[0]?.fileurl;
      url = `${process.env.REACT_APP_PDFJS_URL}${fileArray}?token=${process.env.REACT_APP_MOODLE_TOKEN}`;
      flag = true;
    } else if (data && data.scormid) {
      url = `${process.env.REACT_APP_MOODLE_URL}scorm_view/index.html?wstoken=${window.token}&scoid=${data.scoid}&currentorg=${data.currentorg}&attempt=${data.attempt}&scormid=${data.scormid}&a=${data.a}&sesskey=${data.sesskey}&attemptcount=${data.attemptcount}&moodleUrl=${process.env.REACT_APP_MOODLE_URL}`;
      flag = true;
    } else if (h5pContent && h5pContent.package && h5pContent.package[0] && h5pContent.package[0].fileurl) {
      url = `${process.env.REACT_APP_MOODLE_URL}h5p/embed.php?url=${h5pContent.package[0].fileurl}&token=${process.env.REACT_APP_MOODLE_TOKEN}&preventredirect=1&component=mod_h5pactivity&embed=1`;
      flag = true;
    } else if (video_content && type === 'vm') {
      // console.log('video', video_content, this.state.dataList);
      if (video_content?.fileurl) {
        url = video_content?.fileurl + `&token=${childToken?.token}`;
      }
      
      if (url) {
        flag = true;
      } else {
        flag = false;
      }
      
    } else {
      flag = false;
    }
    console.log('siva', url, type, flag);
    if (flag) {
      
      this.setState({ interactiveContentURL: url });
      this.setState({ loading: false });
      setTimeout(() => {
        $("#quiz_menu_load").removeClass('hide');
        $(".loader").hide();
      }, 1500);
    } else {
      this.setState({ loading: false }, () => {
        $("#no-data").removeClass('hide');
        $(".loader").hide();
      });
    }
  }

  selectTopics(event) {
    let selected_id = event.currentTarget.id;
    this.setState({ selectedMenu: selected_id });
    $(".sub-menu-icon-wrapper").removeClass("selected");
    $(`.${selected_id}`).addClass("selected");
    if (
      selected_id !== "topics_menu" &&
      selected_id !== "ask_menu" &&
      selected_id !== "audioImg_menu" &&
      selected_id !== "book_mark"
    ) {
      this.setState({ expandClass: "expand-content", toggleHeight: "h-61-vh" });
    }
    if (selected_id === "audioImg_menu") {
      if (this.state.play) {
        this.state.audioContent.pause();
      } else {
        this.state.audioContent.play();
      }
      this.setState({ play: !this.state.play });
    } else {
      if (this.state.play) {
        this.state.audioContent.pause();
        this.setState({ play: !this.state.play });
      }
    }
    let temp_type_val = "",
      temp_type_title = "",
      flag_invoke = false;
    switch (selected_id) {
      case "quiz_menu":
        temp_type_val = "qz";
        temp_type_title = "Quiz";
        flag_invoke = true;
        break;

      case "solved_menu":
        temp_type_val = "bs";
        temp_type_title = "NCERT Book Solved";
        flag_invoke = true;
        break;
      case "ncert_menu":
        temp_type_val = "nc";
        temp_type_title = "NCERT";
        flag_invoke = true;
        break;
      case "inter_menu":
        temp_type_val = "is";
        temp_type_title = "Inter-Active";
        flag_invoke = true;
        break;
      case "study_menu":
        temp_type_val = "sm";
        temp_type_title = "Study Materials";
        flag_invoke = true;
        break;
      case "pract_menu":
        temp_type_val = "ws";
        temp_type_title = "Worksheet";
        flag_invoke = true;
        break;
      case "video_menu":
        temp_type_val = 'vm';
        temp_type_title = "Video";
        flag_invoke = true;
      default:
        break;
    }
    if (flag_invoke) {
      this.setState({ interactiveContentURL: "" });
      $(".loader").show().css('display', 'flex');;
      this.setState({ loading: true });
      $("#quiz_menu_load").hide();
      this.setState({ interactiveHeader: temp_type_title });
      this.loadScormContent(temp_type_val);
    }
  }
  navigationChapterGroup(event) {
    var me = this;
    this.setState({ chapterId: event.currentTarget.id }, () => {
      this.state.subjectList.forEach((subj) => {
        subj.chapters.forEach((chapt, ix) => {
          if (chapt.id == this.state.chapterId) {
            this.setState({ subjectName: subj.name });
            this.setState({ subjectId: subj.id });
            this.setState({ chapterName: chapt.fullname });
            this.setState({ chapterSelectId: ix + 1 });
          }
        });
      });
      this.getContent(this.state.chapterId);
      this.getVideoContent(this.state.chapterId);
      this.setState({ selectedMenu: "study_menu" });
      this.loadStudyMaterialContent();
    });

    $(".sub-menu-icon-wrapper").removeClass("selected");
    $(`#study_menu`).addClass("selected");
    window.location.href = `#/courses/${event.currentTarget.id}?chapter=${event.currentTarget.id}`;
  }
  searchContent() {
    if (this.state.searchValue === "") {
      $(".highlight").removeClass("highlight");
      return;
    }
    var pattern = this.state.searchValue.toLowerCase();
    var targetId = "";

    var divs = document.getElementsByClassName("content-body");
    for (var i = 0; i < divs.length; i++) {
      var para = divs[i].getElementsByTagName("p");
      for (let j = 0; j < para.length; j++) {
        var index = para[j].innerText.toLowerCase().indexOf(pattern);
        if (index != -1) {
          let innerHTML =
            para[j].innerText.substring(0, index) +
            "<span class='highlight'>" +
            para[j].innerText.substring(index, index + pattern.length) +
            "</span>" +
            para[j].innerText.substring(index + pattern.length);
          para[j].innerHTML = innerHTML;
          targetId = divs[i].parentNode.id;
          let _offset = para[j].parentNode.offsetTop;
          scroll.scrollTo(_offset - 100);
        }
      }
    }
  }
  toggleBookMark(ev) {
    let id = ev.currentTarget.parentElement.parentElement.id;
    let itemId =
      ev.currentTarget.parentElement.parentElement.attributes.contentid.value;
    if (!$("#" + ev.currentTarget.id).hasClass("book-mark-selected")) {
      this.addBookMark(itemId, id);
    } else {
      this.removeBookMark(itemId, id);
    }
    $("#" + ev.currentTarget.id).toggleClass("book-mark-selected");
  }
  removeBookMark(id, name) {
    let ret_value = [];
    axios.get(type.RemoveFavourites(window.token, id, "page")).then((res) => {
      console.log(res.data);
    });
    let value_temp = this.state.bookmarkValue;
    value_temp.forEach((element) => {
      if (element.name !== name) {
        let temp = {};
        temp.id = name;
        temp.name = name;
        ret_value.push(temp);
      }
    });

    this.setState({ bookmarkValue: ret_value });
  }
  addQuestions(event) {
    let questionList = this.state.questionList;
    let temp = {
      id: this.state.questionList.length + 1,
      value: this.state.questions,
    };
    questionList.push(temp);
    this.setState({ questionList: questionList });
    this.setState({ questions: "" });
  }
  addBookMark(id, name) {
    console.log("id", id);
    axios.get(type.AddFavourites(window.token, id, "page")).then((res) => {
      console.log(res.data);
    });
    let value_temp = this.state.bookmarkValue;
    let temp = {};
    temp.id = name;
    temp.name = name;
    value_temp.push(temp);
    this.setState({ bookmarkValue: value_temp });
  }

  handleChange(ev) {
    this.setState({ searchValue: ev.target.value });
  }

  GotToSubscription() {
    let childInfo = JSON.parse(localStorage.getItem("child"));
    sessionStorage.setItem(
      "child",
      JSON.stringify(childInfo)
    );
    window.location.href = "#/subscription-list";
  }

  render() {
    let childInfo = JSON.parse(localStorage.getItem("child"));
    return (
      <div className="course-container">
        <SubjectHeader
          selectedSubject={this.state.subjectId}
          subjectName={this.state.subjectName}
          selectedChapter={this.state.chapterId}
          navigationBetweenChapter={this.navigationChapterGroup.bind(this)}
          headerColour="primary-colour"
          profileColor="green"
        />
        {!this.state.loading && (
          <div className={`course-block  ${this.state.expandClass}`}>
            <div className="chapter-list">
              {/* <div className="header">CHAPTERS</div> */}
              
                <div className="sub-menu-content">
                    {/* <div id="topics_menu" onClick={this.selectTopics.bind(this)} className=" selected sub-menu-icon-wrapper">
                                          <img src={TopicsImg} className="sub-menu-icon" />
                                          <p> Topics</p>
                                      </div> */}
                    
                    <div
                      id="ncert_menu"
                      onClick={this.selectTopics.bind(this)}
                      className="ncert_menu selected sub-menu-icon-wrapper"
                    >
                      <img src={NcrtImg} className="sub-menu-icon" />
                      {this.state.board === 'CBSE' &&
                        <p>  NCERT Book </p>
                      }
                      {this.state.board !== 'CBSE' &&
                        <p> Book  </p>
                      }
                      
                    </div>

                    <div
                      id="study_menu"
                      onClick={this.selectTopics.bind(this)}
                      className=" study_menu sub-menu-icon-wrapper"
                    >
                      <img src={StudyMaterialImg} className="sub-menu-icon" />
                      <p> Study Material</p>
                    </div>
                    
                    <div
                      id="solved_menu"
                      onClick={this.selectTopics.bind(this)}
                      className="solved_menu sub-menu-icon-wrapper"
                    >
                      <img src={NcrtImg} className="sub-menu-icon" />
                      <p> Book Solution </p>
                      
                    </div>
                   
                    <div
                      id="video_menu"
                      onClick={this.selectTopics.bind(this)}
                      className="video_menu sub-menu-icon-wrapper"
                    >
                      <img src={videoImg} className="sub-menu-icon" />
                      <p> Video</p>
                    </div>
                    <div
                      id="pract_menu"
                      onClick={this.selectTopics.bind(this)}
                      className="pract_menu sub-menu-icon-wrapper"
                    >
                      <img src={PracticeImg} className="sub-menu-icon" />
                      <p> Worksheet</p>
                    </div>
                    <div
                      id="ask_menu"
                      onClick={this.selectTopics.bind(this)}
                      className="sub-menu-icon-wrapper disable-feature"
                    >
                      <img src={AskImg} className="sub-menu-icon" />
                      <p> Ask</p>
                    </div>
                    <div
                      id="audioImg_menu"
                      onClick={this.selectTopics.bind(this)}
                      className={
                        this.state.audioContent != ""
                          ? " sub-menu-icon-wrapper"
                          : "disable-feature sub-menu-icon-wrapper"
                      }
                    >
                      <img src={audioImg} className="sub-menu-icon" />
                      <p> Listen</p>
                    </div>
                   
                    <div
                      id="book_mark"
                      onClick={this.selectTopics.bind(this)}
                      className="disable-feature sub-menu-icon-wrapper"
                    >
                      <img src={BookBackImg} className="sub-menu-icon" />
                      <p> Bookmark</p>
                    </div>

                    
                    <div
                      id="inter_menu"
                      onClick={this.selectTopics.bind(this)}
                      className="sub-menu-icon-wrapper disable-feature"
                    >
                      <img src={InterImg} className="sub-menu-icon" />
                      <p> Inter - Active </p>
                    </div>
                    <div
                      id="quiz_menu"
                      onClick={this.selectTopics.bind(this)}
                      className="sub-menu-icon-wrapper disable-feature"
                    >
                      <img src={QuizImg} className="sub-menu-icon" />
                      <p> Quiz </p>
                    </div>
                    
                  </div>
            </div>
            <div className=" col-lg-11 content-block">
              <div className="content-nav-wrapper">
                {/* <span className="chapter-id">Chapter {this.state.chapterSelectId + " / "}</span> */}
                <span className="chapter-title">
                  {this.state.chapterName}
                </span>
              </div>
             
              {(this.state.selectedMenu == "topics_menu" ||
                this.state.selectedMenu == "ask_menu" ||
                this.state.selectedMenu == "book_mark" ||
                this.state.selectedMenu == "audioImg_menu") && (
                  <div className="content-wrapper">
                    <div className="content-header">
                      <div className="topic-title">
                        <span>
                          {this.state.chapterName}
                        </span>
                      </div>

                      <img
                        onClick={this.toggleExpand.bind(this)}
                        className="expand-img"
                        src={expandImg}
                      />
                      <div className="button-group-search">
                        <input
                          type="text"
                          value={this.state.searchValue}
                          onChange={this.handleChange.bind(this)}
                          placeholder=""
                          name="search"
                        />
                        <button
                          onClick={this.searchContent.bind(this)}
                          type="submit"
                        >
                          <FontAwesomeIcon icon={faSearch} />
                        </button>
                      </div>
                    </div>
                    <div className="content-body">
                      {this.state.chapterContent &&
                        this.state.chapterContent.pages &&
                        this.state.chapterContent.pages.map((content) => {
                          return (
                            <div contentId={content.id} id={content.name}>
                              <div>
                                <FontAwesomeIcon
                                  id={"icon_" + content.id}
                                  onClick={this.toggleBookMark.bind(this)}
                                  className="bookmark-icon"
                                  icon={faBookmark}
                                />
                              </div>
                              <ChapterBodyContent value={content.content} />
                            </div>
                          );
                        })}
                    </div>

                    {this.state.loginFlag && (
                      <div className="explore_features">
                        <img
                          onClick={() => {
                            window.location.href = "#/signup";
                          }}
                          src={MoreOptionsImg}
                        />
                      </div>
                    )}
                  </div>
                )}
                {
                  (!childInfo.payment || childInfo.payment == "") && (
                    <div
                      className="explore_features-right"
                      onClick={()=>{this.GotToSubscription()}}
                    >
                      {/* <img src={MoreOptionsImg} /> */}
                      <button className="explore-features">Explore More Features</button>
                    </div>
                  )
                }
              {(this.state.selectedMenu == "quiz_menu" ||
                this.state.selectedMenu == "study_menu" ||
                this.state.selectedMenu == "solved_menu" ||
                this.state.selectedMenu == "ncert_menu" ||
                this.state.selectedMenu == "inter_menu" ||
                this.state.selectedMenu == "video_menu" ||
                this.state.selectedMenu == "pract_menu") && (
                  <div className="content-wrapper content-wrapper-quiz">
                    {/* <img
                      onClick={this.toggleExpand.bind(this)}
                      className="expand-img"
                      src={expandImg}
                    /> */}
                    <div className="quiz-content-inner">
                      {/* <h3 className="header">{this.state.interactiveHeader}</h3> */}
                      {this.state.interactiveContentURL && (
                        <iframe
                          id="quiz_menu_load"
                          className={`hide ${this.state.toggleHeight}`}
                          src={this.state.interactiveContentURL}
                          key={this.state.interactiveContentURL}
                        />
                      )}
                      {!this.state.interactiveContentURL && (
                        <div id="no-data" className="hide">
                          {(this.state.selectedMenu == "solved_menu" ||
                            this.state.selectedMenu == "inter_menu" ||
                            this.state.selectedMenu == "ncrt_menu" ||
                            this.state.selectedMenu == "ncert_menu") 
                            && (
                              <img src={NoActivityImg} />
                            )}
                          {this.state.selectedMenu == "quiz_menu" && (
                            <img src={NoQuizImg} />
                          )}
                          {this.state.selectedMenu == "pract_menu" && (
                            <img src={NoWorksheetImg} />
                          )}
                          {this.state.selectedMenu == "study_menu" && (
                            <img src={NoSMImg} />
                          )}
                          {this.state.selectedMenu == "video_menu" && (
                            <img src={NoVideoImg} />
                          )}
                        </div>
                      )}
                      {/* <iframe src={`http://20.198.89.85:8081/moodle/mod/hvp/embed.php?id=111&secret=${window.token}&user_id=testuser@xyz.com`} /> */}
                    </div>
                  </div>
                )}
               <div className="d-flex d-sm-flex d-md-flex d-lg-none d-xl-none  sub-menu-content">
                  <div className="sub-menu-content-group">
                      
                        <div>
                          {this.state.selectedMenu == "topics_menu" && (
                            <>
                              {this.state.chapterContent &&
                                this.state.chapterContent.topicList && (
                                  <span className="topics-title"> Topics</span>
                                )}

                              <Nav className="flex-column navigation-sub-chapter">
                                {this.state.chapterContent &&
                                  this.state.chapterContent.topicList &&
                                  this.state.chapterContent.topicList.map(
                                    (index) => {
                                      return (
                                        <Nav.Link
                                          className="nav_link_content"
                                          onClick={this.navigationSubChapter.bind(
                                            this
                                          )}
                                          eventKey={index.topicName}
                                        >
                                          {index.topicName}
                                        </Nav.Link>
                                      );
                                    }
                                  )}
                              </Nav>
                            </>
                          )}
                          {this.state.selectedMenu == "book_mark" && (
                            <>
                              <Nav className="flex-column navigation-sub-chapter">
                                {this.state.bookmarkValue &&
                                  this.state.bookmarkValue.map((index) => {
                                    return (
                                      <Nav.Link
                                        className="nav_link_content"
                                        onClick={this.navigationSubChapter.bind(
                                          this
                                        )}
                                        eventKey={index.id}
                                      >
                                        <FontAwesomeIcon
                                          id={"icon_nav_" + index.id}
                                          className="bookmark-icon-nav"
                                          icon={faBookmark}
                                        />
                                        {index.name}
                                      </Nav.Link>
                                    );
                                  })}
                              </Nav>
                            </>
                          )}

                          {this.state.selectedMenu == "ask_menu" && (
                            <>
                              <div className="ask-wrapper">
                                <img src={AskGroupImg} className="ask-img" />
                                <div className="ask-ip-wrapper">
                                  <label>Ask Your Questions</label>
                                  <textarea
                                    onChange={(evt) => {
                                      this.setState({
                                        questions: evt.target.value,
                                      });
                                    }}
                                    id="question_val"
                                    value={this.state.questions}
                                  />
                                  <button
                                    onClick={this.addQuestions.bind(this)}
                                    className="ask-submit"
                                  >
                                    Submit
                                  </button>
                                </div>
                              </div>
                              <div>
                                {this.state.questionList &&
                                  this.state.questionList.map((val) => {
                                    return (
                                      <div className="question-list">
                                        <div className="id-val">
                                          {val.id}
                                          {" Q "}
                                        </div>
                                        <div className="q-val">{val.value}</div>
                                      </div>
                                    );
                                  })}
                              </div>
                            </>
                          )}
                        </div>
                  </div>
              
                
                </div>
                <div className="  sub-menu-content-wrapper">
                  {childInfo && (
                    <div className="d-none d-sm-none d-md-none d-lg-block  sub-menu-content-inner">
                      {/* <div className="sub-menu-help-me">
                        <img src={ThinkGifImg} />
                      </div> */}
                      <div className="sub-menu-content-group">
                       
                        {/* <div className="sub-menu-content-group-line">
                                        <div id="audioImg_menu" onClick={this.selectTopics.bind(this)} className="sub-menu-icon-wrapper">
                                                <img src={audioImg} className="sub-menu-icon" />
                                                <p> Audio</p>
                                        </div>
                                        <div id="audioImg_menu" onClick={this.selectTopics.bind(this)} className="sub-menu-icon-wrapper">
                                            <img src={AskImg} className="sub-menu-icon" />
                                            <p> Ask</p>
                                        </div>
                                    </div> */}
                        <div>
                          {this.state.selectedMenu == "topics_menu" && (
                            <>
                              {this.state.chapterContent &&
                                this.state.chapterContent.topicList && (
                                  <span className="topics-title"> Topics</span>
                                )}

                              <Nav className="flex-column navigation-sub-chapter">
                                {this.state.chapterContent &&
                                  this.state.chapterContent.topicList &&
                                  this.state.chapterContent.topicList.map(
                                    (index) => {
                                      return (
                                        <Nav.Link
                                          className="nav_link_content"
                                          onClick={this.navigationSubChapter.bind(
                                            this
                                          )}
                                          eventKey={index.topicName}
                                        >
                                          {index.topicName}
                                        </Nav.Link>
                                      );
                                    }
                                  )}
                              </Nav>
                            </>
                          )}
                          {this.state.selectedMenu == "book_mark" && (
                            <>
                              <Nav className="flex-column navigation-sub-chapter">
                                {this.state.bookmarkValue &&
                                  this.state.bookmarkValue.map((index) => {
                                    return (
                                      <Nav.Link
                                        className="nav_link_content"
                                        onClick={this.navigationSubChapter.bind(
                                          this
                                        )}
                                        eventKey={index.id}
                                      >
                                        <FontAwesomeIcon
                                          id={"icon_nav_" + index.id}
                                          className="bookmark-icon-nav"
                                          icon={faBookmark}
                                        />
                                        {index.name}
                                      </Nav.Link>
                                    );
                                  })}
                              </Nav>
                            </>
                          )}

                          {this.state.selectedMenu == "ask_menu" && (
                            <>
                              <div className="ask-wrapper">
                                <img src={AskGroupImg} className="ask-img" />
                                <div className="ask-ip-wrapper">
                                  <label>Ask Your Questions</label>
                                  <textarea
                                    onChange={(evt) => {
                                      this.setState({
                                        questions: evt.target.value,
                                      });
                                    }}
                                    id="question_val"
                                    value={this.state.questions}
                                  />
                                  <button
                                    onClick={this.addQuestions.bind(this)}
                                    className="ask-submit"
                                  >
                                    Submit
                                  </button>
                                </div>
                              </div>
                              <div>
                                {this.state.questionList &&
                                  this.state.questionList.map((val) => {
                                    return (
                                      <div className="question-list">
                                        <div className="id-val">
                                          {val.id}
                                          {" Q "}
                                        </div>
                                        <div className="q-val">{val.value}</div>
                                      </div>
                                    );
                                  })}
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
            </div>
            
          </div>
        )}
        {!this.state.loading && <FooterGreen />}
      </div>
    );
  }
}
export default Courses;
