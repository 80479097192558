import React, { useState, useEffect } from "react";
import "../grades/chapter.scss";
import "./subjectHeader.scss";
import LogoImg from "../../images/Logoicon.png";
import MenuImg from "../grades/images/menu.png";
import CommunityImg from "../grades/images/community.png";
import LivePng from "../grades/images/live.png";
import StudentImgGreen from "../grades/images/profile-picture-green.svg";
import StudentImgOrange from "../grades/images/profile-picture-orange.svg";
import StudentImgGrey from "../grades/images/profile-picture-grey.svg";
import StudentImgRed from "../grades/images/profile-picture-red.svg";
import NotificationImg from "../grades/images/notification.png";
import $ from 'jquery';

import { useSelector, useDispatch } from "react-redux";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLessThan } from "@fortawesome/free-solid-svg-icons";

import { useHistory } from "react-router-dom";
import { Nav, NavDropdown, Navbar, Container, DropdownButton, Dropdown } from "react-bootstrap";
// API
import axios from "../../api/axios";
import { ApiServices } from "../../api/ApiServices";
import { GetSubjectURL } from "../../urls";

// Redux Actions
import { authenticationActions } from "../../reducers/authenticationReducers";
import { parentInfoActions } from "../../reducers/parentInfoReducers";
import { childInfoActions } from "../../reducers/childInfoReducers";
import { subjectActions } from "../../reducers/subjectReducers";


const SubjectHeader = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    let childInfo = JSON.parse(localStorage.getItem("child"));
    let childToken = JSON.parse(localStorage.getItem("childToken"));

    // Redux Variables
    const storedSubjectList = useSelector((state) => state.subjects.data);
    const LoggedInfo = useSelector((state) => state.authentication);
    console.log(props.homeHeader);
    const [chapterList, setChapterList] = useState([]);
    const [subjectList, setSubjectList] = useState(storedSubjectList ? storedSubjectList : []);
    const [selecteSubjectName, setSelectedSubjectName] = useState('');
    const [show, setShow] = useState(false);
    const [showFlagMobile, setShowFlagMobile] = useState();

    useEffect(() => {
        let childToken = JSON.parse(localStorage.getItem("childToken"));

        if (
            (!LoggedInfo.userInfo || !LoggedInfo.userInfo.token) &&
            childToken &&
            childToken.id &&
            childToken != ""
        ) {
            axios.get(`${ApiServices("getuserBasedonId")}&wstoken=${childToken.token}&userid=${childToken.id}`).then(
                (response) => {
                    axios.get(GetSubjectURL(childToken.token)).then((res) => {
                        let boardInfo = "";
                        if (!res.data.message) {
                            res.data.forEach((val) => {
                                if (response.data.childinfo && response.data.childinfo[0] && val.name == response.data.childinfo[0].board) {
                                    boardInfo = val;
                                }
                            });
                            res.data.forEach((val) => {
                                if (boardInfo.id == val.parent && val.name == `GRADE ${response.data.childinfo[0].grade}`) {
                                    var childGrade = val;
                                    window.gradeId = val.id;

                                    let childInfo = {
                                        ...response.data.childinfo[0],
                                        gradeId: childGrade,
                                        token: childToken.token,
                                        id: response.data.childinfo[0].childId,
                                        chatId: res.data.find((category) => category.name == `${response.data.childinfo[0].board} Chat`)
                                    };

                                    dispatch(
                                        authenticationActions.login({
                                            token: childToken.token,
                                            email: response.data.childinfo[0].email,
                                            username: response.data.childinfo[0].username,
                                            id: response.data.childinfo[0].childId,
                                            role: "child",
                                        })
                                    );
                                    dispatch(childInfoActions.addInfo(childInfo));
                                    dispatch(
                                        parentInfoActions.addInfo({
                                            ...response.data.parentdetails,
                                        })
                                    );

                                    window.token = childToken.token;

                                    let subjectList = res.data.filter(category => category.parent == val.id);
                                    let subjectChapterList = [];

                                    axios.get(`${ApiServices("getEnrolledCourses")}${process.env.REACT_APP_MOODLE_TOKEN}&userid=${response.data.childinfo[0].childId}`).then((courses) => {
                                        subjectList.forEach((subject) => {
                                            let chapters = [];
                                            chapters = courses.data.filter(course => course.categoryid == subject.id);
                                            subjectChapterList.push({ ...subject, chapters })
                                        })
                                        setTimeout(() => {
                                            dispatch(
                                                subjectActions.addSubject(subjectChapterList)
                                            );
                                            setSubjectList(subjectChapterList);
                                        }, 100);
                                    });
                                }
                            })
                        } else {
                            localStorage.removeItem("childToken")
                        }
                    })
                });
        }
    }, []);

    const logout = () => {
        dispatch(authenticationActions.logout());
        dispatch(parentInfoActions.removeInfo());
        dispatch(childInfoActions.removeInfo());
        localStorage.removeItem("parentToken");
        localStorage.removeItem('child');
        localStorage.removeItem('childToken');
        sessionStorage.removeItem('childToken');
        sessionStorage.removeItem('child');
        window.location.href = '#/home';
    }

    const handleSubjectClick = (event) => {
        let subjectId = event.target.attributes.subjectid.value;
        subjectList.forEach(subj => {
            if (subj.id == subjectId) {
                setSelectedSubjectName(subj.name)
                setChapterList(subj.chapters);
            }
        });
    }

    const navigationChapterGroup = (event) => {
        window.location.href = `#/courses/${event.currentTarget.id}?chapter=${event.currentTarget.id}`;
        if (props.navigationBetweenChapter) {
            props.navigationBetweenChapter(event);
        }
    }

    let sortedSubject = [];
    if (subjectList && subjectList.length > 0) {
        sortedSubject = [...subjectList];
        sortedSubject = sortedSubject.sort((a, b) => a.name > b.name ? 1 : -1);
    }

    const chooseType = (event) => {
        $('.link-list').removeClass('selected');
        // window.location.href = '#/dashboard?view=' + value;
        props.chooseType(event)
    }

    const profileColorSelector = () => {
        if (props.profileColor) {
            if (props.profileColor.toLowerCase() == "red") {
                return StudentImgRed;
            }
            if (props.profileColor.toLowerCase() == "green") {
                return StudentImgGreen;
            }
            if (props.profileColor.toLowerCase() == "orange") {
                return StudentImgOrange;
            }
        }
        return StudentImgGrey;
    }
    console.log(childToken);
    return (
        <div className="header-container">
            <Navbar collapseOnSelect className={`${props.headerColour ? props.headerColour : ""} header-menu `}>
                <Container>
                    <Navbar.Brand>
                        <img onClick={() => { window.location.href = '#/dashboard?view=dashboard'; window.location.reload() }} src={LogoImg} className=" Group-2" />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        {props.subjectName &&
                            <Nav className="col-6 col-xs-3 col-sm-5 col-md-6 col-lg-4 me-auto header-link">
                                <Nav.Link href="#/dashboard?view=subjects"> {props.subjectName && <><FontAwesomeIcon icon={faLessThan} className="icon" /> {props.subjectName}</>}</Nav.Link>
                            </Nav>
                        }
                        {(Number(childToken?.package) == 0 || !childToken?.package) &&
                            <Nav className="col-6 col-xs-5 col-sm-5 col-md-6 col-lg-5 me-auto header-link back-button-wrapper">
                                <Nav.Link href="#/discover-courses"> <FontAwesomeIcon icon={faLessThan} className="icon" /> Discover Courses</Nav.Link>
                            </Nav>
                        }

                        <Nav className={`col-3 col-xs-6 col-md-6 header-menus ${(childToken?.package && Number(childToken?.package) > 0) ? "" : "d-none"}`}>
                            {props.home && <>
                                <>
                                <div onClick={chooseType.bind(this)} id='dashboard' className="col-3 link-list">Dashboard</div>
                                {props.homeHeader && props.homeHeader.map((val, i) => {
                                    return (
                                        <div key={i} onClick={chooseType.bind(this)} id={val?.id} className="col-3 link-list">{val?.name}</div>
                                    )
                                })

                                }
                                
                                </>
                            </>}
                        </Nav>
                        <Nav className={`${(Number(childToken?.package) == 0 || !childToken?.package) ? "logout-seperate-wrapper" : "d-none"}`}>
                            <div onClick={logout} className="logout-child mt-2 font-bold">Logout</div>
                        </Nav>
                        <Nav className={`col-3 col-xs-3 col-sm-3 col-md-3 col-lg-3 pull-right ${(childToken?.package && Number(childToken?.package) > 0) ? "" : "d-none"}`}>
                            <Nav.Link>
                                <img src={CommunityImg} className="d-none d-xs-none d-sm-flex community-img" />
                            </Nav.Link>
                            <Nav.Link>
                                <img src={LivePng} className="d-none d-xs-none d-sm-flex community-img" />
                            </Nav.Link>
                            <Nav.Link>
                                <img src={NotificationImg} className="d-none d-xs-none d-sm-flex community-img" />
                            </Nav.Link>
                            {childInfo &&
                                <NavDropdown className="menu-item" title={
                                    <div className="pull-left">
                                        <img className="thumbnail-image"
                                            src={profileColorSelector()}
                                            alt="user pic"
                                        />
                                    </div>
                                } id="basic-nav-dropdown" align="end" >
                                    <div className="group-student-name">
                                        <div>
                                            <span onClick={() => { window.location.href = '#/dashboard?view=dashboard'; window.location.reload() }} className="chapter-header-title">Dashboard</span>
                                            <span onClick={() => window.location.href = '#/community'} className="chapter-header-title">Messages</span>
                                            <span onClick={() => window.location.href = '#/activities'} className="chapter-header-title ">Assignments</span>
                                        </div>
                                        <div className="d-none d-xs-none d-sm-none d-md-block user-info-details highlight-color">
                                            {/* <span className="subscription-plan">Subscription Plan</span> */}
                                            <div className="chapter-group">
                                                <span className="chapter-header-title cursor-default">{childToken?.firstname}</span>
                                                <span className="chapter-header-title cursor-default">{childToken?.gradeId?.name}</span>
                                            </div>
                                        </div>

                                        <div className="d-none d-xs-none d-sm-none d-md-block student-info highlight-color">
                                            <img className="student-icon" src={StudentImgGrey} />
                                            {/* <div className="chapter-group">
                                                <span className="chapter-header-title">{childToken.firstname}</span>
                                                <span className="chapter-header-title">{childToken.gradeId.name}</span>
                                            </div> */}
                                        </div>
                                    </div>
                                    <div onClick={logout} className="logout-child mt-2 font-bold">Logout</div>
                                </NavDropdown>
                                // <DropdownButton id="studnet-info" title="" align="end" className={`col-2 col-xs-2 col-md-2 col-lg-3 icon-menu`}>
                                //     <Dropdown.Item className="menu-item" >

                                //     </Dropdown.Item>
                                // </DropdownButton>
                            }

                        </Nav>
                    </Navbar.Collapse>
                    {/* <div key={5} className=" student-details">
                        <span>{childToken?.boardName} {childToken?.gradeId?.name}</span>
                    </div> */}
                </Container>
            </Navbar>
        </div>

    )
    // return (
    //     <div className="header-container">
    //         {/* <SubjectModal chapters={chapterList} navigationChapterGroup={(e) => { setShow(false); setShowFlagMobile(); navigationChapterGroup(e) }} show={show} setShowFlag={setShowFlag} /> */}
    //         <div className={`header-menu ${props.headerColour}`}>
    //             <img onClick={() => {window.location.href = '#/dashboard?view=dashboard'; window.location.reload()} } src={LogoImg} className="col-2 col-xs-2 col-md-1 Group-2" />

    //             <div className="col-md-6 subjects-redirect">{props.subjectName && props.subjectName}</div>

    //             {/* <img src={MenuImg} srcSet="./images//menu@2x.png 2x, ./images//menu@3x.png 3x" className="menu" /> */}
    //             {/* <div key={2} className={props.flag ? 'hide': 'd-none d-sm-none d-md-none d-lg-none d-xl-flex'}>
    //                 {sortedSubject && sortedSubject.map((value, j) => {
    //                     return (
    //                         <>
    //                             <DropdownButton key={j} id="dropdown-basic-button" title={value.name ? value.name : ""} className={`${value.id == props.selectedSubject ? `${props.headerColour} ` : ""}`} disabled={(value.chapters && value.chapters.length == 0) ? true : false}>
    //                                 {value.chapters && value.chapters.map((chapter, i) => {
    //                                     return (
    //                                         <>
    //                                             {childInfo &&
    //                                                 <Dropdown.Item key={chapter.id} id={chapter.id} onClick={navigationChapterGroup.bind(this)} className="menu-item" href={`#/courses/${chapter.id}?chapter=${chapter.id}`}>

    //                                                     <span className="chapter-header-title">{chapter.displayname}</span>
    //                                                 </Dropdown.Item>
    //                                             }
    //                                             {!childInfo &&
    //                                                 <Dropdown.Item key={chapter.id} id={chapter.id} onClick={navigationChapterGroup.bind(this)} className={chapter.displayname === "View Demo" ? "menu-item" : "disable-menu-item menu-item"} href={`#/courses/${chapter.id}?chapter=${chapter.id}`}>

    //                                                     <span className="chapter-header-title">{chapter.displayname}</span>
    //                                                 </Dropdown.Item>
    //                                             }
    //                                         </>
    //                                     )

    //                                 })}
    //                             </DropdownButton>

    //                         </>
    //                     )
    //                 })
    //                 }
    //             </div> */}
    //             {/* <div className={props.flag ? "hide" : "d-sm-flex d-md-flex d-lg-flex d-xl-none mobile-view-header"}>
    //                 {sortedSubject && sortedSubject[0] &&
    //                     <>
    //                         <DropdownButton show={showFlagMobile} id="dropdown-basic-button" title={props.subjectName || 'Subjects'} >
    //                             {subjectList && subjectList.map((value, i) => {
    //                                 return (
    //                                     <div
    //                                         key={value.id}
    //                                         subjectid={value.id}
    //                                         title={value.name ? value.name : ""}
    //                                         onClick={handleSubjectClick}
    //                                         className={(value.chapters && value.chapters.length == 0) ? 'disable-menu-item' : ''}
    //                                     >{value.name}
    //                                     </div>

    //                                 )

    //                             })}
    //                         </DropdownButton>
    //                         <DropdownButton show={showFlagMobile} id="dropdown-basic-button" className="chapter-list-mobile" title='Chapters' >
    //                             {props.subjectList && props.subjectList.map((value, i) => {

    //                                 return (
    //                                     <>
    //                                         { value.chapters &&  value.chapters.map((chapter, i) => {
    //                                             return (
    //                                                 <>
    //                                                 {value.name === props.subjectName &&
    //                                                     <Dropdown.Item key={chapter.id} id={chapter.id} onClick={navigationChapterGroup.bind(this)} className="menu-item" href="">

    //                                                         <span className="chapter-header-title">{chapter.displayname}</span>
    //                                                     </Dropdown.Item>
    //                                                 }
    //                                                 </>

    //                                             )

    //                                         })
    //                                         }
    //                                     </>

    //                                 )

    //                             })}
    //                         </DropdownButton>

    //                     </>
    //                 }

    //             </div> */}

    //             <div key={4} className="d-none d-xs-none d-md-flex icon-chapter-header">
    //                 <img src={CommunityImg} className="community-img" />
    //                 <img src={LivePng} className="live-img" />
    //                 <img src={NotificationImg} className="notification-img" />
    //             </div>

    //             <div key={5} className="d-none d-sm-none d-md-none d-lg-flex student-details">
    //                 <span>{childToken?.board}: {childToken?.gradeId?.name}</span>
    //             </div>
    //         </div>

    //     </div>
    // )
};

export default React.memo(SubjectHeader);
