import ParentHeader from "./ParentHeader";
import ParentFooter from "./ParentFooter";
import { useSelector } from "react-redux";
import {useEffect, useState} from 'react';
import "./Reports.scss";
import { format } from 'date-fns'
import TextField from '@mui/material/TextField';
import StaticDateRangePicker from '@mui/lab/StaticDateRangePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import Box from '@mui/material/Box';

// Images
import Child1 from "../home/images/child-1.png";
import Child12X from "../home/images/child-1@2x.png";
import Child13X from "../home/images/child-1@3x.png";

const Reports = () => {
  let childrenInfo = useSelector((state) => state.childInfo.info);
  const [selectedIndex, setSelectedIndex]= useState();
  const [dateValue, setDateValue] = useState([null, null]);
  const [filterType, setFilterType] = useState();
  let childArray = [];
  for (const [key, value] of Object.entries(childrenInfo)) {
    childArray.push(childrenInfo[key]);
  }
  const generateReport = () => {
    window.location.href = '#/reportdetails'
  }
  const SelectIndex = (val) => {
    setSelectedIndex(val);
    setDateValue([null, null]);
    setFilterType();
  }
  const onChangeValue = (ev) => {
    setFilterType(ev.target.value);
  }
  return (
    <>
      <ParentHeader active="#/parent/reports" />
      <main className="parent-portal">
        <div className="page-header">
          <p>Reports</p>
        </div>
        <div className="parent-reports-sections">
          <div className="col-md-4 report-card-container-wrapper">
          {childArray &&
              childArray.map((child, i) => {
                return (
                  <ReportCard updateStatus={(val) => SelectIndex(val)} info={child} index={i}  />
                )
                
            })
          }
          </div>
          <div className="col-md-5 report-details">
            <div>Selet Duration</div>
            <div className="type-filter-wrapper"> 
              <div  onChange={onChangeValue} className="type-filter">
                <input type="radio" id="week" checked={filterType === "week"} name="filterType" value="week"/>
                <label for="week">This Week</label><br></br>
                <input type="radio" checked={filterType === "calender"} name="filterType" id="calender" value="calender"/>
                <label for="calender">Use Calender</label><br></br>
              </div>
              {filterType === "calender" && 
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                <StaticDateRangePicker
                  displayStaticWrapperAs="desktop"
                  value={dateValue}
                  onChange={(newValue) => {
                    setDateValue(newValue);
                  }}
                  renderInput={(startProps, endProps) => (
                    <>
                      <TextField {...startProps} />
                      <Box sx={{ mx: 2 }}> to </Box>
                      <TextField {...endProps} />
                    </>
                  )}
                />
                </LocalizationProvider>
              }
              
            </div>
            
          </div>
          {  dateValue[0] &&  dateValue[1] &&
            <div className="col-md-3 report-link">
              <div className="report-header">Generate Report</div>
              {/* <span>{dateValue[0] && format(dateValue[0], 'dd/mm/yyyy') + " " + dateValue[1] && format(dateValue[1], 'dd/mm/yyyy')}</span> */}
              <div className="duration">{ "Duration: " + format(new Date(dateValue[0]).getTime(), 'dd/MM/yyyy') + " " +  format(new Date(dateValue[1]).getTime(), 'dd/MM/yyyy') }</div>
              <div onClick={generateReport} className="report-button">Genertate Report</div>
            </div>
          }
          
        </div>
      </main>
      <ParentFooter />
    </>
  );
};

const ReportCard = (props) => {
  const setFlag = (ev) => {
    let selectedIdVal = ev.currentTarget.closest('.report-card-container').attributes.idVal.value;
    console.log(selectedIdVal);
    const currentClass = document.getElementsByClassName("report-card-container");
    for (let i = 0; i < currentClass.length; i++) {
      currentClass[i].classList.remove("selected");
    }
    for (let i = 0; i < currentClass.length; i++) {
      if (currentClass[i].attributes.idVal.value === selectedIdVal) {
        currentClass[i].classList.add("selected");
        props.updateStatus(selectedIdVal);
      }
    }
  }
  useEffect(() => {
    const currentClass = document.getElementsByClassName("report-card-container");
    for (let i = 0; i < currentClass.length; i++) {
      if (i === 0) {
        currentClass[i].classList.add("selected");
      }
    }
  }, [])
  return (
    <div onClick={setFlag} idVal={props.index} className="report-card-container">
      <div  className="child-details-card col-md-12">
        <img
          src={Child1}
          srcSet={`${Child1} 1x, ${Child12X} 2x, ${Child13X} 3x`}
          alt="Child 1"
          className="child-logo"
        />
        <div className="right-side-content">
          <div className="name-field">{props.info.firstname + ' ' + props.info.lastname}</div>
          <div className="user-name">{props.info.username}</div>
          <div className="bold-grade">Board: {props.info.board}</div>
          <div className="bold-grade">Grade: {props.info.grade}</div>
        </div>
      </div>
      <div></div>
    </div>
  );
};
export default Reports;
