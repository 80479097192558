let apiNames = [
  {
    apiName: "login",
    url: `${process.env.REACT_APP_MOODLE_URL}local/custom_webservice/logintoken.php`,
  },
  {
    apiName: "signup",
    url: `${process.env.REACT_APP_MOODLE_URL}webservice/rest/server.php?wstoken=${process.env.REACT_APP_MOODLE_TOKEN}&wsfunction=local_custom_webservice_parentregister&moodlewsrestformat=json`,
  },
  {
    apiName: "childSignup",
    url: `${process.env.REACT_APP_MOODLE_URL}webservice/rest/server.php?wstoken=${process.env.REACT_APP_MOODLE_TOKEN}&wsfunction=local_custom_webservice_child_registration&moodlewsrestformat=json`,
  },
  {
    apiName: "verifyOtp",
    url: `${process.env.REACT_APP_MOODLE_URL}webservice/rest/server.php?wstoken=${process.env.REACT_APP_MOODLE_TOKEN}&wsfunction=local_custom_webservice_parentregister_confirmation&moodlewsrestformat=json`,
  },
  {
    apiName: "sendResetLink",
    url: `${process.env.REACT_APP_MOODLE_URL}webservice/rest/server.php?wstoken=${process.env.REACT_APP_MOODLE_TOKEN}&wsfunction=local_user_forgot_password&moodlewsrestformat=json`,
  },
  {
    apiName: "updatePassword",
    url: `${process.env.REACT_APP_MOODLE_URL}webservice/rest/server.php?wstoken=${process.env.REACT_APP_MOODLE_TOKEN}&wsfunction=local_user_update_password&moodlewsrestformat=json`,
  },
  {
    apiName: "getuser",
    url: `${process.env.REACT_APP_MOODLE_URL}webservice/rest/server.php?wstoken=${process.env.REACT_APP_MOODLE_TOKEN}&moodlewsrestformat=json&wsfunction=core_user_get_users_by_field&field=username`,
  },
  {
    apiName: "updateuser",
    url: `${process.env.REACT_APP_MOODLE_URL}webservice/rest/server.php?wstoken=${process.env.REACT_APP_MOODLE_TOKEN}&moodlewsrestformat=json&wsfunction=core_user_update_users`,
  },
  {
    apiName: "updateprofilepicture",
    url: `${process.env.REACT_APP_MOODLE_URL}webservice/rest/server.php?wstoken=${process.env.REACT_APP_MOODLE_TOKEN}&wsfunction=core_user_update_picture&moodlewsrestformat=json`,
  },
  {
    apiName: "fileupload",
    url: `${process.env.REACT_APP_MOODLE_URL}webservice/rest/server.php?wstoken=${process.env.REACT_APP_MOODLE_TOKEN}&wsfunction=core_files_upload&moodlewsrestformat=json&contextid=0&component=user&filearea=draft&contextlevel=user&instanceid=2`,
  },
  {
    apiName: "allEvents",
    url: `${process.env.REACT_APP_MOODLE_URL}webservice/rest/server.php?wstoken=${process.env.REACT_APP_MOODLE_TOKEN}&moodlewsrestformat=json&wsfunction=core_calendar_get_calendar_day_view`,
  },
  {
    apiName: "allGrades",
    url: `${process.env.REACT_APP_MOODLE_URL}webservice/rest/server.php?wstoken=${process.env.REACT_APP_MOODLE_TOKEN}&moodlewsrestformat=json&wsfunction=core_course_get_categories`,
  },
  {
    apiName: "getCourseBasedOnField",
    url: `${process.env.REACT_APP_MOODLE_URL}webservice/rest/server.php?wstoken=${process.env.REACT_APP_MOODLE_TOKEN}&moodlewsrestformat=json&wsfunction=core_course_get_courses_by_field&field=category&value=`,
  },
  {
    apiName: "getGroupBasedOnCourse",
    url: `${process.env.REACT_APP_MOODLE_URL}webservice/rest/server.php?wstoken=${process.env.REACT_APP_MOODLE_TOKEN}&wsfunction=core_group_get_course_groups&moodlewsrestformat=json&courseid=`,
  },
  {
    apiName: "getGroupMember",
    url: `${process.env.REACT_APP_MOODLE_URL}webservice/rest/server.php?wstoken=${process.env.REACT_APP_MOODLE_TOKEN}&wsfunction=core_group_get_group_members&moodlewsrestformat=json`,
  },
  {
    apiName: "getForumBasedOnCourse",
    url: `${process.env.REACT_APP_MOODLE_URL}webservice/rest/server.php?wstoken=${process.env.REACT_APP_MOODLE_TOKEN}&wsfunction=mod_forum_get_forums_by_courses&moodlewsrestformat=json&courseids[0]=`,
  },
  {
    apiName: "getDiscussionBasedOnForum",
    url: `${process.env.REACT_APP_MOODLE_URL}webservice/rest/server.php?wstoken=${process.env.REACT_APP_MOODLE_TOKEN}&wsfunction=mod_forum_get_forum_discussions&moodlewsrestformat=json&forumid=`,
  },
  {
    apiName: "getPostsBasedOnDiscussion",
    url: `${process.env.REACT_APP_MOODLE_URL}webservice/rest/server.php?wstoken=${process.env.REACT_APP_MOODLE_TOKEN}&wsfunction=mod_forum_get_forum_discussion_posts&moodlewsrestformat=json&discussionid=`,
  },
  {
    apiName: "createRoom",
    url: `${process.env.REACT_APP_MOODLE_URL}webservice/rest/server.php?wstoken=${process.env.REACT_APP_MOODLE_TOKEN}&wsfunction=local_custom_webservice_create_chatroom&moodlewsrestformat=json&courseid=37&createdby=2&name=`,
  },
  {
    apiName: "createRoomApproval",
    url: `${process.env.REACT_APP_MOODLE_URL}webservice/rest/server.php?wsfunction=local_custom_webservice_approvallink&moodlewsrestformat=json&courseid=37&chatid=`,
  },
  {
    apiName: "getChatList",
    url: `${process.env.REACT_APP_MOODLE_URL}webservice/rest/server.php?wstoken=${process.env.REACT_APP_MOODLE_TOKEN}&wsfunction=core_message_get_conversations&moodlewsrestformat=json&userid=`,
  },
  {
    apiName: "getChatMessages",
    url: `${process.env.REACT_APP_MOODLE_URL}webservice/rest/server.php?wstoken=${process.env.REACT_APP_MOODLE_TOKEN}&wsfunction=local_custom_webservice_chatmessage&moodlewsrestformat=json&chatid=`,
  },
  {
    apiName: "getChatSessionId",
    url: `${process.env.REACT_APP_MOODLE_URL}webservice/rest/server.php?wsfunction=mod_chat_login_user&moodlewsrestformat=json&chatid=`,
  },
  {
    apiName: "sendChatMessage",
    url: `${process.env.REACT_APP_MOODLE_URL}webservice/rest/server.php?wsfunction=core_message_send_messages_to_conversation&moodlewsrestformat=json&`,
  },
  {
    apiName: "getuserBasedonId",
    url: `${process.env.REACT_APP_MOODLE_URL}webservice/rest/server.php?wsfunction=local_custom_webservice_userinfo&moodlewsrestformat=json`,
  },
  {
    apiName: "getAllCourses",
    url: `${process.env.REACT_APP_MOODLE_URL}webservice/rest/server.php?moodlewsrestformat=json&wsfunction=core_course_get_courses&wstoken=`,
  },
  {
    apiName: "getChatInfoBasedOnId",
    url: `${process.env.REACT_APP_MOODLE_URL}webservice/rest/server.php?wsfunction=core_message_get_conversation&moodlewsrestformat=json&includecontactrequests=0&includeprivacyinfo=0&wstoken=`,
  },
  {
    apiName: "getChatMessagesWithLimit",
    url: `${process.env.REACT_APP_MOODLE_URL}webservice/rest/server.php?wstoken=${process.env.REACT_APP_MOODLE_TOKEN}&wsfunction=core_message_get_conversation_messages&moodlewsrestformat=json&convid=`,
  },
  {
    apiName: "getSearchChatMessagesWithLimit",
    url: `${process.env.REACT_APP_MOODLE_URL}webservice/rest/server.php?wstoken=${process.env.REACT_APP_MOODLE_TOKEN}&wsfunction=core_message_data_for_messagearea_search_messages&moodlewsrestformat=json&userid=`,
  },
  {
    apiName: "self_manual_enrol",
    url: `${process.env.REACT_APP_MOODLE_URL}webservice/rest/server.php?wsfunction=enrol_manual_enrol_users&moodlewsrestformat=json&enrolments[0][roleid]=5`,
  },
  {
    apiName: "volunteer",
    url: `${process.env.REACT_APP_MOODLE_URL}webservice/rest/server.php?wstoken=${process.env.REACT_APP_MOODLE_TOKEN}&wsfunction=local_submit_volunteer&moodlewsrestformat=json&`,
  },
  {
    apiName: "getCategoryCourse",
    url: `${process.env.REACT_APP_MOODLE_URL}webservice/rest/server.php?wstoken=${process.env.REACT_APP_MOODLE_TOKEN}&wsfunction=local_custom_webservice_categorysub&moodlewsrestformat=json&`,
  },
  {
    apiName: "getEnrolledCourses",
    url: `${process.env.REACT_APP_MOODLE_URL}webservice/rest/server.php?moodlewsrestformat=json&wsfunction=core_enrol_get_users_courses&wstoken=`,
  }
];
export const ApiServices = (name) => {
  const url = apiNames
    .filter((api) => api.apiName === name)
    .map((api) => api.url)
    .toString();
  return url;
};

export const GetToken = () => {
  let childToken = JSON.parse(localStorage.getItem("childToken"));

  return childToken;
};

export const GetCategories = () => { };

export const GetGradeList = () => { };
