import { createSlice } from "@reduxjs/toolkit";

const initialParentState = { info: {} };

const parentInfoSlice = createSlice({
  name: "Parent",
  initialState: initialParentState,
  reducers: {
    addInfo(state, action) {
      state.info = action.payload;
    },
    removeInfo(state) {
      state.info = {};
    },
  },
});

export const parentInfoActions = parentInfoSlice.actions;
export default parentInfoSlice.reducer;
