import { useState, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import AOS from "aos";
import axios from "../../api/axios";
import "aos/dist/aos.css";

// Data
import { GetChapters, GetGrades } from "../../data/discoverCourses";

// Common
import HomeHeader from "./HomeHeader";
import FooterGreen from "../../common/footer/footer";
// Images
import Telescope from "../../images/telescope.svg";
import Grade6 from "./images/grade-1.svg";
import Grade7 from "./images/grade-2.svg";
import Grade8 from "./images/grade-3.svg";
import Grade9 from "./images/grade-4.svg";
import Grade10 from "./images/grade-5.svg";

import Kid1 from "./images/kid-1.webp";
import Kid12X from "./images/kid-1@2x.webp";
import Kid13X from "./images/kid-1@3x.webp";
import Kid2 from "./images/kid-2.webp";
import Kid22X from "./images/kid-2@2x.webp";
import Kid23X from "./images/kid-2@3x.webp";
import Kid3 from "./images/kid-3.webp";
import Kid32X from "./images/kid-3@2x.webp";
import Kid33X from "./images/kid-3@3x.webp";

// CSS
import "./DiscoverCourses.css";

// Swiper

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/bundle";
import "swiper/swiper.min.css";
import "swiper/swiper-bundle.css";

// // import Swiper core and required modules
import SwiperCore, { Navigation, Autoplay } from "swiper";

import { GetToken } from "../../api/ApiServices";
import * as type from "../../urls";
import { ApiServices } from "../../api/ApiServices";
import { map } from "jquery";

// // install Swiper modules
SwiperCore.use([Navigation, Autoplay]);


const DiscoverCourses = () => {
  const history = useHistory();
  const [gradesList, SetGradesList] = useState([]);
  const [loop, SetLoop] = useState(false);
  const [selectedGrade, SetSelectedGrade] = useState(6);
  const myRef = useRef(null);
  const [subjectContent, setSubjectContent] = useState([]);


  useEffect(() => {
    AOS.init({
      disable: 'mobile',
      duration: 2000
    });
    AOS.refresh();
    if (subjectContent.length == 0 && gradesList.length == 0) {
      getSubject();
    }
  }, [subjectContent, gradesList]);

  const executeScroll = () =>
    myRef.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });

  const goToSignUp = () => {
    // history.push("./signup");
  };

  const getSubject = () => {
    if (!window.token) {
      window.token = process.env.REACT_APP_MOODLE_TOKEN;
    }
    getSubjectList();
  };
  const getSubjectList = () => {
    axios.get(`${ApiServices("getCategoryCourse")}boardName=&gradeName=&courses=true`).then((res) => {
      let temp = [];
      res.data.categorylist.forEach((board) => {
        if (board.name == "CBSE_DEMO") {
          board.grades.forEach((grade) => {
            if (grade.name.toLowerCase() === `grade ${selectedGrade}` && grade.parent == board.id) {
              setSubjectContent(grade.subject);
            }

          });
          SetGradesList(board.grades.filter((grade) => grade.parent == board.id));
          SetLoop(gradesList?.length >= 5 ? true : false);
        }
      })
    });
  };

  const redirectSubjectContent = (ev) => {
    let grade = ev.currentTarget.getAttribute("grade");
    let gradename = ev.currentTarget.getAttribute("gradename");
    let content = ev.currentTarget.getAttribute("content");
    let subject = ev.currentTarget.getAttribute("subject");
    let sessionChild = JSON.parse(sessionStorage.getItem("child"));

    if (sessionChild?.id) {
      // window.location.href = `#/courses/${grade}?grade=${grade}&subject=${subject}&chapter=${content}&flag=true&view=true&board=CBSE`;
      history.push(`/courses/${grade}?grade=${grade}&subject=${subject}&chapter=${content}&flag=true&view=true&board=CBSE`)
    } else {
      history.push({
        pathname: "./signup",
        state: {
          grade: grade,
          gradename: gradename,
          subject: subject,
          chapter: content
        }
      })
    }
  };

  const ChangeSubjectHandler = (gradeName) => {
    gradesList.forEach((grade) => {
      if (grade.name.toLowerCase() === gradeName.toLowerCase()) {
        setSubjectContent(grade.subject);
        SetSelectedGrade(parseInt(grade.name.toLowerCase().replace("grade ", "")))
      }
    });
  }
  return (
    <>
      <HomeHeader active="/#/discover-courses" />
      <section className="heading-section d-flex">
        <div className="heading-content w-50">
          <p
            className="content-start"
            data-aos="fade-right"
            data-aos-duration="500"
            data-aos-easing="ease-in-sine"
          >
            Get your kids the
          </p>
          <p
            className="content-end"
            data-aos="fade-right"
            data-aos-duration="500"
            data-aos-easing="ease-in-sine"
          >
            best guide
          </p>
          <p
            className="secondary-content"
            data-aos="fade-right"
            data-aos-duration="500"
            data-aos-easing="ease-in-sine"
          >
            Our trained facilitators reimage traditional-based learning into
            virtual-based learning. It is a student-centered approach where our
            educators will be silent observers. They will help to decode,
            declutter, and add new information to the students’ prior knowledge.{" "}
          </p>
          <button
            className="heading-button"
            onClick={executeScroll}
            data-aos="fade-right"
            data-aos-duration="500"
            data-aos-easing="ease-in-sine"
          >
            <div className="d-flex">
              <p className="button-text">KNOW MORE</p>
              <svg viewBox="0 0 300 100">
                <defs>
                  <marker
                    id="arrowhead"
                    markerWidth="10"
                    markerHeight="8"
                    refX="0"
                    refY="3.5"
                    orient="auto"
                  >
                    <polygon
                      className="heading-button-polygon"
                      points="0 0, 7 3.5, 0 7"
                    ></polygon>
                  </marker>
                </defs>
                <line
                  x1="90"
                  y1="-70"
                  x2="90"
                  y2="70"
                  stroke="#fff"
                  strokeWidth="15"
                  markerEnd="url(#arrowhead)"
                ></line>
              </svg>
            </div>
          </button>
        </div>
        <div
          className="d-none d-sm-none d-md-none d-lg-none d-xl-block heading-image w-50"
          data-aos="fade-up-left"
          data-aos-duration="1000"
        >
          <img src={Telescope} alt="Telescope" />
        </div>
      </section>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 100 100"
        preserveAspectRatio="none"
        className="polygon-layout"
      >
        <polygon fill="#236d06" points="0,0 0,100 400,200" />
      </svg>
      <section className="best-section" ref={myRef}>
        <p
          className="best-heading"
          data-aos="zoom-in-down"
          data-aos-duration="500"
        >
          Why we are the best
        </p>
        <div className="flex-class">
          <div className="col-12 col-sm-12 col-md-4">
            <p
              className="best-sub-heading"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              Our Pedagogy
            </p>
            <p
              className="best-sub-content"
              data-aos="fade-up"
              data-aos-duration="2000"
            >
              Our trained facilitators designed different types of curriculum
              for all levels of learning kids to enhance divergent thinking. The
              pedagogy for learning will not focus on academics alone but also
              unleash your kid’s inherent talents through various approaches.
              The essential skills required for the learners are to have a
              thirst for learning innovative things and getting hands-on
              experience.{" "}
            </p>
          </div>
          <div className="col-md-4">
            <p
              className="best-sub-heading"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              Our Approach
            </p>
            <p
              className="best-sub-content"
              data-aos="fade-up"
              data-aos-duration="2000"
            >
              Making learning a fun process is our motto. Right from interactive
              sessions to game-based learning, we have it all! We envision a
              world of peer-group teaching, where curiosity and creativity are
              the foundation of learning. To bring your dream into reality, our
              mentors will facilitate your kids to develop their critical
              thinking, questioning ability, and problem-solving skills.{" "}
            </p>
          </div>
          <div className="col-md-4">
            <p
              className="best-sub-heading"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              Our Content
            </p>
            <p
              className="best-sub-content"
              data-aos="fade-up"
              data-aos-duration="2000"
            >
              Concepts are presented to the students from the grass root level
              making the content more relatable and easier to understand. To
              widen his area of knowledge and delve deeper into the concepts, we
              provide study materials, book solutions, practice questions,
              Interactive sessions, quiz and mind maps in presentable way with
              videos, voice over and mascot assistance. We ensure that syllabus
              is put forth in such a way that interests a child, and while
              browsing the content he gets lured into the process of learning
              and not just studying.{" "}
            </p>
          </div>
        </div>
        <div className="text-center">
          <button className="best-button" onClick={goToSignUp}>
            <div className="d-flex">
              <p className="button-text">I'm interested</p>
              <svg viewBox="0 0 450 100">
                <defs>
                  <marker
                    id="bestarrowhead"
                    markerWidth="10"
                    markerHeight="7"
                    refX="0"
                    refY="3.5"
                    orient="auto"
                  >
                    <polygon
                      className="best-button-polygon"
                      points="0 0, 10 3.5, 0 7"
                    ></polygon>
                  </marker>
                </defs>
                <line
                  x1="0"
                  y1="50"
                  x2="350"
                  y2="50"
                  stroke="#236d06"
                  strokeWidth="7"
                  markerEnd="url(#bestarrowhead)"
                ></line>
              </svg>
            </div>
          </button>
        </div>
      </section>
      <section className="grades-section">
        <svg className="grades-top-design">
          <g fill="none" stroke="none">
            <rect
              className="yellow-bar"
              x="0"
              width="33%"
              y="0"
              height="100%"
            ></rect>
            <rect
              className="green-bar"
              x="33%"
              width="33%"
              y="0"
              height="100%"
            ></rect>
            <rect
              className="blue-bar"
              x="66%"
              width="34%"
              y="0"
              height="100%"
            ></rect>
          </g>
        </svg>
        <div className="col-md-11 grades-container">
          <Swiper
            breakpoints={{
              1335: {
                slidesPerView: 5,
                slidesPerGroup: 5,
              },
              1000: {
                slidesPerView: 4,
                slidesPerGroup: 4
              },
              800: {
                slidesPerView: 3,
                slidesPerGroup: 3
              },
              100: {
                slidesPerView: 2,
                slidesPerGroup: 2
              },
            }}
            spaceBetween={0}
            // loop={gradesList?.length >= 5 ? true : false}
            loop={loop}
            loopFillGroupWithBlank={true}
            navigation={true}
            className="mySwiper"
            speed={5000}
            autoplay={{ delay: 5000 }}
          >
            {gradesList &&
              gradesList.map((grade, index) => {
                if (grade.id) {
                  return (
                    <SwiperSlide key={grade.id} onClick={() => { ChangeSubjectHandler(grade.name) }}>
                      <div
                        className={`home-grade-container ${grade.name.toLowerCase() == `grade ${selectedGrade}` ? "home-grade-active" : ""
                          }`}
                      >
                        <div className="col-md-5 p-0">
                          <img src={require(`./images/grade-${grade.name.toLowerCase().replace("grade ", "")}.svg`).default} className="home-grade-image" />
                        </div>
                        <div className="col-md-7 home-grade-name">
                          {grade.name}
                        </div>
                      </div>
                    </SwiperSlide>
                  );
                }
              })}
          </Swiper>
          <div className="home-grade-chapters-container">
            <div className="col-md-4 home-grade-chapters-image">
              <svg width="200" height="200">
                <rect
                  width="95"
                  height="95"
                  x="50"
                  y="-50"
                  rx="10"
                  ry="10"
                  transform="rotate(45)"
                  className="home-grade-image-background"
                ></rect>
                <image href={require(`./images/grade-${selectedGrade}.svg`).default} height="140" width="140"></image>
              </svg>
            </div>
            <div className="col-sm-12 col-md-7 home-grade-chapters-content">
              <p className="title">GRADE {selectedGrade}</p>
              {subjectContent?.map((subject, ix) => {
                return (<>
                  {subject.course?.map((e, i) => {
                    if (i == 0) {
                      return (
                        <div
                          onClick={redirectSubjectContent}
                          subject={e.category}
                          grade={subject.parent}
                          gradename={selectedGrade}
                          content={e.id}
                          className="chapter-container"
                          key={i}
                          data-aos="fade-right"
                          data-aos-duration={ix * 500}
                        >
                          <div className="d-flex">
                            <div className="chapter-title-container">
                              <p className="chapter-title">{subject.name}</p>
                            </div>
                            <svg viewBox="0 0 950 100">
                              <defs>
                                <marker
                                  id="chapterarrowhead"
                                  markerWidth="10"
                                  markerHeight="7"
                                  refX="0"
                                  refY="3.5"
                                  orient="auto"
                                >
                                  <polygon
                                    className="chapter-button-polygon"
                                    points="0 0, 10 3.5, 0 7"
                                  ></polygon>
                                </marker>
                              </defs>
                              <line
                                x1="0"
                                y1="50"
                                x2="150"
                                y2="50"
                                stroke="#236d06"
                                strokeWidth="7"
                                markerEnd="url(#chapterarrowhead)"
                              ></line>
                            </svg>
                          </div>
                          <p className="chapter-content">{e.fullname}</p>
                        </div>
                      )
                    }
                  })}
                </>)

              })}
            </div>
          </div>
        </div>
        <svg
          viewBox="0 0 100 100"
          preserveAspectRatio="none"
          className="grades-bottom-design"
        >
          <polygon fill="#d84933" points="1,100 100,0 200,100" />
        </svg>
      </section>
      <section className="learning-section">
        <div className="col-md-8">
          <p
            className="learning-section-title"
            data-aos="zoom-in-right"
            data-aos-duration="500"
          >
            Our approach <br /> to learning
          </p>
          <div className="flex-class">
            <div className="col-12 col-sm-12 col-md-6 learning-container">
              <p
                className="learning-title"
                data-aos="fade-down-right"
                data-aos-duration="1000"
              >
                Activity based learning
              </p>
              <p
                className="learning-content"
                data-aos="fade-down-right"
                data-aos-duration="500"
              >
                We at Shivani academy promote activity-based learning. Knowing
                the diversity and uniqueness of students we provide such type of
                exposure to the kids where they can actively participate in the
                learning. This means they can learn at their own pace under our
                teachers’ supervision. We do not promote rote learning as we
                believe that students will learn better when they do it by
                themselves actively.
              </p>
            </div>
            <div className="col-12 col-sm-12 col-md-6 learning-container">
              <p
                className="learning-title"
                data-aos="fade-down-left"
                data-aos-duration="1000"
              >
                Self-learn phase
              </p>
              <p
                className="learning-content"
                data-aos="fade-down-left"
                data-aos-duration="500"
              >
                Our portal is embedded with a self-learning phase where the
                child would not require any guidance from the elder one. The
                child would learn with much ease, and this would prompt them to
                behold their level of skills to educate themselves. The sessions
                provided just lead the children throughout the lesson on the
                easy learning path. The granted materials enhance the child’s
                efficiency to have a love for learning.
              </p>
            </div>
          </div>
          <div className="flex-class">
            <div className="col-12 col-sm-12 col-md-6 learning-container">
              <p
                className="learning-title"
                data-aos="fade-up-right"
                data-aos-duration="700"
              >
                Learn with ease
              </p>
              <p
                className="learning-content"
                data-aos="fade-up-right"
                data-aos-duration="700"
              >
                Here at Shivani academy we try to reduce the burden of learning
                from kids shoulders and provide the environment and content in
                such a manner that kids don’t have to make hard efforts to learn
                and understand the concepts. Even the complex concepts have been
                explained in a smoother and easier manner. Along with that the
                learning material we provide also makes learning fun. Our child
                centered model is more engaging and interactive. Hence easier
                for the kids to learn.
              </p>
            </div>
            <div className="col-12 col-sm-12 col-md-6 learning-container">
              <p
                className="learning-title"
                data-aos="fade-up-left"
                data-aos-duration="700"
              >
                Game-based learning
              </p>
              <p
                className="learning-content"
                data-aos="fade-up-left"
                data-aos-duration="700"
              >
                Unlike the old, traditional, conventional method, this is just
                learning based on games. This leads the child progressively to
                swap their attention to learn and educate themselves. Such
                learning tools highlight the talents of the child and develop
                various skills in them to explore the world. Such education will
                speed up their learning process amidst their playtimes.
              </p>
            </div>
          </div>
          <div className="text-center">
            <button className="learning-button" onClick={goToSignUp}>
              <div className="flex-class">
                <p className="button-text">I'm interested</p>
                <svg viewBox="0 0 450 100">
                  <defs>
                    <marker
                      id="learnarrowhead"
                      markerWidth="10"
                      markerHeight="7"
                      refX="0"
                      refY="3.5"
                      orient="auto"
                    >
                      <polygon
                        className="learning-button-polygon"
                        points="0 0, 10 3.5, 0 7"
                      ></polygon>
                    </marker>
                  </defs>
                  <line
                    x1="0"
                    y1="50"
                    x2="350"
                    y2="50"
                    stroke="#d84933"
                    strokeWidth="7"
                    markerEnd="url(#learnarrowhead)"
                  ></line>
                </svg>
              </div>
            </button>
          </div>
        </div>
        <div className="col-md-4" data-aos="fade-left" data-aos-duration="1000">
          <img
            src={Kid1}
            srcSet={`${Kid1} 1x, ${Kid12X} 2x, ${Kid13X} 3x`}
            alt="Learning Kid 1"
            className="d-none d-sm-none d-md-inline-flex  learning-kids-1"
          />
          <img
            src={Kid2}
            srcSet={`${Kid2} 1x, ${Kid22X} 2x, ${Kid23X} 3x`}
            alt="Learning Kid 2"
            className="d-none d-sm-none d-md-inline-flex  learning-kids-2"
          />
          <img
            src={Kid3}
            srcSet={`${Kid3} 1x, ${Kid32X} 2x, ${Kid33X} 3x`}
            alt="Learning Kid 3"
            className="d-none d-sm-none d-md-inline-flex  learning-kids-3"
          />
        </div>
      </section>
      <FooterGreen />
    </>
  );
};

export default DiscoverCourses;
