import { useState } from "react";
import { useLocation } from "react-router-dom";
import Moment from "moment";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import ParentHeader from "./ParentHeader";
import ParentFooter from "./ParentFooter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

// IMAGES
import ChatIcon from "../../images/chat-icon.svg";
import NotificationIcon from "../../images/notification-icon.svg";
import LiveTalk from "../../images/group.jpg";
// CSS
import "./ParentDashboard.scss";

let courseUpdates = [
  {
    id: 1,
    content:
      "<p><span class='grade'>Grade 1</span> <span class='subject'>Maths</span> - Chapter 3 - <b><em>Shapes and Space</em></b> - Course is added on <b>28-1-2022</b></p>",
  },
  {
    id: 2,
    content:
      "<p><span class='grade'>Grade 1</span> <span class='subject'>Maths</span> - Chapter 2 - <b><em>Shapes and Space</em></b> - Course is added on <b>28-1-2022</b></p>",
  },
  {
    id: 3,
    content:
      "<p><span class='grade'>Grade 1</span> <span class='subject'>Maths</span> - Chapter 1 - <b><em>Shapes and Space</em></b> - Course is added on <b>28-1-2022</b></p>",
  },
  {
    id: 4,
    content:
      "<p><span class='grade'>Grade 1</span> <span class='subject'>English</span> - Chapter 1 - <b><em>Shapes and Space</em></b> - Course is added on <b>28-1-2022</b></p>",
  },
];

let notificationUpdates = [
  {
    id: 1,
    content:
      "<p><span class='logo-name-1'>Shivani</span> <span class='logo-name-2'>Academy</span> Portal is launching Soon - <b>15.02.2022</b>",
  },
];

const ParentDashboard = () => {
  const [dateSelect, SetDateSelect] = useState(new Date());
  const DateSelectHandler = (event) => {
    SetDateSelect(event);
  };

  let currentMonth = Moment(dateSelect).format("MMMM");
  let currentDate = Moment(dateSelect).format("D");
  let currentYear = Moment(dateSelect).format("YYYY");

  const DateTrail = (number) => {
    if (number == 1) {
      return "st";
    }
    if (number == 2) {
      return "nd";
    }
    if (number == 3) {
      return "rd";
    }
    return "th";
  };
  return (
    <>
      <ParentHeader active="#/parent/home" />
      <main className="parent-dashboard row">
        <div className="col-md-12 d-flex p-20-15">
          <div className="col-md-9">
            <div className="update-section" key="1">
              <div className="d-flex">
                <img src={ChatIcon} alt="Chat Icon" />
                <p className="update-title">Course Updates</p>
              </div>
              <div className="update-list-container">
                {courseUpdates.map((e, i) => {
                  return (
                    <div key={i} className="update-container">
                      <FontAwesomeIcon
                        icon={faArrowRight}
                        className="update-list-icon"
                      />
                      <p
                        className="update-content updates-decoration"
                        dangerouslySetInnerHTML={{ __html: e.content }}
                      ></p>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="update-section" key="2">
              <div className="d-flex">
                <img src={NotificationIcon} alt="Chat Icon" />
                <p className="update-title">Notification</p>
              </div>
              <div className="update-list-container">
                {notificationUpdates.map((e, i) => {
                  return (
                    <div key={i} className="update-container">
                      <FontAwesomeIcon
                        icon={faArrowRight}
                        className="update-list-icon"
                      />
                      <p
                        className="update-content updates-decoration"
                        dangerouslySetInnerHTML={{ __html: e.content }}
                      ></p>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="col-md-3 br-5 events-list-section">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                variant="static"
                format="dd-MMM-yyyy"
                value={dateSelect}
                className={``}
                onChange={DateSelectHandler}
                disableToolbar
                animateYearScrolling
                allowKeyboardControl
                autoOk
              />
            </MuiPickersUtilsProvider>
            <div className="event-list-container">
              <p className="event-date">
                Activities on {currentMonth} {currentDate}
                {DateTrail(currentDate)} {currentYear}
              </p>
              <div className="event-classification" key="1">
                <p className="event-title">Beyond Learning</p>
                <div className="event-detail">
                  <p className="event-info">
                    Check back later for new activities
                  </p>
                </div>
                {/* <div className="d-flex event-detail">
                  <p className="event-grade">4th Grade</p>
                  <p className="event-grade-title">
                    Make a model of pythogorus theorum…
                  </p>
                </div>
                <div className="d-flex event-detail">
                  <p className="event-grade">10th Grade</p>
                  <p className="event-grade-title">Physics - Friction</p>
                </div>
                <div className="d-flex event-detail">
                  <p className="event-grade">10th Grade</p>
                  <p className="event-grade-title">Physics - Friction</p>
                </div>
                <div className="d-flex event-detail">
                  <p className="event-grade">10th Grade</p>
                  <p className="event-grade-title">Physics - Friction</p>
                </div> */}
              </div>
              <div className="event-classification" key="2">
                <p className="event-title">Events</p>
                <div className="event-detail">
                  <p className="event-info">
                    <b>
                      <em>Shivani Academy</em>
                    </b>{" "}
                    Great Launch on <b>15.02.2022</b>
                  </p>
                </div>

                {/* <div className="event-detail">
                  <p className="event-name">
                    Today’s children &amp; their future
                  </p>
                  <div className="d-flex">
                    <img
                      src={LiveTalk}
                      alt="Live Talk"
                      className="event-image"
                    />
                    <p className="event-info">
                      by Tamas Bunce, London, UK at 6:30 PM, on 30-Oct-2021
                    </p>
                  </div>
                </div>
                <div className="event-detail">
                  <p className="event-name">
                    Today’s children &amp; their future
                  </p>
                  <div className="d-flex">
                    <img
                      src={LiveTalk}
                      alt="Live Talk"
                      className="event-image"
                    />
                    <p className="event-info">
                      by Tamas Bunce, London, UK at 6:30 PM, on 30-Oct-2021
                    </p>
                  </div>
                </div>
                <div className="event-detail">
                  <p className="event-name">
                    Today’s children &amp; their future
                  </p>
                  <div className="d-flex">
                    <img
                      src={LiveTalk}
                      alt="Live Talk"
                      className="event-image"
                    />
                    <p className="event-info">
                      by Tamas Bunce, London, UK at 6:30 PM, on 30-Oct-2021
                    </p>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </main>
      <ParentFooter />
    </>
  );
};

export default ParentDashboard;
