// Common
import HomeHeader from "./HomeHeader";
import FooterGreen from "../../common/footer/footer";

import "./BuyCourses.scss";

import _ from 'lodash';
import $ from 'jquery';

import Slider from '../NetflixSlider'

import * as type from '../../urls';

import Modal from 'react-modal';

import axios from "../../api/axios";
import { ApiServices } from "../../api/ApiServices";

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import Button from 'react-bootstrap/Button';

import React from 'react';
import { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {  faSearch, faFilter, faSort } from "@fortawesome/free-solid-svg-icons";

const BuyCourse = (props) => {

    const customStyles = {
        content: {
          background: "#fff",
          width: "20rem",
          left: '75%',
          top: '20%'
        },
    };

    const [gradesList, SetGradesList] = useState({});
    const [GradesBackUpList, setGradesBackUpList] = useState({});
    const [selectedCategory, setSelectedCategory] = useState('');
    const [selectedType, SetSelectedType] = useState('');
    const [searchView, setSearchView] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [subjectContent, setSubjectContent] = useState([]);
    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [_id, setCatId] = useState('');
    const [viewMode, setViewMode] = useState(false);
    const [filterFlag, setFilterflag] = useState(false);

    const { view} = useParams();

    useEffect(() => {
        
        if (view === 'general') {
            SetSelectedType('GENERAL')
        } else {
            SetSelectedType('COMPETITIVE EXAMS')
        }
        
    }, [view]);

    useEffect(() => {
        if (_id) {
            let temp = _.cloneDeep(gradesList);
            temp?.gradeList?.forEach((grade) => {
                console.log(grade?.id, _id);
                if (grade?.id == _id) {
                    grade.selected = true;
                } else {
                    grade.selected = false;
                }
            });
            console.log('id', temp);
            SetGradesList(temp);
        }
    }, [_id]);

    const openModal = () => {
        setIsOpen(true);
    }

    function afterOpenModal() {
    // references are now sync'd and can be accessed.
    //subtitle.style.color = '#f00';
    }

    const closeModal = () => {
        setIsOpen(false);
    }


    useEffect(() => {
        getSubject(selectedType);
    }, [selectedType]);

    useEffect(() => {
        
        if (searchValue?.length > 3) {
            let temp = _.cloneDeep(gradesList);
            temp?.gradeList?.forEach((grade) => {
                let _temp = [];
                grade?.courses?.forEach((course) => {
                    if (course?.displayname?.toLowerCase().includes(searchValue)) {
                        _temp.push(course);
                    }
                });
                grade.courses = _temp;
            });
            SetGradesList(temp);
        } else {
            SetGradesList(GradesBackUpList);
        }
    }, [searchValue])

    const getSubject = () => {
        if (!window.token) {
            window.token = process.env.REACT_APP_MOODLE_TOKEN;
        }
        
        getSubjectList(selectedType);
       
    };

    const getSubjectChapters = (id) => {
        return axios
            .get(type.GetSubjectListURL(process.env.REACT_APP_MOODLE_TOKEN, id));

    }
    const getSubjectList = (type) => {
        setSelectedCategory(type);
        $('.spinner').show();
        axios.get(`${ApiServices("getCategoryCourse")}boardName=&gradeName=&courses=true`).then((res) => {
          // let temp = gradesList;
          res.data.categorylist.forEach((board) => {
            if (board.name === type) {
                board.gradeList = [];
                board?.grades.forEach((grades) => {
                    grades.selected = true;
                    if (grades.parent == board.id) {
                        board.gradeList.push(grades);
                    }
                });
                SetGradesList(board);
                setGradesBackUpList(board);
                console.log(board);
                
                board?.gradeList.forEach((grade) => {
                    if (grade.parent == board.id) {
                        getSubjectChapters(grade?.id).then((res) => {
                            grade.courses = res?.data?.courses;
                            setSubjectContent(res?.data?.courses);
                            console.log(type, res?.data?.courses);
                            setTimeout(() => {
                                const [hash, query] = window.location.hash.split('#')[1].split('?')
                                const params = Object.fromEntries(new URLSearchParams(query))
                                setCatId(params.categoryid  );
                                $('.spinner').hide();
                                setViewMode(true);
                            }, 500);
                            
                        });
                    }
        
                });
            }
          });
        });
    };

    const openSearch = () => {
        setSearchView(!searchView);
    }

    const UpdateFilter = (ev) => {

    }

    const categoryFilter = (ev) => {
        let _selectedId = ev?.target?.value;
        let temp = _.cloneDeep(gradesList);
        temp?.gradeList?.forEach((grade) => {
            if (grade?.id == _selectedId) {
                grade.selected = !grade.selected;
            }
        });
        console.log(temp);
        SetGradesList(temp);
    }

    const sortFilter = (ev) => {
        let _selectedId = ev?.target?.value;
        let temp = _.cloneDeep(gradesList);
       
        if (_selectedId === 'priceasc') {
            temp?.gradeList?.forEach((grade) => {
                grade.courses = _.sortBy(grade.courses, (course) => {
                    return course?.customfields[1].value;
                })
            });
        }
        if (_selectedId === 'pricedesc') {
            temp?.gradeList?.forEach((grade) => {
                let sorting = _.sortBy(grade.courses, (course) => {
                    return course?.customfields[1].value;
                });
                grade.courses = sorting.reverse();
            });
        }

        if (_selectedId === 'dateasc') {
            temp?.gradeList?.forEach((grade) => {
                grade.courses = _.sortBy(grade.courses, (course) => {
                    return course?.timecreated;
                });
            });
        }

        if (_selectedId === 'datedesc') {
            temp?.gradeList?.forEach((grade) => {
                let sorting = _.sortBy(grade.courses, (course) => {
                    return course?.timecreated;
                });
                grade.courses = sorting.reverse();
            });
        }

        console.log(temp);
        SetGradesList(temp)
    }

    return (
        <>
            <HomeHeader active="/#/buy-courses" />
            <section className="heading-group">
                <div className="filter-section">
                    <div className="icons">
                        {searchView &&
                            <div className="subj-search ">
                                <div className="subject-search search">
                                    <input type="text"
                                    id="outlined-basic"
                                    onChange={(ev) => setSearchValue(ev.target.value)}
                                    value={searchValue}
                                    />
                                </div>
                            </div>
                        }
                        <FontAwesomeIcon id="list-search" onClick={openSearch} icon={faSearch} className="icon active" />
                        <FontAwesomeIcon id="list-filter" onClick={() => {setFilterflag(true);openModal();}} icon={faFilter} className="icon active" />
                        <FontAwesomeIcon id="list-sort" onClick={() => {setFilterflag(false);openModal();}} icon={faSort} className="icon active" />
                        {/* <OverlayTrigger
                            trigger="click"
                            placement="bottom"
                            overlay={
                                <Popover id="popover-positioned-left">
                                <Popover.Header as="h3">Filters</Popover.Header>
                                <Popover.Body className="filter-group-wrapper">
                                    <div className="filter-single">
                                        <input value="best" onChange={UpdateFilter} type="radio" name="filterGroup" /> Best Selling
                                    </div>
                                    <div className="filter-single">
                                        <input value="dateasc" onChange={UpdateFilter} type="radio" name="filterGroup" /> Date Old to new
                                    </div>
                                    <div className="filter-single">
                                        <input value="datedesc" onChange={UpdateFilter} type="radio" name="filterGroup" /> Date New to Old
                                    </div>
                                    <div className="filter-single">
                                        <input value="priceasc" onChange={UpdateFilter} type="radio" name="filterGroup" /> Price: Low to High
                                    </div>
                                    <div className="filter-single">
                                        <input value="pricedesc" onChange={UpdateFilter} type="radio" name="filterGroup" /> Price: High to Low
                                    </div>
                                    <div className="filter-single">
                                        <button value="" onClick={UpdateFilter} > Reset </button>
                                    </div>
                                </Popover.Body>
                                </Popover>
                            }
                            >
                            <Button className="filter-wrapper"><FontAwesomeIcon id="filter" icon={faSort} className="icon active" /></Button>

                        </OverlayTrigger> */}
                        {/* <FontAwesomeIcon id="grid" onClick={this.setView.bind(this)} icon={faBorderAll} className="icon" /> */}
                    </div>
                </div>
                <div>
                    {viewMode && 
                        <>
                            {gradesList?.gradeList?.map((grade, index) => {
                                return (
                                    <>
                                        <h3>{grade?.courses?.length === 0 ? '' : (grade?.selected ? grade.name : '')}</h3>
                                        {grade?.courses?.length != 0 && grade?.selected &&
                                            <Slider>
                                                {grade?.courses?.map((movie, ix) => (
                                                    <Slider.ItemHome ix={ix} movie={movie} view={view} key={movie.id}>item1</Slider.ItemHome>
                                                ))}

                                            </Slider>
                                        }
                                        
                                    </>
                                
                                )
                            })}
                        </>
                    }
                        
                    
                </div>
                <Modal
                    isOpen={modalIsOpen}
                    onAfterOpen={afterOpenModal}
                    onRequestClose={closeModal}
                    style={customStyles}
                    ariaHideApp={false}
                    contentLabel="Example Modal"
                >
                    {filterFlag && 
                        <>
                            <div className="col-md-12 title-modal d-flex">
                                <h4 className='col-md-11 row cart-title'>Filter</h4>
                                <span onClick={closeModal} className="col-md-1  close-icon">X</span>
                            </div>

                            <div className="col-md-12 category-group">
                                <h5>Categories</h5>
                                <div className="filter-group-category">
                                    {gradesList?.gradeList?.map((grade) => {
                                        return (
                                            <div className="filter-single">
                                                <input defaultChecked={grade?.selected} value={grade?.id} onChange={categoryFilter} type="checkbox" name="filterGroup" />{grade?.name}
                                            </div>
                                        )
                                    })}
                                            
                                </div>
                            </div>
                        </>
                    }
                    {!filterFlag && 
                        <>
                            <div className="col-md-12 title-modal d-flex">
                                <h4 className='col-md-11 row cart-title'>Sort</h4>
                                <span onClick={closeModal} className="col-md-1  close-icon">X</span>
                            </div>

                            <div className="col-md-12 category-group">
                                <h5>Sort By</h5>
                                <div className="filter-group-category">
                                    <div className="filter-single">
                                        <input  value="priceasc" onChange={sortFilter} type="radio" name="filterGroup" />Price - Low to High
                                    </div>
                                    <div className="filter-single">
                                        <input  value="pricedesc" onChange={sortFilter} type="radio" name="filterGroup" />Price - High to Low           
                                    </div>
                                    <div className="filter-single">
                                        <input  value="dateasc" onChange={sortFilter} type="radio" name="filterGroup" />Date - Old to New           
                                    </div>
                                    <div className="filter-single">
                                        <input  value="datedesc" onChange={sortFilter} type="radio" name="filterGroup" />Date - New to Old           
                                    </div>
                                    
                                </div>
                            </div>
                        </>
                    }

                      
                </Modal>
            </section>
            <FooterGreen />
        </>
    )
}

export default BuyCourse;