import React, {useState, useEffect} from 'react';
import { GetToken } from "../../api/ApiServices";

import * as type from "../../urls";

import axios from "../../api/axios";

import SubjectHeader from '../common/subjectHeader.jsx';

import {GetStoryView} from "../../data/storeView";
import $ from 'jquery';

import "./store.scss";

//Image
import StoreIcon from './image/store_icon.png';
import BookImg from './image/book.png';
import StarImg from './image/star.png';

const StoreView = () => {
    const [subjectContent, setSubjectContent] = useState([]);
    const [storeContent, setStoreContent] = useState([]);
    const [selectedFlag, setSelectedFlag] = useState([]);

    const navigationChapterGroup = (event) => {
        window.location.href = `#/courses/${event.currentTarget.id}?chapter=${event.currentTarget.id}`;
        //this.setState({chapterContent: GetChapterContent(event.currentTarget.id)});
    }

    useEffect(() => {
        getSubject(6);
        setStoreContent(GetStoryView());
        console.log(GetStoryView());
    }, []);

    const getSubject = (id) => {
        if (!window.token) {
            GetToken().then((res) => {
                window.token = res.data.token;
                getSubjectList(id);
            });
        } else {
            getSubjectList(id);
        }
    };
    const setFlag = (evt) => {
        let id = evt.target.parentElement.id;
        $('.store-single').removeClass('opacity-confirm');
        $('#' + id).addClass('opacity-confirm');
        console.log(id);
    }
    const removeFlag = (evt) => {
        $('.store-single').removeClass('opacity-confirm');
    }
    const getSubjectList = (id) => {
        axios.get(type.GetSubjectURL(window.token)).then((res) => {
            let temp = [];
            res.data.forEach((val) => {
            if (val.parent == id) {
                temp.push(val);
            }
            });
            temp.forEach((subj) => {
            getSubjectChapters(subj.id).then((chapterList) => {
                subj.chapters = {};
                subj.chapters = chapterList.data.courses;
            });
            });
            setTimeout(() => {
                console.log(temp);
                setSubjectContent(temp);
            }, 500);
        });
    };

    const getSubjectChapters = (id) => {
        return axios.get(type.GetSubjectListURL(window.token, id));
    };
    return (
        <>
            <div className="course-container">
                <SubjectHeader headerColour="blue-header-background" subjectList={subjectContent} navigationChapterGroup={navigationChapterGroup} />  
            </div>
            <div className="store-background">
                <div className="row header-block">
                    <img className="col-sm-3" src={StoreIcon} />
                    <div className="col-sm-2 points-count green-bg">
                        <p className="count">145</p>
                        <p className="title">Earned Points</p>
                    </div>
                    <div className="col-sm-2 points-count green-bg">
                        <p className="count">35</p>
                        <p className="title">Points Sent</p>
                    </div>
                    <div className="col-sm-2 points-count green-bg">
                        <p className="count">35</p>
                        <p className="title">Balance Points</p>
                    </div>
                </div>
                <input className="col-sm-10 form-control search-text" type="text" />
                <div className="store-list">
                    
                    {storeContent && storeContent.map((val) => {
                        return (

                            <div id={"store_" + val.id} key={val.id} className="col-sm-2 store-single">
                                <span className="title"> {val.name} </span>
                                <img className="reward-img" src={val.image.default} />
                                <div className="confirm-buy">
                                    <span> Do you want to buy?</span>
                                    <div className="btn_group">
                                        <button className="yes">Yes</button>
                                        <button className="no" onClick={removeFlag}> No</button>
                                    </div>
                                    
                                </div>
                                <div className=" points">
                                    <img src={StarImg} />
                                    <div className="price">350</div>
                                    <div className="price-point">Points</div>
                                </div>
                                <div onClick={setFlag} className="reward-buy">Buy</div>
                                
                            </div>
                        )
                        
                    })}
                </div>
            </div>
            
        </>
    )
}

export default StoreView;
