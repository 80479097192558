// Common
import HomeHeader from "../HomeHeader";
import FooterGreen from "../../../common/footer/footer";
import { useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGraduationCap } from '@fortawesome/free-solid-svg-icons';

import axios from "../../../api/axios";
import * as type from '../../../urls';

import './cartView.scss';   

const CartView = () => {
    
    const [cartList, setCartList] = useState([]);
    const [similarContent, setSimilarContent] = useState([]);
    const [simlist, setSimList] = useState([]);
   

    useEffect(() => {
        let temp = [];
        if (localStorage.getItem('cart')) {
            temp = JSON.parse(localStorage.getItem('cart'));
        }
        setCartList(temp);
        temp?.forEach(async val => {
            getSubjectChapters(val?.categoryid);
            
        });
       
    }, []);

    useEffect(() => {
        
        let temp = similarContent;
        if (temp) {
            temp = simlist;
        } else {
            temp.push(simlist);
        }
        setSimilarContent(temp);
        console.log(temp);
    }, [simlist]);

    const getSubjectChapters = (categoryid) => {
        if (!window.token) {
            window.token = process.env.REACT_APP_MOODLE_TOKEN;
        }
        return axios
            .get(type.GetSubjectListURL(window.token, categoryid)).then((res) => {
                setSimList(res?.data?.courses);
            })

    }

    const similarProductRedirect = (course) => {
        window.location.href = `#/detail/${course.categoryid}/${course.id}`;
    }

    const removeCart = (ev) => {
        let id = ev?.target?.id.split('_')[0], _temp = [];
        const temp = JSON.parse(localStorage.getItem('cart'));
        temp?.forEach((val) => {
            if (val?.id != id) {
                _temp.push(val);
            }
        });
        localStorage.setItem('cart', JSON.stringify(_temp));
        setCartList(_temp);
    }

    return (
        <>
            <HomeHeader active="/#/buy-courses" />
            <section className="heading-detail">
                <h3>Shopping Cart</h3>
                <div className="content-wrapper">
                    <div className="cart-wrapper col-md-10">
                        {cartList && cartList?.map((val) => {
                            return (
                                <div className="cart-single d-block d-xs-block d-sm-block d-md-flex">
                                     {!(val?.overviewfiles && val?.overviewfiles[0] && val?.overviewfiles[0].fileurl) &&
                                            <div className="image-content">
                                                <FontAwesomeIcon  icon={faGraduationCap}  />    
                                            </div>
                                        }

                                        {(val?.overviewfiles && val?.overviewfiles[0] && val?.overviewfiles[0].fileurl) &&
                                            <div className="image-content" style={{
                                                background : `url("${val?.overviewfiles && val?.overviewfiles[0] && val?.overviewfiles[0].fileurl + '?token=' + window.token}")` ,
                                                backgroundSize: "100% 100%"
                                            }}>  
                                            </div>
                                        }
                                    <div className="col-12 col-xs-12 col-sm-12 col-md-6 subj-content">
                                        <p className="title">{val?.displayname}</p>
                                        <p className="text-info" dangerouslySetInnerHTML={{__html: val?.customfields[0]?.value}}></p>
                                    </div>
                                    <div className="col-12 col-xs-12 col-sm-12 col-md-3">
                                        <a id={val?.id + '_remove'} onClick={removeCart} className="link">Remove from cart</a>
                                    </div>
                                </div>
                            )
                            
                        })

                        }

                        {!cartList.length && 
                            <div className="d-block cart-msg">
                                <span>Empty Cart.</span>
                            </div>
                        }
                    </div>
                </div>

                {/* <div className="similar">
                    <h5>Similar Courses</h5>
                    {similarContent && similarContent?.map((val) => {
                        return (
                            <div onClick={() => {similarProductRedirect(val)}} className="similar-single d-flex">
                                <div className="col-md-3 image-content">
                                    <FontAwesomeIcon  icon={faGraduationCap}  />    
                                </div>
                                <div className="col-md-9 subj-content">
                                    <p className="title">{val?.displayname}</p>
                                    <p dangerouslySetInnerHTML={{__html: val?.summary}}></p>
                                </div>
                            </div>
                        )
                        
                    })}
                </div> */}
                
                
            </section>
            <FooterGreen />
        </>
    )

}

export default CartView;