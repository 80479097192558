// Common
import HomeHeader from "../HomeHeader";
import FooterGreen from "../../../common/footer/footer";

import "./detailView.scss";
import { useEffect, useState } from "react";
import React from 'react';
import { useParams } from 'react-router-dom';

import Modal from 'react-modal';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGraduationCap, faCheckCircle } from '@fortawesome/free-solid-svg-icons';

import * as type from '../../../urls';

import $ from 'jquery';

import axios from "../../../api/axios";
import { ApiServices } from "../../../api/ApiServices";

const DetailView = (props) => {
    const customStyles = {
        content: {
          background: "#fff",
          maxWidth: "40rem",
          left: '30%',
        },
    };
    const { id, categoryid, view } = useParams();
    const [content, SetContent] = useState({});
    const [similarContent, setSimilarContent] = useState([]);
    const [rating, setRating] = useState(3);
    const [url, setUrl] = useState('');
    const [contentList, setContentList] = useState([]);
    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [modalScroll, setModalScroll]= useState(false);

    const handleChange = (value) => {
        setRating(value);
    }

    const openModal = () => {
        setIsOpen(true);
    }

    function afterOpenModal() {
    // references are now sync'd and can be accessed.
    //subtitle.style.color = '#f00';
    }

    const closeModal = () => {
        setIsOpen(false);
    }

    useEffect(() => {
        console.log('Detail', id, categoryid);
        getSubjectChapters(categoryid);
        getContentList(id);
        window.addEventListener('scroll', handleScroll);
    }, []);

    const handleScroll = (event) => {
        let scrollPosition = window.pageYOffset;
        console.log(scrollPosition);
        let pageSize = document.body.scrollHeight - 605;
        if (scrollPosition >= pageSize) {
            setModalScroll(false);
        } else {
            setModalScroll(true);
        }
    }

    const getContentList = (id) => {
        if (!window.token) {
            window.token = process.env.REACT_APP_MOODLE_TOKEN;
        }
        $('.spinner').show();
        axios
            .get(type.GetH5pContent(window.token, id)).then((res) => {
                setContentList(res?.data);
                $('.spinner').hide();
            })
    }

    const getSubjectChapters = (categoryid) => {
        if (!window.token) {
            window.token = process.env.REACT_APP_MOODLE_TOKEN;
        }
        axios
            .get(type.GetSubjectListURL(window.token, categoryid)).then((res) => {
                setSimilarContent(res?.data?.courses);
                console.log(res?.data?.courses);
                res?.data?.courses?.forEach((val) => {
                    if (val?.id == id) {
                        SetContent(val);
                        console.log(val);
                        let url = val?.overviewfiles && val?.overviewfiles[0] && val?.overviewfiles[0].fileurl + '?token=' + window.token;
                        console.log(url);
                        setUrl(url);
                    }
                })
            });

    }

    const addToCart = () => {
        let temp = [], flag = false;
        if (localStorage.getItem('cart')) {
            temp = JSON.parse(localStorage.getItem('cart'));
            temp.forEach((val) => {
                if (val.id === content?.id) {
                    flag = true;
                }
            })
        }
        if (!flag) {
            temp.push(content);
        }
       
        localStorage.setItem('cart', JSON.stringify(temp));
        openModal();
    }

    const similarProductRedirect = (course) => {
        window.location.href = `#/detail/${course.categoryid}/${course.id}/${view}`;
        window.location.reload();
    }

    const redirectView = () => {
        window.location.href = '#/buy-courses/' + view;
    }
    
    return (
        <>
            <HomeHeader active="/#/buy-courses" />
            <section className="heading-detail">
                <div className={modalScroll ? "modal-price fixed-one": "modal-price floated-one"}>
                    {!url &&
                        <div className="image-content">
                            <FontAwesomeIcon  icon={faGraduationCap}  />    
                        </div>
                    }

                    {url &&
                        <div className="image-content" style={{
                            background : `url("${url}")` ,
                            backgroundSize: "100% 100%",
                        }}>  
                        </div>
                    }
                    
                    <div className="main-content">
                        <div className="price">{(content?.customfields && content?.customfields[1] && content?.customfields[1]?.value == 0) ? 'Free' : '₹ ' + (content?.customfields && content?.customfields[1] && content?.customfields[1]?.value)}</div>
                        <button onClick={addToCart} className="buy-course">Buy Course</button>
                    </div>
                </div>
                <div className="row dark-background">
                    <div className="col-md-2"></div>
                    <div className="col-md-6">
                        <h6 className="leads">
                            <span onClick={redirectView} >{view === 'general' ? 'General' : 'Competitive Exams'}</span> 
                            <span>{">"}</span>
                            <span onClick={() => {window.location.href = '#/buy-courses/' + view + '?categoryid=' + categoryid}} >{content?.categoryname}</span>  
                            <span>{">"}</span>
                            <span>{ content?.displayname}</span> 
                        </h6>
                        <h4>{content?.displayname}</h4>
                        <div className="sub-info" dangerouslySetInnerHTML={{__html: content?.customfields && content?.customfields[0] && content?.customfields[0]?.value}}></div>
                        {/* <span>Rating: </span>
                        <StarRating 
                            count={5}
                            size={40}
                            value={rating}
                            activeColor ={'#fdad25'}
                            inactiveColor={'#ddd'}
                            onChange={handleChange} 
                        /> */}
                        <div className="row time-values">
                            <div className="col-md-6">
                                <span>Last Updated: </span>
                                <span>{new Date(content?.timemodified*1000).getMonth() + 1 + " / " + new Date(content?.timemodified*1000).getFullYear()}</span>
                            </div>
                            <div className="col-md-6">
                                <span>Duration: </span>
                                <span>{content?.customfields && content?.customfields[2] && content?.customfields[2]?.value}</span>
                            </div>
                            
                        </div>

                        <div className="main-content-list">
                            <div className="price">{(content?.customfields && content?.customfields[1] && content?.customfields[1]?.value == 0) ? 'Free' : '₹ ' + (content?.customfields && content?.customfields[1] && content?.customfields[1]?.value)}</div>
                            <button onClick={addToCart} className="buy-course">Buy Course</button>
                        </div>
                    </div>
                </div>
                <div className="row content-details-wrapper">
                    <div className="col-md-2 d-none d-xs-none d-sm-none d-md-flex"></div>
                    <div className="col-12 col-xs-12 col-sm-12 col-md-6 ">
                        <div className="row content-details">
                            {/* <h5>What you'll Learn:</h5> */}
                            <div dangerouslySetInnerHTML={{__html: content?.summary}}></div>
                        </div>
                        {/* <div className="content-includes">
                            <h5 className="">This course includes:</h5>
                            <div className="">
                                <ul className="course-list">
                                    <li>60 hours on-demand video</li>
                                    <li>129 downloadable resources</li>
                                    <li>Access on mobile and TV</li>
                                    <li>Certificate of completion</li>
                                </ul>
                            </div>
                           
                        </div> */}

                        <div className="course-content">
                            <h5 className="">Course Content:</h5>
                            <div className="list-view">
                                {contentList && contentList?.map((content) => {
                                    return (
                                        <div className="content-single">{content?.name}</div>
                                    )
                                })}
                            </div>
                        </div>
                        
                    </div>
                </div>

                <Modal
                    isOpen={modalIsOpen}
                    onAfterOpen={afterOpenModal}
                    onRequestClose={closeModal}
                    style={customStyles}
                    ariaHideApp={false}
                    contentLabel="Example Modal"
                >
                      <div className="col-sm-12 col-md-12 title-modal d-flex">
                        <h4 className='col-12 col-xs-12 col-sm-12 col-md-11 row cart-title'>Added To Cart</h4>
                        <span onClick={closeModal} className="col-12 col-xs-12 col-sm-12 col-md-1  close-icon">X</span>
                      </div>

                      <div className="cart-view d-md-flex d-block">
                        <FontAwesomeIcon className="check-done" icon={faCheckCircle}  /> 
                        <div className="image-content">
                            <FontAwesomeIcon  icon={faGraduationCap}  />    
                        </div>
                        <div className="col-12 col-xs-12 col-sm-12 col-md-6 subj-content">
                            <p className="title">{content?.displayname}</p>
                            <p className="price">{(content?.customfields && content?.customfields[1] && content?.customfields[1]?.value == 0) ? 'Free' : '₹ ' + (content?.customfields && content?.customfields[1] && content?.customfields[1]?.value)}</p>
                            
                        </div>
                        <div className="col-12 col-xs-12 col-sm-12 col-md-3">
                            <button onClick={() => window.location.href = '#/cart'} className="cart-btn">Go To Cart</button>
                        </div>
                      </div>

                      <div className="similar d-none d-xs-none d-sm-none d-md-block">
                        <h5>Similar Courses</h5>
                            {similarContent && similarContent?.map((val) => {
                                return (
                                    <div onClick={() => {similarProductRedirect(val)}} className="similar-single d-flex">
                                        <div className="image-content">
                                            <FontAwesomeIcon  icon={faGraduationCap}  />    
                                        </div>
                                        <div className="col-md-8 subj-content">
                                            <p className="title">{val?.displayname}</p>
                                            {/* <p dangerouslySetInnerHTML={{__html: val?.customfields && val?.customfields[0] && val?.customfields[0]?.value}}></p> */}
                                        </div>
                                        <div className="col-md-2 subj-content">
                                            <p className="price">{(val?.customfields && val?.customfields[1] && val?.customfields[1]?.value == 0) ? 'Free' : '₹ ' + (val?.customfields && val?.customfields[1] && val?.customfields[1]?.value)}</p>
                                            {/* <p dangerouslySetInnerHTML={{__html: val?.customfields && val?.customfields[0] && val?.customfields[0]?.value}}></p> */}
                                        </div>
                                    </div>
                                )
                                
                            })}
                      </div>
                </Modal>
                
                
            </section>
            <FooterGreen />
        </>
    )
}

export default DetailView;

function StarRating({count, value, 
    inactiveColor='#ddd',
    size=24,
    activeColor='#fdad25', onChange}) {

  // short trick 
  const stars = Array.from({length: count}, () => '🟊')

  // Internal handle change function
  const handleChange = (value) => {
    onChange(value + 1);
  }

  return (
    <div>
      {stars.map((s, index) => {
        let style = inactiveColor;
        if (index < value) {
          style=activeColor;
        }
        return (
          <span className={"star"}  
            key={index}
            style={{color: style, width:size, height:size, fontSize: size}}
            onClick={()=>handleChange(index)}>{s}</span>
        )
      })}
      {value}
    </div>
  )
}