import React, { useState, useEffect } from "react";
import HomeHeader from "../home/HomeHeader";
import BorderImg from "./image/border.png";
import axios from "../../api/axios";

import { GetList } from "../../data/subscription";
// URL
import { PostPackage } from "../../urls";


//Image
import Border1 from "./image/color-1.png";
import Border2 from "./image/color-2.png";
import Border3 from "./image/color-3.png";
import Border4 from "./image/color-4.png";
import Flag1 from "./image/flag-1.png";
import Flag2 from "./image/flag-2.png";
import Flag3 from "./image/flag-3.png";
import Flag4 from "./image/flag-1.png";
import HandRise from "./image/rise-hand.png";

import { animateScroll as scroll } from "react-scroll";

import { useHistory } from "react-router";

import "./subscription.scss";

const SubscriptionListView = () => {
  const history = useHistory();
  let childSession = JSON.parse(sessionStorage.getItem("child"));
  const [errorId, SetErrorId] = useState(0);

  const chooseGrade = (value) => {
    if (value) {
      document
        .getElementsByClassName("choose-plan")[0]
        .classList.remove("hide");
      let _comp = document.getElementById("planWrapper");
      let _offset = _comp.offsetTop;
      scroll.scrollTo(_offset - 50);
    } else {
      document.getElementsByClassName("choose-plan")[0].classList.add("hide");
    }
  };
  const [subscriptionList, setSubscription] = useState([]);

  useEffect(() => {
    setSubscription(GetList());
  }, []);

  useEffect(() => {
    if (childSession == null) {
      history.replace("../signup");
    }
  }, [history, childSession]);

  const listView = (id, event) => {
    axios.post(PostPackage(childSession.childid)).then(
      (response) => {
        if (response.data == "Package Updated Successfully") {
          let id = event.target.id.split("_")[1];
          history.push("/subscription/" + id);
        } else {
          SetErrorId(id);
        }
      },
      (error) => {
        SetErrorId(id);
      }
    );
  };

  useEffect(() => {
    if (childSession && childSession.grade) {
      chooseGrade(childSession.grade);
    }
  }, [chooseGrade, childSession]);

  return (
    <>
      <HomeHeader />
      <div className="subscription-list">
        <img className="border-img" src={BorderImg} />
        <img className="rise-hand" src={HandRise} />
        <div className="choose-grade">
          <h3>A plan for each one</h3>
        </div>

        <div className="choose-plan hide">
          <h4>Subscrption plans for Grade {childSession?.grade}</h4>

          <div id="planWrapper" className="plan-wrapper">
            {subscriptionList &&
              subscriptionList.map((item) => {
                return (
                  <div className="plans-details">
                    {item.id === 1 && (
                      <>
                        <div className="plan-border-wrapper">
                          <img className="plan-border" src={Border1} />
                          <span className="price plan-value">
                            ₹{item.price}
                          </span>
                        </div>
                        <div className="plan-border-wrapper">
                          <img className="plan-flag" src={Flag1} />
                          <span className="plan-flag-plan">{item.name}</span>
                        </div>
                      </>
                    )}
                    {item.id === 2 && (
                      <>
                        <div className="plan-border-wrapper">
                          <img className="plan-border" src={Border2} />
                          <span className="price plan-value-white">
                            ₹{item.price}
                          </span>
                        </div>
                        <div className="plan-border-wrapper">
                          <img className="plan-flag" src={Flag1} />
                          <span className="plan-flag-plan">{item.name}</span>
                        </div>
                      </>
                    )}
                    {item.id === 3 && (
                      <>
                        <div className="plan-border-wrapper">
                          <img className="plan-border" src={Border3} />
                          <span className="price plan-value-white">
                            ₹{item.price}
                          </span>
                        </div>

                        <div className="plan-border-wrapper">
                          <img className="plan-flag" src={Flag3} />
                          <span className="plan-flag-plan">{item.name}</span>
                        </div>
                      </>
                    )}
                    {item.id === 4 && (
                      <>
                        <div className="plan-border-wrapper">
                          <img className="plan-border" src={Border4} />
                          <span className="price plan-value-white">
                            ₹{item.price}
                          </span>
                        </div>

                        <div className="plan-border-wrapper">
                          <img className="plan-flag" src={Flag3} />
                          <span className="plan-flag-plan">{item.name}</span>
                        </div>
                      </>
                    )}

                    {item.duration && (
                      <div className="plan-border-wrapper">
                        <img className="plan-flag plan-flag-2" src={Flag2} />
                        <span className="plan-flag-plan">{item.duration}</span>
                      </div>
                    )}

                    <div className="plan-features">
                      <h6 className="sub-headers">BOARDS</h6>
                      <div className="wrapper">
                        {item.boards &&
                          item.boards.map((val) => {
                            return <span>{val}</span>;
                          })}
                      </div>
                      <h6 className="sub-headers">SUBJECTS</h6>
                      <div className="wrapper">
                        {item.subjects &&
                          item.subjects.map((val) => {
                            return <span>{val}</span>;
                          })}
                      </div>
                      <h6 className="sub-headers">ADDON's</h6>
                      <div className="wrapper">
                        {!item.addons.length && <span>No addon's</span>}
                        {item.addons &&
                          item.addons.map((val) => {
                            return <span>{val}</span>;
                          })}
                      </div>
                      <h6 className="sub-headers">USER GROUPS</h6>
                      <div className="wrapper">
                        {!item.userGroups.length && <span>No usergroups</span>}
                        {item.userGroups &&
                          item.userGroups.map((val) => {
                            return <span>{val}</span>;
                          })}
                      </div>
                      <h6 className="headers">FEATURES</h6>
                      <div className="wrapper">
                        {item.features &&
                          item.features.map((val) => {
                            return <span>{val.name}</span>;
                          })}
                      </div>
                    </div>
                    <p className={`error-statement ${item.id == errorId ? "d-block" : "d-none"}`}>Error in Choosing Package</p>
                    <div
                      onClick={listView.bind(this, item.id)}
                      id={"button_" + item.id}
                      className={
                        item.id != 1 ? "disabled plan-button" : "plan-button"
                      }
                    >
                      Choose this plan
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </>
  );
};

export default SubscriptionListView;
