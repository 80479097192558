import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import InputContainer from "../../common/inputs/InputContainer";
import Footer from "../../common/footer/footer";

//API
import axios from "../../api/axios";
import { ApiServices } from "../../api/ApiServices";
import * as type from "../../urls";

// Header
// import GetSubjectChapters from "../../data/gradeList";
import SubjectHeader from "../common/subjectHeader.jsx";
import "../gradeList/gradelist.scss";
import "../grades/chapter.scss";

// CSS
import "./index.scss";

// Images
import ChapterCountActive from "./images/chapter-count-active.svg";
// import ChapterCount from "./images/chapter-count.svg";
import DiscussionImage from "./images/discussion-image.jpg";
// import LikeImage from "./images/like.svg";
// import BoySurprised from "../../images/boy-surprise.svg";
import BoySurprised from "../../images/boy-surprise.svg";
import ComingSoonTopic from "./images/coming-soon-topic.svg";
import MessageCommonImage from "../../images/group-image.svg";

let subjectOptions = [
  {
    label: "Maths",
    value: "Maths",
  },
  {
    label: "English",
    value: "English",
  },
  {
    label: "Science",
    value: "Science",
  },
];

let chapterList = [
  {
    name: "Chapter -1 : Basic mathematics",
    count: "420",
  },
  {
    name: "Chapter -2 : Basic mathematics",
    count: "420",
  },
  {
    name: "Chapter -3 : Basic mathematics",
    count: "420",
  },
  {
    name: "Chapter -4 : Basic mathematics",
    count: "420",
  },
  {
    name: "Chapter -5 : Basic mathematics",
    count: "0",
  },
  {
    name: "Chapter -6 : Basic mathematics",
    count: "1",
  },
  {
    name: "Chapter -7 : Basic mathematics",
    count: "420",
  },
  {
    name: "Chapter -8 : Basic mathematics",
    count: "420",
  },
  {
    name: "Chapter -9 : Basic mathematics",
    count: "420",
  },
  {
    name: "Chapter -10 : Basic mathematics",
    count: "420",
  },
  {
    name: "Chapter -11 : Basic mathematics",
    count: "420",
  },
];
const Community = () => {
  const history = useHistory();
  const [view, SetView] = useState(true);
  const [haveTopic, SetHaveTopic] = useState(false);
  const [needSupport, SetNeedSupport] = useState(false);
  const [topicSubmitted, SetTopicSubmitted] = useState(false);
  const [supportSubmitted, SetSupportSubmitted] = useState(false);
  const [gradeId, SetGradeId] = useState([]);
  const [chapterList, SetChapterList] = useState([]);
  const [forumListData, SetForumListData] = useState([]);
  const [allSubjects, SetAllSubjects] = useState([]);
  const [selectedChapter, SetSelectedChapter] = useState(null);
  const [selectedGroup, SetSelectedGroup] = useState(null);
  const [month, SetMonth] = useState({
    value: new Date().getMonth(),
    error: false,
    errorText: "",
  });

  const [email, SetEmail] = useState({
    value: "",
    error: false,
    errorText: "",
  });

  const [newDiscussion, SetNewDiscussion] = useState({
    value: "",
    error: false,
    errorText: "",
  });

  const [subject, SetSubject] = useState({
    value: "",
    error: false,
    errorText: "",
  });

  const [discussionList, SetDiscussionList] = useState([]);
  const [userInfo, SetUserInfo] = useState({});

  // Redux Variables
  const subjectList = useSelector((state) => state.subjects.data);
  // const userInfo = useSelector((state) => state.childInfo);

  useEffect(() => {
    let localVar = JSON.parse(localStorage.getItem("childToken"))
    if (localVar && localVar.id && localVar != "") {
      SetUserInfo(localVar)
    }
  }, [])

  const HaveTopicHandler = (value) => {
    SetHaveTopic(value);
    if (!value) {
      SetSubject({
        ...subject,
        error: false,
        errorText: "",
      });

      SetNewDiscussion({
        ...newDiscussion,
        error: false,
        errorText: "",
      });
    }
  };
  const NeedSupportHandler = (value) => {
    SetNeedSupport(value);
  };
  const TopicSubmittedHandler = (value) => {
    SetTopicSubmitted(value);
  };
  const SupportSubmittedHandler = (value) => {
    SetSupportSubmitted(value);
  };

  const EmailHandler = (event) => {
    SetEmail({ value: event.target.value, error: false });
  };

  const NewDiscussionHandler = (event) => {
    SetNewDiscussion({ value: event.target.value, error: false });
  };

  const SubjectHandler = (value) => {
    SetSubject({ value: value, error: false });
  };

  const MonthHandler = (value) => {
    allSubjects.map((subject) => {
      if (subject.id == value) {
        SetChapterList(subject.chapters);
      }
    });
  };

  const ViewHandler = (value) => {
    SetView(value);
  };

  const SeeMoreOnDiscussion = (i) => {
    history.push("../community/" + i);
  };

  const ChangeForumHandler = (ChapterId, groupId) => {
    SetSelectedChapter(ChapterId);
    SetSelectedGroup(groupId);
    let chapterData = [];
    chapterList.map((chapter) => {
      if (chapter.id == ChapterId && chapter.forums.length > 0) {
        chapter.forums?.map((forum) => {
          chapterData.push(forum);
        });
      }
    });
    SetForumListData(chapterData);
  };
  const SubmitNewTopic = () => {
    if (newDiscussion.value == "") {
      SetNewDiscussion({
        ...newDiscussion,
        error: true,
        errorText: "Topics Name should not be blank",
      });
    }
    if (subject.value == "") {
      SetSubject({
        ...subject,
        error: true,
        errorText: "Subject should not be blank",
      });
    }

    if (subject.value != "" && newDiscussion.value != "") {
      axios
        .post(ApiServices("createRoom") + newDiscussion.value)
        .then((res) => {
          axios
            .post(
              ApiServices("createRoomApproval") + res.data.statusval[0].chatid
            )
            .then((response) => {
              SetSubject({
                value: "",
                error: false,
                errorText: "",
              });
              SetNewDiscussion({
                value: "",
                error: false,
                errorText: "",
              });
              TopicSubmittedHandler(true);
              HaveTopicHandler(true);
              GetChatList();
            });
        });
    }
  };

  const GetChatList = () => {
    if (userInfo.childId) {
      axios.get(`${ApiServices("getChatList")}${userInfo.childId}`).then((response) => {
        SetDiscussionList(response.data.conversations);
      });
    }
  };

  useEffect(() => {
    if (userInfo.childId) {
      GetChatList();
    }
  }, [userInfo.childId]);

  const navigationChapterGroup = (event) => {
    window.location.href = `#/courses/${event.currentTarget.id}?chapter=${event.currentTarget.id}`;
  }

  const GradeInfo = (value) => {
    if (value == 1) {
      return '1st';
    }
    if (value == 2) {
      return '2ndst';
    }
    if (value == 3) {
      return '3rd';
    }
    return `${value}th`
  }

  const AddDefaultSrc = (e) => {
    e.target.src = MessageCommonImage;
  }
  return (
    <main className="community-section">
      <div className="course-container">
        <SubjectHeader
          headerColour="red-background"
          profileColor="red"
        />
      </div>
      <div className="community-content community-list-content">
        <p className="page-header">Messages</p>
        <div className="col-md-12 p-0 d-flex mb-50">
          <div className="col-md-12 col-lg-8 chapter-list-container p-sm-0">
            <p className="chapter-header">
              {chapterList?.map((chapter) => {
                if (chapter.id == selectedChapter) {
                  return chapter.groups?.map((group) => {
                    if (group.id == selectedGroup) {
                      return group.name;
                    }
                  });
                }
              })}
            </p>
            <InputContainer
              type="text"
              name="email"
              labelName=""
              labelClass="d-none"
              className=""
              placeholder="Search discussion topic"
              onChange={EmailHandler}
              error={email.error}
              errorText={email.errorText}
            />
            <div>
              <div className="discussion-heading">
                Discussion rooms -{" "}
                {
                  (discussionList && discussionList.length > 0) ? discussionList.filter(
                    (discussion) => discussion.name
                  ).length : 0
                }
              </div>
              <div className={`discussion-list-container`}>
                {(discussionList && discussionList.length > 0) &&
                  discussionList.map((discussion) => {
                    if (discussion.name) {
                      return (
                        <div
                          className="discussion-item d-flex"
                          key={discussion.id}
                          onClick={() => {
                            SeeMoreOnDiscussion(discussion.id);
                          }}
                        >
                          <div className="d-grid discussion-details">
                            <p className="discussion-name">
                              {discussion.name}
                            </p>
                            <div className="d-flex discussion-count">
                              <div className="d-flex like-container">
                                <div>
                                  <img
                                    src={discussion.imageurl}
                                    onError={AddDefaultSrc}
                                    alt="Group Picture"
                                    className="child-profile-picture"
                                  />
                                </div>
                                <div className="child-detail-container">
                                  <p className="child-name">
                                    {discussion.createdby}
                                  </p>
                                  {/* <p className="child-grade">{GradeInfo(userInfo.grade)} Grd, Maths</p> */}
                                </div>
                              </div>
                              <div className="d-flex count-counter">
                                <div className="d-flex">
                                  <img
                                    src={ChapterCountActive}
                                    alt="Discussion Count Icon"
                                    className="discussion-count-icon"
                                  />
                                  <p className="discussion-members">{discussion.membercount} members</p>
                                </div>
                                {/* <p className="discussion-status">Approved</p>
                                <p className="discussion-created-date">
                                  Created on {new Date().toLocaleDateString()}
                                </p> */}
                              </div>
                            </div>
                          </div>
                          <p
                            className={`new-message-container ${discussion.status == "closed" ? "room-closed" : ""
                              }`}
                          >
                            {discussion.status == "closed"
                              ? "Room Closed"
                              : ""}
                          </p>
                        </div>
                      );
                    }
                  })}
                <p className="discussion-view-all">View All</p>
              </div>
              {/* <div className="discussion-heading mt-20">
                Discussion rooms - 3
              </div>
              <div className={`discussion-list-container`}>
                <div
                  className="discussion-item d-flex"
                  key={1}
                  onClick={() => {
                    SeeMoreOnDiscussion(1);
                  }}
                >
                  <div className="d-grid discussion-details">
                    <p className="discussion-name">
                      Linear Equations in One Variable
                    </p>
                    <div className="d-flex discussion-count">
                      <div className="d-flex like-container">
                        <div>
                          <img
                            src={DiscussionImage}
                            alt="Child Profile Picture"
                            className="child-profile-picture"
                          />
                        </div>
                        <div className="child-detail-container">
                          <p className="child-name">Nitin Metha</p>
                          <p className="child-grade">1st Grd, Maths</p>
                        </div>
                      </div>
                      <div className="d-flex count-counter">
                        <img
                          src={ChapterCountActive}
                          alt="Discussion Count Icon"
                          className="discussion-count-icon"
                        />
                        <p className="discussion-members">1 members</p>
                      </div>
                    </div>
                  </div>
                  <div className="discussion-status-container">
                    <p className="discussion-new-message">5 New message</p>
                    <div className="d-flex">
                      <p className="discussion-status">Approved</p>
                      <p className="discussion-created-date">
                        Created on 10Dec2021
                      </p>
                    </div>
                  </div>
                  <p className="discussion-leave-button">Leave Discussion</p>
                </div>
                <div
                  className="discussion-item d-flex"
                  key={1122}
                  onClick={() => {
                    SeeMoreOnDiscussion(1);
                  }}
                >
                  <div className="d-grid discussion-details">
                    <p className="discussion-name">
                      Linear Equations in One Variable
                    </p>
                    <div className="d-flex discussion-count">
                      <div className="d-flex like-container">
                        <div>
                          <img
                            src={DiscussionImage}
                            alt="Child Profile Picture"
                            className="child-profile-picture"
                          />
                        </div>
                        <div className="child-detail-container">
                          <p className="child-name">Nitin Metha</p>
                          <p className="child-grade">1st Grd, Maths</p>
                        </div>
                      </div>
                      <div className="d-flex count-counter">
                        <img
                          src={ChapterCountActive}
                          alt="Discussion Count Icon"
                          className="discussion-count-icon"
                        />
                        <p className="discussion-members">1 members</p>
                      </div>
                    </div>
                  </div>
                  <div className="discussion-status-container">
                    <p className="discussion-new-message">5 New message</p>
                    <div className="d-flex">
                      <p className="discussion-status">Approved</p>
                      <p className="discussion-created-date">
                        Created on 10Dec2021
                      </p>
                    </div>
                  </div>
                  <div className="discussion-update-container">
                    <p className="discussion-left">Left Discussion Room</p>
                    <p className="discussion-rejoin">Rejoin</p>
                  </div>
                </div>
                <div
                  className="discussion-item d-flex"
                  key={1211}
                  onClick={() => {
                    SeeMoreOnDiscussion(1);
                  }}
                >
                  <div className="d-grid discussion-details">
                    <p className="discussion-name">
                      Linear Equations in One Variable
                    </p>
                    <div className="d-flex discussion-count">
                      <div className="d-flex like-container">
                        <div>
                          <img
                            src={DiscussionImage}
                            alt="Child Profile Picture"
                            className="child-profile-picture"
                          />
                        </div>
                        <div className="child-detail-container">
                          <p className="child-name">Nitin Metha</p>
                          <p className="child-grade">1st Grd, Maths</p>
                        </div>
                      </div>
                      <div className="d-flex count-counter">
                        <img
                          src={ChapterCountActive}
                          alt="Discussion Count Icon"
                          className="discussion-count-icon"
                        />
                        <p className="discussion-members">1 members</p>
                      </div>
                    </div>
                  </div>
                  <div className="discussion-status-container">
                    <p className="discussion-closed-date">
                      Room closed on 24 Dec 2021
                    </p>
                    <div className="d-flex">
                      <p className="discussion-status">Approved</p>
                      <p className="discussion-created-date">
                        Created on 10 Dec 2021
                      </p>
                    </div>
                  </div>

                  <p className="discussion-closed">Room Closed</p>
                </div>
                <p className="discussion-view-all">View All</p>
              </div> */}
            </div>
          </div>
          <div className="d-none d-xs-none d-sm-none d-md-none d-lg-block col-md-4 new-topic-container">
            <div>
              <img
                src={BoySurprised}
                alt="Boy Surprised"
                className="new-topic-icon"
              />
              <div className="new-topic-content-container">
                {!haveTopic && !needSupport && (
                  <>
                    <div className="new-topic-comming-soon">
                      <div className="position-relative">
                        <img
                          src={ComingSoonTopic}
                          alt="Coming Soon"
                          className="new-topic-coming-soon-icon"
                        />
                        <p className="create-your-topic">Create your topic</p>
                        <p className="coming-soon">Coming Soon ...</p>
                      </div>
                    </div>
                    <div className="opacity-0-2">
                      <p className="new-topic-heading">
                        Have a topic to dicsuss?
                      </p>
                      <p className="new-topic-cotnent">
                        The standard chunk of Lorem Ipsum used since the 1500s
                        is reproduced below for those interested. Sections
                        1.10.32 and 1.10.33 from "de Finibus Bonorum et Malorum"
                        by Cicero are also reproduced in their exact original
                        form, accompanied by English versions from the 1914
                        translation by H. Rackham.
                      </p>
                      <button
                        className="discussion-button"
                        onClick={() => {
                          HaveTopicHandler(true);
                        }}
                        disabled
                      >
                        Submit Your Topic
                      </button>
                    </div>
                    <div className="opacity-0-2">
                      <p className="new-topic-heading mt-20">
                        Need support on a topic?
                      </p>
                      <p className="new-topic-cotnent">
                        The standard chunk of Lorem Ipsum used since the 1500s
                        is reproduced below for those interested. Sections
                        1.10.32 and 1.10.33 from "de Finibus Bonorum et Malorum"
                        by Cicero are also reproduced in their exact original
                        form, accompanied by English versions from the 1914
                        translation by H. Rackham.
                      </p>
                      <button
                        className="discussion-button"
                        onClick={() => {
                          NeedSupportHandler(true);
                        }}
                        disabled
                      >
                        Enter Support Topic
                      </button>
                    </div>
                    <div className="opacity-0-2 submitted-topics-container">
                      <div className="submitted-topics-item-container">
                        <div className="submitted-topics-item">
                          <p className="topic-heading">A Thing of Beauty</p>
                          <p className="topic-grade">1st Grd, English</p>
                        </div>
                        <div className="col-md-12 p-0 d-flex">
                          <div className="col-md-8 topics-counter">
                            <div className="d-flex mt-5">
                              <img
                                src={ChapterCountActive}
                                alt="Discussion Count Icon"
                                className="discussion-count-icon"
                              />
                              <p className="discussion-members">1 members</p>
                            </div>
                            <p className="discussion-topic-details">
                              Approval pending Requested on 12 Dec 2021
                            </p>
                          </div>
                          <div className="col-md-4">
                            <div className="new-topic-status">Pending</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {haveTopic && !needSupport && (
                  <>
                    {!topicSubmitted && (
                      <div className="new-topics-submit-container">
                        <p className="new-topic-heading">
                          Have a topic to dicsuss?
                        </p>
                        <InputContainer
                          type="text"
                          name="topic-name"
                          labelName="Topics Name (Discussion Room Name)"
                          labelClass=""
                          className="w-100"
                          placeholder=""
                          onChange={NewDiscussionHandler}
                          error={newDiscussion.error}
                          errorText={newDiscussion.errorText}
                        />
                        <InputContainer
                          type="select"
                          name="subject"
                          labelName="Subject"
                          labelClass=""
                          placeholder=""
                          options={subjectOptions}
                          onChange={SubjectHandler}
                          selectValue={subject.value}
                          error={subject.error}
                          errorText={subject.errorText}
                          disabled={false}
                          customStyleSelect={customStyles}
                          className="my-wards-calendar-input"
                        />
                        <div className="col-md-12 text-center">
                          <button
                            className="discussion-button d-inline m-0"
                            onClick={() => {
                              SubmitNewTopic();
                            }}
                          >
                            Send For Approval
                          </button>
                          <button
                            className="discussion-close-button"
                            onClick={() => {
                              HaveTopicHandler(false);
                            }}
                          >
                            Close
                          </button>
                        </div>
                      </div>
                    )}
                    {topicSubmitted && (
                      <div className="new-topics-submit-container success-container">
                        <p className="topic-success-message">
                          Your discussion topic Added Successfully
                        </p>
                        <button
                          className="discussion-close-button m-auto"
                          onClick={() => {
                            HaveTopicHandler(false);
                            TopicSubmittedHandler(false);
                          }}
                        >
                          Close
                        </button>
                      </div>
                    )}
                  </>
                )}
                {!haveTopic && needSupport && (
                  <>
                    {!supportSubmitted && (
                      <div className="new-topics-submit-container">
                        <p className="new-topic-heading">
                          Need support on topic?
                        </p>
                        <InputContainer
                          type="text"
                          name="topic-name"
                          labelName="Topics Name (Discussion Room Name)"
                          labelClass=""
                          className="w-100"
                          placeholder=""
                          onChange={EmailHandler}
                          error={email.error}
                          errorText={email.errorText}
                        />
                        <InputContainer
                          type="select"
                          name="subject"
                          labelName="Subject"
                          labelClass=""
                          placeholder=""
                          options={subjectOptions}
                          onChange={MonthHandler}
                          selectValue={month.value}
                          error={month.error}
                          errorText={month.errorText}
                          disabled={false}
                          customStyleSelect={customStyles}
                          className="my-wards-calendar-input"
                        />
                        <InputContainer
                          type="select"
                          name="grade"
                          labelName="Grade"
                          labelClass=""
                          placeholder=""
                          options={subjectOptions}
                          onChange={MonthHandler}
                          selectValue={month.value}
                          error={month.error}
                          errorText={month.errorText}
                          disabled={false}
                          customStyleSelect={customStyles}
                          className="my-wards-calendar-input"
                        />
                        <div className="col-md-12 text-center">
                          <button
                            className="discussion-button d-inline m-0"
                            onClick={() => {
                              SupportSubmittedHandler(true);
                            }}
                          >
                            Send For topic
                          </button>
                          <button
                            className="discussion-close-button"
                            onClick={() => {
                              NeedSupportHandler(false);
                            }}
                          >
                            Close
                          </button>
                        </div>
                      </div>
                    )}
                    {supportSubmitted && (
                      <div className="new-topics-submit-container success-container">
                        <p className="topic-success-message">
                          Your support is sent for approval
                        </p>
                        <button
                          className="discussion-close-button m-auto"
                          onClick={() => {
                            NeedSupportHandler(false);
                            SupportSubmittedHandler(false);
                          }}
                        >
                          Close
                        </button>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </main>
  );
};

export default Community;

const customStyles = {
  valueContainer: (provided, state) => ({
    ...provided,
    height: "100%",
    padding: "0px 8px",
  }),
  container: (provided, state) => ({
    ...provided,
    paddingTop: 5,
  }),
  placeholder: (provided, state) => ({
    ...provided,
    fontSize: "14px",
  }),
  singleValue: (provided, state) => ({
    ...provided,
    fontSize: "14px",
    fontWeight: "600",
  }),
  menu: (provided, state) => ({
    ...provided,
    marginTop: "0px",
  }),
  control: (provided, state) => ({
    ...provided,
    outline: "none !important",
    boxShadow: "none !important",
    minHeight: "35px",
    height: "35.78px",
    border: "solid 1px #d8d8d8",
    ":hover": {
      border: "2px solid #0478fe !important",
      outline: "none !important",
      boxShadow: "none !important",
    },
  }),
  input: (styles) => ({ ...styles, ...dot() }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      fontSize: "14px",
      color: isFocused || isSelected ? "white" : "black",
    };
  },
};

const dot = (color = "transparent") => ({
  alignItems: "center",
  display: "flex",
  padding: "0px",
  margin: "0px",
  height: "100%",
  input: {
    border: "0px !important",
  },
});
