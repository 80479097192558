import React from 'react';
import GradeListOne from './course_1_copy.jsx';
import './gradelist.scss';
import GetSubjectChapters from "../../data/gradeList";
import SubjectHeader from '../common/subjectHeader.jsx';
import CourseList from './courselist';
import ThinkImg from './images/boy.png';
import _ from "lodash";

import FooterGreen from "../../common/footer/footer";

import axios from "../../api/axios";
import { GetToken } from "../../api/ApiServices"
import * as type from '../../urls';
class GradeList extends React.Component {
    constructor(props) {
        super(props);
        const { match: { params } } = props;
        const qs = new URLSearchParams(props.location.search);
        this.state = {
            gradeId: qs.get('grade') || 1,
            subjectId: qs.get('subject') || 1,
            subjectList: [],
            gamemode: 'checked',
            selectedList: {}
        }
    }

    componentDidMount() {
        // this.getSubjectChapters(this.state.gradeId);
        this.getSubject(this.state.gradeId);
    }
    getSubjectChapters(id) {

        return axios
            .get(type.GetSubjectListURL(window.token, id));

    }
    getSubject(id) {
        if (!window.token) {
            window.token = GetToken().token;
           this.getSubjectList(id)
        } else {
           this.getSubjectList(id);
        }
    }

    getSubjectList(id) {
        axios.get(type.GetSubjectURL(window.token)).then((res) => {
          this.setState({ subjectListGroup: res?.data }, () => {
            this.getCourseContent();
          });
        });
      }

    getCourseContent() {
        let childToken = JSON.parse(localStorage.getItem("childToken"));
    
        axios.get(type.GetEnrollSubject(window.token, childToken?.id)).then((res) => {
          let temp = [], _tmp = [];
          let categoryGroup = _.uniqBy(res?.data, 'category');
          //this.getTimeLineClass();
    
          categoryGroup.forEach((val) => {
            _tmp.push(val.category);
          });
          _tmp.forEach((tmp_val) => {
            this.state?.subjectListGroup?.forEach((subj_list) => {
              if (tmp_val === subj_list.id) {
                temp.push(subj_list);
              }
            })
          })
          temp.forEach((subj) => {
            subj.chapters = {};
            let _chapter = _.filter(res?.data, (cat) => {
              return cat.category === subj.id;
            });
            subj.chapters = _.sortBy(_chapter, 'id');
          });
          this.setState({ subjectList: temp });
          this.setState({selectedList: temp[0]});
          console.log('data', this.state.subjectList);
        });
    
    }
    completeTask (event) {
        // document.getElementById('house-' + this.state.selectedList?.id).classList.add("completed");
        // document.getElementById('house-' + this.state.selectedList?.id).nextSibling.classList.add("house-active");
       
    }
    handleToggle(event) {
        if (this.state.gamemode) {
            this.setState({ gamemode: '' });
        } else {
            this.setState({ gamemode: 'checked' });
        }
    }
    updateSubject(event) {
        let subj_id = event.currentTarget.id.split('-')[1];
        this.setState({selectedList: this.state.subjectList[subj_id]});
    }
    render() {
        return (
            <div className="grade-container">
                <canvas id="myCanvas"></canvas>
                <div className=" course-container">
                    <SubjectHeader profileColor="green" />
                </div>
                <div className="grade-list">
                    <div className="grade-list-right">
                        <div className='subject-header'>
                            {this.state.subjectList && this.state.subjectList.map((d, i) => {
                                return (
                                    <div id={'header-'+ i} onClick={this.updateSubject.bind(this)} className={this.state.selectedList?.name === d.name ? 'col-md-2 selected': 'col-md-2'}>
                                        {d.name}
                                    </div>
                                )
                            })} 
                        </div>
                       
                        <div className="grade-list-slider-group">  
                            <div className="grade-list-slider">
                                <label className="switch">
                                    <input onClick={this.handleToggle.bind(this)} type="checkbox" {...this.state.gamemode} />
                                    <span className="slider round"></span>
                                </label>
                            </div>
                            <span className="grade-list-slider-label">Game Mode: </span>
                        </div>
                        <GradeListOne subjectList={this.state.selectedList && this.state.selectedList.chapters  } completedTask={this.completeTask.bind(this)} gamemode={this.state.gamemode} />
                    </div>

                </div>
                <FooterGreen />
            </div>
        )
    }

}

export default GradeList;