import axios from "axios";

const instance = axios.create({
  baseURL: "",
});

let store;
export const Interceptor = (_store) => {
  store = _store;
};

instance.interceptors.request.use((config) => {
  const token = store.getState().authentication.userInfo.token;
  if (
    token &&
    !config.url.includes(
      `${process.env.REACT_APP_MOODLE_URL}`
    )
  ) {
    config.headers = {
      authorization: `Bearer ${store.getState().authentication.userInfo.token}`,
    };
  }

  return config;
});

export default instance;
