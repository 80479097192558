import React from "react";

import SubjectHeader from "../common/subjectHeader.jsx";
import "./dashboard.scss";
import axios from "../../api/axios";
import Modal from "react-modal";
import $ from "jquery";
import _ from "lodash";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import TextField from "@mui/material/TextField";
import Slider from '../NetflixSlider'
import { GetToken } from "../../api/ApiServices";
import * as type from "../../urls";

import achiveIconImg from "./images/trophy.png";
import cartImg from "./images/cart.png";
import communityImg from "./images/community.png";
import puzzleImg from "./images/puzzle.png";
import speakerImg from "./images/speaker.png";
import awardImg from "./images/cup.png";
import BoyImg from "../grades/images/Boy.png";
import RobotImg from "./images/robot.gif";
import KidImg from "../home/images/kid-1.webp";
import SparkImg from "./images/sparkle.png";
import PathImg from "./images/path-3.png";
import SpinImg from "./images/spin.png";
import StudentImg from "../grades/images/profile-picture-green.svg";
import Wheel from "../common/wheel";
import { Swiper, SwiperSlide } from "swiper/react";

import Accordion from 'react-bootstrap/Accordion';

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import Button from 'react-bootstrap/Button';

import smileGif from "./images/emoji/smile.gif";
import roflGif from "./images/emoji/smily20.gif";
import thinkGif from "./images/emoji/thinking.gif";
import thumbsGif from "./images/emoji/thumbsup.gif";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar, faTrophy, faBook, faCaretDown, faClock, faArrowCircleRight, faGamepad, faPlay, faSearch, faTable, faFilter } from "@fortawesome/free-solid-svg-icons";
import { faList, faBorderAll } from "@fortawesome/free-solid-svg-icons";

import FooterGreen from "../../common/footer/footer";

import { SERVICE_URL } from "../../urls";
import { switchClasses } from "@mui/material";
class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    const qs = new URL(window.location.href.replace('#', ''));
    this.state = {
      subjectList: [],
      headerList: [],
      gridMode: false,
      subjectListGroup: [],
      GroupBySubjects: [],
      subjectSearchList: [],
      lastAccess: [],
      selectedView: 'list',
      view: qs.searchParams.get("view") || 'dashboard',
      modalIsOpen: false,
      options: [
        "Pizzas",
        "Sandwiches",
        "Salads",
        "Soup",
        "Japanese food",
        "Pastas",
      ],
      customStyles: {
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
        },
      },
    };
    this.chooseType = this.chooseType.bind(this)
  }
  componentDidMount() {
    const childToken = JSON.parse(localStorage.getItem("childToken"));
    this.getSubject(childToken.gradeId.id);
    $('.header-menu-dashboard div').removeClass('selected');
    $('#' + this.state.view).addClass('selected');
  }

  getCourseContent() {
    let childToken = JSON.parse(localStorage.getItem("childToken"));

    axios.get(type.GetEnrollSubject(window.token, childToken?.id)).then((res) => {
      let temp = [], _tmp = [];
      let _categoryGroup = _.uniqBy(res?.data, 'category');
      let categoryGroup = _.groupBy(res?.data, 'category');
      this.getTimeLineClass();

      _categoryGroup.forEach((val) => {
        _tmp.push(val.category);
      });

      let _groupedHeader = _.groupBy(this.state?.subjectListGroup, (val) => {
        return val.parent;
      });


      _tmp.forEach((tmp_val) => {
        this.state?.subjectListGroup?.forEach((subj_list) => {
          if (tmp_val === subj_list.id) {
            temp.push(subj_list);
          }
        });
      });
      

      temp.forEach((subj) => {
        subj.chapters = {};
        let _chapter = _.filter(res?.data, (cat) => {
          return cat.category === subj.id;
        });
        subj.chapters = _.sortBy(_chapter, 'id');
      });
      //this.setState({ subjectList: temp });
      //console.log('data', this.state.subjectList);

      let _groupByHeaders = this.getFlatternTree(_groupedHeader, _groupedHeader[0]);

      let _groupBySubjectList = this.setCourseContent(categoryGroup, _groupByHeaders);

      this.setState({ GroupBySubjects: _groupBySubjectList});

      let _subjListFilter = _groupBySubjectList.filter((val, i) => {
        return val?.name.toLowerCase() != 'general' && val?.name.toLowerCase() != 'mis';
      })

      let _generalListFilter = _groupBySubjectList.filter((val, i) => {
        return val?.name.toLowerCase() === 'general';
      })

      let _misListFilter = _groupBySubjectList.filter((val, i) => {
        return val?.name.toLowerCase() === 'mis';
      })


      switch(this.state.view) {
        case 'subjects': 
          console.log('subg', this.getSubjectMap(temp, _subjListFilter, []));
          this.setState({ subjectList: this.getSubjectMap(temp, _subjListFilter, []) });
          break;
        case 'general':
          this.setState({ subjectList: this.getSubjectMap(temp, _generalListFilter, []) });
          break;
        case 'mis':
          this.setState({ subjectList: this.getSubjectMap(temp, _misListFilter, []) });
          break;
      }

      if (this.state.view != 'dashboard') {

        _.each(_groupBySubjectList, (val) => {
          if (val?.id == this.state.view) {
            this.setState({ subjectList: val?.subCategory });
            
          }
        });
      }

      const _tmpHeaderList = [{
        name: 'Subjects',
        id: 'subjects'
      }, {
        name: 'General',
        id: 'general'
      }, {
        name: 'Competitive Exams',
        id: 'mis'
      }];

      // _.each(_groupBySubjectList, (val) => {
      //   if (!val?.name.toLowerCase().includes('demo')) {
          
      //     if (val?.name.toLowerCase().includes('cbse')) {
      //       _tmpHeaderList.push({
      //         name: 'Subjects',
      //         id: val?.id
      //       });
      //     } else {
      //       _tmpHeaderList.push({
      //         name:val?.name,
      //         id: val?.id
      //       });
      //     }
          
      //   }
      // })

      this.setState({headerList: _tmpHeaderList});

      console.log('GroubByTree', _groupByHeaders, _groupBySubjectList, _tmpHeaderList);
    });

  }

  

  getSubjectMap (group, parent, list) {
    console.log('tree', group, parent);
    _.each(parent, (val) => {
      let flag = false;
      _.each(group, (_gVal) => {
        if (_gVal?.id === val?.id) {
          flag = true;
          list.push(_gVal);
        }
      });
      if (!flag && val?.subCategory) {
        this.getSubjectMap(group, val?.subCategory, list);
      }
    });
    return list;
  }

  getTimeLineClass() {
    let childToken = JSON.parse(localStorage.getItem("childToken"));
    axios.get(type.GetTimeLineClass(window.token, childToken?.id)).then((res) => {
      let _lastaccess = _.orderBy(res?.data, 'enddate', 'desc');
      this.setState({ lastAccess: _lastaccess.splice(0, 5) }, () => {
        console.log('lastaccess', this.state.lastAccess);

      });
    });
  }

  displaySubject(categoryId) {
    let ret = <></>;
    this.state.subjectList.forEach((subj) => {
      console.log(categoryId)
      console.log(subj)
      if (subj.id === categoryId) {
        ret = <>{subj.name}</>;
      }
    })
    return ret;
  }

  getSubject(id) {
    if (!window.token) {
      let childToken = JSON.parse(localStorage.getItem("childToken"));

      if (childToken.token) {
        window.token = childToken.token;
        this.getSubjectList(id);
      } else {
        localStorage.removeItem("parentToken");
        localStorage.removeItem("child");
        localStorage.removeItem("childToken");
        window.location.href = "#/home";
      }
    } else {
      this.getSubjectList(id);
    }
  }

  getSubjectList(id) {
    axios.get(type.GetSubjectURL(window.token)).then((res) => {
      this.setState({ subjectListGroup: res?.data }, () => {
        this.getCourseContent();
      });
    });
  }

  getFlatternTree (oriValue, value) {
    let _subjectTree = [];
    _.each(value, (val) => {
      if (oriValue[val?.id]) {
        let temp = val;
        temp.subCategory = oriValue[val?.id];
        this.getFlatternTree(oriValue, oriValue[val?.id]);
        _subjectTree.push(temp);
      }
    })
    return _subjectTree;
  }

  setCourseContent (categoryGroup, groupHeaders) {
    _.each(groupHeaders, (val) => {
      if (categoryGroup[val?.id]) {
        val.chapters = categoryGroup[val?.id];
      } else if (val?.subCategory) {
        this.setCourseContent(categoryGroup, val?.subCategory);
      }
    });
    return groupHeaders;
  }

  navigationChapterGroup(event) {

    // console.log(event.currentTarget.id);
    window.location.href = `#/courses/${event.currentTarget.id}?chapter=${event.currentTarget.id}`;
    //this.setState({chapterContent: GetChapterContent(event.currentTarget.id)});
  }
  afterOpenModal() { }
  closeModal() {
    this.setState({ modalIsOpen: false });
  }
  openModal() {
    this.setState({ modalIsOpen: true });
  }
  setGridView(ev) {

    $('.accordion-list').hide();
    $('#grid-list-icon').addClass('active');
    $('#list').removeClass('active');
    this.setState({ gridMode: true }, () => {
      $('.grid-list').removeClass('hide');
    });
  }

  setListView(ev) {

    $('.accordion-list').show();
    $('#grid-list-icon').removeClass('active');
    $('#list').addClass('active');
    this.setState({ gridMode: false }, () => {
      $('.grid-list').addClass('hide');
    });
  }

  UpdateFilter(event) {
    let _filterVal = event.currentTarget.value;
    // this.setState({fitlerValue: event.currentTarget.value });
    if (!_filterVal) {
      $('.accordion-list').show();
      $('.search-list').hide();
    } else {
      let _tempList = {
        completed: [],
        inprogress: [],
        notstarted: []
      }
      $('.accordion-list, .grid-list').hide();
      $('.search-list').show();
      this.state.subjectList?.forEach((subject, ix) => {
        subject?.chapters.forEach((chapter) => {
          let temp = chapter;
          temp.subjectname = subject?.name;
          if (chapter?.progress === 100) {
            _tempList.completed.push(chapter);
          } else if (chapter?.progress === 0) {
            _tempList.notstarted.push(chapter);
          } else {
            _tempList.inprogress.push(chapter);
          }
        })
      });
      this.setState({ subjectSearchList: _tempList[_filterVal] });
    }
  }

  setViewSearch() {
    if ($('.subject-search').hasClass('show')) {
      $('.subject-search').removeClass('show');
    } else {
      $('.subject-search').addClass('show');
    }
  }

  searchSubjects(ev) {
    let searchString = ev.currentTarget.value || '';
    if (searchString.length < 3) {
      $('.accordion-list').show();
      $('.search-list').hide();
    } else {
      let searchList = [];
      $('.accordion-list, .grid-list').hide();
      $('.search-list').show();
      this.state.subjectList?.forEach((subject, ix) => {
        subject?.chapters.forEach((chapter) => {
          if (chapter?.displayname.toLowerCase().includes(searchString)) {
            let temp = chapter;
            temp.subjectname = subject?.name;
            searchList.push(chapter);
          }
        })
      });
      this.setState({ subjectSearchList: searchList });
      console.log(searchList);
    }
  }

  chooseType(ev) {
    $('.header-menu-dashboard div').removeClass('selected');
    // $('.wrapper').addClass('hide');
    let id = ev.target.id;
    this.setState({ view: id });
    $('#' + id).addClass('selected');
    window.location.href = '#/dashboard?view=' + id;
    if (id != 'dashboard') {
      this.getSubject();
    }
    // $('#' + id + '_wrap').removeClass('hide');
  }

  swipperValue(subject, view) {
    if (view === 'grid') {
      return <Accordion.Body>
        <div className="chapter-single-wrap">
          <Swiper
            breakpoints={{
              1000: {
                slidesPerView: 4,
                slidesPerGroup: 4
              },
              // 800: {
              //   slidesPerView: 3,
              //   slidesPerGroup: 3
              // },
              // 100: {
              //   slidesPerView: 2,
              //   slidesPerGroup: 2
              // },
            }}
            spaceBetween={20}
            loop={true}
            loopFillGroupWithBlank={false}
            navigation={true}
            className="subject-slider"
            speed={5000}
            autoplay={false}
          >
            {subject?.chapters && subject?.chapters.map((chapter, i) => {
              return (
                <SwiperSlide>
                  <div onClick={this.navigationChapterGroup.bind(this)} key={chapter.id} id={chapter.id} className=" col-md-9 chapter-single">

                    <div className="chapter-header">
                      <FontAwesomeIcon icon={faBook} className="icon" />
                      <span>Current Chapter</span>
                      <FontAwesomeIcon icon={faClock} className="calendar-icon icon" />
                    </div>
                    <span className="chapter-name">{chapter.displayname}</span>
                    <FontAwesomeIcon icon={faArrowCircleRight} className="arrow-placement icon" />
                  </div>
                </SwiperSlide>
              )
            })
            }
          </Swiper>
        </div>
      </Accordion.Body>;
    }
    return <div></div>;

  }



  chooseMoods(event) {
    if ($(".moodes-icon img").hasClass("active")) {
      $(".moodes-icon img").removeClass("active");
      $(".moodes-icon img").removeClass("hide");
    } else {
      if (!($(event.target).hasClass('moodes-icon') || $(event.target).hasClass('header'))) {
        $(".moodes-icon img").addClass("hide");
        event.target.classList.add("active");
      }
    }
    // $(".moodes-icon img").removeClass("active");
  }
  render() {
    let childInfo = JSON.parse(localStorage.getItem("child"));
    return (
      <div className="green-background">
        <div className="course-container">
          <SubjectHeader home={true} homeHeader={this.state.headerList} chooseType={this.chooseType} profileColor="green" />
        </div>
        {/* <div onClick={this.chooseType.bind(this)} className="header-menu-dashboard col-md-12">
          <div id="dashboard" className="col-md-4 selected">Dashboard</div>
          <div id="subjects" className="col-md-4">Subjects</div>
          <div id="notification" className="col-md-4">Notifications</div>
        </div> */}
        {this.state.view === 'dashboard' &&
          <div id="dashboard_wrap" className="dashboard-home wrapper">
            <Modal
              isOpen={this.state.modalIsOpen}
              onAfterOpen={this.afterOpenModal.bind(this)}
              onRequestClose={this.closeModal.bind(this)}
              style={this.state.customStyles}
              contentLabel="Example Modal"
            >
              <div className="modal-spin-wheel">
                <h2>Spin Wheel</h2>
                <Wheel items={this.state.options} />
              </div>
            </Modal>
            <div className="dashboard-wrapper">
              <div className="student-profile">
                <div className="col-2 col-xs-2 col-sm-2 col-lg-1 col-xl-1 profile-img">
                  <img src={StudentImg} />
                </div>
                {childInfo && (
                  <div className="col-8 col-xs-8 col-sm-8 col-md-4 col-lg-6 col-xl-2 stu-details">
                    <span className="stu-name">
                      {" "}
                      Hi {childInfo.firstname} {childInfo.lastname},
                    </span>
                    <span className="stu-location">{childInfo.username}</span>
                    <span className="stu-info">{childInfo.boardName}</span>
                    <span className="stu-info">Grade {childInfo.grade}</span>
                    {/* <span className="stu-info">Nov 2021</span> */}
                  </div>
                )}

                <div className="d-none d-sm-none d-md-none d-lg-none d-xl-flex col-sm-2 mascot-character-img">
                  <img src={RobotImg} />
                </div>
                <div className="d-none d-sm-none d-md-none d-lg-none d-xl-flex col-sm-2 quotes-img">
                  <span>
                    “Not only must we be good, but we must also be good for
                    something.” – Henry David Thoreau.
                  </span>
                </div>
                <div className="d-none d-sm-none d-md-flex profile-column-wrapper">
                  <img src={awardImg} />
                </div>
                <div className="d-none d-sm-none d-md-flex rewards-count">
                  <img className="d-none d-xs-none d-sm-flex" src={SparkImg} />
                  <div className="rewards-count-my">
                    <span className="my-rewards"> 500 </span>
                    <span className="rewards-txt"> My Rewards</span>
                    <img className="path-img" src={PathImg} />
                  </div>
                </div>
              </div>
              <div className="dashbaord-tiles">
                <div className="dashboard-tile-group">
                  <div
                    onClick={this.chooseMoods.bind(this)}
                    className="small-block moodes-icon"
                  >
                    <div className="header">How are you doing today?</div>
                    <img className="active" src={smileGif} />
                    <img className="hide" src={roflGif} />
                    <img className="hide" src={thinkGif} />
                    <img className="hide" src={thumbsGif} />
                  </div>

                  <div onClick={() => window.location.href = "#/activities"} className="small-block activities-tile">
                    <span className="header">Assignments</span>
                    <img src={puzzleImg} />
                    <p>
                      We are providing the learners with challenging, exciting and
                      different experiences to help them skilful learning{" "}
                    </p>
                  </div>

                  <div className="small-block community-tiles" onClick={() => window.location.href = "#/community"}>
                    <span className="header">Messages</span>
                    <img src={communityImg} />
                    {/* <p>Math is fun</p> */}
                  </div>
                  <div className="small-block read-courses">
                    <h5>Continue Reading...</h5>
                    <div className="course-reads">
                      {this.state.lastAccess &&
                        this.state.lastAccess.map((value, i) => {
                          return (
                            <div className="col-md-12 course-read-single">
                              <div className="progress-subj" style={{ width: 40, height: 40 }}>
                                <CircularProgressbar value={value?.progress} text={`${Number(value?.progress).toFixed(0)}%`} styles={buildStyles({
                                  textColor: "black",
                                  pathColor: "#e5722e",
                                  trailColor: "#ccc"
                                })} />
                              </div>

                              <div key={i}
                                onClick={() => {
                                  window.location.href = `#/courses/6?chapter=${value.id}`;
                                }}
                                className="col-md-9 subject-reads"
                              >
                                <div>{value.coursecategory}</div>
                                <div className="subj-name-reads">{value.fullname}</div>
                              </div>

                            </div>

                          );
                        })}
                    </div>
                  </div>
                  <div
                    // onClick={this.openModal.bind(this)}
                    className="small-block spin-rewards cursor-not-allowed"
                  >
                    <div className="">
                      <img src={SpinImg} />
                    </div>
                    <div className="">
                      <h3 className="header"> Spin</h3>
                      <span>Give a try</span>
                    </div>
                  </div>
                  <div className="small-block store-tile cursor-not-allowed">
                    <img src={cartImg} className="w-50-per" />
                    <span className="header">Go to store</span>
                  </div>
                  <div className="small-block achieve-tile">
                    <img className="border-icon" src={achiveIconImg} />

                    <span className="header">Achievements</span>

                    <div className="achieve-single achive-padding">
                      <img src={KidImg} />
                      <span>Chandran</span>
                      <FontAwesomeIcon icon={faTrophy} className="icon" />
                      <FontAwesomeIcon icon={faTrophy} className="icon" />
                      <FontAwesomeIcon icon={faTrophy} className="icon" />
                    </div>
                    <div className="achieve-single">
                      <img src={KidImg} />
                      <span>shivas</span>
                      <FontAwesomeIcon icon={faTrophy} className="icon" />
                      <FontAwesomeIcon icon={faTrophy} className="icon" />
                      <FontAwesomeIcon icon={faTrophy} className="icon" />
                      <FontAwesomeIcon icon={faTrophy} className="icon" />
                    </div>
                    <div className="achieve-single">
                      <img src={KidImg} />
                      <span>Lim Yung</span>
                      <FontAwesomeIcon icon={faTrophy} className="icon" />
                      <FontAwesomeIcon icon={faTrophy} className="icon" />
                    </div>
                    <div className="achieve-single">
                      <img src={KidImg} />
                      <span>Kevin</span>
                      <FontAwesomeIcon icon={faTrophy} className="icon" />
                      <FontAwesomeIcon icon={faTrophy} className="icon" />
                      <FontAwesomeIcon icon={faTrophy} className="icon" />
                      <FontAwesomeIcon icon={faTrophy} className="icon" />
                      <FontAwesomeIcon icon={faTrophy} className="icon" />
                    </div>
                  </div>
                  <div className="small-block announce-tile">
                    <img src={speakerImg} />
                    <span className="header">Announcements</span>
                    <div className="announce-wrapper">
                      <div className="announce-single">
                        <h6>
                          <FontAwesomeIcon icon={faCalendar} />
                          24 Aug 2022
                        </h6>
                        <p>
                          Shivani Academy Grand Pilot Launch on 24 August 2022, 8 PM - 9 PM.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        } 

        {this.state.view != 'dashboard' &&
          <div id="subjects_wrap" className=" wrapper">


            <div className="icons-group">

              <div className="icons">
                <div className="subj-search ">
                  <div className="subject-search search">
                    <input type="text"
                      id="outlined-basic"
                      onChange={this.searchSubjects.bind(this)}
                    />
                  </div>
                </div>
                <FontAwesomeIcon id="list-search" onClick={this.setViewSearch.bind(this)} icon={faSearch} className="icon active" />

                <FontAwesomeIcon id="list" onClick={this.setListView.bind(this)} icon={faList} className="icon active" />
                <FontAwesomeIcon id="grid-list-icon" onClick={this.setGridView.bind(this)} icon={faTable} className="icon" />

                <OverlayTrigger
                  trigger="click"
                  placement="bottom"
                  overlay={
                    <Popover id="popover-positioned-left">
                      <Popover.Header as="h3">Filters</Popover.Header>
                      <Popover.Body className="filter-group-wrapper">
                        <div className="filter-single">
                          <input value="inprogress" onChange={this.UpdateFilter.bind(this)} type="radio" name="filterGroup" /> In Progress
                        </div>
                        <div className="filter-single">
                          <input value="completed" onChange={this.UpdateFilter.bind(this)} type="radio" name="filterGroup" /> Completed
                        </div>
                        <div className="filter-single">
                          <input value="notstarted" onChange={this.UpdateFilter.bind(this)} type="radio" name="filterGroup" /> Not Started
                        </div>
                        <div className="filter-single">
                          <button value="" onClick={this.UpdateFilter.bind(this)} > Reset </button>
                        </div>
                      </Popover.Body>
                    </Popover>
                  }
                >
                  {/* <FontAwesomeIcon id="filter" icon={faFilter} className="icon active" /> */}
                  <Button className="filter-wrapper"><FontAwesomeIcon id="filter" icon={faFilter} className="icon active" /></Button>

                </OverlayTrigger>

                <FontAwesomeIcon id="list-game" onClick={() => { window.location.href = '/#/grade' }} icon={faGamepad} className="icon active" />
                <FontAwesomeIcon id="list-game2" onClick={() => { window.location.href = '/#/grades' }} icon={faPlay} className="icon active" />
                {/* <FontAwesomeIcon id="grid" onClick={this.setView.bind(this)} icon={faBorderAll} className="icon" /> */}
              </div>
            </div>
            {this.state.view != 'subjects' &&
              <div onClick={() => window.location.href = this.state.view === 'general' ? '#/buy-courses/general' : '#/buy-courses/competitive'} className="col-md-12 subsc-msg-wrap">
                <div className="col-md-8"></div>
                <button className="col-md-4 subsc-msg">Explore coruses !...</button>
              </div>
            }
            
            <div className="accordion-list">
              {this.state.subjectList && this.state.subjectList.map((subject, ix) => {
                return (
                  <Accordion >
                    <div className="desktop-view hide subject-single">
                      <div className="col-md-12 subject-header">{subject.name}</div>
                      <div className="chapter-single-wrap">
                        {
                          this.swipperValue()
                        }
                      </div>

                    </div>
                    <div className="mobile-view subject-single">
                      <Accordion.Item eventKey={ix}>
                        {/* <div className="col-md-12 subject-header">{subject.name}</div> */}
                        <Accordion.Header className="col-md-12 subject-header">{subject.name}{" - "}{subject.chapters && subject.chapters.length + " Chapters"}<FontAwesomeIcon icon={faCaretDown} className="arrow-expand icon " /></Accordion.Header>
                        <Accordion.Body className={this.state.selectedView === 'list' ? 'list-view' : 'hide'}>
                          {subject.chapters && subject.chapters.map((chapter, i) => {
                            return (
                              <div onClick={this.navigationChapterGroup.bind(this)} key={chapter.id} id={chapter.id} className=" col-md-9 chapter-single">

                                <div className="col-12 chapter-header">
                                  {/* <span className="col-1 chapter-id-wrap"><span className="chapter-id">{i + 1}</span></span> */}
                                  <span className="mx-4"><FontAwesomeIcon icon={faBook} className="icon " /></span>
                                  <span className="chapter-name col-8 cursor-pointer">{chapter.displayname}</span>
                                  <span className="progress-span">{chapter.progress || "0"}% Completed</span>
                                  {/* <span className="col-2"><FontAwesomeIcon icon={faArrowCircleRight} className="arrow-placement icon " /></span> */}

                                </div>

                              </div>
                            )

                          })
                          }
                        </Accordion.Body>
                        {/* {
                          this.swipperValue(subject, this.state.selectedView)
                        } */}
                        {/* <Accordion.Body className={this.state.selectedView === 'grid' ? 'grid-view' : 'hide'}> */}
                        {/* <div className="chapter-single-wrap">
                            <Swiper
                              breakpoints={{
                                1000: {
                                  slidesPerView: 4,
                                  slidesPerGroup: 4
                                },
                                // 800: {
                                //   slidesPerView: 3,
                                //   slidesPerGroup: 3
                                // },
                                // 100: {
                                //   slidesPerView: 2,
                                //   slidesPerGroup: 2
                                // },
                              }}
                              spaceBetween={20}
                              loop={true}
                              loopFillGroupWithBlank={false}
                              navigation={true}
                              className="subject-slider"
                              speed={5000}
                              autoplay={false}
                            >
                              {subject.chapters && subject.chapters.map((chapter, i) => {
                                return (
                                  <SwiperSlide>
                                    <div onClick={this.navigationChapterGroup.bind(this)} key={chapter.id} id={chapter.id} className=" col-md-9 chapter-single">

                                      <div className="chapter-header">
                                        <FontAwesomeIcon icon={faBook} className="icon" />
                                        <span>Current Chapter</span>
                                        <FontAwesomeIcon icon={faClock} className="calendar-icon icon" />
                                      </div>
                                      <span className="chapter-name">{chapter.displayname}</span>
                                      <FontAwesomeIcon icon={faArrowCircleRight} className="arrow-placement icon" />
                                    </div>
                                  </SwiperSlide>
                                )
                              })
                              }
                            </Swiper>
                          </div> */}

                        {/* </Accordion.Body> */}
                      </Accordion.Item>


                    </div>
                  </Accordion>
                )
              })}
              { !this.state.subjectList.length &&
                <div className="subject-empty">No Courses are subscribed</div>
              }
            </div>
            {this.state.gridMode &&
              <div className="grid-list hide">
                {this.state.subjectList && this.state.subjectList.map(subj => (
                  <>
                    <h4>{subj?.name}</h4>
                    <Slider>
                      {subj?.chapters.map((movie, ix) => (
                        <Slider.Item ix={ix} movie={movie} key={movie.id}>item1</Slider.Item>
                      ))}

                    </Slider>
                  </>

                ))}
              </div>
            }





            <div className="search-list">
              {this.state.subjectSearchList && this.state.subjectSearchList.map((subject, ix) => {
                return (
                  <div key={subject.id} id={subject.id} onClick={this.navigationChapterGroup.bind(this)} className="subj">{subject.displayname} - <span className="sub-div">({subject.subjectname})</span> </div>
                )
              })}
            </div>



          </div>
        }
        {this.state.view === 'notification' &&
          <div id="notification_wrap" className=" wrapper">
            Subject List

          </div>
        }

        <FooterGreen />
      </div>
    );
  }
}

export default Dashboard;
