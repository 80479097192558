import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

// API
import axios from "../../api/axios";
import { ApiServices } from "../../api/ApiServices";

// Redux Actions
import { authenticationActions } from "../../reducers/authenticationReducers";

import InputContainer from "../../common/inputs/InputContainer";

// CSS
import "./Verify.css";
import classes from "./Signup.module.css";

// Images
import Boy from "../../images/boy.svg";
import Girl from "../../images/girl.svg";
import Spinner from "../../images/spinner.svg";

const ResetPassword = () => {
  const history = useHistory();
  const params = useParams();
  const dispatch = useDispatch();
  const [newPassword, SetNewPassword] = useState({ value: "", error: false });
  const [confirmpassword, SetConfirmPassword] = useState({
    value: "",
    error: false,
  });
  const [loaderShow, SetLoaderShow] = useState(false);

  useEffect(() => {
    if (params.id.length > 0) {
      let userInfo = window.atob(params.id).split(":::");
      if (userInfo[0].length < 1 || isNaN(parseFloat(userInfo[0])) || userInfo[1].length < 6 || isNaN(parseFloat(userInfo[1]))) {
        history.push("../login");
      }
    } else {
      history.push("../login");
    }
  }, [params.id, history]);
  const NewPasswordHandler = (event) => {
    if (event.target.value === "") {
      SetNewPassword({
        ...newPassword,
        error: true,
        errorText: "Enter valid email ID",
      });
    } else {
      SetNewPassword({
        value: event.target.value,
        error: false,
        errorText: "",
      });
    }
  };

  const ConfirmPasswordHandler = (event) => {
    if (event.target.value === "") {
      SetConfirmPassword({
        ...confirmpassword,
        error: true,
        errorText: "Password should minimum of 8 characters",
      });
    } else {
      SetConfirmPassword({
        value: event.target.value,
        error: false,
        errorText: "",
      });
    }
  };

  const OnClickSignInHandler = () => {
    history.push("../login");
  };

  const LoginSubmitHandler = (event) => {
    event.preventDefault();
    SetLoaderShow(true);
    if (newPassword.value === "" || newPassword.value.length < 8) {
      SetNewPassword({
        ...newPassword,
        error: true,
        errorText: "New Password should minimum of 8 characters",
      });
      SetLoaderShow(false);
    }

    if (confirmpassword.value === "" || confirmpassword.value.length < 8) {
      SetConfirmPassword({
        ...confirmpassword,
        error: true,
        errorText: "Confirm Password should minimum of 8 characters",
      });
      SetLoaderShow(false);
    }

    if (newPassword.value.length >= 8 && confirmpassword.value.length >= 8 && newPassword.value !== confirmpassword.value) {
      SetConfirmPassword({
        ...confirmpassword,
        error: true,
        errorText: "Confirm Password does not match with New Password",
      });
      SetLoaderShow(false);
    }

    if (
      newPassword.value !== "" &&
      newPassword.value.length >= 8 &&
      confirmpassword.value !== "" &&
      confirmpassword.value.length >= 8 &&
      newPassword.value === confirmpassword.value
    ) {
      axios
        .post(`${ApiServices("updatePassword")}&url=${params.id}&password=${window.btoa(confirmpassword.value)}`)
        .then(
          (response) => {
            if (
              response.data.message === "Password updated successfully"
            ) {
              window.alert("Password Updated Successfully")
              history.push("../login");
            } else {
              SetConfirmPassword({
                ...confirmpassword,
                errorText: response.data.message,
                error: true,
              });
            }
            SetLoaderShow(false);
          },
          (error) => {
            SetNewPassword({
              ...newPassword,
              errorText: "Invalid Credentials Provided",
              error: true,
            });
            SetConfirmPassword({
              ...confirmpassword,
              errorText: "Invalid Credentials Provided",
              error: true,
            });
            SetLoaderShow(false);
          }
        );
    }
  };

  return (
    <div className={classes.signupContainer}>
      {loaderShow && (
        <img className={classes.loader} src={Spinner} alt="Loading" />
      )}
      <div className={`${classes.signupContent} login-container`}>
        <div>
          <img src={Boy} className="student-img-boy" alt="Boy Student" />
          <div
            className={`Shivani-Labs ${classes["mb-30"]}`}
            onClick={() => {
              history.push("./home");
            }}
          >
            Shivani <span className="text-style-1">Labs</span>
          </div>
          <h5 className={`${classes["mb-1-rem"]} text-left`}>New password</h5>
          <p
            className={`${classes["mb-1-rem"]} ${classes["reset-instructions"]} text-left`}
          >
            Create new password
          </p>
          <form onSubmit={LoginSubmitHandler}>
            <div className={`${classes.inputContent} w-100`}>
              <InputContainer
                type="password"
                name="new-password"
                labelName="New password"
                placeholder="New password"
                onChange={NewPasswordHandler}
                error={newPassword.error}
                errorText={newPassword.errorText}
              />
              <InputContainer
                type="password"
                name="confirm-password"
                labelName="Confirm password"
                placeholder="Confirm password"
                onChange={ConfirmPasswordHandler}
                error={confirmpassword.error}
                errorText={confirmpassword.errorText}
              />

              <button type="submit">Change password</button>
            </div>
          </form>
          <div className={classes.others}>
            <div className="text-center">
              <label className={`${classes["f-12"]}`}>
                Back to{" "}
                <span
                  onClick={OnClickSignInHandler}
                  className={`${classes.link} ${classes["signup-highlight"]}`}
                >
                  Sign In
                </span>
              </label>
            </div>
          </div>
          <img src={Girl} alt="Girl Student" className="student-img-girl" />
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
