import { createSlice } from "@reduxjs/toolkit";

const initialAuthenticationState = { userInfo: {} };

const authenticationSlice = createSlice({
  name: "Authentication",
  initialState: initialAuthenticationState,
  reducers: {
    login(state, action) {
      state.userInfo = action.payload;
    },
    logout(state) {
      state.userInfo = {};
    },
  },
});

export const authenticationActions = authenticationSlice.actions;
export default authenticationSlice.reducer;
