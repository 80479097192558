import Progressbar from "../../common/progressbar/ProgressBar";
const ChildSubject = (props) => {
  return (
    <div className="subject-card col-md-3" key={props.i}>
      <p className="subject-name">English - 1</p>
      <p className="subject-total-chapter">Total chapters - 10</p>
      <Progressbar
        bgcolor="#0d6d22"
        progress="0"
        showImage={false}
        showCount={true}
      />
      <p className="subject-time-spend mt-40">
        Time spend this today - 1 hrs 30 mints
      </p>
      <p className="subject-time-spend">Time spend this week - 4 hrs</p>
      <p className="subject-time-spend">Time spend this month - 10 hrs</p>
    </div>
  );
};

export default ChildSubject;
