import { configureStore } from "@reduxjs/toolkit";
import authenticationReducer from "../reducers/authenticationReducers";
import childInfoReducer from "../reducers/childInfoReducers";
import parentInfoReducer from "../reducers/parentInfoReducers";
import subjectReducer from "../reducers/subjectReducers";

export const store = configureStore({
  reducer: {
    authentication: authenticationReducer,
    childInfo: childInfoReducer,
    parentInfo: parentInfoReducer,
    subjects: subjectReducer,
  },
});
