import React, { useState, useEffect } from 'react';
import $ from 'jquery';
import BoyImg from './images/boy-chapter.png';
import StartImg from './images/group-3.png';
import CasualImg from './images/casual.svg';
import ThinkImg from './images/think.svg';

import './gradelist.scss';
import './css/courselist.scss';
import { Redirect } from 'react-router';
const CourseList = (props) => { 
    const handleClick = (event) => {
        let {id} = event.currentTarget;
        $('.think-img').addClass('hide');
        $('.course_number').addClass('hide');
        $('.course_line').addClass('hide')
        $('.image-location').addClass('hide');
        $('.chapter-details').addClass('hide');
        
        $('.course_single').removeClass('selected');
        $('#' + id + " .course_number").removeClass('hide');
        $('#' + id).addClass('selected');
        $('#' + id + " .course_line").removeClass('hide'); 
        $('#' + id + " .image-location").removeClass('hide'); 
        $('#' + id + " .chapter-details").removeClass('hide'); 
    }
    const RedirectURL = () => {
        window.location.href = '#/courses/6';
    }
    const displayChapterName = (val, ix) => {
        return (
            <div id={"course_single_"+ val.id} style={{left :(100 + (ix * 100)) + 'px'}} className={" course_single"} onClick={handleClick}>
                <img className="image-location hide" src={BoyImg} />
                <div className="chapter-details hide">
                    <span onClick={RedirectURL}>{val.displayname}</span>
                </div>
                <div className="course_number hide"><span>{ix + 1}</span></div>
                <div className="course_line hide"></div>
                <div className="course_1"></div>
            </div>
        );
    }
    return (
        <div className="grade-list">
             <div className="grade-canvas image-1">
                <img  className="start-icon think-img  " src={ThinkImg} />
                <img style={{left :'80px'}} className="start-icon  " src={StartImg} />
                <div className="start-text"><span>Start</span></div>
                 {props.subjectList && props.subjectList.map((d, i) => (
                     displayChapterName(d, i)
                 ))}
             </div>
        </div>
    );
}

export default CourseList;