import Moment from "moment";
import "./ActivityCard.css";
import ActivitySubmitted from "../../images/date-submitted.svg";
import Rewards from "../../images/rewards.svg";

import TempImage from '../../images/profile-picture-temp.svg'

const ActivityCard = (props) => {
  return (
    <div
      className={`activity-card ${props.className} ${props.completed_activity ? "d-flex" : ""
        }`}
      style={{ backgroundColor: "#fff" }}
    >
      {props.subject &&
        props.postedDate &&
        !props.individual_activity &&
        !props.upload_activity && (
          <div className="d-flex">
            <p className="w-50 subject">Activity in {props.subject}</p>
            <p className="w-50 posted-on">
              {/* Posted on {Moment(props.postedDate).format("DD/MM/YYYY")} */}
              Posted on {Moment().format("DD/MM/YYYY")}
            </p>
          </div>
        )}
      {props.completed_activity && (
        <div className="d-flex">
          <div>
            <img
              // src={props.dp}
              src={TempImage}
              alt="Profile Picture"
              className={`activity-profile increased-width`}
            />
          </div>
          <div className={`activity-person activity-person-new`}>
            <p className="activity-by">{props.username}</p>
            <p className="activity-from">{props.destination}</p>
          </div>
        </div>
      )}
      {!props.completed_activity && !props.upload_activity && (
        <div className="d-flex">
          <div>
            <img
              // src={props.dp}
              src={TempImage}
              alt="Profile Picture"
              className={`activity-profile ${props.individual_activity ? "increased-width" : ""
                }`}
            />
          </div>
          <div
            className={`activity-person ${props.individual_activity ? "activity-person-new" : ""
              }`}
          >
            <p className="activity-by">{props.username}</p>
            <p className="activity-from">{props.destination}</p>
          </div>
        </div>
      )}
      {props.activityname &&
        props.activityimage &&
        !props.individual_activity &&
        !props.upload_activity && (
          <div className="activity-detl">
            <p className="activity-heading">{props.activityname}</p>
            <img
              // src={props.activityimage}
              src={TempImage}
              alt="Activity Image"
              className="activity-image"
            />
          </div>
        )}
      {props.upload_activity && (
        <div className="activity-description">{props.description}</div>
      )}
      <div
        className={`reward-container ${props.individual_activity ? "ml-70" : ""
          } ${props.upload_activity ? "upload-credits" : "d-flex"}`}
      >
        <img src={Rewards} alt="reward" className="reward-icon" />
        <div className="reward-info">
          Earned {props.point} {props.point == "1" ? "point" : "points"}
        </div>
      </div>
      <div
        className={`reward-container ${props.individual_activity ? "ml-70" : ""
          }  ${props.upload_activity ? "upload-credits" : "d-flex"}`}
      >
        <img src={ActivitySubmitted} alt="reward" className="reward-icon" />
        <div className="reward-info reward-date">
          {/* Submitted on {Moment(props.submittedDate).format("DD/MM/YYYY")} */}
          Submitted on {Moment().format("DD/MM/YYYY")}
        </div>
      </div>
    </div>
  );
};

export default ActivityCard;
