import { useState } from "react";
import Moment from "moment";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import InputContainer from "../../common/inputs/InputContainer";

// CSS
import "./ChildActivity.scss";

let monthOptions = [
  {
    label: "JANUARY",
    value: "01",
  },
  {
    label: "FEBRUARY",
    value: "02",
  },
  {
    label: "MARCH",
    value: "03",
  },
  {
    label: "APRIL",
    value: "04",
  },
  {
    label: "MAY",
    value: "05",
  },
  {
    label: "JUNE",
    value: "06",
  },
  {
    label: "JULY",
    value: "07",
  },
  {
    label: "AUGUST",
    value: "08",
  },
  {
    label: "SEPTEMBER",
    value: "09",
  },
  {
    label: "OCTOBER",
    value: "10",
  },
  {
    label: "NOVEMBER",
    value: "11",
  },
  {
    label: "DECEMBER",
    value: "12",
  },
];

const now = new Date().getUTCFullYear();
let NegativeYears = Array(now - (now - 25))
  .fill("")
  .map((v, idx) => now - idx);
let PositiveYears = Array(now - (now - 25))
  .fill("")
  .map((v, idx) => now + idx);

let yearOptions = [];
PositiveYears.reverse().pop();
PositiveYears.map((year) => {
  yearOptions.push({
    label: year,
    value: year,
  });
});
NegativeYears.map((year) => {
  yearOptions.push({
    label: year,
    value: year,
  });
});
const ChildActivity = () => {
  const [dateSelect, SetDateSelect] = useState(new Date());
  const [selectedDays, setSelectedDays] = useState([1, 2, 4, 10, 21, 25, 15]);
  const [daysWithDot, setDaysWithDot] = useState([]);
  const DateSelectHandler = (event) => {
    SetDateSelect(event);
  };

  const [month, SetMonth] = useState({
    value:
      new Date().getMonth() + 1 > 9
        ? Number(new Date().getMonth()) + 1
        : "0" + (Number(new Date().getMonth()) + 1),
    error: false,
    errorText: "",
  });

  const MonthHandler = (value) => {
    SetMonth({ value: value, error: false });
  };

  const [year, SetYear] = useState({
    value: now,
    error: false,
    errorText: "",
  });

  const YearHandler = (value) => {
    SetYear({ value: value, error: false });
  };
  console.log(year.value, month.value);
  return (
    <div className="col-md-12 d-flex mywards-calendar">
      <div className="col-md-3 p-0 mywards-calendar-input-container">
        <InputContainer
          type="select"
          name="year"
          labelName=""
          labelClass="add-child-input-label"
          placeholder=""
          options={yearOptions}
          onChange={YearHandler}
          selectValue={year.value}
          error={year.error}
          errorText={year.errorText}
          disabled={false}
          customStyleSelect={customStyles}
          className="my-wards-calendar-input"
        />
        <InputContainer
          type="select"
          name="month"
          labelName=""
          labelClass="add-child-input-label"
          placeholder=""
          selectValue={month.value}
          options={monthOptions}
          onChange={MonthHandler}
          error={month.error}
          errorText={month.errorText}
          disabled={false}
          customStyleSelect={customStyles}
          className="my-wards-calendar-input"
        />
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DatePicker
            variant="static"
            format="dd-MMM-yyyy"
            value={dateSelect}
            renderDay={(day, selectedDate, isInCurrentMonth, dayComponent) => {
              const date = Moment(day).date(); // skip this step, it is required to support date libs
              const isSelected =
                isInCurrentMonth && selectedDays.includes(date);
              return (
                <div className={isSelected ? "day-activties-done" : ""}>
                  {dayComponent}
                </div>
              );
            }}
            className={``}
            onChange={DateSelectHandler}
            disableToolbar
            animateYearScrolling
            allowKeyboardControl
            autoOk
          />
        </MuiPickersUtilsProvider>
      </div>
      <div className="col-md-9 calendar-details-container">
        <div className="calendar-date-info">
          <div className="date-container">
            <p className="date">9</p>
          </div>
          <p className="month">January,</p>
          <p className="year">{year.value}</p>
        </div>
        <table className="custome-table">
          <thead>
            <tr>
              <th>LOGIN</th>
              <th>SUBJECT</th>
              <th>CHAPTER</th>
              <th>DURATION</th>
              <th>LOGOUT</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div>10.50 AM</div>
                <div>10.15 AM</div>
              </td>
              <td>
                <div>English</div>
                <div>Maths</div>
              </td>
              <td>
                <div>Princess dairy</div>
                <div>Number systems</div>
              </td>
              <td>
                <div>21 mins</div>
                <div>13 mins</div>
              </td>
              <td>
                <div></div>
                <div>10.45 AM</div>
              </td>
            </tr>
            <tr>
              <td>
                <div>10.50 AM</div>
                <div>10.15 AM</div>
              </td>
              <td>
                <div>English</div>
                <div>Maths</div>
              </td>
              <td>
                <div>Princess dairy</div>
                <div>Number systems</div>
              </td>
              <td>
                <div>21 mins</div>
                <div>13 mins</div>
              </td>
              <td>
                <div></div>
                <div>10.45 AM</div>
              </td>
            </tr>
            <tr>
              <td>
                <div>10.50 AM</div>
                <div>10.15 AM</div>
              </td>
              <td>
                <div>English</div>
                <div>Maths</div>
              </td>
              <td>
                <div>Princess dairy</div>
                <div>Number systems</div>
              </td>
              <td>
                <div>21 mins</div>
                <div>13 mins</div>
              </td>
              <td>
                <div></div>
                <div>10.45 AM</div>
              </td>
            </tr>
            <tr>
              <td>
                <div>10.50 AM</div>
                <div>10.15 AM</div>
              </td>
              <td>
                <div>English</div>
                <div>Maths</div>
              </td>
              <td>
                <div>Princess dairy</div>
                <div>Number systems</div>
              </td>
              <td>
                <div>21 mins</div>
                <div>13 mins</div>
              </td>
              <td>
                <div></div>
                <div>10.45 AM</div>
              </td>
            </tr>
            <tr>
              <td>
                <div>10.50 AM</div>
                <div>10.15 AM</div>
              </td>
              <td>
                <div>English</div>
                <div>Maths</div>
              </td>
              <td>
                <div>Princess dairy</div>
                <div>Number systems</div>
              </td>
              <td>
                <div>21 mins</div>
                <div>13 mins</div>
              </td>
              <td>
                <div></div>
                <div>10.45 AM</div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ChildActivity;

const customStyles = {
  valueContainer: (provided, state) => ({
    ...provided,
    height: "100%",
    padding: "0px 8px",
  }),
  container: (provided, state) => ({
    ...provided,
    paddingTop: 5,
  }),
  placeholder: (provided, state) => ({
    ...provided,
    fontSize: "12px",
  }),
  singleValue: (provided, state) => ({
    ...provided,
    fontSize: "12px",
    fontWeight: "600",
  }),
  menu: (provided, state) => ({
    ...provided,
    marginTop: "0px",
  }),
  control: (provided, state) => ({
    ...provided,
    outline: "none !important",
    boxShadow: "none !important",
    minHeight: "35px",
    height: "35.78px",
    border: "solid 1px #d8d8d8",
    ":hover": {
      border: "2px solid #0478fe !important",
      outline: "none !important",
      boxShadow: "none !important",
    },
  }),
  input: (styles) => ({ ...styles, ...dot() }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      fontSize: "12px",
      color: isFocused || isSelected ? "white" : "black",
    };
  },
};

const dot = (color = "transparent") => ({
  alignItems: "center",
  display: "flex",
  padding: "0px",
  margin: "0px",
  height: "100%",
  input: {
    border: "0px !important",
  },
});
