import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Nav, Dropdown, NavDropdown, Navbar } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCartPlus, faSearch } from "@fortawesome/free-solid-svg-icons";

// API
import axios from "../../api/axios";
import { GetSubjectURL } from "../../urls";
import { ApiServices } from "../../api/ApiServices";

import StudentImgGreen from "../grades/images/profile-picture-green.svg";
import StudentImgGrey from "../grades/images/profile-picture-grey.svg";

// Redux Actions
import { authenticationActions } from "../../reducers/authenticationReducers";
import { parentInfoActions } from "../../reducers/parentInfoReducers";
import { childInfoActions } from "../../reducers/childInfoReducers";
import { subjectActions } from "../../reducers/subjectReducers";

// Images
import Support from "./images/support.png";
import Logo from "../../images/logoImg.png";
// import Logo2X from "../../images/logo@2x.png";
// import Logo3X from "../../images/logo@3x.png";
import FilmStrip from "./images/film-strip.webp";
import FilmStrip2X from "./images/film-strip@2x.webp";
import FilmStrip3X from "./images/film-strip@3x.webp";
import Finish from "./images/finish.webp";
import Finish2X from "./images/finish@2x.webp";
import Finish3X from "./images/finish@3x.webp";
import Idea from "./images/idea.webp";
import Idea2X from "./images/idea@2x.webp";
import Idea3X from "./images/idea@3x.webp";
import OpenBook from "./images/open-book.webp";
import OpenBook2X from "./images/open-book@2x.webp";
import OpenBook3X from "./images/open-book@3x.webp";

// CSS
import "./HomeHeader.css";

const HomeHeader = (props) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const isLoggedIn = useSelector((state) => state.authentication);
  const parentInfo = useSelector((state) => state.parentInfo);
  const childInfo = useSelector((state) => state.childInfo);

  const goToSignIn = () => {
    history.push("/login");
  };

  const LogoutHandler = () => {
    dispatch(authenticationActions.logout());
    dispatch(parentInfoActions.removeInfo());
    dispatch(childInfoActions.removeInfo());
    localStorage.removeItem("parentToken");
    localStorage.removeItem("childToken");
    localStorage.removeItem("child");
    sessionStorage.removeItem("child");
    sessionStorage.removeItem("childToken");
    history.push("/home");
  };

  const logout = () => {
    dispatch(authenticationActions.logout());
    dispatch(parentInfoActions.removeInfo());
    dispatch(childInfoActions.removeInfo());
    localStorage.removeItem("parentToken");
    localStorage.removeItem('child');
    localStorage.removeItem('childToken');
    sessionStorage.removeItem('childToken');
    sessionStorage.removeItem('child');
    window.location.href = '#/home';
}

  useEffect(() => {
    const navigate = async () => {
      let parentToken = JSON.parse(localStorage.getItem("parentToken"));
      let childToken = JSON.parse(localStorage.getItem("childToken"));
      let sessionChild = JSON.parse(sessionStorage.getItem("child"));

      if (
        !sessionChild &&
        parentToken &&
        parentToken != "" &&
        parentToken.id
      ) {
        let parentData = await axios.get(`${ApiServices("getuserBasedonId")}&wstoken=${parentToken.token}&userid=${parentToken.id}`).then(
          (response) => {
            if (response.data.message) {
              localStorage.removeItem("parentToken");
            } else {
              dispatch(
                authenticationActions.login({
                  token: parentToken.token,
                  email: response.data.parentdetails[0].email,
                  username: response.data.parentdetails[0].username,
                  id: parentToken.id,
                  role: "parent",
                })
              );

              dispatch(
                childInfoActions.addInfo({ ...response.data.childinfo })
              );
              dispatch(
                parentInfoActions.addInfo({ ...response.data.parentdetails })
              );

              history.push('/parent/home')
            }
          },
          (error) => {
            console.log(error);
          }
        );
      }

      if (
        !parentToken &&
        childToken &&
        childToken.id &&
        childToken != ""
      ) {
        let usersData = await axios.get(`${ApiServices("getuserBasedonId")}&wstoken=${childToken.token}&userid=${childToken.id}`).then(
          (response) => {
            if (response.data?.childinfo) {
              axios.get(GetSubjectURL(childToken.token)).then((res) => {
                let boardInfo = "";

                if (res.data.length > 0) {
                  res.data.forEach((val) => {
                    if (response.data.childinfo[0].board == val.parent && val.name.toLowerCase() == `grade ${response.data.childinfo[0].grade}`) {
                      var childGrade = val;
                      window.gradeId = val.id;

                      let childInfo = {
                        ...response.data.childinfo[0],
                        gradeId: childGrade,
                        gradeid: val.id,
                        token: response.data.token,
                        id: response.data.childinfo[0].childId,
                        childid: response.data.childinfo[0].childId,
                        chatId: res.data.find((category) => category.name == `${response.data.childinfo[0].board} Chat`)
                      };

                      dispatch(
                        authenticationActions.login({
                          token: response.data.token,
                          email: response.data.childinfo[0].email,
                          username: response.data.childinfo[0].username,
                          id: response.data.childinfo[0].childId,
                          role: "child",
                        })
                      );
                      dispatch(childInfoActions.addInfo(childInfo));
                      dispatch(
                        parentInfoActions.addInfo({
                          ...response.data.parentdetails,
                        })
                      );

                      window.token = response.data.token;

                      let subjectList = res.data.filter(category => category.parent == val.id);
                      let subjectChapterList = [];

                      axios.get(`${ApiServices("getEnrolledCourses")}${process.env.REACT_APP_MOODLE_TOKEN}&userid=${response.data.childinfo[0].childId}`).then((courses) => {
                        if (courses.data.length > 0) {
                          subjectList.forEach((subject) => {
                            let chapters = [];
                            chapters = courses.data.filter(course => course.categoryid == subject.id);
                            subjectChapterList.push({ ...subject, chapters })
                          })
                          setTimeout(() => {
                            dispatch(
                              subjectActions.addSubject(subjectChapterList)
                            );
                            // history.replace("/dashboard");
                          }, 100);
                        } else {
                          sessionStorage.setItem(
                            "child",
                            JSON.stringify(childInfo)
                          );

                          // if (location.pathname !== '/subscription-list' || location.pathname.search('/subscription/') < 0) {
                          //   history.replace("/discover-courses");
                          // }
                        }
                      });
                    }
                  })
                }
              })
            }
          },
          (error) => {
            console.log(error);
          }
        );
      }
    }
    navigate();
  }, []);

  const profileColorSelector = () => {
      return StudentImgGreen;;
  }

  return (
    <div className="home-header-container">
      <div className="home-header">
        <Navbar collapseOnSelect expand="lg" bg="#fff">
          <Navbar.Brand className="col-6 col-sm-5 col-md-5 col-lg-3">
            <div
              className={`col-md-2 logo ${window.location.href.search("/home") > 1 ? "cursor-default" : ""
                }`}
              onClick={() => {
                history.push("/home");
              }}
            >
              <img
                src={Logo}
                srcSet={`${Logo} 1x`}
                alt="Logo"
                className={`logo ${window.location.href.search("/home") > 1 ? "cursor-default" : ""
                  }`}
              />
            </div>
          </Navbar.Brand>
          <Navbar.Toggle className="col-3 col-sm-4 col-md-4 col-lg-8" aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse className="col-12 col-sm-12 col-md-12 col-lg-9" id="responsive-navbar-nav">
            <Nav className="mr-auto" justify variant="tabs" defaultActiveKey={props.active}>
              <Nav.Item>
                <Nav.Link href="#/discover-courses">
                  <img
                    src={OpenBook}
                    srcSet={`${OpenBook} 1x, ${OpenBook2X} 2x, ${OpenBook3X} 3x`}
                    alt="Discover Courses"
                    className="d-none d-sm-none d-md-none d-lg-inline header-icons"
                  />
                  <p className="header-name">Discover Courses</p>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="buy-course">
                <img
                    src={Finish}
                    srcSet={`${Finish} 1x, ${Finish2X} 2x, ${Finish3X} 3x`}
                    alt="Explore Features"
                    className="d-none d-sm-none d-md-none d-lg-inline  header-icons"
                  />
                  <NavDropdown className="header-name" title="Buy Courses" id="nav-dropdown">
                    <NavDropdown.Item href="#/buy-courses/general"  >General</NavDropdown.Item>
                    <NavDropdown.Item href="#/buy-courses/competitive"  >Competitive Exams</NavDropdown.Item>
                </NavDropdown>
                {/* <Nav.Link href="#/buy-courses">
                  
                  
                </Nav.Link> */}
              </Nav.Item>
              
              <Nav.Item>
                <Nav.Link href="#/services">
                  <img
                    src={Idea}
                    srcSet={`${Idea} 1x, ${Idea2X} 2x, ${Idea3X} 3x`}
                    alt="Know services"
                    className="d-none d-sm-none d-md-none d-lg-inline header-icons"
                  />
                  <p className="header-name">Know services</p>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link href="#/live-talk" disabled>
                  <img
                    src={FilmStrip}
                    srcSet={`${FilmStrip} 1x, ${FilmStrip2X} 2x, ${FilmStrip3X} 3x`}
                    alt="Live Talk"
                    className="d-none d-sm-none d-md-none d-lg-inline header-icons"
                  />
                  <p className="header-name">News &amp; Events</p>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="ml--30-px">
                <Nav.Link href="#/contact-us" disabled>
                  <img
                    src={Support}
                    alt="Support"
                    className="d-none d-sm-none d-md-none d-lg-inline header-icons h-25-px support-icon"
                  />
                  <p className="header-name ">Support</p>
                </Nav.Link>
              </Nav.Item>
              
              <div className="d-none d-sm-none d-md-none d-lg-flex col-lg-3 header-redirects">
                <FontAwesomeIcon icon={faSearch} className="search-icon" />
                <FontAwesomeIcon onClick={() => window.location.href = '#/cart'} icon={faCartPlus} className="cart-icon" />
                {(!childInfo.info.username && !parentInfo.info.username) && (
                  <>
                    
                    <Link to="./home" className="register-button"></Link>
                    <Dropdown className="d-inline mx-2 sign-dropdown">
                      <Dropdown.Toggle id="dropdown-autoclose-true">
                        Sign In
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item href="#/signup">Register</Dropdown.Item>
                        <Dropdown.Item href="#/login">Student Sign In</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </>
                )}
                {(childInfo.info.username || parentInfo.info.username) && (
                  <NavDropdown className="menu-item" title={
                    <div className="pull-left">
                        <img className="thumbnail-image"
                            src={profileColorSelector()}
                            alt="user pic"
                        />
                    </div>
                } id="basic-nav-dropdown" align="end" >
                    <div className="group-student-name">
                        <div>
                            <span onClick={() => { window.location.href = '#/dashboard?view=dashboard'; window.location.reload() }} className="chapter-header-title">Dashboard</span>
                            <span onClick={() => window.location.href = '#/community'} className="chapter-header-title">Messages</span>
                            <span onClick={() => window.location.href = '#/activities'} className="chapter-header-title ">Assignments</span>
                        </div>
                        <div className="d-none d-xs-none d-sm-none d-md-block user-info-details highlight-color">
                            {/* <span className="subscription-plan">Subscription Plan</span> */}
                            <div className="chapter-group">
                                <span className="chapter-header-title cursor-default">{childInfo.info?.firstname}</span>
                                <span className="chapter-header-title cursor-default">{childInfo.info?.gradeId?.name}</span>
                            </div>
                        </div>

                        <div className="d-none d-xs-none d-sm-none d-md-block student-info highlight-color">
                            <img className="student-icon" src={StudentImgGrey} />
                            {/* <div className="chapter-group">
                                <span className="chapter-header-title">{childToken.firstname}</span>
                                <span className="chapter-header-title">{childToken.gradeId.name}</span>
                            </div> */}
                        </div>
                    </div>
                    <div onClick={logout} className="logout-child mt-2 font-bold">Logout</div>
                </NavDropdown>
                )}
              </div>
              <div className="d-block d-sm-block d-md-block d-lg-none col-lg-3 header-redirects">
                {(!childInfo.info.username && !parentInfo.info.username) && (
                  <>
                    <Nav.Item>
                      <Nav.Link disabled href="#" >
                        <p className="header-name">Register</p>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item >
                      <Nav.Link href="#/login">
                        <p className="header-name">Student Login</p>
                      </Nav.Link>
                    </Nav.Item>
                  </>
                )}
                {(childInfo.info.username || parentInfo.info.username) && (
                  <>
                    <Nav.Item>
                      <Nav.Link className="disabled" >
                        <p className="header-name">{parentInfo.info.username ? parentInfo.info.firstname.concat(" ", parentInfo.info.lastname).substring(0, 10) : childInfo.info.firstname.concat(" ", childInfo.info.lastname).substring(0, 10)}</p>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link onClick={() => { LogoutHandler(); }} href="" >
                        <p className="header-name">Logout</p>
                      </Nav.Link>
                    </Nav.Item>
                  </>
                )}
              </div>

            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    </div>
  );
};

export default HomeHeader;
