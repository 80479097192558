import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import axios from "../../api/axios";
import { ApiServices } from "../../api/ApiServices";

import { authenticationActions } from "../../reducers/authenticationReducers";

import InputContainer from "../../common/inputs/InputContainer";

import "./Verify.css";
import classes from "./Signup.module.css";

import Boy from "../../images/boy.svg";
import Girl from "../../images/girl.svg";
import Spinner from "../../images/spinner.svg";
import ShortLogo from "../../images/Logoicon.png";
import Logo from "../../images/logoImg.png";
import Logo2X from "../../images/logoImg.png";
import Logo3X from "../../images/logoImg.png";

const ForgotPassword = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [username, SetUsername] = useState({ value: "", error: false });
  const [loaderShow, SetLoaderShow] = useState(false);

  const UsernameHandler = (event) => {
    if (event.target.value === "") {
      SetUsername({
        ...username,
        error: true,
        errorText: "Enter valid email ID",
      });
    } else {
      SetUsername({ value: event.target.value, error: false, errorText: "" });
    }
  };

  const OnClickSignInHandler = () => {
    history.push("./login");
  };

  const ResetSubmitHandler = (event) => {
    event.preventDefault();
    SetLoaderShow(true);
    if (username.value === "" || username.value.length < 6) {
      SetUsername({
        ...username,
        error: true,
        errorText: "Enter Valid Email",
      });
      SetLoaderShow(false);
    }

    if (username.value.length > 0) {
      axios
        .post(`${ApiServices("sendResetLink")}&email=${username.value}&role=child`)
        .then(
          (response) => {
            if (
              response.data.message.search("Reset Link Sent Successfully") >= 0
            ) {
              const responseArray = response.data.message.split(":::");
              SetUsername({ value: "", error: false });
              history.replace(`./submit-otp/${responseArray[0]}`);
            } else {
              SetUsername({
                ...username,
                errorText: response.data?.message,
                error: true,
              });
            }

            SetLoaderShow(false);
          },
          (error) => {
            SetUsername({
              ...username,
              errorText: "Invalid Credentials Provided",
              error: true,
            });

            SetLoaderShow(false);
          }
        );
    }
  };

  return (
    <div className={classes.signupContainer}>
      {loaderShow && (
        <img className={classes.loader} src={Spinner} alt="Loading" />
      )}
      <div className={`${classes.signupContent} login-container`}>
        <div>
          <img src={Boy} className="student-img-boy" alt="Boy Student" />
          <div
            className={`Shivani-Labs text-left ${classes["mb-30"]}`}
            onClick={() => {
              history.push("./home");
            }}
          >
            <img
              src={ShortLogo}
              alt="Logo"
              className="logo logo-view"
            />
          </div>
          <h5 className={`${classes["mb-1-rem"]} text-left`}>
            Forgot Password
          </h5>
          <p
            className={`${classes["mb-1-rem"]} ${classes["reset-instructions"]} text-left`}
          >
            Enter your registered email address below, we will send a link to
            reset your password
          </p>
          <form onSubmit={ResetSubmitHandler}>
            <div className={`${classes.inputContent}`}>
              <InputContainer
                type="text"
                name="name"
                labelName="Email"
                placeholder="Eg: johnXyz"
                onChange={UsernameHandler}
                error={username.error}
                errorText={username.errorText}
              />
              <button type="submit">Send Reset Link</button>
            </div>
          </form>
          <div className={classes.others}>
            <div className="text-center">
              <label className={`${classes["f-12"]}`}>
                Back to{" "}
                <span
                  onClick={OnClickSignInHandler}
                  className={`${classes.link} ${classes["signup-highlight"]}`}
                >
                  Sign In
                </span>
              </label>
            </div>
          </div>
          <img src={Girl} alt="Girl Student" className="student-img-girl" />
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
