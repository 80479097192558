import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import axios from "../../api/axios";
import { ApiServices } from "../../api/ApiServices";
import { GetSubjectURL } from "../../urls";
import { authenticationActions } from "../../reducers/authenticationReducers";
import { parentInfoActions } from "../../reducers/parentInfoReducers";
import { childInfoActions } from "../../reducers/childInfoReducers";
import { subjectActions } from "../../reducers/subjectReducers";

import InputContainer from "../../common/inputs/InputContainer";
import VerifyFullPage from "./VerifyFullPage";
import VerifySuccess from "./VerifySuccess";

import "./Verify.css";
import classes from "./Signup.module.css";

import Boy from "../../images/boy.svg";
import Girl from "../../images/girl.svg";
import Spinner from "../../images/spinner.svg";
import ShortLogo from "../../images/Logoicon.png";
import Logo from "../../images/logoImg.png";

const Login = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const LoggedInfo = useSelector((state) => state.authentication);
  const [username, SetUsername] = useState({ value: "", error: false });
  const [password, SetPassword] = useState({ value: "", error: false });
  const [loaderShow, SetLoaderShow] = useState(false);
  const [showVerify, SetShowVerify] = useState(false);
  const [verifyDecision, SetVerifyDecision] = useState(false);

  sessionStorage.removeItem("child");
  sessionStorage.removeItem("parentName");
  sessionStorage.removeItem("parentEmail");

  useEffect(() => {
    if (LoggedInfo.userInfo.token && LoggedInfo.userInfo.role == "child") {
      history.replace("./dashboard");
    }
  }, []);

  const UsernameHandler = (event) => {
    if (event.target.value === "") {
      SetUsername({
        ...username,
        error: true,
        errorText: "Enter valid user name",
      });
    } else {
      SetUsername({ value: event.target.value, error: false, errorText: "" });
    }
  };

  const PasswordHandler = (event) => {
    if (event.target.value === "") {
      SetPassword({
        ...password,
        error: true,
        errorText: "Password should minimum of 8 characters",
      });
    } else {
      SetPassword({ value: event.target.value, error: false, errorText: "" });
    }
  };

  const getName = (parent, res) => {
    let boardName = '';
    res.data.forEach((val) => {
      if (val.id == parent) {
        boardName = val.name;
      }
    });
    return boardName;
  }

  const OnClickHandler = () => {
    history.push("./forgot-password");
  };

  const OnClickSignUpHandler = () => {
    history.push("./signup");
  };

  const LoginSubmitHandler = (event) => {
    event.preventDefault();
    SetLoaderShow(true);
    if (username.value === "") {
      SetUsername({
        ...username,
        error: true,
        errorText: "Enter valid user name",
      });
      SetLoaderShow(false);
    }

    if (password.value === "" || password.value.length < 8) {
      SetPassword({
        ...password,
        error: true,
        errorText: "Password should minimum of 8 characters",
      });
      SetLoaderShow(false);
    }

    if (
      username.value !== "" &&
      password.value !== "" &&
      password.value.length >= 8
    ) {
      axios
        .post(
          ApiServices("login"),
          { servicename: "shivaniacademy", role: "child" },
          {
            auth: {
              username: username.value,
              password: password.value,
            },
          }
        )
        .then(
          (response) => {
            if (response.data.msg === "Please revisit username and password") {
              SetUsername({
                ...username,
                errorText: "Invalid Credentials Provided",
                error: true,
              });
              SetPassword({
                ...password,
                errorText: "Invalid Credentials Provided",
                error: true,
              });
              SetLoaderShow(false);
            }

            if (response.data.msg?.search("Verification") >= 0) {
              SetUsername({ value: "", error: false });
              SetPassword({ value: "", error: false });
              SetShowVerify(true);
              SetLoaderShow(false);
            }

            if (
              response.status === 200 &&
              response.data.status === 1 &&
              response.data.childinfo.length > 0
            ) {
              axios.get(GetSubjectURL(response.data.token)).then((res) => {

                res.data.forEach((val) => {
                  if (response.data.childinfo[0].board == val.parent && val.name.toLowerCase() == `grade ${response.data.childinfo[0].grade}`) {
                    var childGrade = val;
                    window.gradeId = val.id;

                    let childInfo = {
                      ...response.data.childinfo[0],
                      boardName: getName(val.parent, res),
                      gradeId: childGrade,
                      gradeid: val.id,
                      token: response.data.token,
                      id: response.data.childinfo[0].childId,
                      childid: response.data.childinfo[0].childId,
                      chatId: res.data.find((category) => category.name == `${response.data.childinfo[0].board} Chat`)
                    };

                    dispatch(
                      authenticationActions.login({
                        token: response.data.token,
                        email: response.data.childinfo[0].email,
                        username: response.data.childinfo[0].username,
                        id: response.data.childinfo[0].childId,
                        role: "child",
                      })
                    );
                    dispatch(childInfoActions.addInfo(childInfo));
                    dispatch(
                      parentInfoActions.addInfo({
                        ...response.data.parentdetails,
                      })
                    );

                    window.token = response.data.token;

                    localStorage.setItem(
                      "childToken",
                      JSON.stringify(childInfo)
                    );

                    localStorage.setItem(
                      "child",
                      JSON.stringify(childInfo)
                    );

                    let subjectList = res.data.filter(category => category.parent == val.id);
                    let subjectChapterList = [];
                    axios.get(`${ApiServices("getEnrolledCourses")}${response.data.token}&userid=${response.data.childinfo[0].childId}`).then((courses) => {
                      if (courses.data.length > 0) {
                        subjectList.forEach((subject) => {
                          let chapters = [];
                          chapters = courses.data.filter(course => course.category == subject.id);
                          subjectChapterList.push({ ...subject, chapters })
                        })
                        setTimeout(() => {
                          dispatch(
                            subjectActions.addSubject(subjectChapterList)
                          );
                          SetLoaderShow(false);
                          if ((response.data.childinfo[0].package == "" || response.data.childinfo[0].package == "0") && (response.data.childinfo[0].payment == "" || response.data.childinfo[0].payment == "0")) {
                            history.replace("./discover-courses");
                          } else {
                            history.replace("./dashboard");
                          }
                        }, 100);
                      } else {
                        sessionStorage.setItem(
                          "child",
                          JSON.stringify(childInfo)
                        );
                        // if (!response.data.childinfo[0].package || response.data.childinfo[0].package == "" || response.data.childinfo[0].package == "0") {
                        // }
                        history.replace("./subscription-list");
                        // if (response.data.childinfo[0].package && (!response.data.childinfo[0].payment || response.data.childinfo[0].payment == "" || response.data.childinfo[0].payment == "0")) {
                        //   history.replace(`./subscription/${response.data.childinfo[0].package}`);
                        // }
                        SetLoaderShow(false);
                      }
                    });
                  }
                });
              });
            }
          },
          (error) => {
            SetUsername({
              ...username,
              errorText: "Invalid Credentials Provided",
              error: true,
            });
            SetPassword({
              ...password,
              errorText: "Invalid Credentials Provided",
              error: true,
            });
            SetLoaderShow(false);
          }
        );
    }
  };

  const VerifyDecisionHandler = (value) => {
    if (value) {
      SetVerifyDecision(true);
    }
  };

  return (
    <>
      {loaderShow && (
        <div className={classes["loader-container"]}>
          <img className={classes.loader} src={Spinner} alt="Loading" />
        </div>
      )}
      {!showVerify && (
        <div className={classes.signupContainer}>
          <div className={`${classes.signupContent} login-container`}>
            <div>
              <img src={Boy} className="student-img-boy" alt="Boy Student" />
              <div
                className={`Shivani-Labs ${classes["mb-30"]} text-left`}
                onClick={() => {
                  history.push("./home");
                }}
              >
                <img src={ShortLogo} alt="Logo" className="logo logo-view" />
              </div>
              <h5 className={`${classes["mb-1-rem"]} text-left`}>Sign In</h5>
              <form onSubmit={LoginSubmitHandler}>
                <div className={`${classes.inputContent} ${classes.m100}`}>
                  <InputContainer
                    type="text"
                    name="name"
                    labelName="User Name"
                    placeholder="User Name"
                    onChange={UsernameHandler}
                    error={username.error}
                    errorText={username.errorText}
                  />
                  <InputContainer
                    type="password"
                    name="password"
                    labelName="Password"
                    placeholder="Password"
                    onChange={PasswordHandler}
                    error={password.error}
                    errorText={password.errorText}
                  />
                  <div className={classes.others}>
                    <div className="text-right">
                      <label
                        onClick={OnClickHandler}
                        className={`${classes.link} ${classes["signup-highlight"]} ${classes["f-12"]}`}
                      >
                        Forgot Password?
                      </label>
                    </div>
                  </div>
                  <button type="submit">Sign In</button>
                </div>
              </form>
              <div className={classes.others}>
                <div className="text-center">
                  {/* <label className={`${classes["f-12"]}`}>
                    Don't have an account?{" "}
                    <span
                      onClick={OnClickSignUpHandler}
                      className={`${classes.link} ${classes["signup-highlight"]}`}
                    >
                      Sign Up
                    </span>
                  </label> */}
                </div>
              </div>
              <img src={Girl} alt="Girl Student" className="student-img-girl" />
            </div>
          </div>
        </div>
      )}
      {showVerify && !verifyDecision && (
        <VerifyFullPage
          email={username}
          VerifyDecisionHandler={VerifyDecisionHandler}
        />
      )}
      {showVerify && verifyDecision && <VerifySuccess url="./dashboard" />}
    </>
  );
};

export default Login;
