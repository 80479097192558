import { createSlice } from "@reduxjs/toolkit";

const initialParentState = { data: [] };

const subjectSlice = createSlice({
    name: "Subject",
    initialState: initialParentState,
    reducers: {
        addSubject(state, action) {
            state.data = action.payload;
        },
        removeSubject(state) {
            state.data = [];
        },
    },
});

export const subjectActions = subjectSlice.actions;
export default subjectSlice.reducer;
