import Moment from "moment";
import "./ActivityCard.css";
import ActivitySubmitted from "../../images/date-submitted.svg";
import Rewards from "../../images/rewards.svg";

const MyActivityCard = (props) => {
  let time1 = Moment(props.submittedDate, "YYYY-MM-DD HH:mm:ss");
  let time2 = Moment(new Date(), "YYYY-MM-DD HH:mm:ss");
  var duration = Moment.duration(time1.diff(time2));

  // console.log(time1);
  // console.log(time2);
  // console.log(duration);
  return (
    <div className={`w-100 activity-card ${props.className}`} style={{ backgroundColor: "#fff" }}>
      <div className="d-flex">
        <p className="subject details-activity">Activity in {props.subject}</p>
        <p className="posted-on text-left details-activity">
          {/* Posted on {Moment(props.postedDate).format("DD/MM/YYYY")} */}
          Posted on {Moment().format("DD/MM/YYYY")}
        </p>
        <div className="time-details-activity">
          <p className="posted-on text-end">
            Time left : {duration._data.days} days {duration._data.hours} hours{" "}
          </p>
        </div>
      </div>
      <p className="activity-heading m-5-0">{props.activityname}</p>
      <div className="d-flex">
        <div className="d-flex reward-container enrolled-activity">
          <img src={Rewards} alt="reward" className="reward-icon" />
          <div className="reward-info">Earned new avatar</div>
        </div>
        <div className="d-flex reward-container enrolled-activity">
          <img src={ActivitySubmitted} alt="reward" className="reward-icon" />
          <div className="reward-info reward-date">
            Submitted on or before{" "}
            {/* {Moment(props.submittedDate).format("DD/MM/YYYY")} */}
            {Moment().format("DD/MM/YYYY")}
          </div>
        </div>
        <div className="time-details-activity">
          <button className="details-button">Details</button>
        </div>
      </div>
    </div>
  );
};

export default MyActivityCard;
