import NewActivityCard from "./NewActivityCard";

const NewActivity = (props) => {
  console.log(props.newActivities);
  return (
    <>
      <div className="w-100">
        <div className="activity-date">New Assignments to participate</div>
        <div className="date-underline"></div>
      </div>
      <div className="col-md-12 activities">
        {props.newActivities &&
          props.newActivities.map((data) => {
            return (
              <NewActivityCard
                activityDescription={data.newActivityDescription}
                activityId={data.newActivityId}
                activityImage={data.newActivityImage}
                activityName={data.newActivityName}
                activityPicture={data.newActivityPicture}
                activityPoints={data.newActivityPoints}
                postedDate={data.newActivityPostedDate}
                subject={data.newActivitySubject}
                submittedDate={data.newActivitySubmittedDate}
                activityUserDestination={data.newActivityUserDestination}
                activityUsername={data.newActivityUsername}
              />
            );
          })}
      </div>
    </>
  );
};

export default NewActivity;
