import { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";

import axios from "../../api/axios";
import { ApiServices } from "../../api/ApiServices";

import InputContainer from "../../common/inputs/InputContainer";

import "./Verify.css";
import classes from "./Signup.module.css";

import Boy from "../../images/boy.svg";
import Girl from "../../images/girl.svg";
import Spinner from "../../images/spinner.svg";
import Logo from "../../images/logo.png";
import Logo2X from "../../images/logo@2x.png";
import Logo3X from "../../images/logo@3x.png";

const SubmitOtp = () => {
    const [loaderShow, SetLoaderShow] = useState(false);
    const [veriNumber, SetVeriNumber] = useState({ value: "", error: false });
    const params = useParams();
    const history = useHistory();

    useEffect(() => {
        if (!params.userid) {
            history.push("../home");
        }
    }, [params.userid, history]);

    const VerifyFormHandler = (event) => {
        event.preventDefault();
        SetLoaderShow(true);
        if (veriNumber.value === "" || veriNumber.value.length < 6) {
            SetVeriNumber({ ...veriNumber, errorText: "Please enter the OTP", error: true });
            SetLoaderShow(false);
            return true;
        }

        axios
            .post(
                `${ApiServices("verifyOtp")}&userid=${Buffer.from(params.userid, 'base64')}&secretkey=${veriNumber.value
                }`
            )
            .then(
                (response) => {
                    if (response.data === "Parent is Registered Successfully") {
                        let encoded = `${Buffer.from(params.userid, 'base64')}:::${veriNumber.value}`;
                        history.replace(`../reset-password/${Buffer.from(encoded).toString('base64')}`);
                    } else {
                        SetVeriNumber({ ...veriNumber, error: true });
                    }
                    SetLoaderShow(false);
                },
                (error) => {
                    SetVeriNumber({ ...veriNumber, error: true });
                    SetLoaderShow(false);
                }
            );
    };

    const VerfiChangeHandler = (event) => {
        SetVeriNumber({ value: event.target.value, error: false });
    };

    const OnClickSignInHandler = () => {
        history.push("../login");
    };

    return (
        <div className={classes.signupContainer}>
            {loaderShow && (
                <img className={classes.loader} src={Spinner} alt="Loading" />
            )}
            <div className={`${classes.signupContent} login-container`}>
                <div>
                    <img src={Boy} className="student-img-boy student-img-boy-otp" alt="Boy Student" />
                    <div
                        className={`Shivani-Labs ${classes["mb-30"]}`}
                        onClick={() => {
                            history.push("../home");
                        }}
                    >
                        <img
                            src={Logo}
                            srcSet={`${Logo} 1x, ${Logo2X} 2x, ${Logo3X} 3x`}
                            alt="Logo"
                            className="logo"
                        />
                    </div>
                    <form onSubmit={VerifyFormHandler}>
                        <div className={`${classes.inputContent} w-100`}>
                            <InputContainer
                                type="text"
                                name="name"
                                labelName="Enter OTP"
                                placeholder="Enter your OTP"
                                onChange={VerfiChangeHandler}
                                error={veriNumber.error}
                                errorText={veriNumber.errorText}
                            />
                            <button type="submit">Submit OTP</button>
                        </div>
                    </form>
                    <div className={classes.others}>
                        <div className="text-center">
                            <label className={`${classes["f-12"]}`}>
                                Back to{" "}
                                <span
                                    onClick={OnClickSignInHandler}
                                    className={`${classes.link} ${classes["signup-highlight"]}`}
                                >
                                    Sign In
                                </span>
                            </label>
                        </div>
                    </div>
                    <img src={Girl} alt="Girl Student" className="student-img-girl student-img-girl-otp" />
                </div>
            </div>
        </div>
    )
}
export default SubmitOtp;