import styled from "styled-components";
import image from '../../../images/signup-details-3.svg';
import './index.css';

const SignupDetails3 = () => {
    return (
        <div className="details-section">
            <div className="image-container">
                <img src={image} alt="Detail Image 3" />
            </div>
            <div className="details-container">
                <span className="details-header">Gamification</span>
                <span className="details-content">Gamification is a splendid feature of interactive learning because it instructs children playing in a group to depend on and trust each other to win a game or achieve their goals.
                </span>
            </div>
        </div>
    )
}

export default SignupDetails3;