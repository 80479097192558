import './loader.scss';

const Loader = (props) => {
    return (
        <div className={`loader ${props.show ? 'loader-update' : ""}`}>
            <div className={`loader-img ${props.show ? 'loader-update-img' : ""}`}></div>
        </div>
    )
}

export default Loader;