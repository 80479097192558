import { useState } from "react";
import { useHistory } from "react-router-dom";
import success from "../../images/success.png";
import "./Verify.css";

const VerifySuccess = ({ redirectedTo, url }) => {
  const history = useHistory();
  const RedirectHandle = () => {
    if (redirectedTo == "demo") {
      history.push(url);
    } else {
      history.push({
        pathname: "./discover-courses",
        state: {
          grade: url
        }
      })
    }
  };
  return (
    <div className="verify-success-container">
      <div className="verify-success-wrapper">
        <div className="Shivani-Labs w-auto h-auto">
          Shivani <span className="text-style-1">Labs</span>
        </div>
        <div className="success-info-container">
          <div>
            <img
              src={success}
              alt="Verification Successful"
              className="success-image"
            />
            <div className="success-content">
              <p className="success-message">
                Registration <br /> Successful
              </p>
              <button className="redirect" onClick={RedirectHandle}>
                Continue
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifySuccess;
