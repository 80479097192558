import styled from "styled-components";
import image from '../../../images/signup-details-1.svg';
import './index.css';

const SignupDetails1 = () => {
    return (
        <div className="details-section">
            <div className="image-container">
                <img src={image} alt="Detail Image 1" />
            </div>
            <div className="details-container">
                <span className="details-header">We Practice What We Preach</span>
                <span className="details-content">
                    Serving the society is our ultimate motto. And in achieving our goal, we ensure that the hands that had our back always, can count on us to hold their precious ones’ little fingers as we help them walk the path of life.
                </span>
            </div>
        </div>
    )
}

export default SignupDetails1;