import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import axios from "../../api/axios";
import { ApiServices } from "../../api/ApiServices";
import { authenticationActions } from "../../reducers/authenticationReducers";
import { parentInfoActions } from "../../reducers/parentInfoReducers";
import { childInfoActions } from "../../reducers/childInfoReducers";

import InputContainer from "../../common/inputs/InputContainer";
import VerifyFullPage from "./VerifyFullPage";
import VerifySuccess from "./VerifySuccess";

import "./Verify.css";
import "./ParentLogin.scss";
import classes from "./Signup.module.css";

// Images
import ShortLogo from "../../images/Logoicon.png";
import Logo from "../../images/logoImg.png";
import Spinner from "../../images/spinner.svg";

const ParentLogin = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const LoggedInfo = useSelector((state) => state.authentication);
  const [username, SetUsername] = useState({ value: "", error: false });
  const [password, SetPassword] = useState({ value: "", error: false });
  const [loaderShow, SetLoaderShow] = useState(false);
  const [showVerify, SetShowVerify] = useState(false);
  const [verifyDecision, SetVerifyDecision] = useState(false);

  const UsernameHandler = (event) => {
    if (event.target.value === "") {
      SetUsername({
        ...username,
        error: true,
        errorText: "Enter valid email ID",
      });
    } else {
      SetUsername({ value: event.target.value, error: false, errorText: "" });
    }
  };

  const PasswordHandler = (event) => {
    if (event.target.value === "") {
      SetPassword({
        ...password,
        error: true,
        errorText: "Password should minimum of 8 characters",
      });
    } else {
      SetPassword({ value: event.target.value, error: false, errorText: "" });
    }
  };

  const OnClickHandler = () => {
    history.push("./forgot-password");
  };

  const OnClickSignUpHandler = () => {
    history.push("./signup");
  };

  const LoginSubmitHandler = (event) => {
    event.preventDefault();
    SetLoaderShow(true);
    if (username.value === "") {
      SetUsername({
        ...username,
        error: true,
        errorText: "Enter valid email ID",
      });
      SetLoaderShow(false);
    }

    if (password.value === "" || password.value.length < 8) {
      SetPassword({
        ...password,
        error: true,
        errorText: "Password should minimum of 8 characters",
      });
      SetLoaderShow(false);
    }

    if (
      username.value !== "" &&
      password.value !== "" &&
      password.value.length >= 8
    ) {
      axios
        .post(
          ApiServices("login"),
          { servicename: "shivaniacademy", role: "parent" },
          {
            auth: {
              username: username.value,
              password: password.value,
            },
          }
        )
        .then(
          (response) => {
            if (response.data.msg === "Please revisit username and password") {
              SetUsername({
                ...username,
                errorText: "Invalid Credentials Provided",
                error: true,
              });
              SetPassword({
                ...password,
                errorText: "Invalid Credentials Provided",
                error: true,
              });
            }

            if (response.data.msg?.search("Verification") >= 0) {
              SetUsername({ value: "", error: false });
              SetPassword({ value: "", error: false });
              SetShowVerify(true);
            }

            if (
              response.status === 200 &&
              response.data.status === 1 &&
              response.data.childinfo.length > 0
            ) {
              dispatch(
                authenticationActions.login({
                  token: response.data.token,
                  email: response.data.parentdetails.email,
                  username: response.data.parentdetails.username,
                  id: response.data.parentdetails.id,
                  role: "parent",
                })
              );
              localStorage.setItem(
                "parentToken",
                JSON.stringify({
                  token: response.data.token,
                  id: response.data.parentdetails.id,
                })
              );

              dispatch(
                childInfoActions.addInfo({ ...response.data.childinfo })
              );
              dispatch(
                parentInfoActions.addInfo({ ...response.data.parentdetails })
              );

              history.replace("./parent/home");
            }
            SetLoaderShow(false);
          },
          (error) => {
            SetUsername({
              ...username,
              errorText: "Invalid Credentials Provided",
              error: true,
            });
            SetPassword({
              ...password,
              errorText: "Invalid Credentials Provided",
              error: true,
            });
            SetLoaderShow(false);
          }
        );
    }
  };

  const VerifyDecisionHandler = (value) => {
    if (value) {
      SetVerifyDecision(true);
    }
  };

  useEffect(() => {
    if (LoggedInfo.userInfo.token && LoggedInfo.userInfo.role == "parent") {
      history.replace("../parent/home");
    }
  }, []);

  return (
    <>
      {loaderShow && (
        <div className={classes["loader-container"]}>
          <img className={classes.loader} src={Spinner} alt="Loading" />
        </div>
      )}
      {!showVerify && (
        <div className={`parent-portal-login`}>
          <div className={`${classes.signupContent} col-md-6`}>
            <div className="parent-container">
              <img
                src={ShortLogo}
                alt="Logo"
                className={`${classes["mb-1-rem"]} logo mobile-view`}
              />
              <h5 className={`${classes["mb-1-rem"]} text-left`}>
                Parent Sign In
              </h5>
              <form onSubmit={LoginSubmitHandler}>
                <div className={`${classes.inputContent} ${classes.m100} text-center`}>
                  <InputContainer
                    type="text"
                    name="name"
                    labelName="Email Id"
                    placeholder="Email Id"
                    onChange={UsernameHandler}
                    error={username.error}
                    errorText={username.errorText}
                  />
                  <InputContainer
                    type="password"
                    name="password"
                    labelName="Password"
                    placeholder="Password"
                    onChange={PasswordHandler}
                    error={password.error}
                    errorText={password.errorText}
                  />
                  <div className={classes.others}>
                    <div className="text-right">
                      {/* <label
                        onClick={OnClickHandler}
                        className={`${classes.link} ${classes["signup-highlight"]} ${classes["f-12"]}`}
                      >
                        Forgot Password?
                      </label> */}
                    </div>
                  </div>
                  <button type="submit" className="parent-sign-in">
                    Sign In
                  </button>
                </div>
              </form>
              <div className={classes.others}>
                <div className="text-center">
                  {/* <label className={`${classes["f-12"]}`}>
                    Don't have an account?{" "}
                    <span
                      onClick={OnClickSignUpHandler}
                      className={`${classes.link} ${classes["signup-highlight"]}`}
                    >
                      Sign Up
                    </span>
                  </label> */}
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 parent-banner">
            <img
              src={Logo}
              alt="Logo White"
              className="parent-login-logo"
            />
            <p className="welcome-content">
              typesetting industry. Lorem Ipsum has been the industry's standard
              dummy text ever since the 1500s, when an unknown printer took a
              galley of type simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type{" "}
            </p>
          </div>
        </div>
      )}
      {showVerify && !verifyDecision && (
        <VerifyFullPage
          email={username}
          VerifyDecisionHandler={VerifyDecisionHandler}
        />
      )}
      {showVerify && verifyDecision && <VerifySuccess url="./parent/home" />}
    </>
  );
};

export default ParentLogin;
