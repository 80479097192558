// CSS
import "./ParentFooter.scss";

const ParentFooter = () => {
  return (
    <div className="parent-footer">
      <p className="company-name">
        Shivani <span className="second-name">Academy</span>
      </p>

      <p className="info-text">
        Copyrights © 2021 Konnectgen All rights reserved
      </p>
    </div>
  );
};

export default ParentFooter;
