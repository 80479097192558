import { useState, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Moment from "moment";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import { ApiServices } from "../../api/ApiServices";
import axios from "../../api/axios";

import AOS from "aos";
import "aos/dist/aos.css";

// Images
import Child1 from "./images/child-1.png";
import GreyWave from "./images/grey-waves.svg";
import EventsImage from "./images/events.svg";
import Calendar from "../../images/calendar.webp";
import Calendar2X from "../../images/calendar@2x.webp";
import Calendar3X from "../../images/calendar@3x.webp";

// CSS
import "./index.css";
import "./Event.css";

// Swiper

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/bundle";
import "swiper/swiper.min.css";
import "swiper/swiper-bundle.css";

// // import Swiper core and required modules
import SwiperCore, { Navigation, Autoplay } from "swiper";

// // install Swiper modules
SwiperCore.use([Navigation, Autoplay]);

let monthsArray = [
  {
    name: "JAN",
    month: "01",
  },
  {
    name: "FEB",
    month: "02",
  },
  {
    name: "MAR",
    month: "03",
  },
  {
    name: "APR",
    month: "04",
  },
  {
    name: "MAY",
    month: "05",
  },
  {
    name: "JUN",
    month: "06",
  },
  {
    name: "JLY",
    month: "07",
  },
  {
    name: "AUG",
    month: "08",
  },
  {
    name: "SEP",
    month: "09",
  },
  {
    name: "OCT",
    month: "10",
  },
  {
    name: "NOV",
    month: "11",
  },
  {
    name: "DEC",
    month: "12",
  },
];

const Event = () => {
  const [eventHistory, SetEventHistory] = useState([]);
  const [selectedDate, SetSelectedDate] = useState(Moment(new Date()).date());
  const [selectedMonth, SetSelectedMonth] = useState(
    1 + Moment(new Date()).month()
  );
  const [selectedYear, SetSelectedYear] = useState(Moment(new Date()).year());
  const [numberOfMonths, SetNumberOfMonths] = useState(
    new Date(
      Moment(new Date()).year(),
      Moment(new Date()).month() + 1,
      0
    ).getDate()
  );
  const [dateSelect, SetDateSelect] = useState(
    new Date(
      Moment(new Date()).year(),
      Moment(new Date()).month(),
      Moment(new Date()).date()
    )
  );

  const ChangeEventDateHandler = (date) => {
    SetSelectedDate(date);
    SetDateSelect(new Date(selectedYear, selectedMonth - 1, date));
  };
  const ChangeEventMonthHandler = (month) => {
    SetSelectedMonth(month);
    SetNumberOfMonths(new Date(selectedYear, month, 0).getDate());
    SetDateSelect(new Date(selectedYear, month - 1, selectedDate));
  };

  const ChangeEventYearHandler = (value) => {
    let updatedValue = parseInt(selectedYear) + parseInt(value);
    SetSelectedYear(updatedValue);
    SetNumberOfMonths(new Date(updatedValue, selectedMonth, 0).getDate());
    SetDateSelect(new Date(updatedValue, selectedMonth - 1, selectedDate));
  };

  const DateSelectHandler = (event) => {
    SetDateSelect(event);
    SetSelectedDate(Moment(event).date());
    SetSelectedMonth(Moment(event).month() + 1);
    SetSelectedYear(Moment(event).year());
    SetNumberOfMonths(
      new Date(Moment(event).year(), Moment(event).month() + 1, 0).getDate()
    );
  };

  useEffect(() => {
    axios
      .post(
        `${ApiServices(
          "allEvents"
        )}&day=${selectedDate}&month=${selectedMonth}&year=${selectedYear}`
      )
      .then(
        (response) => {
          SetEventHistory(response.data.events);
        },
        (error) => {
          console.log(error);
        }
      );
  }, [selectedDate, selectedMonth, selectedYear]);

  let dateMom = Moment(
    `${selectedDate}-${
      monthsArray.find((month) => month.month == selectedMonth).month
    }-${selectedYear}`,
    "DD-MM-YYYY"
  );

  return (
    <section className="event-section">
      {eventHistory && eventHistory?.length &&
        <div className="event-background">
          <img
            src={GreyWave}
            alt="Grey Wave"
            className="event-background-image"
          />
        </div>
      }
      
      <div className="col-md-12 p-0">
        <div className="d-flex">
          <p className="event-heading">
            Upcoming <span className="green-text">Events</span>
          </p>
          <img src={EventsImage} alt="Events Header" />
        </div>
        <div className="event-details-container">
          <div className="d-flex">
            <div
              className="event-arrow-container arrow-opposite"
              onClick={() => {
                ChangeEventYearHandler(-1);
              }}
            >
              <svg viewBox="0 -50 150 150">
                <defs>
                  <marker
                    id="eventarrowheadright"
                    markerWidth="10"
                    markerHeight="8"
                    refX="0"
                    refY="3.5"
                    orient="auto"
                  >
                    <polygon
                      className="event-year-button"
                      points="0 0, 6 3.5, 0 7"
                    ></polygon>
                  </marker>
                </defs>
                <line
                  x1="50"
                  y1="59"
                  x2="100"
                  y2="59"
                  stroke="#939393"
                  strokeWidth="7"
                  markerEnd="url(#eventarrowheadright)"
                ></line>
              </svg>
            </div>
            <div className="event-year">{selectedYear}</div>
            <div
              className="event-arrow-container"
              onClick={() => {
                ChangeEventYearHandler(+1);
              }}
            >
              <svg viewBox="0 0 150 150">
                <defs>
                  <marker
                    id="eventarrowheadright"
                    markerWidth="10"
                    markerHeight="8"
                    refX="0"
                    refY="3.5"
                    orient="auto"
                  >
                    <polygon
                      className="event-year-button"
                      points="0 0, 6 3.5, 0 7"
                    ></polygon>
                  </marker>
                </defs>
                <line
                  x1="40"
                  y1="40"
                  x2="90"
                  y2="40"
                  stroke="#939393"
                  strokeWidth="7"
                  markerEnd="url(#eventarrowheadright)"
                ></line>
              </svg>
            </div>
            <div>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  initialFocusedDate={dateSelect}
                  variant="inline"
                  format="dd-MMM-yyyy"
                  value={dateSelect}
                  onChange={DateSelectHandler}
                  animateYearScrolling
                  allowKeyboardControl
                  autoOk
                />
              </MuiPickersUtilsProvider>
              <img
                src={Calendar}
                srcSet={`${Calendar} 1x, ${Calendar2X} 2x, ${Calendar3X} 3x`}
                alt="Calendar Icon"
                className="calendar-icon"
              />
            </div>
          </div>
        </div>
        <div className="d-none d-sm-none d-md-none d-lg-flex event-month-container">
          {monthsArray.map((month, i) => {
            return (
              <div key={i} className="event-month-box">
                <div
                  className={`event-month-calen ${
                    selectedMonth == month.month ? "event-active" : ""
                  }`}
                  onClick={() => {
                    ChangeEventMonthHandler(month.month);
                  }}
                >
                  <span>{month.name}</span>
                </div>
              </div>
            );
          })}
        </div>
        <div className="d-none d-sm-none d-md-none d-lg-flex event-date-container">
          {[...Array(numberOfMonths)].map((e, i) => {
            return (
              <div key={i} className="event-date-box">
                <div
                  className={`event-date-calen ${
                    selectedDate == i + 1 ? "event-active" : ""
                  } ${
                    Moment(new Date()).date() == i + 1 &&
                    selectedDate != i + 1 &&
                    selectedMonth == Moment(new Date()).month() &&
                    selectedYear == Moment(new Date()).year()
                      ? "event-today"
                      : ""
                  }`}
                  onClick={() => {
                    ChangeEventDateHandler(i + 1);
                  }}
                >
                  <span>{i + 1}</span>
                </div>
              </div>
            );
          })}
        </div>
        <p
          className={`event-time-info-header ${!dateMom.isValid() && "d-none"}`}
        >
          {Moment(dateSelect).format("dddd")},{" "}
          {`${selectedDate}-${monthsArray
            .find((month) => month.month == selectedMonth)
            .name.toString()}-${selectedYear}`}
          ,{" "}
          <span className="event-count">
            {eventHistory
              ? eventHistory.length == 1
                ? "1 event"
                : eventHistory.length + " events"
              : "0 events"}
          </span>
        </p>
        {eventHistory && eventHistory?.length &&
          <Swiper
          slidesPerView={3}
          spaceBetween={0}
          slidesPerGroup={3}
          loop={true}
          loopFillGroupWithBlank={true}
          navigation={true}
          className="event-swiper"
          speed={5000}
          autoplay={{ delay: 5000 }}
        >
          {eventHistory &&
            eventHistory.map((event) => {
              return (
                <SwiperSlide key={event.id}>
                  <div className="event-container">
                    <p className="event-time-header">
                      {`${Moment.unix(event.timestart).format(
                        "HH:mm A"
                      )} - ${Moment.unix(
                        event.timestart + event.timeduration
                      ).format("HH:mm A")} `}{" "}
                      IST
                    </p>
                    <div className="event-box">
                      <div className="event-info">
                        <p className="event-name">{event.name}</p>
                        <p className="event-by">
                          <span className="pre-text">by</span> Admin,
                          {event.location}
                        </p>
                      </div>
                      <div className="event-time-info">
                        <p className="event-type">{event.eventtype}</p>
                        <p className="event-date">
                          {`${Moment.unix(event.timestart)
                            .utc()
                            .format("dddd")} , ${Moment.unix(event.timestart)
                            .utc()
                            .format("DD MMMM YYYY")}`}
                        </p>
                        <p className="event-time">
                          {`${Moment.unix(event.timestart).format(
                            "HH:mm A"
                          )} - ${Moment.unix(
                            event.timestart + event.timeduration
                          ).format("HH:mm A")} `}{" "}
                          IST
                        </p>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
        </Swiper>
        }
        
      </div>
    </section>
  );
};

export default Event;
