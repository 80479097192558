import React from "react";
import styled from "styled-components";
// import Logo from "../../images/shivani-labs.png";
// import Logo2x from "../../images/shivani-labs@2x.png";
// import Logo3x from "../../images/shivani-labs@3x.png";
import CloudImg from "../../images/cloud.png";
import LeafImg from "../../images/leaf.png";
import FBImg from "../../images/facebook-logo.png";
import UTubeImg from "../../images/youtube-logo.png";
import InstaImg from "../../images/instagram-logo.png";
import "./index.css";

const FooterGreen = () => {
  return (
    <RectangleStack className=" footer-wrapper ">
      <Rectangle className="d-none d-sm-block col-md-12  footer-color">
        <img className="cloud-img" src={CloudImg} alt="Cloud" />
        <SimplyDummyTextOfRow className="col-md-12">
          <SimplyDummyTextOf className="col-md-4">
            <p className="footer-description">
              We provide valuable educational resources for the CBSE students to
              achieve their academic goals. The portal helps you to learn the
              concepts from very fundamentals which make the students masters in
              every subject. The portal opens you to many opportunities to
              sharpen your academic skills.
            </p>
          </SimplyDummyTextOf>
          <div className="col-md-4 d-flex f-12 pages-list">
            <div className="col-md-6 ">
              <ul>
                <li>Discover courses</li>
                <li>Explore Features</li>
                <li>Get to know services</li>
                <li>Live talk</li>
                <li>About us</li>
              </ul>
            </div>
            <div className="col-md-6 link-footer">
              <ul>
                <li>FAQ</li>
                <li>Support</li>
                <a href="#/privacy" className="cursor">Privacy Policy</a>
                <a href="#/terms" className="cursor">Terms & Conditions</a>
              </ul>
            </div>
          </div>
          <CopyRightContainer className="col-md-4 f-12">
            <FollowUsInColumn>
              <div className="footer-follow-logos">
                <FollowUsIn>Follow us in</FollowUsIn>

                <img alt="Facebook" className="footer-logos" src={FBImg} />
                <img alt="Youtube" className="footer-logos" src={UTubeImg} />
                <img alt="Instagram" className="footer-logos" src={InstaImg} />
                <img alt="Leaf" className="footer-leaf-logo" src={LeafImg} />
              </div>
            </FollowUsInColumn>
          </CopyRightContainer>
        </SimplyDummyTextOfRow>
        <Copyrights>
          <span>
            Copyright © 2022, <strong>Shivani Technologies LLC - USA</strong>. All Rights Reserved.
          </span>
          <span>
            Product Maintenance and Support by <strong>KonnectGen</strong>
          </span>
        </Copyrights>
      </Rectangle>
      <div className=" small-view-footer d-block d-sm-none">
        <div className="icons">
          <img alt="Facebook" className="footer-logos" src={FBImg} />
          <img alt="Youtube" className="footer-logos" src={UTubeImg} />
          <img alt="Instagram" className="footer-logos" src={InstaImg} />
        </div>
        <div className="copy-right">
          <span className="col-4 small-view-name"><strong>Shivani Technologies LLC - USA</strong></span>
          <span className="col-8 copy-right-txt">Product Maintenance and Support by <strong>KonnectGen</strong></span>
        </div>
      </div>
    </RectangleStack>
  );
};

const CopyRightContainer = styled.div`
  margin: auto 0px;
`;

const SimplyDummyTextOf = styled.div`
  width: 335px;
  background-color: transparent;
  color: rgba(124, 124, 124, 1);
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
`;

const FollowUsIn = styled.span`
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  color: #6b6b6b;
  margin-top: 30px;
`;

const Copyrights = styled.div`
  margin: 10px 0px 10px 0px;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  color: #6b6b6b;
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
`;

const FollowUsInColumn = styled.div`
  flex-direction: column;
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: end;
`;


const Rectangle = styled.div`
  width: 100%;
  background-image: linear-gradient(to bottom, #9ce2ae, #fff 54%),
    linear-gradient(to bottom, #082e0e, #082e0e);
  flex-direction: column;
  display: flex;
`;

const RectangleStack = styled.div`
  width: 100%;
`;

const SimplyDummyTextOfRow = styled.div`
  flex-direction: row;
  display: flex;
  margin-top: 10px;
`;


export default FooterGreen;
