var storeView = [{
    id: 1,
    name: 'Books',
    image : require('./book.png'),
    points: 350
}, {
    id: 2,
    name: 'Globe',
    image : require('./globe.png'),
    points: 350
}, {
    id: 3,
    name: 'Apple',
    image : require('./apple.png'),
    points: 350
}, {
    id: 4,
    name: 'Bee',
    image : require('./bee.png'),
    points: 350
}, {
    id: 5,
    name: 'Books',
    image : require('./book.png'),
    points: 350
}, {
    id: 6,
    name: 'Books',
    image : require('./book.png'),
    points: 350
}, {
    id: 7,
    name: 'Books',
    image : require('./book.png'),
    points: 350
}, {
    id: 8,
    name: 'Books',
    image : require('./book.png'),
    points: 350
}];

export const GetStoryView = () => {
    return storeView;
}