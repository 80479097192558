import { sub } from "date-fns";

var addons = [
  {
    id: 1,
    name: "Ask a question",
    description:
      "Allow learners to ask questions related to their chapters and solve their queries. Authors can help with the queries to drive the learners knowledge. Every time when a learner asks questions about the topics teacher will be notified with an email. So they will solve the subject related queries for the learners as soon as possible. By doing so learners will be able to understand the concepts clear. We are providing an easy process to approach the authors and get clarified.",
  },
  {
    id: 2,
    name: "Community Discussion",
    description:
      "A Community Forum is an online “meeting place” that is used to engage  learners with others to share knowledge and communicate with others about their subject and topics. As it relates to their learning experience, Learners can find a resolution to their solutions within the community by discussing with their peers. ",
  },
  {
    id: 3,
    name: "Enroll Events",
    description:
      "We are conducting online events that transforms a presentation into a live conversation with participants from all around the world. We are providing the opportunities for large groups of people to participate in online discussions or training sessions and share their thoughts about technologies, knowledge etc",
  },
  {
    id: 4,
    name: "Beyond Learning / Extra miles",
    description:
      "We are providing our learners with many more learning possibilities by extending learning beyond the classroom. By making learning more entertaining and relevant. Learners should have more possibilities to access and interact with learning resources online. Expanding a learners digital, collaborative, and independent learning abilities. Additionally we are providing more possibilities for revisioning and informal learning. Increase the capacity for inclusive and self learning.",
  },
  {
    id: 5,
    name: "Special Guidance",
    description:
      "The goal of this specific assistance is to help students who do not have a basic understanding of the subject they are studying. Second, to instil in them the desire to learn both internally and externally. The children will be able to excel in their schoolwork and activities as a result of this guidance.",
  },
];

var subscriptionList = {
  subscription: [
    {
      id: 1,
      name: "FREE PLAN",
      price: "0",
      description:
        "We provide valuable educational resources for all the board students to achieve their academic goals. The portal helps you to learn the concepts from very fundamentals which make the students masters in every subject. The portal opens you too many opportunities to sharpen your academic skills. We provide valuable educational resources for all the board students to achieve their academic goals.",
      duration: "12 months",
      boards: ["CBSE"],
      subjects: ["All subjects"],
      addons: [],
      userGroups: [],
      features: [
        {
          id: 1,
          name: "Content Notes",
          price: 0,
        },
        {
          id: 2,
          name: "Book Solutions",
          price: 0,
        },
      ],
    },
    {
      id: 2,
      name: "BASIC PLAN",
      price: "150",
      description:
        "We provide valuable educational resources for all the board students to achieve their academic goals. The portal helps you to learn the concepts from very fundamentals which make the students masters in every subject. The portal opens you too many opportunities to sharpen your academic skills. We provide valuable educational resources for all the board students to achieve their academic goals.",
      duration: "Renewed each month",
      boards: ["All Broads"],
      subjects: ["All subjects"],
      addons: ["Ask a question"],
      userGroups: [],
      features: [
        {
          id: 1,
          name: "Content Notes",
          price: 0,
        },
        {
          id: 2,
          name: "Book Solutions",
          price: 0,
        },
        {
          id: 3,
          name: "Worksheets",
          price: 0,
        },
      ],
    },
    {
      id: 3,
      name: "PREMIUM PLAN",
      duration: "",
      description:
        "We provide valuable educational resources for all the board students to achieve their academic goals. The portal helps you to learn the concepts from very fundamentals which make the students masters in every subject. The portal opens you too many opportunities to sharpen your academic skills. We provide valuable educational resources for all the board students to achieve their academic goals.",
      price: "1500",
      boards: ["All Broads"],
      subjects: ["All subjects"],
      addons: ["Ask a question", "Community Discussion", "Enroll Events"],
      userGroups: ["Mentor for all subject", "Access all question papers"],
      features: [
        {
          id: 1,
          name: "Content Notes",
          price: 0,
        },
        {
          id: 2,
          name: "Book Solutions",
          price: 0,
        },
        {
          id: 3,
          name: "Practice Solutions",
          price: 0,
        },
        {
          id: 4,
          name: "Audio Content",
          price: 0,
        },
        {
          id: 5,
          name: "Interactive Sessions",
          price: 0,
        },
        {
          id: 6,
          name: "Quizzes",
          price: 0,
        },
        {
          id: 7,
          name: "Pick Addons",
          price: 0,
        },
      ],
    },
    {
      id: 4,
      name: "PLATINUM PLAN",
      duration: "",
      price: "3500",
      description:
        "We provide valuable educational resources for all the board students to achieve their academic goals. The portal helps you to learn the concepts from very fundamentals which make the students masters in every subject. The portal opens you too many opportunities to sharpen your academic skills. We provide valuable educational resources for all the board students to achieve their academic goals.",
      boards: ["All Broads"],
      subjects: ["All subjects"],
      addons: [
        "Ask a question",
        "Community Discussion",
        "Enroll Events",
        "Beyond Learning/Extra miles",
        "Special Guidance",
      ],
      userGroups: ["Mentor for all subject", "Access all question papers"],
      features: [
        {
          id: 1,
          name: "Content Notes",
          price: 0,
        },
        {
          id: 2,
          name: "Book Solutions",
          price: 0,
        },
        {
          id: 3,
          name: "Practice Solutions",
          price: 0,
        },
        {
          id: 4,
          name: "Audio Content",
          price: 0,
        },
        {
          id: 5,
          name: "Interactive Sessions",
          price: 0,
        },
        {
          id: 6,
          name: "Quizzes",
          price: 0,
        },
        {
          id: 7,
          name: " All Addons",
          price: 0,
        },
      ],
      addedValue: ["Mascot Assistants", "Earn rewards", "Discount & Offers"],
    },
  ],
};

export const GetList = () => {
  return subscriptionList.subscription;
};

export const GetListByID = (id) => {
  window.data = subscriptionList.subscription;
  return subscriptionList.subscription.filter((val) => val.id == id);
};

export const GetAddon = () => {
  return addons;
};
