import { useState } from "react";
import { Nav } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import ChildCard from "./ChildCard";
import ChildSubject from "./ChildSubject";
import ChidPerformance from "./ChidPerformance";
import ChildActivity from "./ChildActivity";
import ChildRewards from "./ChildRewards";

import ParentHeader from "./ParentHeader";
import ParentFooter from "./ParentFooter";
import ParentReportView from "../../features/reports/parentReport";

import "./ChildList.scss";
import "./ChildHistory.scss";
import { useEffect } from "react";

const ChildHistory = () => {
  const params = useParams();
  let childrenInfo = useSelector((state) => state.childInfo.info);
  const history = useHistory();
  const [tab, setTab] = useState("subjects");
  const TabChangeHandler = (tabName) => {
    setTab(tabName);
  };

  let childArray = [];
  for (const [key, value] of Object.entries(childrenInfo)) {
    childArray.push(childrenInfo[key]);
  }

  if (childArray.length > 0) {
    childArray = childArray.filter((child) => child.childId == params.id);
  }
  useEffect(() => {
    if (
      childrenInfo == null ||
      childrenInfo == undefined ||
      Object.keys(childrenInfo).length < 1
    ) {
      history.push("../mywards");
    }
  }, [childrenInfo]);

  return (
    <>
      <ParentHeader active="#/parent/mywards" />
      <main className="parent-portal child-specific">
        <div className="page-header">My wards</div>
        <div className="">
          {childArray?.map((child, i) => {
            return (
              <ChildCard
                key={i}
                {...child}
                id={child.childId}
                redirection={false}
              />
            );
          })}
        </div>
        <div className="col-md-12">
          <Nav justify variant="tabs" defaultActiveKey={""}>
            <Nav.Item>
              <p
                className={`header-name ${tab == "subjects" ? "active" : ""}`}
                onClick={() => {
                  TabChangeHandler("subjects");
                }}
              >
                SUBJECTS
              </p>
            </Nav.Item>
            <Nav.Item>
              <p
                className={`header-name ${tab == "activities" ? "active" : ""}`}
                onClick={() => {
                  TabChangeHandler("activities");
                }}
              >
                ACTIVITIES
              </p>
            </Nav.Item>
            <Nav.Item>
              <p
                className={`header-name ${tab == "rewards" ? "active" : ""}`}
                onClick={() => {
                  TabChangeHandler("rewards");
                }}
              >
                REWARDS
              </p>
            </Nav.Item>
            <Nav.Item>
              <p
                className={`header-name ${tab == "calendar" ? "active" : ""}`}
                onClick={() => {
                  TabChangeHandler("calendar");
                }}
              >
                CALENDAR
              </p>
            </Nav.Item>
            <Nav.Item>
              <p
                className={`header-name ${tab == "performance" ? "active" : ""
                  }`}
                onClick={() => {
                  TabChangeHandler("performance");
                }}
              >
                PERFORMANCE
              </p>
            </Nav.Item>
            {/* <Nav.Item>
              <p
                className={`header-name ${tab == "reports" ? "active" : ""}`}
                onClick={() => {
                  TabChangeHandler("reports");
                }}
              >
                REPORTS
              </p>
            </Nav.Item> */}
          </Nav>
        </div>
        <div className="details-container">
          {tab == "subjects" &&
            [...Array(4)].map((e, i) => {
              return <ChildSubject i={i} key={i} />;
            })}
          {tab == "performance" && <ChidPerformance />}
          {tab == "calendar" && <ChildActivity />}
          {/* {tab == "reports" && <ParentReportView childInfo={childrenInfo} />} */}
          {tab == "rewards" && <ChildRewards />}
        </div>
      </main>
      <ParentFooter />
    </>
  );
};

export default ChildHistory;
