import { useEffect, useState } from "react";
import { DatePicker, TimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Select from "react-select";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "./PhoneNumber.css";
import classes from "./InputContainer.module.css";
import "./InputContainer.css";

const dot = (color = "transparent") => ({
  alignItems: "center",
  display: "flex",
  padding: "0px",
  margin: "0px",
  height: "100%",
  input: {
    border: "0px !important",
  },
});

const InputContainer = (props) => {
  const [phone, SetPhone] = useState("");
  const [textArea, SetTextArea] = useState("");
  const [checkbox, SetCheckBox] = useState([]);
  const [selectValue, SetSelectValue] = useState("");
  const [dateSelect, SetDateSelect] = useState(null);

  const SelectHandler = (event) => {
    props.onChange(event.value);
    SetSelectValue(event.value);
  };

  const DateSelectHandler = (event) => {
    props.onChange(event);
    SetDateSelect(event);
  };

  const UsernameHandler = (event) => {
    if (props.onChange) {
      props.onChange(event);
    }
  };
  const CheckBoxHandler = (event) => {
    if (event.target.checked) {
      SetCheckBox([...checkbox, event.target.value]);
      let response = [...checkbox, event.target.value];
      if (props.onChange) {
        props.onChange(response);
      }
    } else {
      SetCheckBox(
        checkbox.filter((checkbox) => checkbox !== event.target.value)
      );
      let response = checkbox.filter((checkbox) => checkbox !== event.target.value);
      if (props.onChange) {
        props.onChange(response);
      }
    }
  };

  const UsernameKeyUpHandler = (event) => {
    if (props.onKeyUp) {
      props.onKeyUp(event);
    }
  };

  const TextAreaHandler = (event) => {
    props.onChange(event);
    SetTextArea(event.target.value);
  }

  const PhoneNumberHandler = (number) => {
    props.onChange(number);
  };

  useEffect(() => {
    if (props.type == "textarea") {
      SetTextArea(props.value);
    }

    if (props.type == "select") {
      SetSelectValue(props.value);
    }
  }, [props.value, props.type])

  const customStyles = {
    valueContainer: (provided, state) => ({
      ...provided,
      height: "100%",
      padding: "0px 8px",
    }),
    container: (provided, state) => ({
      ...provided,
      paddingTop: 5,
    }),
    placeholder: (provided, state) => ({
      ...provided,
      fontSize: "12px",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      fontSize: "12px",
    }),
    menu: (provided, state) => ({
      ...provided,
      marginTop: "0px",
    }),
    control: (provided, state) => ({
      ...provided,
      outline: "none !important",
      boxShadow: "none !important",
      minHeight: "35px",
      height: "35.78px",
      border: props.error
        ? "2px solid #fc3904 !important"
        : "solid 1px var(--gradient-btn-yellow)",
      ":hover": {
        border: "2px solid #0478fe !important",
        outline: "none !important",
        boxShadow: "none !important",
      },
    }),
    input: (styles) => ({ ...styles, ...dot() }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        fontSize: "12px",
        color: isFocused || isSelected ? "white" : "black",
      };
    },
  };

  let decider;

  if (
    props.type === "text" ||
    props.type === "search" ||
    props.type === "password" ||
    props.type === "number"
  ) {
    decider = "input";
  }

  if (props.type === "radio" || props.type === "checkbox") {
    decider = "radio";
  }
  if (props.type === "checkbox") {
    decider = "checkbox";
  }

  if (props.type === "textarea") {
    decider = "textarea";
  }
  if (props.type === "date") {
    decider = "date";
  }

  if (props.type === "time") {
    decider = "time";
  }

  if (props.type === "select") {
    decider = "select";
  }

  if (props.type === "phoneNumber") {
    decider = "phoneNumber";
  }

  let containerClass = "";
  if (props.className && props.className.length > 0) {
    containerClass = props.className;
  }

  let labelClass = "";
  if (props.labelClass && props.labelClass.length > 0) {
    labelClass = props.labelClass;
  }
  let inputContainerClass = "";
  if (props.inputContainerClass && props.inputContainerClass.length > 0) {
    inputContainerClass = props.inputContainerClass;
  }
  let inputListClass = "";
  if (props.inputListClass && props.inputListClass.length > 0) {
    inputListClass = props.inputListClass;
  }

  return (
    <div className={`${classes.inputContainer} ${containerClass}`}>
      <label className={`${labelClass}`} htmlFor={props.name ? props.name : ""}>
        {props.labelName}{" "}
        <span className={classes["extra-label"]}>{props.labelExtra}</span>
      </label>
      <div
        className={`${props.disabled == false && props.status !== ""
          ? classes["function-container"]
          : ""} ${inputContainerClass}`
        }
      >
        {props.disabled === true && props.status === "Verified" && (
          <span className={classes["verified"]}>
            {props.status.toUpperCase()}
          </span>
        )}
        {props.disabled === true && props.status === "Not Verified" && (
          <span className={classes["not-verified"]}>
            {props.status.toUpperCase()}
          </span>
        )}
        {decider === "input" && (
          <input
            type={props.type}
            name={props.name}
            value={props.value}
            placeholder={props.placeholder}
            onChange={UsernameHandler}
            onKeyUp={UsernameKeyUpHandler}
            className={`${props.error !== "" && Boolean(props.error) ? classes.error : ""
              } ${props.disabled === true && props.status && classes["pl-75-px"]
              } ${props.inputClass !== "" && props.inputClass}`}
            disabled={props.disabled}
          />
        )}
        {decider === "radio" && (
          props.lists && props.lists.map((list) => {
            return (
              <div className={`${inputListClass}`}>
                <input
                  type={props.type}
                  name={props.name}
                  value={list.value}
                  onChange={UsernameHandler}
                  className={` ${classes.inputRadio} ${props.error !== "" && Boolean(props.error) ? classes.error : ""
                    } ${props.disabled === true && props.status && classes["pl-75-px"]
                    } ${props.inputClass !== "" && props.inputClass}`}
                  disabled={props.disabled}
                />
                <span className={` ${classes.inputRadioLabel}`}>{list.label}</span>
              </div>
            )
          })
        )}
        {decider === "checkbox" && (
          props.lists && props.lists.map((list) => {
            return (
              <div className={`${inputListClass}`}>
                <input
                  type={props.type}
                  name={props.name}
                  value={list.value}
                  onChange={CheckBoxHandler}
                  className={` ${classes.inputCheckbox} ${props.error !== "" && Boolean(props.error) ? classes.error : ""
                    } ${props.disabled === true && props.status && classes["pl-75-px"]
                    } ${props.inputClass !== "" && props.inputClass}`}
                  disabled={props.disabled}
                />
                <div className="col-11 p-0">
                  <div className={`checbox-label ${classes.inputRadioLabel}`}>{list.label}</div>
                  {list.description && <div className="checbox-description">{list.description}</div>}
                </div>
              </div>
            )
          })
        )}
        {decider === "textarea" && (
          <textarea
            name={props.name}
            placeholder={props.placeholder}
            onChange={TextAreaHandler}
            value={textArea}
            className={`${props.error !== "" && Boolean(props.error) ? classes.error : ""
              } ${props.disabled === true && props.status && classes["pl-75-px"]
              } ${props.inputClass !== "" && props.inputClass}`}
            disabled={props.disabled}
          />
        )}
        {decider === "date" && (
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              variant={`${props.variant ? props.variant : "inline"}`}
              format="dd-MMM-yyyy"
              value={dateSelect}
              className={`${props.error !== "" && Boolean(props.error)
                ? classes["error-input"]
                : ""
                }`}
              onChange={DateSelectHandler}
              maxDate={`${props.maxDate ? props.maxDate : "2016-11-29"}`}
              minDate={`${props.minDate ? props.minDate : "2006-01-01"}`}
              animateYearScrolling
              allowKeyboardControl
              autoOk
            />
          </MuiPickersUtilsProvider>
        )}
        {decider === "time" && (
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <TimePicker
              variant={`${props.variant ? props.variant : "inline"}`}
              inputFormat="mm:ss"
              value={dateSelect}
              className={`${props.error !== "" && Boolean(props.error)
                ? classes["error-input"]
                : ""
                }`}
              onChange={DateSelectHandler}
              openTo="minutes"
              animateYearScrolling
              allowKeyboardControl
              autoOk
            />
          </MuiPickersUtilsProvider>
        )}
        {decider === "select" && (
          <>
            {props.options && (
              <Select
                value={props.options.filter(
                  (option) => option.value === selectValue
                )}
                name={props.name}
                menuColor="red"
                styles={
                  props.customStyleSelect
                    ? props.customStyleSelect
                    : customStyles
                }
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 5,
                  colors: {
                    ...theme.colors,
                    primary25: "rgba(253, 173, 37, 1)",
                  },
                })}
                isDisabled={props.disabled}
                onChange={SelectHandler}
                options={props.options}
              />
            )}
          </>
        )}
        {decider === "phoneNumber" && (
          <PhoneInput
            country={"in"}
            value={phone}
            enableSearch={true}
            disableSearchIcon={true}
            className={`${props.error !== "" && Boolean(props.error) ? "phone-input-error" : ""}`}
            inputClass={`${props.error !== "" && Boolean(props.error) ? classes.error : ""
              } ${classes["phone-number"]} `}
            onChange={(number) => PhoneNumberHandler({ number })}
          />
        )}
        {props.disabled === false && props.status && (
          <button className={classes["secondary-button"]}>Send OTP</button>
        )}
      </div>
      <p className={`error-statement ${classes["error-statement"]}`}>
        {props.error !== "" && Boolean(props.error) && props.errorText}
      </p>
    </div>
  );
};

export default InputContainer;
