import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'

import './ActivityHeader.css';

const ActivityHeader = (props) => {
    const history = useHistory();

    const ActivitiesHomeHandler = () => {
        history.push('../activities');
    }
    return (
        <div className="w-100 mb-10 d-flex">
            <div className="icon-container">
                <FontAwesomeIcon icon={faChevronLeft} className="activities-page" onClick={ActivitiesHomeHandler} />
            </div>
            <div>
                <div className="description-header">{props.name}</div>
                <div className="description-subject">Activity in {props.subject}</div>
                <div className="description-date">Posted on {props.postedDate}</div>
            </div>
        </div>
    )
}

export default ActivityHeader;