import APPLOGO from '../../images/weblogo.png'
import "./navbar.css";
function Navbar() {
  return (
    <div>
      <nav className="navbar">
        <div className="container-fluid sticky">
        <a href="{() => false}">
            <img 
              alt="Shivani Labs"
              src={APPLOGO}
              data-holder-rendered="true"
            ></img></a>
          <div className="topnav">
            <a  href="{() => false}">
            DISCOVER COURSES
            </a>
            <a href="{() => false}">
            EXPLORE FEATURES
            </a>
            <a  href="{() => false}">
            GET TO KNOW SERVICES
            </a>
            <a className="active" href="{() => false}">
            ABOUT US
            </a>
            <a href="{() => false}">
              FAQ
            </a>
            <a href="{() => false}">
            CONTACT US
            </a>
            <a  href="{() => false}"  className="register-label">
             REGISTER
            </a>
            <button type="button" className="btn btn-warning signin-label">
            SIGN IN
            </button>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Navbar;
