const activityData = [
  {
    id: "1",
    subject: "English",
    posted_date: "27-Aug-2021",
    dp: "https://images-na.ssl-images-amazon.com/images/I/71nv0TXxLLL.jpg",
    username: "Vincent Luggers",
    destination: "Chennai, Tamilnadu",
    activity_name: "Oscar wild - Happy princes",
    activity_image:
      "https://www.marketing91.com/wp-content/uploads/2019/03/Task-and-Activity-1.jpg",
    point: "1",
    submitted_date: "05/09/2021",
    description:
      "simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.  the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make",
  },
  {
    id: "2",
    subject: "English",
    posted_date: "27-Aug-2021",
    dp: "https://www.humanium.org/en/wp-content/uploads/2017/02/shutterstock_102463487web.jpg",
    username: "Sanju Samson",
    destination: "Kollam, Kerala",
    activity_name: "Oscar wild - Happy princes",
    activity_image:
      "https://images-na.ssl-images-amazon.com/images/I/71nv0TXxLLL.jpg",
    point: "1",
    submitted_date: "05/09/2021",
    description:
      "simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.  the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make",
  },
  {
    id: "3",
    subject: "English",
    posted_date: "27-Aug-2021",
    dp: "https://www.humanium.org/en/wp-content/uploads/2017/02/shutterstock_102463487web.jpg",
    username: "KL Rahul",
    destination: "Bangalore, Karnataka",
    activity_name: "Oscar wild - Happy princes",
    activity_image:
      "https://cdn.cdnparenting.com/articles/2018/04/338324834-H.jpg",
    point: "1",
    submitted_date: "05/09/2021",
    description:
      "simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.  the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make",
  },
  {
    id: "4",
    subject: "English",
    posted_date: "27-Aug-2021",
    dp: "https://www.humanium.org/en/wp-content/uploads/2017/02/shutterstock_102463487web.jpg",
    username: "Tokunaga Yae",
    destination: "Chennai, Tamilnadu",
    activity_name: "Oscar wild - Happy princes",
    activity_image:
      "https://image.freepik.com/free-vector/kids-activities-set_23-2147540904.jpg",
    point: "1",
    submitted_date: "05/09/2021",
    description:
      "simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.  the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make",
  },
  {
    id: "5",
    subject: "English",
    posted_date: "27-Aug-2021",
    dp: "https://www.humanium.org/en/wp-content/uploads/2017/02/shutterstock_102463487web.jpg",
    username: "Tokunaga Yae",
    destination: "Chennai, Tamilnadu",
    activity_name: "Oscar wild - Happy princes",
    activity_image:
      "https://image.freepik.com/free-vector/kids-having-online-lessons_52683-36481.jpg",
    point: "1",
    submitted_date: "05/09/2021",
    description:
      "simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.  the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make",
  },
  {
    id: "6",
    subject: "English",
    posted_date: "27-Aug-2021",
    dp: "https://www.humanium.org/en/wp-content/uploads/2017/02/shutterstock_102463487web.jpg",
    username: "Tokunaga Yae",
    destination: "Chennai, Tamilnadu",
    activity_name: "Oscar wild - Happy princes",
    activity_image:
      "https://image.freepik.com/free-photo/small-caucasian-boy-playing-with-colored-plasticine-making-figures-white-table-happy-child-idea_1268-16984.jpg",
    point: "1",
    submitted_date: "05/09/2021",
    description:
      "simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.  the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make",
  },
  {
    id: "7",
    subject: "English",
    posted_date: "27-Aug-2021",
    dp: "https://www.humanium.org/en/wp-content/uploads/2017/02/shutterstock_102463487web.jpg",
    username: "Tokunaga Yae",
    destination: "Chennai, Tamilnadu",
    activity_name: "Oscar wild - Happy princes",
    activity_image:
      "https://image.freepik.com/free-vector/students-with-kindergarten-room-elements-white_1308-55756.jpg",
    point: "1",
    submitted_date: "05/09/2021",
    description:
      "simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.  the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make",
  },
  {
    id: "8",
    subject: "English",
    posted_date: "27-Aug-2021",
    dp: "https://www.humanium.org/en/wp-content/uploads/2017/02/shutterstock_102463487web.jpg",
    username: "Tokunaga Yae",
    destination: "Chennai, Tamilnadu",
    activity_name: "Oscar wild - Happy princes",
    activity_image:
      "https://image.freepik.com/free-vector/kindergarten-illustration_1284-22416.jpg",
    point: "1",
    submitted_date: "05/09/2021",
    description:
      "simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.  the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make",
  },
  {
    id: "9",
    subject: "English",
    posted_date: "27-Aug-2021",
    dp: "https://www.humanium.org/en/wp-content/uploads/2017/02/shutterstock_102463487web.jpg",
    username: "Tokunaga Yae",
    destination: "Chennai, Tamilnadu",
    activity_name: "Oscar wild - Happy princes",
    activity_image:
      "https://image.freepik.com/free-vector/kids-learning-playing-illustration_53876-40285.jpg",
    point: "1",
    submitted_date: "05/09/2021",
    description:
      "simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.  the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make",
  },
  {
    id: "10",
    subject: "English",
    posted_date: "27-Aug-2021",
    dp: "https://image.freepik.com/free-photo/little-boy-playing-superhero-playground_53876-46868.jpg",
    username: "Tokunaga Yae",
    destination: "Chennai, Tamilnadu",
    activity_name: "Oscar wild - Happy princes",
    activity_image:
      "https://image.freepik.com/free-photo/group-children-colored-t-shirts-sitting-table-with-raised-hands_186202-5282.jpg",
    point: "1",
    submitted_date: "05/09/2021",
    description:
      "simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.  the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make",
  },
  {
    id: "11",
    subject: "English",
    posted_date: "27-Aug-2021",
    dp: "https://www.humanium.org/en/wp-content/uploads/2017/02/shutterstock_102463487web.jpg",
    username: "Tokunaga Yae",
    destination: "Chennai, Tamilnadu",
    activity_name: "Oscar wild - Happy princes",
    activity_image:
      "https://image.freepik.com/free-vector/diverse-group-children-kindergarten_74855-5811.jpg",
    point: "1",
    submitted_date: "05/09/2021",
    description:
      "simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.  the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make",
  },
];

export const activityNewData = [
  {
    id: "1",
    subject: "English",
    posted_date: "27-Aug-2021",
    dp: "https://images-na.ssl-images-amazon.com/images/I/71nv0TXxLLL.jpg",
    username: "Vincent Luggers",
    destination: "Chennai, Tamilnadu",
    activity_name: "Oscar wild - Happy princes",
    activity_image:
      "https://www.marketing91.com/wp-content/uploads/2019/03/Task-and-Activity-1.jpg",
    point: "1",
    submitted_date: "05/09/2021",
    description:
      "simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.  the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make",
  },
  {
    id: "2",
    subject: "English",
    posted_date: "27-Aug-2021",
    dp: "https://www.humanium.org/en/wp-content/uploads/2017/02/shutterstock_102463487web.jpg",
    username: "Sanju Samson",
    destination: "Kollam, Kerala",
    activity_name: "Oscar wild - Happy princes",
    activity_image:
      "https://images-na.ssl-images-amazon.com/images/I/71nv0TXxLLL.jpg",
    point: "1",
    submitted_date: "05/09/2021",
    description:
      "simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.  the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make",
  },
  {
    id: "3",
    subject: "English",
    posted_date: "27-Aug-2021",
    dp: "https://www.humanium.org/en/wp-content/uploads/2017/02/shutterstock_102463487web.jpg",
    username: "KL Rahul",
    destination: "Bangalore, Karnataka",
    activity_name: "Oscar wild - Happy princes",
    activity_image:
      "https://cdn.cdnparenting.com/articles/2018/04/338324834-H.jpg",
    point: "1",
    submitted_date: "05/09/2021",
    description:
      "simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.  the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make",
  },
];

export default activityData;
