import React from 'react';
import IconCross from './../Icons/IconCross';
import './Content.scss';

const Content = ({ movie, onClose }) => (
  <div className="content">
    <div className="content__background">
      <div className="content__background__shadow" />
      <div
        className="content__background__image"
        style={{ 'background-image': `url(${movie.imageBg})` }}
      />
    </div>
    <div className="content__area">
      <div className="content__area__container">
        <div className="content__title">{movie.displayname}</div>
        <div className="content__description">
          <div dangerouslySetInnerHTML={{__html: movie.summary}}></div>
          <button className="subj-button" onClick={() => {window.location.href = `#/courses/${movie.id}?chapter=${movie.id}`}}>
            Buy Now
          </button>
          <button className="subj-button" onClick={() => {window.location.href = `#/courses/${movie.id}?chapter=${movie.id}`}}>
            Add to Cart
          </button>
        </div>
      </div>
      <button className="content__close" onClick={onClose}>
        <IconCross />
      </button>
    </div>
  </div>
);

export default Content;
