import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Moment from "moment";
import axios from "../../api/axios";
import { ApiServices } from "../../api/ApiServices";
import { alpha } from '@material-ui/core/styles';

// URL
import { GetSubjectURL } from "../../urls";

// CSS
import "./AddChild.css";
import "../authentication/Verify.css";
import classes from "../authentication/Signup.module.css";

// Images
import Spinner from "../../images/spinner.svg";
import AddChildImage from "../../images/add-child.svg";
import InputContainer from "../../common/inputs/InputContainer";

let genderOptions = [
  {
    label: "Male",
    value: "Male",
  },
  {
    label: "Female",
    value: "Female",
  },
  {
    label: "Prefer Not To Say",
    value: "Prefer Not To Say",
  },
];

const AddChildForm = (props) => {
  const history = useHistory();
  const [childFirstName, SetChildFirstName] = useState({
    value: "",
    error: false,
    errorText: "",
  });

  const [childLastName, SetChildLastName] = useState({
    value: "",
    error: false,
    errorText: "",
  });

  const [gender, SetGender] = useState({
    value: "",
    error: false,
    errorText: "",
  });

  const [dateOfBirth, SetDateOfBirth] = useState({
    value: "",
    error: false,
    errorText: "",
  });

  const [grade, SetGrade] = useState({
    value: "",
    error: false,
    errorText: "",
  });

  const [board, SetBoard] = useState({
    value: "",
    error: false,
    errorText: "",
  });

  const [location, SetLocation] = useState({
    value: "",
    error: false,
    errorText: "",
  });

  const [address, SetAddress] = useState({
    value: "",
    error: false,
    errorText: "",
  });

  const [childUserName, SetChildUserName] = useState({
    value: "",
    error: false,
    errorText: "",
  });

  const [childPassword, SetChildPassword] = useState({
    value: "",
    error: false,
    errorText: "",
  });
  const [loaderShow, SetLoaderShow] = useState(false);
  const [boardOptions, SetBoardOptions] = useState([]);
  const [allCategories, SetAllCategories] = useState([]);
  const [gradeOptions, SetGradeOptions] = useState([]);

  useEffect(() => {
    axios.get(GetSubjectURL(process.env.REACT_APP_MOODLE_TOKEN)).then((res) => {
      let BoardFiltered = res.data.filter((data) => data.parent == 0);
      let options = [];
      BoardFiltered.map((option) => {
        options.push({
          label: option.name,
          value: option.id,
        })
      })
      SetAllCategories(res.data);
      SetBoardOptions(options)
    });

  }, [])

  const ChildFirstNameHandler = (event) => {
    SetChildFirstName({
      value: event.target.value.replace(/[^a-zA-Z]/g, ""),
      error: false,
    });
  };

  const ChildLastNameHandler = (event) => {
    SetChildLastName({
      value: event.target.value.replace(/[^a-zA-Z]/g, ""),
      error: false,
    });
  };

  const GenderHandler = (value) => {
    SetGender({ value: value, error: false });
  };

  const DateOfBirthHandler = (value) => {
    SetDateOfBirth({ value: value, error: false });
  };

  const GradeHandler = (value) => {
    SetGrade({ value: value, error: false });
  };

  const BoardHandler = (value) => {
    SetBoard({ value: value, error: false });
    let options = [];
    allCategories.map((option) => {
      if (option.parent == value) {
        options.push({
          label: option.name,
          value: option.id,
        })
      }
    })

    SetGradeOptions(options);
  };

  const LocationHandler = (event) => {
    SetLocation({
      value: event.target.value.replace(/[^a-zA-Z]/g, ""),
      error: false,
    });
  };

  const AddressHandler = (event) => {
    SetAddress({
      value: event.target.value.replace(/[^a-zA-Z]/g, ""),
      error: false,
    });
  };

  const ChildUserNameHandler = (event) => {
    SetChildUserName({
      value: event.target.value.replace(/[^\w\s]/gi, ""),
      error: false,
    });
  };

  const ChildPasswordHandler = (event) => {
    SetChildPassword({ value: event.target.value, error: false });
  };

  const SignUpSubmitHandler = (event) => {
    event.preventDefault();
    SetLoaderShow(true);

    if (childFirstName.value === "") {
      SetChildFirstName({
        ...childFirstName,
        error: true,
        errorText: "Enter child first name",
      });
    }
    if (childLastName.value === "") {
      SetChildLastName({
        ...childLastName,
        error: true,
        errorText: "Enter child last name",
      });
    }
    if (gender.value === "") {
      SetGender({
        ...gender,
        error: true,
        errorText: "Please select child gender",
      });
    }
    if (dateOfBirth.value === "") {
      SetDateOfBirth({
        ...dateOfBirth,
        error: true,
        errorText: "Please select child date of birth",
      });
    }
    if (grade.value === "") {
      SetGrade({
        ...grade,
        error: true,
        errorText: "Please select child grade",
      });
    }
    if (board.value === "") {
      SetBoard({
        ...board,
        error: true,
        errorText: "Please select child board",
      });
    }
    if (location.value === "") {
      SetLocation({
        ...location,
        error: true,
        errorText: "Enter your location",
      });
    }
    if (address.value === "") {
      SetAddress({
        ...address,
        error: true,
        errorText: "Enter your address",
      });
    }
    if (childUserName.value === "") {
      SetChildUserName({
        ...childUserName,
        error: true,
        errorText: "Enter child user name",
      });
    }

    if (childUserName.value.length < 6 || childUserName.value.length > 30) {
      SetChildUserName({
        ...childUserName,
        error: true,
        errorText: "Enter child user name as per instruction",
      });
    }

    if (
      childPassword.value.length < 8 ||
      (childPassword.value.match(/[A-Z]/g) || []).length < 1 ||
      (childPassword.value.match(/[a-z]/g) || []).length < 1 ||
      (
        childPassword.value.match(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/) ||
        []
      ).length < 1
    ) {
      SetChildPassword({
        ...childPassword,
        error: true,
        errorText: "Enter child password as per instruction",
      });
    }

    if (
      childFirstName.value !== "" &&
      childLastName.value !== "" &&
      gender.value !== "" &&
      dateOfBirth.value !== "" &&
      grade.value !== "" &&
      board.value !== "" &&
      location.value !== "" &&
      address.value !== "" &&
      childUserName.value !== "" &&
      childUserName.value.length >= 6 &&
      childUserName.value.length <= 30 &&
      childPassword.value.length >= 8 &&
      (childPassword.value.match(/[A-Z]/g) || []).length >= 1 &&
      (childPassword.value.match(/[a-z]/g) || []).length >= 1 &&
      (
        childPassword.value.match(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/) ||
        []
      ).length >= 1
    ) {
      SetLoaderShow(true);
      let gradeInfo = allCategories.find((data) => data.id == grade.value);

      let gradeName = gradeInfo.name?.toLowerCase().replace("grade ", '');
      axios
        .post(
          `${ApiServices("childSignup")}&firstname=${childFirstName.value}&lastname=${childLastName.value}&username=${childUserName.value}&classname=${address.value}&parentid=${props.parentId}&gender=${gender.value}&gradeid=${grade.value}&dob=${Moment(dateOfBirth.value).format("DD-MM-YY")}&grade=${gradeName}&board=${board.value}&password=${childPassword.value}&createdby=parent`
        )
        .then(
          (response) => {
            if (!isNaN(response.data)) {
              let res = {
                childid: response.data,
                firstname: childFirstName.value,
                lastname: childLastName.value,
                username: childUserName.value,
                parentid: props.parentId,
                address: address.value,
                gender: gender.value,
                dob: Moment(dateOfBirth.value).format("DD-MM-YY"),
                grade: "Grade " + gradeName,
                gradeid: grade.value,
                board: board.value,
              };

              localStorage.setItem("child", JSON.stringify(res));
              sessionStorage.setItem("child", JSON.stringify(res));
              SetLoaderShow(false);

              setTimeout(() => {
                history.push(
                  props.redirectionUrl
                    ? props.redirectionUrl
                    : "./subscription-list"
                );
              }, 100);
            } else {
              if (response.data.message) {
                SetChildPassword({
                  ...childPassword,
                  error: true,
                  errorText: response.data.message,
                });
                SetLoaderShow(false);
                return;
              }
              if (response.data.search("First Name") > 0) {
                SetChildFirstName({
                  ...childFirstName,
                  error: true,
                  errorText: "Enter parent first name",
                });
                SetLoaderShow(false);
                return;
              }
              if (response.data.search("Last Name") > 0) {
                SetChildLastName({
                  ...childLastName,
                  error: true,
                  errorText: "Enter child last name",
                });
                SetLoaderShow(false);
                return;
              }
              if (response.data.search("Gender") > 0) {
                SetGender({
                  ...gender,
                  error: true,
                  errorText: "Please select child gender",
                });
                SetLoaderShow(false);
                return;
              }
              if (response.data.search("Date of Birth") > 0) {
                SetDateOfBirth({
                  ...dateOfBirth,
                  error: true,
                  errorText: "Please select child date of birth",
                });
                SetLoaderShow(false);
                return;
              }
              if (response.data.search("Grade") > 0) {
                SetGrade({
                  ...grade,
                  error: true,
                  errorText: "Please select child grade",
                });
                SetLoaderShow(false);
                return;
              }
              if (response.data.search("Board") > 0) {
                SetBoard({
                  ...board,
                  error: true,
                  errorText: "Please select child board",
                });
                SetLoaderShow(false);
                return;
              }
              if (response.data.search("classname") > 0) {
                SetAddress({
                  ...address,
                  error: true,
                  errorText: "Enter your address",
                });
                SetLoaderShow(false);
                return;
              }
              if (response.data.search("User Name") > 0) {
                SetChildUserName({
                  ...childUserName,
                  error: true,
                  errorText: "Enter child user name",
                });
                SetLoaderShow(false);
                return;
              }
              if (response.data.search("Already exist") > 0) {
                SetChildUserName({
                  ...childUserName,
                  error: true,
                  errorText: "Child user name already exists",
                });
                SetLoaderShow(false);
                return;
              }
              if (response.data.search("Passsword") > 0) {
                SetChildPassword({
                  ...childPassword,
                  error: true,
                  errorText: "Enter child password as per instruction",
                });
                SetLoaderShow(false);
                return;
              }

              SetChildPassword({
                ...childPassword,
                error: true,
                errorText: response.data,
              });
              SetLoaderShow(false);
            }
          },
          (error) => {
            SetLoaderShow(false);
          }
        );
    } else {
      SetLoaderShow(false);
    }
  };

  return (
    <>
      {loaderShow && (
        <img className={classes.loader} src={Spinner} alt="Loading" />
      )}
      <form onSubmit={SignUpSubmitHandler}>
        <div className="d-flex">
          {loaderShow && (
            <img className={classes.loader} src={Spinner} alt="Loading" />
          )}
          <div className="col-md-7 p-0">
            <div className="d-flex">
              <div className="col-md-6 d-inline-block p-0 p-r-20">
                <InputContainer
                  type="text"
                  name="name"
                  labelName="Child First Name"
                  labelClass="add-child-input-label"
                  placeholder=""
                  onChange={ChildFirstNameHandler}
                  error={childFirstName.error}
                  value={childFirstName.value}
                  errorText={childFirstName.errorText}
                  disabled={loaderShow}
                  className="col-md-12 p-0 h-80"
                />
              </div>
              <div className="col-md-6 d-inline-block p-0">
                <InputContainer
                  type="text"
                  name="lastname"
                  labelName="Child Last Name"
                  labelClass="add-child-input-label"
                  placeholder=""
                  onChange={ChildLastNameHandler}
                  error={childLastName.error}
                  value={childLastName.value}
                  errorText={childLastName.errorText}
                  disabled={loaderShow}
                  className="col-md-12 p-0 h-80"
                />
              </div>
            </div>
            <div className="d-flex">
              <div className="col-md-6 d-inline-block p-0 p-r-20">
                <InputContainer
                  type="select"
                  name="gender"
                  labelName="Gender"
                  labelClass="add-child-input-label"
                  placeholder=""
                  options={genderOptions}
                  onChange={GenderHandler}
                  error={gender.error}
                  errorText={gender.errorText}
                  disabled={loaderShow}
                  className={`col-md-12 p-0 h-80 ${props.selectClass ? props.selectClass : ""
                    }`}
                />
              </div>
              <div className="col-md-6 d-inline-block p-0">
                <InputContainer
                  type="date"
                  name="dob"
                  labelName="Date of Birth (dd/mm/yyyy)"
                  labelClass="add-child-input-label"
                  placeholder=""
                  onChange={DateOfBirthHandler}
                  error={dateOfBirth.error}
                  errorText={dateOfBirth.errorText}
                  disabled={loaderShow}
                  className="col-md-12 p-0 h-80"
                />
              </div>
            </div>
            <div className="d-flex">
              <div className="col-md-6 d-inline-block p-0 p-r-20">
                <InputContainer
                  type="select"
                  name="board"
                  labelName="Board"
                  labelClass="add-child-input-label"
                  placeholder=""
                  options={boardOptions}
                  onChange={BoardHandler}
                  error={board.error}
                  errorText={board.errorText}
                  disabled={loaderShow}
                  className={`col-md-12 p-0 h-80 ${props.selectClass ? props.selectClass : ""
                    }`}
                />
              </div>
              <div className="col-md-6 d-inline-block p-0">
                <InputContainer
                  type="select"
                  name="grade"
                  labelName="Grade"
                  labelClass="add-child-input-label"
                  placeholder=""
                  options={gradeOptions}
                  onChange={GradeHandler}
                  error={grade.error}
                  errorText={grade.errorText}
                  disabled={loaderShow}
                  className={`col-md-12 p-0 h-80 ${props.selectClass ? props.selectClass : ""
                    }`}
                />
              </div>
            </div>
            <div className="d-flex">
              <div className="col-md-6 d-inline-block p-0 p-r-20">
                <InputContainer
                  type="text"
                  name="location"
                  labelName="Location"
                  labelClass="add-child-input-label"
                  placeholder=""
                  onChange={LocationHandler}
                  error={location.error}
                  value={location.value}
                  errorText={location.errorText}
                  disabled={loaderShow}
                  className="col-md-12 p-0 h-80"
                />
              </div>
              <div className="col-md-6 d-inline-block p-0">
                <InputContainer
                  type="text"
                  name="address"
                  labelName="Address"
                  labelClass="add-child-input-label"
                  placeholder=""
                  onChange={AddressHandler}
                  error={address.error}
                  value={address.value}
                  errorText={address.errorText}
                  disabled={loaderShow}
                  className="col-md-12 p-0 h-80"
                />
              </div>
            </div>
            <div className="">
              <div className="username-instruction-container">
                <p className="username-instruction-header">
                  What should a username contain?
                </p>
                <p className="username-instruction-content">
                  Choose a username 6–30 characters long. Your username can be
                  any combination of letters or numbers
                </p>
                <p className="username-instruction-content">
                  Usernames can contain letters (a-z), numbers (0-9), and
                  periods. No special Character allowed
                </p>
              </div>
              <div className="col-md-12 d-inline-block p-0">
                <InputContainer
                  type="text"
                  name="username"
                  labelName="User Name"
                  labelClass="add-child-input-label"
                  placeholder=""
                  value={childUserName.value}
                  onChange={ChildUserNameHandler}
                  error={childUserName.error}
                  errorText={childUserName.errorText}
                  disabled={loaderShow}
                  className="col-md-12 p-0 h-80"
                />
              </div>

              <div className="col-md-12 d-inline-block p-0">
                <InputContainer
                  type="password"
                  name="password"
                  labelName="Password"
                  labelClass="add-child-input-label"
                  placeholder=""
                  onChange={ChildPasswordHandler}
                  error={childPassword.error}
                  errorText={childPassword.errorText}
                  disabled={loaderShow}
                  className="col-md-12 p-0"
                />
              </div>
              <div className="username-instruction-container">
                <p className="username-instruction-content username-instruction-password">
                  At least 8 characters <br />A mixture of both uppercase and
                  lowercase letters and numbers.
                  <br />
                  Inclusion of at least one special character, e.g., ! @ # ? ]
                </p>
              </div>
            </div>
            <div className="col-md-12 text-center">
              <button
                className={
                  props.buttonClass
                    ? props.buttonClass
                    : classes["signup-button"]
                }
                disabled={loaderShow}
                type="submit"
              >
                {props.buttonName}
              </button>
            </div>
          </div>
          <div className="col-md-5 text-align-end">
            {props.showImg && (
              <img
                className="add-child-image"
                src={AddChildImage}
                alt="Add Child"
              />
            )}
          </div>
        </div>
      </form>
    </>
  );
};

export default AddChildForm;
