import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import axios from "../../api/axios";

import { parentInfoActions } from "../../reducers/parentInfoReducers";

import ParentHeader from "./ParentHeader";
import ParentFooter from "./ParentFooter";
import ChildCard from "./ChildCard";

import "./ChildList.scss";

const ChildList = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  let childrenInfo = useSelector((state) => state.childInfo.info);
  const isLoggedIn = useSelector((state) => state.authentication);

  useEffect(() => {
    if (isLoggedIn.authentication?.userInfo?.id) {
      // axios
      //   .get(`/api/myProfile/parent/${isLoggedIn.authentication.userInfo.id}`)
      //   .then(
      //     (parentResponse) => {
      //       dispatch(parentInfoActions.addInfo({ ...parentResponse.data }));
      //     },
      //     (error) => {
      //       history.replace("../../home");
      //     }
      //   );
    }
  }, []);

  const GoToAddChildHandler = () => {
    history.push("./add-child");
  };

  let childArray = [];
  for (const [key, value] of Object.entries(childrenInfo)) {
    childArray.push(childrenInfo[key]);
  }

  return (
    <>
      <ParentHeader active="#/parent/mywards" />
      <main className="parent-portal">
        <div className="page-header">
          <p>My wards</p>
          <button
            className="add-more-child"
            onClick={() => {
              GoToAddChildHandler();
            }}
          >
            Register Another Child
          </button>
        </div>
        <div className="page-content">
          {childArray &&
            childArray.map((child, i) => {
              return (
                <ChildCard
                  key={i}
                  {...child}
                  id={child.childId}
                  redirection={true}
                />
              );
            })}
        </div>
      </main>
      <ParentFooter />
    </>
  );
};

export default ChildList;
