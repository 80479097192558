import Grade1 from "../features/home/images/grade-1.svg";
import Grade2 from "../features/home/images/grade-2.svg";
import Grade3 from "../features/home/images/grade-3.svg";
import Grade4 from "../features/home/images/grade-4.svg";
import Grade5 from "../features/home/images/grade-5.svg";

var grades = {
  results: [
    {
      id: -1,
      standard: "Select Grades",
    },
    {
      id: 101,
      standard: "Grade 6",
      image: Grade1,
    },
    {
      id: 102,
      standard: "Grade 7",
      image: Grade2,
    },
    {
      id: 103,
      standard: "Grade 8",
      image: Grade3,
    },
    {
      id: 104,
      standard: "Grade 9",
      image: Grade4,
    },
    {
      id: 105,
      standard: "Grade 10",
      image: Grade5,
    },
  ],
};

var subjects = {
  results: [
    {
      subject_id: 1,
      subject_name: "English",
      course_id: 101,
    },
    {
      subject_id: 2,
      subject_name: "Maths",
      course_id: 101,
    },
    {
      subject_id: 3,
      subject_name: "Science",
      course_id: 101,
    },
  ],
};

var chapters = {
  results: [
    {
      chapter: "Chapter 1: I and My Body",
      id: 1,
      subject_id: 3,
    },
    {
      chapter: "Chapter 2: Our needs",
      id: 2,
      subject_id: 3,
    },
    {
      chapter: "Chapter 3: My family and our celebrations",
      id: 3,
      subject_id: 3,
    },
    {
      chapter: "Chapter 4: Means of transport and road safety",
      id: 4,
      subject_id: 3,
    },
    {
      chapter: "Chapter 5: Plants and animals around us",
      id: 5,
      subject_id: 3,
    },
    {
      chapter: "Chapter 6: My Neighbourhood",
      id: 6,
      subject_id: 3,
    },
    {
      chapter: "Chapter 7: The Earth and the sky",
      id: 7,
      subject_id: 3,
    },
    {
      chapter: "Chapter 8: Good Habits",
      id: 8,
      subject_id: 3,
    },
    {
      chapter: "Chapter 1: Shapes and Space",
      id: 9,
      subject_id: 2,
    },
    {
      chapter: "Chapter 2: Numbers from one to nine  ",
      id: 10,
      subject_id: 2,
    },
    {
      chapter: "Chapter 3: Addition",
      id: 11,
      subject_id: 2,
    },
    {
      chapter: "Chapter 4: Subraction",
      id: 12,
      subject_id: 2,
    },
    {
      chapter: "Chapter 5: Numbers from ten to twenty",
      id: 13,
      subject_id: 2,
    },
    {
      chapter: "Chapter 6: Time",
      id: 14,
      subject_id: 2,
    },
    {
      chapter: "Chapter 7: Measurements",
      id: 15,
      subject_id: 2,
    },
    {
      chapter: "Chapter 8: Numbers from Twenty one to Fifty",
      id: 16,
      subject_id: 2,
    },
    {
      chapter: "Chapter 9: Data Handling",
      id: 17,
      subject_id: 2,
    },
    {
      chapter: "Chapter 10: Pattern",
      id: 18,
      subject_id: 2,
    },
    {
      chapter: "Chapter 11: Numbers",
      id: 19,
      subject_id: 2,
    },
    {
      chapter: "Chapter 12: Coins",
      id: 20,
      subject_id: 2,
    },
    {
      chapter: "Chapter 13: How many",
      id: 21,
      subject_id: 2,
    },
    {
      chapter: "Chapter 1: Three Little Pigs",
      id: 22,
      subject_id: 1,
    },
    {
      chapter: "Chapter 2: The Bubble, the Straw and the shoe",
      id: 23,
      subject_id: 1,
    },
    {
      chapter: "Chapter 3: Lalu and Peelu",
      id: 24,
      subject_id: 1,
    },
    {
      chapter: "Chapter 4: Mittu and the Yellow Mango",
      id: 25,
      subject_id: 1,
    },
    {
      chapter: "Chapter 5: Circle",
      id: 26,
      subject_id: 1,
    },
    {
      chapter: "Chapter 6: Murali's Mango Tree",
      id: 27,
      subject_id: 1,
    },
    {
      chapter: "Chapter 7: Sundari",
      id: 28,
      subject_id: 1,
    },
    {
      chapter: "Chapter 8: The Tiger and the Mosquito",
      id: 29,
      subject_id: 1,
    },
    {
      chapter: "Chapter 9: Anandi’s Rainbow",
      id: 30,
      subject_id: 1,
    },
    {
      chapter: "Chapter 10: The Tailor and his Friend",
      id: 31,
      subject_id: 1,
    },
  ],
};

export const GetGrades = () => {
  return grades.results;
};

export const GetChapters = (id) => {
  let matching = [];

  chapters.results.forEach((chapter) => {
    var resultedSubject = subjects.results.find(
      (subject) => subject.subject_id == chapter.subject_id
    );
    var resultedGrade = grades.results.find(
      (grade) => grade.id == resultedSubject.course_id
    );
    if (resultedSubject && resultedGrade) {
      let result = { ...chapter, ...resultedSubject, ...resultedGrade };
      matching.push(result);
    }
  });
  matching.sort((a, b) => a.subject_name.localeCompare(b.subject_name));
  return matching;
};
