var subjectList = [
    {
        grade: 1,
        subject: [
            {
                id: "1_eng",
                name: "English",
                Chapters: [{
                    id: "1",
                    name: "Three Little Pigs"
                }, {
                    id: "2",
                    name: "The Bubble, the Straw and the shoe"
                }, {
                    id: "3",
                    name: "Lalu and Peelu"
                }, {
                    id: "4",
                    name: "Mittu and the Yellow Mango"
                }, {
                    id: "5",
                    name: "Circle"
                }, {
                    id: "6",
                    name: "Murali's Mango Tree"
                }, {
                    id: "7",
                    name: "Sundari"
                }, {
                    id: "8",
                    name: "The Tiger and the Mosquito"
                }, {
                    id: "9",
                    name: "Anandi’s Rainbow"
                }, {
                    id: "10",
                    name: "The Tailor and his Friend"
                }]
            } , {
                id: "1_mat",
                name: "Science",
                Chapters: [{
                    id: "1",
                    name: "Me and My Body"
                }]
            }
        ]
    }, {
        grade: 6,
        subject: [
            {
                id: "6_sci",
                name: "Science",
                Chapters: [{
                    id: "6_sci_1",
                    name: "Food: where does it come from?"
                }, {
                    id: "6_sci_2",
                    name: "Components of food"
                }, {
                    id: "6_sci_3",
                    name: "Fibre to fabric"
                }, {
                    id: "6_sci_4",
                    name: "Sorting materials into groups"
                }, {
                    id: "6_sci_5",
                    name: "Separation of substances"
                }, {
                    id: "6_sci_6",
                    name: "Changes around us"
                }, {
                    id: "6_sci_7",
                    name: "Getting to know plants"
                }, {
                    id: "6_sci_8",
                    name: "Body movements"
                }, {
                    id: "6_sci_9",
                    name: "The living organisms — characteristics and habitats"
                }, {
                    id: "6_sci_10",
                    name: "Motion and measuremnet of distances"
                }, {
                    id: "6_sci_11",
                    name: "Light, shadows and reflections"
                }, {
                    id: "6_sci_12",
                    name: "Electricity and circuits"
                }, {
                    id: "6_sci_13",
                    name: "Fun with magnets"
                }, {
                    id: "6_sci_14",
                    name: "Water"
                }, {
                    id: "6_sci_15",
                    name: "Air around us"
                }, {
                    id: "6_sci_16",
                    name: "Garbage in, garbage out"
                }]
            } 
        ]
    },
]

const  GetSubjectChapters = (id) => {
    return subjectList.filter(value => value.grade == id)[0];
}

export default GetSubjectChapters;
