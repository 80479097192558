import { useEffect } from "react";
import { useHistory } from "react-router-dom";

import AddChildForm from "./AddChildForm";
// CSS
import "./AddChild.css";
import "../authentication/Verify.css";
import classes from "../authentication/Signup.module.css";

// Images
import Logo from "../../images/logoImg.png";

import Footer from "../../common/footer";

const AddChild = () => {
  const history = useHistory();
  let parentName = sessionStorage.getItem("parentName")
    ? sessionStorage.getItem("parentName")
    : "";
  let parentEmail = sessionStorage.getItem("parentEmail")
    ? sessionStorage.getItem("parentEmail")
    : "";
  let parentId = sessionStorage.getItem("parentId")
    ? sessionStorage.getItem("parentId")
    : "";

  useEffect(() => {
    // if (parentName.trim().length < 1) {
    //   history.replace("./signup");
    // }
  }, [history, parentName]);
  return (
    <>
      <div className="add-child-container">
        <div className="add-child-header-container">
          <div
            onClick={() => {
              history.push("./home");
            }}
          >
            <img
              src={Logo}
              alt="Logo"
              className="logo"
            />
          </div>
          <p className="parent-name">Hi {parentName},</p>
          <p className="add-child-info">Please provide child details</p>
        </div>
        <div className="add-child-details-container">
          <p className="add-child-details-info">Child details</p>
          <AddChildForm
            parentEmail={parentEmail}
            parentId={parentId}
            parentName={parentName}
            showImg={true}
            buttonName="Get Started"
          />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AddChild;
