import Logo from "../../images/logoImg.png";

// Images
import Child1 from "../home/images/child-1.png";
import Child12X from "../home/images/child-1@2x.png";
import Child13X from "../home/images/child-1@3x.png";

import './ReportDetail.scss';

const ReportDetails = (props) => {
    return (
        <div className="parent-report-details">
            <div className="image-icon">
                <img
                    src={Logo}
                    srcSet={`${Logo} 1x`}
                    alt="Logo"
                    className={`logo ${window.location.href.search("/home") > 1 ? "cursor-default" : ""
                    }`}
                />
            </div>
            
            <div className="col-md-12 header-details">
                <div className="report-header">Weekly Report</div>
                <div className="report-duration">Duration</div>
                <div className="spent-time">
                    <span>TOTAL HOURS SPENT - 45 HOURS</span>
                    <span>AVERAGE TIME SPENT PER DAY - 45 HOURS</span>
                </div>
            </div>
            <div className="child-details">
            <div  className="child-details-card col-md-12">
                <img
                src={Child1}
                srcSet={`${Child1} 1x, ${Child12X} 2x, ${Child13X} 3x`}
                alt="Child 1"
                className="child-logo"
                />
                <div className="right-side-content">
                    <div className="name-field">Test</div>
                    <div className="user-name">Test</div>
                    <div className="bold-grade">Board: test</div>
                    <div className="bold-grade">Grade: test</div>
                    </div>
                </div>
            </div>
            <div className="subject-table-status">
                <div className="table-body">
                    <span className="row-content"></span>
                    <div className="progressBar">
                        <div className="header" >English</div>
                        <div  className="header">Science</div>
                        <div className="header" >English</div>
                        <div  className="header">Science</div>
                    </div>
                </div>
    
                <div className="table-body">
                    <span className="row-content">Video Content</span>
                    <div className="progressBar">
                        <div className="bar"></div>
                        <div className="bar"></div>
                        <div className="bar"></div>
                        <div className="bar"></div>
                    </div>
                </div>
                <div className="table-body">
                    <span className="row-content">Interactive</span>
                    <div className="progressBar">
                        <div className="bar"></div>
                        <div className="bar"></div>
                        <div className="bar"></div>
                        <div className="bar"></div>
                    </div>
                </div>
                <div className="table-body">
                    <span className="row-content">Book Solution</span>
                    <div className="progressBar">
                        <div className="bar"></div>
                        <div className="bar"></div>
                        <div className="bar"></div>
                        <div className="bar"></div>
                    </div>
                </div>
                <div className="table-body">
                    <span className="row-content">Ask</span>
                    <div className="progressBar">
                        <div className="bar"></div>
                        <div className="bar"></div>
                        <div className="bar"></div>
                        <div className="bar"></div>
                    </div>
                </div>
                <div className="table-body">
                    <span className="row-content">Practice Test</span>
                    <div className="progressBar">
                        <div className="bar"></div>
                        <div className="bar"></div>
                        <div className="bar"></div>
                        <div className="bar"></div>
                    </div>
                </div>
            </div>
            <div className="course-details-report">
                <div className="header">
                    <span>English</span>
                    <span>{'>'}</span>
                    <span>Chapter Completed: 3</span>
                    <span>Inprogress: 3</span>
                    <span>Interactive: 3</span>
                    <span>Ask: 3</span>
                </div>
                <div className="chapter-header">
                    <span>Chapter Names</span>
                    <span>Status</span>
                    <span>Start Date</span>
                    <span>End Date</span>
                    <span>Total Time</span>
                </div>
                <div className="chapter-list-details">
                    <div className="chapter-list-single">
                        <span>Chapter Names</span>
                        <span>Status</span>
                        <span>Start Date</span>
                        <span>End Date</span>
                        <span>Total Time</span>
                    </div>
                    <div className="feedback">
                        Feedback:
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ReportDetails;