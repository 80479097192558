import ActivityCard from "./ActivityCard";
import MyActivityCard from "./MyActivityCard";

import { Carousel, Form } from "react-bootstrap";
import ScoreCard from "../../images/score-card.svg";
import ActivityImage1 from "../../images/collage-1.png";
import ActivityImage2 from "../../images/collage-2.png";
import ActivityImage3 from "../../images/collage-3.png";
import PerformActivity from "../../images/perform-activity.svg";

const MyActivity = (props) => {
  return (
    <>
      <div className="w-100 activity-date color-black">
        Participating Assignments
      </div>
      <div className="col-md-12 results-container d-flex">
        <div className="col-md-8 p-0">
          {props.activities.activityList &&
            props.activities.activityList.map((data) => {
              return (
                <MyActivityCard
                  className="mt-10-px m-0"
                  subject={data.activitySubject}
                  postedDate={data.activityPostedDate}
                  activityname={data.activityName}
                  submittedDate={data.activitySubmittedDate}
                />
              );
            })}
        </div>
        <div className="col-md-4 activity-details">
          <div className="h-50">
            <div className="d-flex activity-container">
              <div className="activity-holder">
                <div className="d-flex activity-info">
                  <div className="indicator-1"></div>
                  <p className="activity-indicator">
                    Assignment In Progress
                  </p>
                </div>
                <div className="d-flex activity-info">
                  <div className="indicator-2"></div>
                  <p className="activity-indicator">
                    Assingment In Completed
                  </p>
                </div>
              </div>
              <div className="score-container">
                <p className="activity-score">Score</p>
                <div className="position-relative">
                  <img
                    src={ScoreCard}
                    alt="Score Card"
                    className="score-card"
                  />
                  <p className="score">{props.activities.score}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="h-50">
            <img
              src={PerformActivity}
              alt="Children Performing Acitivity"
              className="activity-represenation"
            />
          </div>
        </div>
      </div>
      <div className="w-100 activity-date date-header-underline color-black">
        Completed Assignment list
      </div>
      <div className="w-100 float-right filter-container">
        <Form className="float-right d-flex activity-select">
          <Form.Group controlId="formBasicSelect">
            <Form.Control
              as="select"
              // value={type}
              value=""
              onChange={(e) => {
                console.log("e.target.value", e.target.value);
              }}
            >
              <option value="All Subjects">All Subjects</option>
              <option value="English">English</option>
              <option value="Maths">Maths</option>
              <option value="Science">Science</option>
            </Form.Control>
          </Form.Group>
          <Form.Group controlId="formBasicSelect">
            <Form.Control
              as="select"
              // value={type}
              value=""
              onChange={(e) => {
                console.log("e.target.value", e.target.value);
                // setType(e.target.value);
              }}
            >
              <option value="All Dates">All Dates</option>
              <option value="Today">Today</option>
              <option value="This Week">This Week</option>
              <option value="This Month">This Month</option>
              <option value="This Year">This Year</option>
            </Form.Control>
          </Form.Group>
        </Form>
      </div>
      <div className="w-100">
        <div className="activity-date">27-Aug-2021</div>
        <div className="date-underline"></div>
      </div>
      <div className="col-md-12 activities">
        {props.activities.activityList &&
          props.activities.activityList.map((data) => {
            return (
              <ActivityCard
                description={data.activityDescription}
                dp={data.activityDisplayPicture}
                activityimage={data.activityImage}
                activityname={data.activityName}
                point={data.activityPoints}
                postedDate={data.activityPostedDate}
                subject={data.activitySubject}
                submittedDate={data.activitySubmittedDate}
                destination={data.activityUserDestination}
                username={data.activityUsername}
              />
            );
          })}
      </div>
    </>
  );
};

export default MyActivity;
