import React from "react";
import styled from "styled-components";
import Logo from "../../images/shivani-labs.svg";
// import Logo2x from "../../images/shivani-labs@2x.png";
// import Logo3x from "../../images/shivani-labs@3x.png";
import Facebook from "../../images/facebook.png";
import Instagram from "../../images/instagram.png";
import Youtube from "../../images/youtube.png";
import "./index.css";

const Footer = () => {
  return (
    <RectangleStack>
      <Rectangle className="col-md-12">
        <SimplyDummyTextOfRow className="col-md-12 footer-contents">
          <SimplyDummyTextOf className="col-md-4">
            <ShivaniLabs>
              <img src={Logo} alt="Logo" className="footer-logo" />
            </ShivaniLabs>
            <p className="footer-description">
              We provide valuable educational resources for the CBSE students to
              achieve their academic goals. The portal helps you to learn the
              concepts from very fundamentals which make the students masters in
              every subject. The portal opens you to many opportunities to
              sharpen your academic skills.
            </p>
          </SimplyDummyTextOf>
          <div className="col-md-4 d-flex f-12 pages-list">
            <div className="col-md-6 m-t-45">
              <ul>
                <li>Discover courses</li>
                <li>Explore Features</li>
                <li>Know services</li>
                <li>Live talk</li>
                <li>About us</li>
              </ul>
            </div>
            <div className="col-md-6 m-t-45">
              <ul>
                <li>FAQ</li>
                <li>Support</li>
              </ul>
            </div>
          </div>
          <CopyRightContainer className="col-md-4 f-12 copyright-contents">
            <FollowUsInColumn>
              <FollowUsIn>
                Follow us in
                <img src={Facebook} className="footer-icons" alt="Facebook" />
                <img src={Youtube} className="footer-icons" alt="Youtube" />
                <img src={Instagram} className="footer-icons" alt="Instagram" />
              </FollowUsIn>
            </FollowUsInColumn>
          </CopyRightContainer>
        </SimplyDummyTextOfRow>
        <Copyrights>
          <span>
            Copyright © 2022, <strong>Shivani Technologies LLC - USA</strong>. All Rights Reserved.
          </span>
          <span>
            Product Maintenance and Support by <strong>KonnectGen</strong>
          </span>
        </Copyrights>
      </Rectangle>
    </RectangleStack>
  );
};

const CopyRightContainer = styled.div`
  margin: auto 0px;
`;
const ShivaniLabs = styled.div`
  background-color: transparent;
  color: rgba(174, 174, 174, 1);
  font-size: 0.9rem;
  font-weight: 700;
  font-style: normal;
  margin-bottom: 10px;
`;

const SimplyDummyTextOf = styled.div`
  width: 335px;
  background-color: transparent;
  color: rgba(124, 124, 124, 1);
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
`;

const FollowUsIn = styled.span`
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  color: #6b6b6b;
  margin-top: 30px;
`;

const Copyrights = styled.div`
  margin: 10px 0px 0px 0px;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  color: #6b6b6b;
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
`;

const FollowUsInColumn = styled.div`
  flex-direction: column;
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: end;
`;

const Rectangle = styled.div`
  width: 100%;
  background-color: hsl(29deg 100% 94%);
  flex-direction: column;
  display: flex;
`;

const RectangleStack = styled.div`
  width: 100%;
`;

const SimplyDummyTextOfRow = styled.div`
  flex-direction: row;
  display: flex;
  margin-top: 50px;

`;

export default Footer;
