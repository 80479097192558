import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import AddChildForm from "../../features/dashboard/AddChildForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import ParentHeader from "./ParentHeader";
import ParentFooter from "./ParentFooter";
const ParentAddChild = () => {
  const history = useHistory();
  const loggedInUser = useSelector((state) => state.authentication);

  const ActivitiesHomeHandler = () => {
    history.push("./mywards");
  };
  return (
    <>
      <ParentHeader active="#/parent/mywards" />
      <main className="parent-portal">
        <div className="page-header">
          <div className="parent-icon-container">
            <FontAwesomeIcon
              icon={faChevronLeft}
              className="parent-back-icon"
              onClick={ActivitiesHomeHandler}
            />
          </div>
          <p>Child Details</p>
        </div>
        <div className="page-content add-child-parent">
          <AddChildForm
            parentEmail={loggedInUser.userInfo.email}
            parentId={loggedInUser.userInfo.id}
            parentName=""
            showImg={false}
            buttonName="Add Child"
            buttonClass="add-child-submit"
            selectClass="add-child-select"
            redirectionUrl="../../subscription-list"
          />
        </div>
      </main>
      <ParentFooter />
    </>
  );
};

export default ParentAddChild;
