import React from 'react';

const ChapterBodyContent = (props) => {

    // const LoadBodyContent = props.value.map((content) => {
    //     switch(content.contentType) {
    //         case "_HEAD" :
    //             return <div><h5>{content.content}</h5></div>
    //             break;
    //         case "_BODY" :
    //             return <p>{content.content}</p>
    //             break;
    //         case "_QUES" :
    //             return <p className="question">{content.content}</p>
    //             break;
    //         case "_ANS" :
    //             return <p className="answer">{content.content}</p>
    //             break;
    //         case "HTML" :
    //             return <div dangerouslySetInnerHTML={{__html: content.content}}></div>
    //             break;
    //         case "_IMG" :
    //             let image_ = content.content;
    //             return <img src={image_.default}></img>
    //             break;
    //         default:

    //     }
    //     return "";
    // });
    const LoadBodyContent = () => {
        return <div dangerouslySetInnerHTML={{__html: props.value}}></div>
    }
    return (
        <div className="body-content">
           <div dangerouslySetInnerHTML={{__html: props.value}}></div>
            {/* {props.value && 
                LoadBodyContent
            } */}
        </div>
        
    )
};

export default ChapterBodyContent;