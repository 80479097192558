import React, { useState, useEffect } from "react";

import HomeHeader from "../home/HomeHeader";

import BorderImg from "./image/border.png";
import Fill3 from "./image/fill-3.png";
import HandRise from "./image/rise-hand.png";

import { GetList, GetAddon } from "../../data/subscription";

import FooterGreen from "../../common/footer/footer";

const SubscriptionView = () => {
  const [subscriptionList, setSubscriptionList] = useState([]);
  const [addons, setAddons] = useState([]);
  useEffect(() => {
    setSubscriptionList(GetList());
    setAddons(GetAddon());
  }, []);

  const listView = (ev) => {
    if (!localStorage.getItem("parentName")) {
      window.location.href = "#/signup";
    } else {
      let id = ev.target.id.split("_")[1];
      window.location.href = "#/subscription/" + id;
    }
  };
  return (
    <>
      <HomeHeader />
      <div className="subscription-list">
        <img className="border-img" src={BorderImg} />
        <img className="rise-hand" src={HandRise} />
        <img className="Fill-3" src={Fill3} />
        <h3 className="header">A plan for each one</h3>

        <div className="subscription-group">
          {subscriptionList &&
            subscriptionList.map((value) => {
              return (
                <div key={value.id} className="plan-group">
                  <h4>{value.name}</h4>
                  <p>{value.description}</p>
                  <div className="duration">Duration: {value.duration}</div>
                  <div>
                    <span className="duration">Features: </span>
                    {value.features &&
                      value.features.map((item) => {
                        return (
                          <span className="gray-shaded" key={item.id}>
                            {item.name},{" "}
                          </span>
                        );
                      })}
                  </div>
                  <button
                    onClick={listView}
                    id={"button_" + value.id}
                    className={
                      value.id != 0 ? "disabled plan-button" : "plan-button"
                    }
                  >
                    Choose this plan
                  </button>
                </div>
              );
            })}
        </div>
        <div className="addons-details">
          <h4>Addon's</h4>
          {addons &&
            addons.map((addon) => {
              return (
                <div className="plan-group">
                  <h5>{addon.name}</h5>
                  <p>{addon.description}</p>
                </div>
              );
            })}
        </div>
      </div>
      <FooterGreen />
    </>
  );
};

export default SubscriptionView;
