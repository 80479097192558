import React, { Suspense } from "react";
import "./App.css";
import Header from "./common/header";
import TinymceEditor from "./features/tinymce/TinymceEditor";

// Authentication
import Signup from "./features/authentication/Signup";
import Login from "./features/authentication/Login";
import ParentLogin from "./features/authentication/ParentLogin";
import ForgotPassword from "./features/authentication/ForgotPassword";
import ResetPassword from "./features/authentication/ResetPassword";
import SuccessPage from "./features/subscription/subsuccess";
import SubmitOtp from "./features/authentication/SubmitOtp";

// Dashboard
import Dashboard from "./features/dashboard/dashboard";
import AddChild from "./features/dashboard/AddChild";

// My Profile
import ChangePassword from "./features/myprofile/ChangePassword";
import RegistrationDetails from "./features/myprofile/RegistrationDetails";

//Subscription
import SubscriptionListView from "./features/subscription/listView";
import SubscriptionDetailView from "./features/subscription/detailView";
import SubscriptionView from "./features/subscription/subscription";

// Activities
import Activities from "./features/activities";
import ActivityDescription from "./features/activities/ActivityDescription";
import ActivityUpload from "./features/activities/ActivityUpload";

// Community
import Community from "./features/community";
import Discussion from "./features/community/Discussion";

// Courses
import Courses from "./features/grades/chapter";
import GradeList from "./features/gradeList/gradelist";
import Grades from "./features/gradeList/grades";
import AboutUs from "./features/aboutUs/index";

import Privacy from "./features/home/Privacy";
import Terms from "./features/home/Terms";

import { Interceptor } from "./api/axios";
import { store } from "./common/store";

//Store
import StoreView from "./features/store/storeView";

//Reward
import RewardView from "./features/rewards/rewards";

// Parent Portal
import ParentDashboard from "./features/parent/ParentDashboard";
import ParentAddChild from "./features/parent/ParentAddChild";
import ChildList from "./features/parent/ChildList";
import ChildHistory from "./features/parent/ChildHistory";
import Reports from "./features/parent/Reports";
import ReportDetails from './features/parent/ReportDetail';
import ChildSubscription from "./features/parent/ChildSubscription";

// Scroll to Top
import ScrollToTop from "./common/ScrollToTop";
/* Router */
import {
  HashRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";

import Loader from "./common/loader/index";

// Home
import Home from "./features/home";
import DiscoverCourses from './features/home/DiscoverCourses';
import BuyCourse from './features/home/BuyCourses';
import DetailView from './features/home/BuyCourse/detailView';
import CartView from './features/home/BuyCourse/cartView';
const Volunteer = React.lazy(() => import('./features/Volunteer/Index'));


function App() {
  Interceptor(store);

  return (
    <div className="App">
      <div className="loader">
        <div className="loader-img"></div>
      </div>
      <div className="spinner">
        <div className="spinner-img"></div>
      </div>
      <Suspense fallback={<Loader />}>
        <Router>
          <ScrollToTop />
          <Switch>
            <Route path="/about-us" key="about-us" exact>
              <AboutUs />
            </Route>
            <Route path="/privacy" key="privacy" exact>
              <Privacy />
            </Route>
            <Route path="/terms" key="terms" exact>
              <Terms />
            </Route>
            <Route path="/login" key="login" exact>
              <Login />
            </Route>
            <Route path="/signup" key="signup" exact>
              <Signup />
            </Route>
            <Route path="/volunteer" key="contact-us" exact>
              <Volunteer />
            </Route>
            <Route path="/forgot-password" key="forgot-password" exact>
              <ForgotPassword />
            </Route>
            <Route
              path="/grade"
              key="gradelist"
              component={GradeList}
              exact
            ></Route>
            <Route
              path="/grades"
              key="gradeslist"
              component={Grades}
              exact
            ></Route>
            <Route path="/submit-otp/:userid" key="submit-otp" exact>
              <SubmitOtp />
            </Route>
            <Route path="/reset-password/:id" key="reset-password" exact>
              <ResetPassword />
            </Route>
            <Route path="/editor" key="tinymceeditor" exact>
              <Header />
              <TinymceEditor />
            </Route>
            <Route path="/dashboard" key="dashboard" exact>
              <Dashboard />
            </Route>
            <Route path="/add-child" key="add-child" exact>
              <AddChild />
            </Route>
            <Route path="/change-password" key="change-password" exact>
              <ChangePassword />
            </Route>
            <Route path="/registration-details" key="registration-details" exact>
              <RegistrationDetails />
            </Route>
            <Route path="/activities" key="activities" exact>
              <Activities />
            </Route>
            <Route path="/activity/:id" key="activity-description" exact>
              <ActivityDescription />
            </Route>
            <Route path="/activity-upload/:id" key="activity-upload" exact>
              <ActivityUpload />
            </Route>
            <Route path="/discover-courses" key="discover-courses" exact>
              <DiscoverCourses />
            </Route>
            <Route path="/buy-courses/:view" key="buy-courses" exact>
              <BuyCourse />
            </Route>
            <Route path="/detail/:categoryid/:id/:view" key="detail-courses" exact>
              <DetailView />
            </Route>
            <Route path="/cart" key="cart" exact>
              <CartView />
            </Route>
            
            <Route path="/home" key="home" exact>
              <Home />
            </Route>
            <Route path="/parent" key="parent-login" exact>
              <ParentLogin />
            </Route>
            <Route path="/parent/home" key="parent-dashboard" exact>
              <ParentDashboard />
            </Route>
            <Route path="/parent/mywards/:id" key="child-history" exact>
              <ChildHistory />
            </Route>
            <Route path="/parent/subscription" key="parent-subscription" exact>
              <ChildSubscription />
            </Route>
            <Route path="/parent/mywards" key="mywards" exact>
              <ChildList />
            </Route>
            <Route path="/parent/reports" key="mywards" exact>
              <Reports />
            </Route>
            <Route path="/reportdetails" key="reportdetails" excat>
              <ReportDetails />
            </Route>
            <Route path="/parent/add-child" key="parent-add-child" exact>
              <ParentAddChild />
            </Route>
            <Route path="/subscription-list" key="subscription" exact>
              <SubscriptionListView />
            </Route>
            <Route path="/success/:id" key="successpage" exact>
              <SuccessPage />
            </Route>
            <Route path="/subscription/:id" key="subscription-detail" exact>
              <SubscriptionDetailView />
            </Route>
            <Route path="/services" key="features" exact>
              <SubscriptionView />
            </Route>
            <Route path="/store" key="store" exact>
              <StoreView />
            </Route>
            <Route path="/reward" key="reward" exact>
              <RewardView />
            </Route>
            <Route
              path="/courses/:id"
              key="courses"
              component={Courses}
              exact
            ></Route>
            <Route path="/community" key="community" exact>
              <Community />
            </Route>
            <Route path="/community/:id" key="discussion" exact>
              <Discussion />
            </Route>
            <Route path="*" key="others">
              <Redirect to="/home" />
            </Route>
          </Switch>
        </Router>
      </Suspense>
    </div>
  );
}

export default App;
