import { createSlice } from "@reduxjs/toolkit";

const initialChildState = { info: {} };

const childInfoSlice = createSlice({
  name: "Child",
  initialState: initialChildState,
  reducers: {
    addInfo(state, action) {
      state.info = action.payload;
    },
    removeInfo(state) {
      state.info = {};
    },
  },
});

export const childInfoActions = childInfoSlice.actions;
export default childInfoSlice.reducer;
