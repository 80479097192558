import React, { useEffect, useState } from "react";
import axios from "../../api/axios";
import HomeHeader from "../home/HomeHeader";

import { GetSubjectURL, GetAllCourse, EnrollCourses, PostPayment } from "../../urls";

import { GetListByID } from "../../data/subscription";

import Footer from "../../common/footer";
import { useParams, useHistory } from "react-router";

const SubscriptionDetailView = () => {
  const history = useHistory();
  let childSession = JSON.parse(sessionStorage.getItem("child"));
  const [subscription, setSubscription] = useState([]);
  const [courseList, SetCourseList] = useState([]);
  const [error, setError] = useState(false);
  const param = useParams();

  const getCourseListHandler = async () => {
    let GradeCategories = [], coursesItem = [], child = childSession;


    await axios.get(GetSubjectURL(process.env.REACT_APP_MOODLE_TOKEN)).then((res) => {
      GradeCategories = res.data.filter((data) => data.parent == child.gradeid);
    });


    await axios.get(GetAllCourse(process.env.REACT_APP_MOODLE_TOKEN)).then((courses) => {
      if (courses.data.length > 0) {
        GradeCategories.forEach((subject) => {
          let chapters = [];
          chapters = courses.data.filter(course => course.categoryid == subject.id);
          coursesItem = [...coursesItem, ...courses.data.filter(course => course.categoryid == subject.id)]
        })
      }
    });

    SetCourseList(coursesItem);
  }

  useEffect(() => {
    getCourseListHandler()
  }, []);

  useEffect(() => {
    setSubscription(GetListByID(param.id)[0]);
  }, [param]);

  useEffect(() => {
    if (childSession == null) {
      history.replace("../signup");
    }
  }, [history, childSession]);

  const PaymentSubmitHandler = async () => {
    setError(false);
    let enrolments = "";

    courseList.map((course, index) => {
      enrolments += `&enrolments[${index}][courseid]=${course.id}&enrolments[${index}][roleid]=5&enrolments[${index}][userid]=${childSession.childid}`;
    })

    await axios.post(PostPayment(childSession.childid)).then(
      (response) => {
        if (response.data == "Payment Updated Successfully") {
          axios.get(`${EnrollCourses(process.env.REACT_APP_MOODLE_TOKEN)}${enrolments}`).then((res) => {
            if (res.status == 200) {
              history.push("/success/" + param.id);
            }
          });
        } else {
          setError(true);
        }
      },
      (error) => {
        setError(true);
      }
    );
  }

  return (
    <>
      <HomeHeader />
      <div className="sub-detail-view">
        <h3>Your order details</h3>
        <div className="sub-detail-view-group">
          <div className="wrapper-header ">
            <span className="plan-name bolder-text size-20">
              {subscription.name}
            </span>
            <span className="duration bolder-text size-16">
              {subscription.duration}
            </span>
            <span className="amount">{subscription.price}</span>
          </div>
          <span className="size-14 plan-details">
            SUBSCRIPTION PLAN DETAILS
          </span>
          <div className="wrapper">
            <div>
              {subscription.boards &&
                subscription.boards.map((val) => {
                  return <div>{val}</div>;
                })}
            </div>
            <div>ALL SUBJECTS</div>
          </div>

          {subscription.userGroups && subscription.userGroups.length > 0 && (
            <div className="wrapper">
              <div>USER GROUPS</div>
              <div className="sub-wrapper">
                {subscription.userGroups &&
                  subscription.userGroups.map((value) => {
                    return <span>{value}</span>;
                  })}
              </div>
            </div>
          )}

          <div className="wrapper">
            <div>FEATURES</div>
            <div className="sub-wrapper">
              {subscription.features &&
                subscription.features.map((value) => {
                  return <span>{value.name}</span>;
                })}
            </div>
          </div>
          <div className="wrapper">
            <div>ADDED VALUE</div>
            <div className="sub-wrapper">
              {subscription.addedValue &&
                subscription.addedValue.map((value) => {
                  return <span>{value}</span>;
                })}
            </div>
          </div>
          <div className="gst-details">
            <div className="label">GST - 18.5%</div>
            <div className="value">0</div>
          </div>
          <div className="total-count">
            <span className="label">Total</span>
            <span className="value">0</span>
          </div>
        </div>
        {error && <p className="error-statement">Payment is not Successfull</p>}
        <div className="payment-wrapper">
          <button
            onClick={PaymentSubmitHandler}
            className="payment-btn"
          >
            Confirm Payment
          </button>
        </div>

        <Footer />
      </div>
    </>
  );
};

export default SubscriptionDetailView;
