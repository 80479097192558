import { Link } from "react-router-dom";

const MyProfile = (props) => {
    return (
        <div className="page-container primary-background">
            <div className="section-heading">My Profile</div>
            <div className="d-flex">
                <div className="w-20-per card mr-10 p-0">
                    <ul className="profile-tab">
                        <li className={props.active === "Registration Details" ? "active-tab" : ""} ><Link to="/registration-details">Registration Details</Link></li>
                        <li className={props.active === "Change Password" ? "active-tab" : ""} ><Link to="/change-password">Change Password</Link></li>
                        <li>Logout</li>
                    </ul>
                </div>
                <div className="col card p-30">
                    <div className="page-header">{props.active}</div>
                    {props.children}
                </div>
            </div>
        </div>
    )
}

export default MyProfile;