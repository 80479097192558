import "bootstrap/dist/css/bootstrap.min.css";
// import "./index.css";
import About from "../../images/boy.jpg";
import Logo from "../../images/bitmap.jpg";
import Groups from "../../images/group.jpg";
import Team from "../../images/team.jpg";
import Learning from "../../images/learning.jpg";
import Navabar from "../aboutUs/navbar";
function AboutUs() {
  return (
    <div className="App">
      <div className="header fixed-top">
        <Navabar></Navabar>
      </div>
      <br />
      <br />
      <br />
      <div className="row">
        <p>
          <small>HOME /</small> <span className="text-label">ABOUT US</span>
        </p>
      </div>
      <div className="row">
        <div className="col-8">
          <h1>About </h1>
          <h3 className="sub-title">Our Heart & Soul</h3>
          <br />
          <p>
            Shivani Labs is a platform for the learners to chase their dreams
            fear lessly. Digital technology makes us to achieve new things every
            day. Our educational portal individuals from all over the world to
            learn the courses and attempt activities. We provide an effective
            learning management where the learners can spot their progress and
            can upgrade themselves.
          </p>
          <p>
            Learning methodology has been highly improvised due to the
            technology development. Each day fills with new concepts, theories
            and discoveries, missions adn new opportunities. we will be your
            companion to find the right hidden treasures of opportunities and
            knowledge in this world.
          </p>
        </div>
        {/* <div className="col-1"></div> */}
        <div className="col-4">
          <img
            className="rounded-circle rounded-sm rounded-border mt-6"
            alt="Shivani Labs"
            src={About}
            id="boyImg"
            data-holder-rendered="true"
          ></img>
        </div>
        <div className="col-4"></div>
        {/* <div className="col-1"></div> */}
        <div className="col-4">
          <img
            className="rounded-circle rounded-sm rounded-border mt-6"
            alt="Shivani Labs"
            src={About}
            height="380px"
            width="380px"
            data-holder-rendered="true"
          ></img>
        </div>
        <div className="col-4"></div>
        {/* </div> */}
        <div className="row">
          <div class="col-3"></div>
          <div className="col-2">
            <div className="circleBase type1"></div>
            <div className="circleBase type2"> </div>
            <div className="circleBase type3"></div>
            <div className="circleBase type4">
              <p className="label">
                <h2 className="title-label">Why Shivani labs?</h2>
                We provide valuable educational resources for the CBSE students
                to achieve their academic goals. The portal helps you to learn
                the concepts from very fundamentals which make the students
                masters in every subject. The portal opens you to many
                opportunities to sharpen your academic skills.We guide you to be
                a good teacher to become good students. Our frictionless
                navigation experience engages the user and aims to establish a
                technologically aware society. It bridges the gap among
                students, educators, parents and administration, in order to
                increase interactivity, communication and to facilitate
                collaborative teaching and research.
              </p>
            </div>
          </div>
        </div>
        <br />
        <br />
        <div className="row">
          <div className="col-4">
            <h2>Why learning? </h2>
            <h3 className="sub-title">Our begining...</h3>&nbsp;
            <p>
              Learning is much easier and interesting if there is game involved
              in the process. Micro modules are formed in order to make the
              students know and understand the concepts. We have created games
              which they play, they learn and they win points. Making learning
              easier, we teach through play more that makes them learn more. As
              per researches the maze and puzzles helps to stimulate the brain
              tissues. This increases our concentration and also makes kids more
              knowledgeable. The site also introduces different exercises
              through the games.
            </p>
          </div>
          {/* <div className="col-1">
            </div> */}
          <div className="col-5">
            <img
              className="square "
              alt="Shivani Labs"
              src={Team}
              id="ourHeartSoul"
            ></img>
          </div>
          <div className="col-4">
            <h2>Our Heart & Soul</h2>
            <h3 className="sub-title">Never stops...</h3>
            <br />
            <p>
              The learners are encouraged to learn more. Our educational portal
              engages user with inter esting facts, quizzes and animated videos.
              We showcase the active participants from across the country and
              their progress on regular basis on our portal. Our testimonials
              help the students, teachers and the par ents to understand and
              learn about the experience of fellow learners
            </p>
            <p>
              We support teacher-learner collaboration and for us, the for emost
              is the engagement of our user. We provide a platform where the
              learners are free to share their lear ning experience with others
            </p>
          </div>
        </div>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <div className="row">
          <div className="col-4">
            <h2>Our Team</h2>
            <h3 className="sub-title">One mind...</h3>
            <br />
            <p>
              Once you join us, there is no looking back. We will surely engage
              you in the variety of content we offer . We offer you to convert
              your learning credits into rewards. Giving rewards is our way of
              showing encouragement to the talented learners. While at tempting
              quizzes and involving yourself in the activities will help you
              learn points which can be treated as rewards.{" "}
            </p>
            <p>
              We provide various membership offers in our educational challenge,
              the need of the hour which is to maximize access to the infor
              mation resources required to improve existing teaching, advanced
              learning and research practices in education.{" "}
            </p>
          </div>
          <div className="col-7">
            <img
              className="square square-lg group-logo"
              alt="Shivani Labs"
              src={Groups}
            ></img>
          </div>
        </div>
        <br />
        <br />
        <br />
        <div className="row">
          <div className="">
            <img alt="Shivani Labs" src={Logo} id="logo"></img>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
