import React from 'react';
import GradeListOne from './course_1_copy.jsx';
import './gradelist.scss';
import GetSubjectChapters from "../../data/gradeList";
import SubjectHeader from '../common/subjectHeader.jsx';
import CourseList from './courselist';
import ThinkImg from './images/boy.png';
import _ from "lodash";

import FooterGreen from "../../common/footer/footer";

import axios from "../../api/axios";
import { GetToken } from "../../api/ApiServices"
import * as type from '../../urls';
class Grades extends React.Component {
    constructor(props) {
        super(props);
        const { match: { params } } = props;
        const qs = new URLSearchParams(props.location.search);
        this.state = {
            gradeId: qs.get('grade') || 1,
            subjectId: qs.get('subject') || 1,
            subjectList: [],
            filterList: [],
            gamemode: 'checked'
        }
    }

    componentDidMount() {
        // this.getSubjectChapters(this.state.gradeId);
        this.getSubject(this.state.gradeId);
        
        // this.setState({ subjectList: GetSubjectChapters(1) }, () => {
        //     console.log(this.state.subjectList);
        // });
    }
    getSubjectChapters(id) {

        return axios
            .get(type.GetSubjectListURL(window.token, id));

    }
    getSubject(id) {
        if (!window.token) {
            window.token = GetToken().token;
           this.getSubjectList(id)
           //this.getSubjectList();
        } else {
           this.getSubjectList(id);
           //this.getSubjectList();
        }
    }

    getSubjectList(id) {
        axios.get(type.GetSubjectURL(window.token)).then((res) => {
          this.setState({ subjectListGroup: res?.data }, () => {
            this.getCourseContent();
          });
        });
      }

    getCourseContent() {
        let childToken = JSON.parse(localStorage.getItem("childToken"));
    
        axios.get(type.GetEnrollSubject(window.token, childToken?.id)).then((res) => {
          let temp = [], _tmp = [];
          let categoryGroup = _.uniqBy(res?.data, 'category');
          //this.getTimeLineClass();
    
          categoryGroup.forEach((val) => {
            _tmp.push(val.category);
          });
          _tmp.forEach((tmp_val) => {
            this.state?.subjectListGroup?.forEach((subj_list) => {
              if (tmp_val === subj_list.id) {
                temp.push(subj_list);
              }
            })
          })
          temp.forEach((subj) => {
            subj.chapters = {};
            let _chapter = _.filter(res?.data, (cat) => {
              return cat.category === subj.id;
            });
            subj.chapters = _.sortBy(_chapter, 'id');
          });
          this.setState({ subjectList: temp });
        });
    
      }

    // getSubjectList(id) {
    //     axios
    //         .get(type.GetSubjectURL(window.token))
    //         .then(
    //             (res) => {
    //                 let temp = [];
    //                 res.data.forEach(val => {
    //                     if (val.parent == id) {
    //                         temp.push(val);
    //                     }

    //                 });
    //                 temp.forEach(subj => {
    //                     this.getSubjectChapters(subj.id).then((chapterList) => {
    //                         subj.chapters = {};
    //                         subj.chapters = chapterList.data.courses;
    //                     });
    //                 });
    //                 setTimeout(() => {
    //                     console.log(temp);
    //                     this.setState({ subjectList: temp });
    //                 }, 500);


    //             }
    //         )
    // }
    handleToggle(event) {
        if (this.state.gamemode) {
            this.setState({ gamemode: '' });
        } else {
            this.setState({ gamemode: 'checked' });
        }
    }
    render() {
        return (
            <div className="grade-container">
                <div className=" course-container">
                    <SubjectHeader profileColor="green" />
                </div>
                <div className="grade-list">
                    <div className="grade-list-right">
                        <div className="grade-list-slider-group">

                            <div className="grade-list-slider">

                                <label className="switch">
                                    <input onClick={this.handleToggle.bind(this)} type="checkbox" {...this.state.gamemode} />
                                    <span className="slider round"></span>
                                </label>
                            </div>
                            <span className="grade-list-slider-label">Game Mode: </span>
                        </div>
                        <CourseList subjectList={this.state.subjectList && this.state.subjectList[0] && this.state.subjectList[0].chapters?.slice(10)  } />
                        {/* <GradeListOne gamemode={this.state.gamemode} /> */}
                    </div>

                </div>
                <FooterGreen />
            </div>


            //<GradeListOne />
        )
    }

}

export default Grades;