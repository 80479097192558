import React from 'react';
import cx from 'classnames';
import SliderContext from './context'
import ShowDetailsButton from './ShowDetailsButton'
import Mark from './Mark'
import './Item.scss'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGraduationCap } from '@fortawesome/free-solid-svg-icons';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';

const Item = ({ movie, ix }) => (
  <SliderContext.Consumer>
    {({ onSelectSlide, currentSlide, elementRef }) => {
      const isActive = currentSlide && currentSlide.id === movie.id;

      return (
        <div onClick={() => window.location.href = `#/courses/${movie.id}?chapter=${movie.id}` }
          ref={elementRef}
          className={cx('item course-color-'+ (ix % 10), {
            'item--open': isActive,
          })}
        >
          <FontAwesomeIcon  icon={faGraduationCap}  />
          <div className='details'>
            <div className='slot-left'></div>
            <p >{movie.displayname}</p>
            <div className="progress-list" style={{ width: 40, height: 40 }}>
              <CircularProgressbar value={movie?.progress} text={`${Number(movie?.progress).toFixed(0)}%`} styles={buildStyles({
                textColor: "black",
                pathColor: "#e5722e",
                trailColor: "#ccc"
              })} />
            </div>
           
          </div>
          <ShowDetailsButton onClick={() => onSelectSlide(movie)} />
          {isActive && <Mark name={movie.displayname} desc={movie.summary} />}
        </div>
      );
    }}
  </SliderContext.Consumer>
);

export default Item;
