import React from "react";
import { Bar } from "react-chartjs-2";

const data = {
  labels: ["MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN"],
  datasets: [
    {
      label: "# of Votes",
      data: [12, 19, 3, 5, 2, 3, 5],
      backgroundColor: [
        "#43a026",
        "#43a026",
        "#43a026",
        "#43a026",
        "#43a026",
        "#43a026",
        "rgba(206, 210, 206, 0.26)",
      ],
      borderWidth: 0,
      barThickness: 30,
      maxBarThickness: 30,
    },
  ],
};

var options = {
  plugins: {
    legend: {
      display: false,
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
    },
    y: {
      ticks: {
        beginAtZero: true,
        stepSize: 5,
      },
      grid: {
        display: false,
      },
    },
  },
};
const PerformanceChart = () => (
  <>
    <Bar data={data} width={500} height={200} options={options} />
  </>
);

export default PerformanceChart;
