import { useState } from "react";
import { Nav } from "react-bootstrap";
import PerformanceChart from "./PerformanceChart";
import InputContainer from "../../common/inputs/InputContainer";

let subjectOptions = [
  {
    label: "English",
    value: "English",
  },
  {
    label: "Maths",
    value: "Maths",
  },
  {
    label: "Science",
    value: "Science",
  },
];

let timeLineOptions = [
  {
    label: "This Week",
    value: "This Week",
  },
  {
    label: "This Month",
    value: "This Month",
  },
  {
    label: "This Year",
    value: "This Year",
  },
];

const ChidPerformance = () => {
  const [tab, setTab] = useState("overall");
  const [Gender, SetGender] = useState({
    value: "",
    error: false,
    errorText: "",
  });
  const TabChangeHandler = (tabName) => {
    setTab(tabName);
  };

  const GenderHandler = (value) => {
    SetGender({ value: value, error: false });
  };
  return (
    <div className="performance-container">
      <Nav variant="tabs" defaultActiveKey={""}>
        <Nav.Item>
          <p
            className={`header-name ${tab == "overall" ? "active" : ""}`}
            onClick={() => {
              TabChangeHandler("overall");
            }}
          >
            Overall
          </p>
        </Nav.Item>
        <Nav.Item>
          <p
            className={`header-name ${tab == "subject" ? "active" : ""}`}
            onClick={() => {
              TabChangeHandler("subject");
            }}
          >
            Subject
          </p>
        </Nav.Item>
      </Nav>
      <div className="performance-chart-container">
        {tab == "subject" && (
          <div className="col-md-12 d-flex performance-change">
            <InputContainer
              type="select"
              name="gender"
              labelName=""
              labelClass="add-child-input-label"
              placeholder=""
              options={subjectOptions}
              onChange={GenderHandler}
              error={Gender.error}
              errorText={Gender.errorText}
              disabled={false}
              className="col-md-2 performance-input"
            />
            <InputContainer
              type="select"
              name="gender"
              labelName=""
              labelClass="add-child-input-label"
              placeholder=""
              options={timeLineOptions}
              onChange={GenderHandler}
              error={Gender.error}
              errorText={Gender.errorText}
              disabled={false}
              className="col-md-2 performance-input"
            />
          </div>
        )}
        <div className="performance-chart-section">
          <PerformanceChart />
        </div>
      </div>
    </div>
  );
};

export default ChidPerformance;
