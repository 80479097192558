import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Carousel } from "react-bootstrap";
import "./index.css";
import "./ActivityDescription.css";

import ActivityHeader from "./ActivityHeader";
import ActivityCompletedCard from "./ActivityCompletedCard";
import GetSubjectChapters from "../../data/gradeList";
import SubjectHeader from "../common/subjectHeader.jsx";

import ActivityImage1 from "../../images/collage-1.png";
import ActivityImage2 from "../../images/collage-2.png";
import ActivityImage3 from "../../images/collage-3.png";

import activityData from "../../data/activity.js";

const ActivityDescription = () => {
  const params = useParams();
  const [gradeId, SetGradeId] = useState([]);
  const navigationChapterGroup = () => {};
  useEffect(() => {
    SetGradeId(GetSubjectChapters("1"));
  }, []);

  return (
    <div className="course-container">
      <SubjectHeader
        subjectList={gradeId}
        navigationChapterGroup={navigationChapterGroup}
        headerColour="secondary-header-background"
        profileColor="orange"
      />
      <div className="page-container secondary-background pl-20">
        <ActivityHeader
          name="Oscar wild - Happy princes"
          subject="English"
          postedDate="27-Aug-2021"
        />
        <div className="col-md-12 pl-30">
          <div className="m-0">
            {activityData.map((data) => {
              if (data.id == params.id) {
                return (
                  <ActivityCompletedCard
                    {...data}
                    completed_activity={true}
                    className="w-100 m-0"
                    status="completed"
                    grade="5th Grade"
                  />
                );
              }
            })}
          </div>
          <div className="p-10 col-md-11 activity-images">
            <Carousel fade>
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src={ActivityImage1}
                  alt="First slide"
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src={ActivityImage2}
                  alt="Second slide"
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src={ActivityImage3}
                  alt="Third slide"
                />
              </Carousel.Item>
            </Carousel>
          </div>
        </div>
        <div className="col-md-12 pl-30 description-details">
          simply dummy text of the printing and typesetting industry. Lorem
          Ipsum has been the industry's standard dummy text ever since the
          1500s, when an unknown printer took a galley of type and scrambled it
          to make a type specimen book. simply dummy text of the printing and
          typesetting industry. Lorem Ipsum has been the industry's standard
          dummy text ever since the 1500s, when an unknown printer took a galley
          of type and scrambled it to make a type specimen book. simply dummy
          text of the printing and typesetting industry. Lorem Ipsum has been
          the industry's standard dummy text ever since the 1500s, when an
          unknown printer took a galley of type and scrambled it to make a type
          specimen book. simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard dummy text ever
          since the 1500s, when an unknown printer took a galley of type and
          scrambled it to make a type specimen book. simply dummy text of the
          printing and typesetting industry. Lorem Ipsum has been the industry's
          standard dummy text ever since the 1500s, when an unknown printer took
          a galley of type and scrambled it to make a type specimen book. simply
          dummy text of the printing and typesetting industry. Lorem Ipsum has
          been the industry's standard dummy text ever since the 1500s, when an
          unknown printer took a galley of type and scrambled it to make a type
          specimen book. simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard dummy text ever
          since the 1500s, when an unknown printer took a galley of type and
          scrambled it to make a type specimen book. simply dummy text of the
          printing and typesetting industry. Lorem Ipsum has been the industry's
          standard dummy text ever since the 1500s, when an unknown printer took
          a galley of type and scrambled it to make a type specimen book. simply
          dummy text of the printing and typesetting industry. Lorem Ipsum has
          been the industry's standard dummy text ever since the 1500s, when an
          unknown printer took a galley of type and scrambled it to make a type
          specimen book.
        </div>
      </div>
    </div>
  );
};

export default ActivityDescription;
