import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

// API
import axios from "../../api/axios";
import { ApiServices } from "../../api/ApiServices";

import { Link } from "react-router-dom";
import { Nav, Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";

// Redux Actions
import { authenticationActions } from "../../reducers/authenticationReducers";
import { parentInfoActions } from "../../reducers/parentInfoReducers";
import { childInfoActions } from "../../reducers/childInfoReducers";

// Images
import Logo from "../../images/logo.png";
import ShortLogo from "../../images/short-logo.svg";
import Logo2X from "../../images/logo@2x.png";
import Logo3X from "../../images/logo@3x.png";
import ProfileTemp from "../../images/profile-temp.webp";
import ProfileTemp2X from "../../images/profile-temp@2x.webp";
import ProfileTemp3X from "../../images/profile-temp@3x.webp";

// CSS
import "./ParentHeader.scss";

let parentHeaderList = [
  {
    name: "Dashboard",
    url: "#/parent/home",
    disabled: false,
  },
  {
    name: "My Wards",
    url: "#/parent/mywards",
    disabled: false,
  },
  {
    name: "Events",
    url: "#/parent/events",
    disabled: true,
  },
  {
    name: "Subscription",
    url: "#/parent/subscription",
    disabled: false,
  },
  {
    name: "Support",
    url: "#/parent/support",
    disabled: true,
  },
  {
    name: "Reports",
    url: "#/parent/reports",
    disabled: false,
  },
  {
    name: "Notification",
    url: "#/parent/notification",
    disabled: true,
  },
];
const ParentHeader = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [redirect, SetRedirect] = useState("");
  const LoggedInfo = useSelector((state) => state.authentication);

  const RedirectHandler = () => {
    if (LoggedInfo.userInfo.token) {
      history.push("../parent/home");
    } else {
      history.replace("./parent");
    }
  };

  const LogoutHandler = () => {
    dispatch(authenticationActions.logout());
    dispatch(parentInfoActions.removeInfo());
    localStorage.removeItem("parentToken");
    history.push("/");
  };

  useEffect(() => {
    const navigate = async () => {
      let parentToken = JSON.parse(localStorage.getItem("parentToken"));

      if (
        parentToken &&
        parentToken != "" &&
        parentToken.id &&
        !LoggedInfo.userInfo.token
      ) {
        let parentData = await axios.get(`${ApiServices("getuserBasedonId")}&wstoken=${parentToken.token}&userid=${parentToken.id}`).then(
          (response) => {
            dispatch(
              authenticationActions.login({
                token: parentToken.token,
                email: response.data.parentdetails[0].email,
                username: response.data.parentdetails[0].username,
                id: parentToken.id,
                role: "parent",
              })
            );
            dispatch(
              childInfoActions.addInfo({ ...response.data.childinfo })
            );
            dispatch(parentInfoActions.addInfo({ ...response.data.parentdetails[0] }));
          },
          (error) => {
            console.log(error);
          }
        );
      } else if (!LoggedInfo.userInfo.token && !parentToken) {
        history.push("/");
      }
    }
    navigate();
  }, [axios]);

  return (
    <nav className="parent-header-container">
      <div className="home-header">
        <div
          className="col-md-3"
          onClick={() => {
            RedirectHandler();
          }}
        >
          <img
            src={Logo}
            srcSet={`${Logo} 1x, ${Logo2X} 2x, ${Logo3X} 3x`}
            alt="Logo"
            className="logo desktop-show"
          />
          <img
            src={ShortLogo}
            alt="Logo"
            className="logo short-logo mobile-show"
          />
        </div>
        <div className="col-md-8 p-0">
          <Nav
            justify
            variant="tabs"
            className="desktop-items-show"
            defaultActiveKey={props.active}
          >
            {parentHeaderList &&
              parentHeaderList.map((header) => {
                return (
                  <Nav.Item key={header.name}>
                    <Nav.Link href={header.url} disabled={header.disabled}>
                      <p className="header-name">{header.name}</p>
                    </Nav.Link>
                  </Nav.Item>
                );
              })}
          </Nav>
        </div>
        <div className="col-md-1 header-redirects">
          <img
            src={ProfileTemp}
            srcSet={`${ProfileTemp} 1x, ${ProfileTemp2X} 2x, ${ProfileTemp3X} 3x`}
            alt="Logo"
            className="profile-temp mobile-show"
          />
          <Dropdown className="d-inline mx-2 sign-dropdown icon-margin text-align-end">
            <Dropdown.Toggle
              id="dropdown-autoclose-true"
              className="dropdown-parent"
            >
              <FontAwesomeIcon
                icon={faBars}
                className="hamburger-icon mobile-show"
              />
              <img
                src={ProfileTemp}
                srcSet={`${ProfileTemp} 1x, ${ProfileTemp2X} 2x, ${ProfileTemp3X} 3x`}
                alt="Logo"
                className="profile-temp desktop-show"
              />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {parentHeaderList &&
                parentHeaderList.map((header) => {
                  return (
                    <Dropdown.Item
                      className="d-block d-sm-block d-md-block d-lg-block d-xl-none"
                      key={header.name}
                      href={header.url}
                      disabled={header.disabled}
                    >
                      {header.name}
                    </Dropdown.Item>
                  );
                })}
              <Dropdown.Item
                onClick={() => {
                  LogoutHandler();
                }}
              >
                Logout
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </nav>
  );
};

export default ParentHeader;
