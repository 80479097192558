import { useState, useEffect, useRef, useCallback, Fragment } from "react";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faChevronCircleDown } from "@fortawesome/free-solid-svg-icons";
import useInView from "react-cool-inview";
import { differenceBy, escape } from "lodash";

// Template
import InputContainer from "../../common/inputs/InputContainer";
import Loader from "../../common/loader/index";
import LoaderDots from "../../common/loader/LoaderDots";

//API
import axios from "../../api/axios";
import { ApiServices } from "../../api/ApiServices";

// Images
import SendIcon from "./images/send-icon.webp";
import SendIcon2X from "./images/send-icon@2x.webp";
import SendIcon3X from "./images/send-icon@3x.webp";
import ChildImage from "../../images/profile-picture-temp.svg";

// CSS
import "./Discussion.scss";

const Messages = (props) => {
    const [search, SetSearch] = useState({
        value: "",
        error: false,
        errorText: "",
    });
    const [textMessage, SetTextMessage] = useState("");
    const [allPosts, SetAllPosts] = useState([]);
    const [newPosts, SetNewPosts] = useState([]);
    const [membersList, SetMembersList] = useState([]);
    const [chatSessionId, SetChatSessionId] = useState("");
    const messageRef = useRef();
    const newmessageRef = useRef();
    const [limit, SetLimit] = useState(0);
    const [start, SetStart] = useState(0);
    const [showLoader, SetShowLoader] = useState(true);
    const [noNewPost, SetNoNewPost] = useState(false);
    const [lastPost, SetLastPost] = useState({});
    const [showMessageCount, SetShowMessageCount] = useState(false);
    const [userInfo, SetUserInfo] = useState({});

    let interval;

    const SearchHandler = (event) => {
        SetShowLoader(true);
        SetSearch({ value: event.target.value, error: false });
        clearInterval(interval)
        if (event.target.value !== "") {
            axios.get(`${ApiServices("getSearchChatMessagesWithLimit")}${userInfo.childId}&search=${event.target.value}&limitfrom=${limit}&limitnum=${(1000)}`).then((response) => {
                SetAllPosts(response.data.messages);
                SetMembersList(response.data.members);
                SetShowLoader(false);
            });
        } else {
            if (start > 0) {
                SetAllPosts([]);
                SetStart(0);
            }
        }
    };

    // Redux Variables
    // const userInfo = useSelector((state) => state.childInfo);

    const GetAllPost = useCallback(() => {
        axios.get(`${ApiServices("getChatMessagesWithLimit")}${props.id}&currentuserid=${userInfo.childId}&limitfrom=${limit}&limitnum=${(10)}`).then((response) => {
            if (response.data.messages && response.data.messages.length > 0) {
                SetLastPost(response.data.messages[response.data.messages.length - 1]);
                let totalPost = [...response.data.messages, ...allPosts];
                SetAllPosts([...new Map(totalPost.map(item => [item.id, item])).values()]);

                let totalMembers = [...response.data.members, ...membersList];
                SetMembersList(totalMembers);
            } else {
                SetNoNewPost(true);
            }
            SetShowLoader(false);
        });
    });

    const GetNewPost = useCallback(() => {
        axios.get(`${ApiServices("getChatMessagesWithLimit")}${props.id}&currentuserid=${userInfo.childId}&limitfrom=${0}&limitnum=${(newPosts.length + 50)}`).then((response) => {
            if (response.data.messages && response.data.messages.length > 0) {
                let totalPost = [...response.data.messages];
                SetNewPosts([...new Map(totalPost.map(item => [item.id, item])).values()]);

                // props.recentMessage([...new Map(totalPost.map(item => [item.id, item])).values()])
                let totalMembers = [...response.data.members, ...membersList];
                props.recentMessage([...new Map(totalMembers.map(item => [item.id, item])).values()]);
            } else {
                SetNoNewPost(true);
            }
            SetShowLoader(false);
        });
    });

    useEffect(() => {
        let localVar = JSON.parse(localStorage.getItem("childToken"))
        if (localVar && localVar.id && localVar != "") {
            SetUserInfo(localVar)
        }
    }, [])

    useEffect(() => {
        interval = setInterval(() => {
            if (userInfo && userInfo.id && userInfo != "") {
                GetNewPost()
            }
        }, 2000);

        return () => {
            clearInterval(interval);
        };
    }, [search.value, GetNewPost])

    useEffect(() => {
        if (allPosts && allPosts.length > 0 && limit >= 0) {
            setTimeout(() => {
                if (messageRef.current) {
                    messageRef.current.scrollIntoView();
                }
            }, 0);
        }
    }, [allPosts, limit]);

    const textMessageHandler = (event) => {
        SetTextMessage(escape(event.target.value));
    };

    const SendMessageHandler = () => {
        SendMessage();
    };

    const sendMessageOnKeyUP = (e) => {
        if (e.key === "Enter") {
            SetTextMessage(escape(e.target.value));
            SendMessageHandler();
        }
    };
    const SendMessage = () => {
        if (
            textMessage !== null &&
            textMessage !== undefined &&
            textMessage !== "" &&
            textMessage.length > 0
        ) {
            axios
                .post(
                    `${ApiServices("sendChatMessage")}wstoken=${userInfo.token}&conversationid=${props.id}&messages[0][text]=${textMessage}&messages[0][textformat]=1`
                )
                .then((response) => {
                    SetTextMessage("");
                    GetNewPost();
                    console.log(messageRef.current);
                    console.log(newmessageRef.current);
                });
        }
    };

    let allUsers = [];
    allUsers = allPosts?.filter(
        (v, i, a) => a.findIndex((t) => t.userid === v.userid) === i
    );

    const { observe, unobserve, inView, scrollDirection, entry } = useInView({
        threshold: 0.25, // Default is 0
        onEnter: ({ scrollDirection, entry, observe, unobserve }) => {
            // Triggered when the target enters the viewport
            GetAllPost();
            clearInterval(interval);
        },
        onLeave: ({ scrollDirection, entry, observe, unobserve }) => {
            // Triggered when the target leaves the viewport
            SetLimit(10);
        },
    });

    const { observeNewMessage, inViewNewMessage } = useInView({
        threshold: 0.25, // Default is 0
        onEnter: ({ scrollDirection, entry, observe, unobserve }) => {
            // Triggered when the target enters the viewport
            SetShowMessageCount(false);
        }
    });

    const moveToNewMessage = () => {
        if (newmessageRef.current) {
            newmessageRef.current.scrollIntoView();
            let totalPost = [...newPosts, ...allPosts];
            SetAllPosts([...new Map(totalPost.map(item => [item.id, item])).values()]);
            SetNewPosts([]);
            // props.recentMessage([...new Map(totalPost.map(item => [item.id, item])).values()]);
        }
    }

    let filteredNewPost = [];
    if (allPosts && allPosts[0]?.id) {
        let allPostPosition = newPosts.find(post => post.id === allPosts[0].id);
        if (allPostPosition && allPostPosition.id) {
            let newPostPosition = newPosts.findIndex(post => post.id === allPostPosition.id);

            const filteredAllPost = newPosts.filter((post, i) => {
                return i > newPostPosition
            });

            filteredNewPost = differenceBy(filteredAllPost, allPosts, 'id');
        }
    } else {
        filteredNewPost = newPosts;
    }

    return (
        <>
            {/* <InputContainer
                type="search"
                name="search"
                labelName=""
                labelClass="d-none"
                className=""
                inputClass="bg-transparent search-thread"
                placeholder="Search discussion thread"
                onKeyUp={SearchHandler}
                error={search.error}
                errorText={search.errorText}
            /> */}
            <div className="message-section">
                <div ref={observe}>{(inView && allPosts && !showLoader && !noNewPost && allPosts.length > 6) ? <LoaderDots /> : ""}</div>
                {(allPosts && !showLoader) ?
                    allPosts.length > 0 && allPosts.map((post, index) => {
                        if (post.text) {
                            post.userInfo = (membersList && membersList.length > 0) ? membersList.find((member) => member.id == post.useridfrom) : {};
                            return (
                                <>
                                    {post.id == lastPost.id ? <div ref={messageRef} className="messageRef"/> : ""}
                                    <div
                                        key={index}
                                        className={`discussion-message-container d-flex ${post.useridfrom == userInfo.childId
                                            ? "discussion-message-container-align-right"
                                            : ""
                                            }`}
                                    >
                                        <div className="discussion-image">
                                            {post.userInfo.isonline && <FontAwesomeIcon
                                                icon={faCircle}
                                                className="active-icon"
                                            />
                                            }
                                            <img
                                                src={(post.userInfo && post.userInfo.profileimageurlsmall) ? post.userInfo.profileimageurlsmall : ChildImage}
                                                alt="Children Profile Picture"
                                                className="discussion-child-profile-picture"
                                            />
                                        </div>
                                        <div className="discussion-item-content">
                                            <p className="discussion-item-title">
                                                {post.userInfo.fullname}
                                            </p>
                                            <p className="discussion-item-description" dangerouslySetInnerHTML={{ __html: post.text }}></p>
                                        </div>
                                    </div>
                                </>
                            );
                        }
                    })
                    : <Loader show={true} />}

                {(filteredNewPost.length > 0 && filteredNewPost.map((post, index) => {
                    if (post.text) {
                        post.userInfo = (membersList && membersList.length > 0) ? membersList.find((member) => member.id == post.useridfrom) : {};
                        return (
                            <>
                                <div
                                    key={index}
                                    className={`discussion-message-container d-flex ${post.useridfrom == userInfo.childId
                                        ? "discussion-message-container-align-right"
                                        : ""
                                        }`}
                                >
                                    <div className="discussion-image">
                                        <FontAwesomeIcon
                                            icon={faCircle}
                                            className="active-icon"
                                        />
                                        <img
                                            src={(post.userInfo && post.userInfo.profileimageurlsmall) ? post.userInfo.profileimageurlsmall : ChildImage}
                                            alt="Children Profile Picture"
                                            className="discussion-child-profile-picture"
                                        />
                                    </div>
                                    <div className="discussion-item-content">
                                        <p className="discussion-item-title">
                                            {post.userInfo.fullname}
                                        </p>
                                        <p className="discussion-item-description" dangerouslySetInnerHTML={{ __html: post.text }}></p>
                                    </div>
                                </div>
                                {(index + 1) == filteredNewPost.length ? <div ref={newmessageRef} className="newmessageRef"/> : ""}
                            </>
                        )
                    }
                }))}
            </div>
            <div className={`new-message-indicator ${(((allPosts && allPosts.length > 5) || (filteredNewPost && filteredNewPost.length > 5)) && filteredNewPost.length > 0) ? "d-block" : "d-none"}`} onClick={() => { moveToNewMessage() }}>
                <div>
                    <FontAwesomeIcon icon={faChevronCircleDown} className="notification-icon" />
                    <p className="notification-count">{filteredNewPost.length}</p>
                </div>
            </div>
            <div className="discussion-update-container position-relative">
                <textarea
                    placeholder="Type Your Reply Here"
                    className="discussion-textarea"
                    value={textMessage}
                    onChange={textMessageHandler}
                    onKeyUp={sendMessageOnKeyUP}
                ></textarea>
                <img
                    onClick={() => {
                        SendMessageHandler();
                    }}
                    src={SendIcon}
                    srcSet={`${SendIcon} 1x, ${SendIcon2X} 2x, ${SendIcon3X} 3x`}
                    alt="Logo"
                    className="logo send-icon"
                />
            </div>
        </>
    )
}

export default Messages;