
import { useRef, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import CountUp from "react-countup";
import useInView from "react-cool-inview";
import VisibilitySensor from 'react-visibility-sensor';


import AOS from "aos";
import "aos/dist/aos.css";

import Event from "./Event";

// Common
import HomeHeader from "./HomeHeader";
import FooterGreen from "../../common/footer/footer";

import YellowWaves from "./images/yellow-waves.svg";
import FlyingKids from "./images/flying-kids.svg";

// Images
import Child1 from "./images/child-1.png";
import Child12X from "./images/child-1@2x.png";
import Child13X from "./images/child-1@3x.png";
import Child2 from "./images/child-2.png";
import Child22X from "./images/child-2@2x.png";
import Child23X from "./images/child-2@3x.png";
import Child3 from "./images/child-3.png";
import Child32X from "./images/child-3@2x.png";
import Child33X from "./images/child-3@3x.png";
import Child4 from "./images/child-4.png";
import Child42X from "./images/child-4@2x.png";
import Child43X from "./images/child-4@3x.png";
import Parent1 from "./images/parent-1.webp";
import Parent12X from "./images/parent-1@2x.webp";
import Parent13X from "./images/parent-1@3x.webp";
import Parent2 from "./images/parent-2.webp";
import Parent22X from "./images/parent-2@2x.webp";
import Parent23X from "./images/parent-2@3x.webp";
import Parent3 from "./images/parent-3.webp";
import Parent32X from "./images/parent-3@2x.webp";
import Parent33X from "./images/parent-3@3x.webp";
import Parent4 from "./images/parent-4.webp";
import Parent42X from "./images/parent-4@2x.webp";
import Parent43X from "./images/parent-4@3x.webp";
import Toper1 from "./images/toper-1.webp";
import Toper12X from "./images/toper-1@2x.webp";
import Toper13X from "./images/toper-1@3x.webp";
import Toper2 from "./images/toper-2.webp";
import Toper22X from "./images/toper-2@2x.webp";
import Toper23X from "./images/toper-2@3x.webp";
import Toper3 from "./images/toper-3.webp";
import Toper32X from "./images/toper-3@2x.webp";
import Toper33X from "./images/toper-3@3x.webp";
import Toper4 from "./images/toper-4.webp";
import Toper42X from "./images/toper-4@2x.webp";
import Toper43X from "./images/toper-4@3x.webp";
import Exclamation from "./images/exclamation.webp";
import Exclamation2X from "./images/exclamation@2x.webp";
import Exclamation3X from "./images/exclamation@3x.webp";
import InfoBulb from "./images/info-bulb.svg";
import GreyWave from "./images/grey-waves.svg";
import GreenWave from "./images/green-wave.svg";
import ToperBackground1 from "./images/toper-background-1.svg";
import ToperBackground2 from "./images/toper-background-2.svg";
import EventsImage from "./images/events.svg";
import Book from "./images/book.svg";
import Play from "./images/play.svg";
import Lens from "./images/lens.svg";

import Temp from "../../images/profile-picture-temp.svg";
import Temp2X from "../../images/profile-temp@2x.webp";
import Temp3X from "../../images/profile-temp@3x.webp";

import volunteer1 from "./images/grade-6.svg";
import volunteer2 from "./images/grade-7.svg";
import volunteer3 from "./images/grade-8.svg";
import volunteer4 from "./images/grade-9.svg";


// CSS
import "./index.css";

// Swiper

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/bundle";
import "swiper/swiper.min.css";
import "swiper/swiper-bundle.css";

// // import Swiper core and required modules
import SwiperCore, { Navigation, Autoplay } from "swiper";

// // install Swiper modules
SwiperCore.use([Navigation, Autoplay]);

const Home = () => {
  const history = useHistory();
  const myRef = useRef(null);

  useEffect(() => {
    AOS.init({ disable: 'mobile' });
    AOS.refresh();
  }, []);

  const executeScroll = () =>
    myRef.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });

  const goToSignUp = () => {
    history.push("./signup");
  };

  const goToVolunteer = () => {
    history.push("./volunteer");
  };

  const { observe, inView } = useInView();

  return (
    <>
      <HomeHeader />
      <div className="col-12 p-0">
        <section className="home-section heading-section home-first-section d-flex">
          <div className="heading-content w-50 position-relative">
            <p
              className="content-start"
              data-aos="fade-right"
              data-aos-duration="500"
              data-aos-easing="ease-in-sine"
            >
              Give Your Child
            </p>
            <p
              className="content-end"
              data-aos="fade-right"
              data-aos-duration="500"
              data-aos-easing="ease-in-sine"
            >
              Wings To Fly
            </p>
            <p
              className="secondary-content"
              data-aos="fade-right"
              data-aos-duration="500"
              data-aos-easing="ease-in-sine"
            >
              Education is the foundation for a better future and the key to
              success. The power of knowledge is the roots and wings of one's
              life; Roots to stand firm in an unstable world; Wings to soar to
              great heights. Our learning zone unleashes your child's potential
              and caters to their dreams to fly in the world of knowledge.{" "}
            </p>
            <button
              className="heading-button"
              onClick={executeScroll}
              data-aos="fade-right"
              data-aos-duration="500"
              data-aos-easing="ease-in-sine"
            >
              <div className="d-flex">
                <p className="button-text">KNOW MORE</p>
                <svg viewBox="0 0 300 100">
                  <defs>
                    <marker
                      id="arrowhead"
                      markerWidth="10"
                      markerHeight="8"
                      refX="0"
                      refY="3.5"
                      orient="auto"
                    >
                      <polygon
                        className="heading-button-polygon"
                        points="0 0, 7 3.5, 0 7"
                      ></polygon>
                    </marker>
                  </defs>
                  <line
                    x1="90"
                    y1="-70"
                    x2="90"
                    y2="70"
                    stroke="#fff"
                    strokeWidth="15"
                    markerEnd="url(#arrowhead)"
                  ></line>
                </svg>
              </div>
            </button>
            <img src={Book} className="d-none d-sm-none d-md-none d-lg-none d-xl-block book-icon" />
            <img src={Lens} className="d-none d-sm-none d-md-none d-lg-none d-xl-block lens-icon" />
            <img src={Play} className="d-none d-sm-none d-md-none d-lg-none d-xl-block play-icon" />
          </div>
          <div
            className="d-none d-sm-none d-md-none d-lg-none d-xl-block heading-image w-50"
            data-aos="fade-up-left"
            data-aos-duration="1000"
          >
            <img src={FlyingKids} alt="Flying Kids" className="home-image" />
          </div>
        </section>
        <section className="research-section" ref={myRef}>
          <svg
            viewBox="0 0 100 100"
            preserveAspectRatio="none"
            className="research-top-design"
          >
            <polygon fill="#fff" points="0,100 0,0 110,0" />
          </svg>
          <div className="col-md-12 d-flex research-container">
            <div className="col-12 col-sm-12 col-md-12 col-xl-5">
              <div className="research-box-1">
                <p className="research-heading">Learn with fun!</p>
                <p className="research-description">
                  We believe that learning will be easier when we add fun to it.
                  Children love to play and we knew it. That’s why Shivani academy
                  adheres to game-based learning. We teach concepts with the help
                  of games.
                </p>
                <p className="research-description">
                  Moreover, we add gamification to level up the student’s
                  motivation to learn. Students gain rewards, points while
                  completing each worksheet and quiz. Students can evaluate their
                  progress after completing the chapters.
                </p>
              </div>
              <div className="research-box-2">
                <p className="research-heading">Digital technology learning.</p>
                <p className="research-description">
                  Technology and learning go hand in hand nowadays. Thus, Shivani
                  academy intends to collaborate the learning with digital
                  technology to enhance their learning experience.
                </p>
                <p className="research-description">
                  We have developed study materials and other resources virtually
                  which can be easily accessible to children. Our user-friendly
                  portal makes the child learn without much difficulty.
                </p>
              </div>
              <div className="">
                <button className="research-button" onClick={goToSignUp}>
                  <div className="d-flex">
                    <p className="button-text">I'm interested</p>
                    <svg viewBox="0 0 450 100">
                      <defs>
                        <marker
                          id="learnarrowhead"
                          markerWidth="10"
                          markerHeight="7"
                          refX="0"
                          refY="3.5"
                          orient="auto"
                        >
                          <polygon
                            className="research-button-polygon"
                            points="0 0, 10 3.5, 0 7"
                          ></polygon>
                        </marker>
                      </defs>
                      <line
                        x1="0"
                        y1="50"
                        x2="350"
                        y2="50"
                        stroke="#094b3e"
                        strokeWidth="7"
                        markerEnd="url(#learnarrowhead)"
                      ></line>
                    </svg>
                  </div>
                </button>
              </div>
            </div>
            <div className="d-none d-sm-none d-md-none d-lg-flex col-xl-7">
              <img
                src={InfoBulb}
                alt="Information Material"
                className="info-material"
              />
            </div>
          </div>
          <div className="green-wave-container">
            <img
              src={GreenWave}
              alt="Green Wave"
              className="green-background-1"
            />
          </div>
        </section>
        <Event />
        <section className="testimonial-section">
          <p className="testimonial-heading">
            They felt we are <br /> <span className="green-text">correct</span>
          </p>
          <p className="testimonial-description">
            See testimonials from our parents, teachers, and students and know
            what they love about us and why they say Shivani Academy is one of the
            best educational portals.
          </p>
          <div className="feedback-swiper">
            <Swiper
              breakpoints={{
                1335: {
                  slidesPerView: 4,
                  slidesPerGroup: 4,
                },
                1000: {
                  slidesPerView: 3,
                  slidesPerGroup: 3
                },
                800: {
                  slidesPerView: 2,
                  slidesPerGroup: 2
                },
                100: {
                  slidesPerView: 1,
                  slidesPerGroup: 1
                },
              }}
              spaceBetween={10}
              loop={true}
              loopFillGroupWithBlank={true}
              navigation={true}
              className="testimonial-swiper"
              speed={5000}
              autoplay={{ delay: 5000 }}
            >
              <SwiperSlide key="1">
                <div className="parent-testimonial-container text-center">
                  <div className="text-center">
                    <img
                      src={Temp}
                      alt="Child 1"
                      className="home-page-parent"
                    />
                  </div>
                  <p className="testimonial-parent-info">Banumathy Y, parent</p>
                  <div className="parent-feedback-container">
                    <img
                      src={Exclamation}
                      srcSet={`${Exclamation} 1x, ${Exclamation2X} 2x, ${Exclamation3X} 3x`}
                      alt="Child 1"
                      className="home-page-exclamation"
                    />
                    <p className="parent-feedback">
                      As a parent, when I went through the materials, I found them
                      to be amazingly simple and interesting. I would introduce
                      this to my children for sure. They would really love to
                      learn from your portal as it is more interactive.{" "}
                    </p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide key="2">
                <div className="parent-testimonial-container text-center">
                  <div className="text-center">
                    <img
                      src={Temp}
                      alt="Child 1"
                      className="home-page-parent"
                    />
                  </div>
                  <p className="testimonial-parent-info">
                    Prachi Bhattacharya, teacher
                  </p>
                  <div className="parent-feedback-container">
                    <img
                      src={Exclamation}
                      srcSet={`${Exclamation} 1x, ${Exclamation2X} 2x, ${Exclamation3X} 3x`}
                      alt="Child 1"
                      className="home-page-exclamation"
                    />
                    <p className="parent-feedback">
                      Innovation! It is what I found here. All these materials
                      would really be helpful to students. The materials are
                      designed in such a way that any level of student can behold
                      it. This would enhance students to enact more on their
                      studies.{" "}
                    </p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide key="3">
                <div className="parent-testimonial-container text-center">
                  <div className="text-center">
                    <img
                      src={Temp}

                      alt="Child 1"
                      className="home-page-parent"
                    />
                  </div>
                  <p className="testimonial-parent-info">Sathya K, parent</p>
                  <div className="parent-feedback-container">
                    <img
                      src={Exclamation}
                      srcSet={`${Exclamation} 1x, ${Exclamation2X} 2x, ${Exclamation3X} 3x`}
                      alt="Child 1"
                      className="home-page-exclamation"
                    />
                    <p className="parent-feedback">
                      The study materials provided here are so inventive. They are
                      so up-to-date and in an advanced kind of mode. Learning with
                      these things would never hit my child to feel boredom.
                      Because it takes the children through undefined sets of
                      materials.{" "}
                    </p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide key="4">
                <div className="parent-testimonial-container text-center">
                  <div className="text-center">
                    <img
                      src={Temp}

                      alt="Child 1"
                      className="home-page-parent"
                    />
                  </div>
                  <p className="testimonial-parent-info">
                    Sherin Thomas, teacher
                  </p>
                  <div className="parent-feedback-container">
                    <img
                      src={Exclamation}
                      srcSet={`${Exclamation} 1x, ${Exclamation2X} 2x, ${Exclamation3X} 3x`}
                      alt="Child 1"
                      className="home-page-exclamation"
                    />
                    <p className="parent-feedback">
                      Contrivance! This would bridge the gap between the students’
                      hard work and their studies. This improves the readings of
                      the students in accordance with their Intelligence and
                      develops them to achieve their goals.{" "}
                    </p>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </section>
        <section className="count-section">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="10 10 40 50"
            preserveAspectRatio="none"
            className="count-curve"
          >
            <path
              d="M 10 10 C 5 10, 35 30, 60 5"
              stroke="transparent"
              fill="#fafafa"
            />
          </svg>
          <div className="count-content">
            <p className="count-heading">
              Count our <br />
              <span className="green-text">performance </span>
            </p>
            <p className="count-description">
              Our well experienced teachers provide high quality content from
              lower grade to higher grade for English, Maths and Science covering
              NCERT and State board syllabus. To analyse the taught concept, we
              have interactive sessions and quiz's. To engage the students and
              help them improve their life skills, creativity and develop their
              various hobby, or passions into a work profile, we come up with
              various challenging and exciting activities.
            </p>
          </div>
          <div className="d-flex col-md-10 statistics-container">
            <div className="w-20">
              <div className="statistics-number">
                <div className="container" ref={observe}>
                  <div className={inView ? "fade-in" : ""}>
                    <CountUp end={3} duration={1} redraw={true}>
                      {({ countUpRef }) => (
                        <span ref={countUpRef} />
                      )}
                    </CountUp>
                  </div>
                </div>
              </div>
              <p className="statistics-description">Boards</p>
            </div>
            <div className="w-20">
              <div className="statistics-number">
                <div className="container" ref={observe}>
                  <div className={inView ? "fade-in" : ""}>
                    <CountUp end={30} duration={1} redraw={true}>
                      {({ countUpRef }) => (
                        <span ref={countUpRef} />
                      )}
                    </CountUp>
                  </div>
                </div>
              </div>
              <p className="statistics-description">Teachers</p>
            </div>
            <div className="w-20">
              <div className="statistics-number">
                <div className="container" ref={observe}>
                  <div className={inView ? "fade-in" : ""}>
                    <CountUp end={48} duration={1} redraw={true}>
                      {({ countUpRef }) => (
                        <span ref={countUpRef} />
                      )}
                    </CountUp>
                  </div>
                </div>
              </div>
              <p className="statistics-description">Subjects</p>
            </div>
            <div className="w-20">
              <div className="statistics-number">
                <div className="container" ref={observe}>
                  <div className={inView ? "fade-in" : ""}>
                    <CountUp end={614} duration={1} redraw={true}>
                      {({ countUpRef }) => (
                        <span ref={countUpRef} />
                      )}
                    </CountUp>
                  </div>
                </div>
              </div>
              <p className="statistics-description">Topics</p>
            </div>
            <div className="w-20">
              <div className="statistics-number">
                <div className="container" ref={observe}>
                  <div className={inView ? "fade-in" : ""}>
                    <CountUp end={214} duration={1} redraw={true}>
                      {({ countUpRef }) => (
                        <span ref={countUpRef} />
                      )}
                    </CountUp>
                  </div>
                </div>
              </div>
              <p className="statistics-description">Activities</p>
            </div>
          </div>
        </section>
        <section className="join-us-section">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="10 10 40 50"
            preserveAspectRatio="none"
            className="display-small count-curve"
          >
            <path
              d="M 10 10 C 5 10, 35 30, 60 5"
              stroke="transparent"
              fill="#fafafa"
            />
          </svg>
          <h1 className="join-us-heading">Join us</h1>
          <p className="join-us-content">
            Are you a visionary? Then you would be the best fit for our community.
            What if this opportunity could change your world! We at Shivani
            Academy invite you to join us in this collaborative effort to upskill
            the potential of our children and nurture the team. Let us join our
            efforts towards building a strong foundation of the next generation
          </p>
          <div className="row volunteer-container">
            <div className="col-md-3">
              <div className="volunteer-item">
                <div className="col-md-4 p-0">
                  <img src={volunteer1} className="" />
                </div>
                <div className="col-md-8 volunteer-item-name">
                  <p>Student Mentorship</p>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="volunteer-item">
                <div className="col-md-4 p-0">
                  <img src={volunteer2} className="" />
                </div>
                <div className="col-md-8 volunteer-item-name">
                  <p>Teacher Mentorship</p>
                </div>

              </div>
            </div>
            <div className="col-md-3">
              <div className="volunteer-item">
                <div className="col-md-4 p-0">
                  <img src={volunteer3} className="" />
                </div>
                <div className="col-md-8 volunteer-item-name">
                  <p>Volunteer</p>
                </div>

              </div>
            </div>
            <div className="col-md-3">
              <div className="volunteer-item">
                <div className="col-md-4 p-0">
                  <img src={volunteer4} className="" />
                </div>
                <div className="col-md-8 volunteer-item-name">
                  <p>Scholarship</p>
                </div>

              </div>
            </div>
          </div>
        </section>
      </div>

      <FooterGreen />
    </>
  );
};

export default Home;
