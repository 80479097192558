import { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt, faPlus } from "@fortawesome/free-solid-svg-icons";
import VideoJS from "../../common/player/VideoJS";

import "./ActivityUpload.css";

import ActivityHeader from "./ActivityHeader";
import ActivityCard from "./ActivityCard";
import GetSubjectChapters from "../../data/gradeList";
import SubjectHeader from "../common/subjectHeader.jsx";

import activityData from "../../data/activity.js";

import video from "../../images/001.mp4";

const ActivityUpload = () => {
  const [gradeId, SetGradeId] = useState([]);
  const [showAddRow, SetAddRow] = useState(true);
  const [activityVideo, SetActivityVideo] = useState("");
  const [activityPicture, SetActivityPicture] = useState({
    img1: "",
    img2: "",
    img3: "",
    img4: "",
    img5: "",
  });

  const navigationChapterGroup = () => {};
  useEffect(() => {
    SetGradeId(GetSubjectChapters("1"));
  }, []);

  const ActivityUploadHandler = (e) => {
    e.preventDefault();
  };

  const UploadImageHandler = (event, indexId) => {
    let ImageName = `img${event.target.id}`;
    SetActivityPicture({
      ...activityPicture,
      [ImageName]: URL.createObjectURL(event.target.files[0]),
    });
  };

  const DeleteImageHandler = (id) => {
    let ImageName = `img${id}`;
    SetActivityPicture({
      ...activityPicture,
      [ImageName]: "",
    });
  };

  const AddNewRowHandler = () => {
    SetActivityPicture({
      ...activityPicture,
      img6: "",
      img7: "",
      img8: "",
      img9: "",
      img10: "",
    });
    SetAddRow(false);
  };

  const UploadVideoHandler = (event) => {
    console.log(event.target.files[0].size);
    SetActivityVideo(URL.createObjectURL(event.target.files[0]));
  };

  const DeleteVideoHandler = () => {
    SetActivityVideo("");
  };

  const playerRef = useRef(null);

  const videoJsOptions = {
    // lookup the options in the docs for more options
    autoplay: false,
    controls: true,
    responsive: true,
    fluid: false,
    sources: [
      {
        src: video,
        type: "video/mp4",
      },
    ],
  };

  const handlePlayerReady = (player) => {
    playerRef.current = player;

    // you can handle player events here
    player.on("waiting", () => {
      console.log("player is waiting");
    });

    player.on("dispose", () => {
      console.log("player will dispose");
    });
  };

  return (
    <div className="course-container">
      <SubjectHeader
        subjectList={gradeId}
        navigationChapterGroup={navigationChapterGroup}
        headerColour="secondary-header-background"
        profileColor="orange"
      />

      <div className="page-container secondary-background pl-20">
        <ActivityHeader
          name="Oscar wild - Happy princes"
          subject="English"
          postedDate="27-Aug-2021"
        />
        <form onSubmit={ActivityUploadHandler}>
          <div className="pl-30">
            {activityData.map((data) => {
              if (data.id == "1") {
                return (
                  <ActivityCard
                    {...data}
                    upload_activity={true}
                    className="w-100 m-0"
                  />
                );
              }
            })}
            <div className="w-100 upload-header">
              <div className="activity-date">
                Upload images of your activity
              </div>
              <div className="date-underline"></div>
            </div>
            <div className="upload-instruction">
              Image should .PNG, .JPG extensions. Image size should be maximum
              1MB size
            </div>
            <div className="col-md-12 upload-container-all p-0">
              {Object.keys(activityPicture).map(function (picture, index) {
                return (
                  <div className="upload-container">
                    {activityPicture[picture] && (
                      <>
                        <FontAwesomeIcon
                          icon={faTrashAlt}
                          className="preview-delete"
                          onClick={() => {
                            DeleteImageHandler(index + 1);
                          }}
                        />
                        <img
                          src={activityPicture[picture]}
                          alt={`Activity Image ${index + 1}`}
                          className="preview-image"
                        />
                      </>
                    )}
                    <span className="upload-label">Click to upload image</span>
                    <input
                      type="file"
                      name={`upload-${index + 1}`}
                      id={`${index + 1}`}
                      className="upload-input"
                      accept="image/*"
                      onChange={(event) => {
                        UploadImageHandler(event, index + 1);
                      }}
                    />
                  </div>
                );
              })}
            </div>
            {showAddRow && (
              <div className="w-100 h-30">
                <button className="add-row" onClick={AddNewRowHandler}>
                  <FontAwesomeIcon icon={faPlus} className="add-row-icon" />
                  <p className="m-auto">Add row</p>
                </button>
              </div>
            )}
            <div className="w-100 upload-header">
              <div className="activity-date">Upload video of your activity</div>
              <div className="date-underline"></div>
            </div>
            <div className="upload-instruction">
              Video should .MP4 extensions. Video size should be maximum 5MB
              size
            </div>
            <div className="col-md-12 upload-container-all p-0">
              <div className="upload-container w-35">
                {activityVideo !== "" && (
                  <>
                    <FontAwesomeIcon
                      icon={faTrashAlt}
                      className="preview-delete"
                      onClick={() => {
                        DeleteVideoHandler("1");
                      }}
                    />
                    {/* <video className="preview-video" controls>
                      <source src={activityVideo} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video> */}
                    <VideoJS
                      options={videoJsOptions}
                      className="preview-video"
                      onReady={handlePlayerReady}
                    />
                  </>
                )}
                {activityVideo === "" && (
                  <>
                    <span className="upload-label">Click to upload video</span>
                    <input
                      type="file"
                      name="upload-video"
                      className="upload-input"
                      accept="video/mp4"
                      onChange={(event) => {
                        UploadVideoHandler(event);
                      }}
                    />
                  </>
                )}
              </div>
            </div>
            <div className="w-100 upload-header">
              <div className="activity-date">Write about your activity</div>
              <div className="date-underline"></div>
            </div>
            <textarea
              name="activity-describe"
              id="activity-describe"
              className="activity-describe"
              placeholder="Describe about your activity"
            ></textarea>
          </div>
        </form>
        <div className="text-center">
          <button className="activity-upload">Upload activity</button>
          <button className="activity-cancel">Cancel</button>
        </div>
      </div>
    </div>
  );
};

export default ActivityUpload;
