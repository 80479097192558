import React, { useEffect, useState } from "react";

import Footer from "../../common/footer";

import HomeHeader from "../home/HomeHeader";

import classes from "../authentication/Signup.module.css";
import Spinner from "../../images/spinner.svg";

import { GetListByID } from "../../data/subscription";
import { useParams, useHistory } from "react-router";
const SuccessPage = () => {
  const history = useHistory();
  let childInfo = { ...JSON.parse(localStorage.getItem("child")) };
  let childSession = { ...JSON.parse(sessionStorage.getItem("child")) };
  const param = useParams();

  const [showLoader, SetShowLoader] = useState(false);
  const [subscription, setSubscription] = useState([]);

  useEffect(() => {
    setSubscription(GetListByID(param.id)[0]);
  }, [param]);

  useEffect(() => {
    if (childSession == null) {
      history.replace("/signup");
    }
  }, [history, childSession]);

  const OnSubmitHandler = async () => {
    SetShowLoader(true)
    sessionStorage.removeItem("child");
    sessionStorage.removeItem("childToken");
    localStorage.removeItem("child");
    localStorage.removeItem("childToken");

    history.push("/login");
    SetShowLoader(false)
  }

  return (
    <>
      {showLoader && (
        <img className={classes.loader} src={Spinner} alt="Loading" />
      )}
      <HomeHeader />
      {subscription && (
        <div className="success-page">
          <h3>Payment received successfully</h3>
          <div className="success-wrapper">
            <span className="size-20 bolder-text">{subscription.name}</span>
            <span className="size-16 plan-duration">
              {subscription.duration}
            </span>
            <span className="size-20 bolder-text plan-price">
              ₹ {subscription.price}
            </span>
          </div>
          <div className="success-wrapper student-info w-auto">
            <div>
              <span className="size-20 bolder-text">{`${childInfo.firstname} ${childInfo.lastname}`}</span>
              <span className="size-16 bolder-text plan-duration">
                {childInfo.grade.toLowerCase().search("grade") >= 0 ? childInfo.grade : `Grade ${childInfo.grade}`}
              </span>
              <div className="hint-text">
                Use your student user name & password to enter student portal
              </div>
            </div>
            <button onClick={OnSubmitHandler}>
              Login
            </button>
          </div>
        </div>
      )}

      <Footer />
    </>
  );
};

export default SuccessPage;
