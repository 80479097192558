import Moment from "moment";
import "./ActivityCard.css";
import ActivitySubmitted from "../../images/date-submitted.svg";
import Rewards from "../../images/rewards.svg";

const ActivityCard = (props) => {
  return (
    <div className="activity-card" style={{ backgroundColor: "#d7effe" }}>
      <div className="d-flex">
        <p className="w-50 subject ml-0">Activity in {props.subject}</p>
        <p className="w-50 posted-on">
          Posted on {Moment(props.postedDate).format("DD/MM/YYYY")}
        </p>
      </div>
      <div className="">
        <p className="activity-heading m-0">{props.activityName}</p>
        <p className="activity-description">{props.activityDescription}</p>
      </div>
      <div className="d-flex reward-container">
        <img src={Rewards} alt="reward" className="reward-icon" />
        <div className="reward-info">
          Earned {props.activityPoints}{" "}
          {props.activityPoints == "1" ? "point" : "points"}
        </div>
      </div>
      <div className="d-flex reward-container">
        <img src={ActivitySubmitted} alt="reward" className="reward-icon" />
        <div className="reward-info reward-date">
          Submitted on or before{" "}
          {Moment(props.submittedDate).format("DD/MM/YYYY")}
        </div>
      </div>
      <button className="activity-participate">I like to participate</button>
    </div>
  );
};

export default ActivityCard;
