import { useHistory } from "react-router-dom";
import Progressbar from "../../common/progressbar/ProgressBar";
// Images
import Child1 from "../home/images/child-1.png";
import Child12X from "../home/images/child-1@2x.png";
import Child13X from "../home/images/child-1@3x.png";

const ChildCard = (props) => {
  const history = useHistory();

  const RedirectionHandler = (props) => {
    if (props.redirection) {
      history.push("/parent/mywards/" + props.childId);
    }
  };
  return (
    <div
      className="child-container"
      onClick={() => {
        RedirectionHandler(props);
      }}
    >
      <p className="child-last-login">Last login - 30-SEP-2021 - 8:24 PM</p>
      <div className="child-details-container">
        <div className="col-md-4 d-flex">
          <img
            src={Child1}
            srcSet={`${Child1} 1x, ${Child12X} 2x, ${Child13X} 3x`}
            alt="Child 1"
            className="child-logo"
          />
          <div className="child-specific">
            <p className="child-name">{`${props.firstname} ${props.lastname}`}</p>
            <p className="child-username">{props.username}</p>
            <p className="mt-5-px child-board">Board - {props.board}</p>
            <p className="child-grade">Grade - {props.grade}</p>
            {/* <p className="mt-5-px child-member-since">
              Member since 12-AUG-2021
            </p> */}
            <p className="child-member-since">Subscription - Free Plan</p>
            <p className="child-plan-expiration">Expires in 200 days</p>
          </div>
        </div>
        <div className="col-md-4 child-duration-container">
          <p className="child-hours-spend">
            Total hours spent till date - 45 hours
          </p>
          <p className="child-hours-breakdown mt-15-px">
            Average time spent per day - 2 hours
          </p>
          <p className="child-hours-breakdown">
            Average time spent per week - 2 hours
          </p>
          <p className="child-hours-breakdown">
            Average time spent per month - 2 hours
          </p>
        </div>
        <div className="col-md-3 actions-container">
          <p className="action-header">Recent actions</p>
          <p className="action-date">30-Sep-2021</p>
          <p className="action-chapter">English - 10th chapter</p>
          <p className="action-chapter">Maths - 10th chapter</p>
          <p className="action-chapter">Science - 10th chapter</p>
        </div>
      </div>
      {props.redirection && (
        <div className="child-progress-container">
          <p className="progress-header">Learning Phase</p>
          <Progressbar
            bgcolor="#0d6d22"
            progress="2"
            showImage={true}
            showCount={false}
          />
        </div>
      )}
    </div>
  );
};

export default ChildCard;
