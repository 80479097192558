import Moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import "./ActivityCard.css";
import ActivitySubmitted from "../../images/date-submitted.svg";
import Rewards from "../../images/rewards.svg";

const ActivityCompletedCard = (props) => {
  return (
    <div
      className={`activity-card ${props.className} ${
        props.completed_activity ? "d-flex" : ""
      }`}
      style={{ backgroundColor: "#fff" }}
    >
      {props.completed_activity && (
        <div className="d-flex">
          <div>
            <img
              src={props.dp}
              alt="Profile Picture"
              className={`activity-profile increased-width`}
            />
          </div>
          <div className={`activity-person activity-person-new`}>
            <p className="activity-by">{props.username}</p>
            <p className="activity-from ">{props.destination}</p>
            <p className="activity-grade">{props.grade}</p>
            {props.status === "completed" && (
              <p className="activity-status">
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  className="complete-icon"
                />
                Assignment Completed
              </p>
            )}
          </div>
        </div>
      )}
      <div className="ml-auto">
        <div
          className={`reward-container ${
            props.individual_activity ? "ml-70" : ""
          } ${props.upload_activity ? "upload-credits" : "d-flex"}`}
        >
          <img src={Rewards} alt="reward" className="reward-icon" />
          <div className="reward-info f-600">
            Earned {props.point} {props.point == "1" ? "point" : "points"}
          </div>
        </div>
        <div
          className={`reward-container ${
            props.individual_activity ? "ml-70" : ""
          }  ${props.upload_activity ? "upload-credits" : "d-flex"}`}
        >
          <img src={ActivitySubmitted} alt="reward" className="reward-icon" />
          <div className="reward-info reward-date f-600">
            Submitted on {Moment(props.submittedDate).format("DD/MM/YYYY")}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActivityCompletedCard;
